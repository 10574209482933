import { replace } from 'connected-react-router';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { addPassword } from '../../actions/profile';
import { PasswordInput } from '../../components/Inputs';
import useAvatarSettings from '../../core/useAvatarSettings';
import { MetricsEvents, Routes } from '../../types/enums';
import useForm from '../../utils/useForm';
import useLogEventFirstRender from '../../utils/useLogEventFirstRender';
import {
  AuthForm,
  AuthFormInner,
  SubmitButton,
  SubmitError,
} from './AuthLayout';
import { accountPasswordAlreadyAddedAtom } from './atoms';

type FieldValues = {
  newPassword: string;
  newPassword2: string;
};

export default function SignupYourPassword() {
  const dispatch = useDispatch();

  const {
    handleSubmit,
    setServerError,
    resetField,
    isSubmitting,
    firstError,
    register,
  } = useForm<FieldValues>({
    defaultValues: {
      newPassword: undefined,
      newPassword2: undefined,
    },
  });

  useLogEventFirstRender(MetricsEvents.OnboardingCreatePassword);

  useAvatarSettings({
    hidden: true,
    cameraSlot: 'desktop_onboarding',
    baseBundleSet: 'onboarding',
  });

  // Handle refresh page.
  const [passwordAlreadyAdded, setPasswordAlreadyAdded] = useAtom(
    accountPasswordAlreadyAddedAtom,
  );

  const redirectToChoosePlatform = () => {
    dispatch(replace(Routes.SignupChoosePlatform));
  };

  useEffect(() => {
    if (passwordAlreadyAdded) {
      redirectToChoosePlatform();
    }
  }, [passwordAlreadyAdded]);

  const handleFormSubmit = handleSubmit(
    async ({ newPassword }: FieldValues) => {
      try {
        await dispatch(addPassword(newPassword));

        resetField('newPassword', { defaultValue: undefined });
        resetField('newPassword2', { defaultValue: undefined });

        setPasswordAlreadyAdded(true);
        redirectToChoosePlatform();
      } catch (e: any) {
        setServerError(e);
      }

      return;
    },
  );

  return (
    <StyledAuthForm onSubmit={handleFormSubmit}>
      <PasswordInput
        {...register('newPassword', {
          required: 'Please enter password',
          validate: (value) => {
            return (
              value.length >= 8 ||
              'Please enter a longer password (8 characters or more)'
            );
          },
        })}
        labeled
        id="new-password"
        autoComplete="new-password"
        data-testid="change-password-new-password-input"
        placeholder="Password"
      />
      <PasswordInput
        {...register('newPassword2', {
          required: 'Please confirm password',
          validate: (value, values) => {
            return value === values.newPassword || 'Passwords do not match';
          },
        })}
        labeled
        id="new-password2"
        autoComplete="new-password"
        data-testid="change-password-new-password2-input"
        placeholder="Confirm password"
      />
      <SubmitError error={firstError?.message || null} />
      <SubmitButton showSpinner={isSubmitting}>Continue</SubmitButton>
    </StyledAuthForm>
  );
}

const StyledAuthForm = styled(AuthForm)`
  align-items: center;

  ${AuthFormInner} {
    width: 100%;
  }
`;
