import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components/macro';

import { getCategoryTreeNode } from '../../actions/store';
import { ReactComponent as CheckIcon } from '../../icons/SmallCheck.svg';
import {
  CategoryTree,
  DialogVariationItem,
  StoreCustomizationItem,
  StoreItem,
  StoreVariationItem,
  isColorVariation,
} from '../../types/models';
import getPriceLabel from '../../utils/getPriceLabel';
import { CardGridItem, CardGridItemLink } from '../CardGrid';
import { PriceIcon } from '../legacy/WalletIcons';

type Props = {
  item: StoreItem | null;
  className?: string;
  standalone?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  to?: string;
  tabIndex?: number;
  size?: number;
  checked?: boolean;
  onRemove?: () => void;
  noFocusWithin?: boolean;
  variationId?: string;
  claimed?: boolean;
  noPriceBadge?: boolean;
  selectedVariationIndex?: number;
};

const SIZES = [
  {
    minSize: 150,
    fontSize: 18,
    lineHeight: 22,
    priceSize: 13,
    borderRadius: 16,
    padding: '12px 16px',
  },
  {
    minSize: 120,
    fontSize: 18,
    lineHeight: 22,
    priceSize: 12,
    borderRadius: 16,
    padding: '10px 12px',
  },
  {
    minSize: 0,
    fontSize: 13,
    lineHeight: 15,
    priceSize: 11,
    borderRadius: 10,
    padding: '8px 10px 6px',
  },
] as const;

function isDialogVariationItem(
  item: StoreVariationItem,
): item is DialogVariationItem {
  return item.hasOwnProperty('enabled');
}

function showItemTitle(item: StoreItem) {
  return (
    item.variation_type === 'Dialog' || item.variation_type === 'ChatSkill'
  );
}

function hasVariations(item: StoreItem): item is StoreCustomizationItem {
  return (item as StoreCustomizationItem).variations !== undefined;
}

function LegacyStoreItemCard({
  className,
  item,
  onClick,
  to,
  standalone,
  tabIndex,
  size = 152,
  checked,
  onRemove,
  noFocusWithin,
  variationId,
  claimed,
  noPriceBadge,
}: Props) {
  if (!item) {
    return <CardGridItem legacy size={152} />;
  }

  const itemVariations = hasVariations(item) ? item.variations : [];

  const variation = itemVariations.find(
    (variation) => variation.id === variationId,
  );
  const itemBought = variation
    ? variation.bought_count > 0
    : itemVariations.some((variation) => variation.bought_count > 0);

  const sizes = SIZES.find((s) => size > s.minSize)!;

  const priceBadge = noPriceBadge ? null : (
    <LegacyPriceBadge
      itemBought={itemBought}
      itemSize={size}
      price={item.price}
    />
  );

  const label = itemBought ? '' : `${item.title}, ${getPriceLabel(item.price)}`;

  let disabled = false;
  switch (item.variation_type) {
    case 'Dialog':
      disabled =
        itemBought &&
        !claimed &&
        !(variation && isDialogVariationItem(variation) && variation.enabled);
      break;
    default:
      disabled = false;
      break;
  }

  const title = size > 100 && showItemTitle(item) ? item.title : '';

  const colorBadge =
    variation && isColorVariation(variation) ? (
      <ColorBadge $color={variation.color} />
    ) : null;

  return (
    <StoreItemCardRoot
      id={'store-item-' + item.id}
      key={item.id}
      className={className}
      backgroundImageUrl={item.icon_url}
      standalone={standalone}
      size={size}
      $sizes={sizes}
      disabled={disabled}
      $checked={checked}
      stretchContent
      onRemove={onRemove}
      noFocusWithin={noFocusWithin}
    >
      {onClick || to ? (
        <CardGridItemLink
          tabIndex={tabIndex}
          onClick={onClick}
          to={to}
          aria-label={label}
          aria-checked={checked}
        >
          {title}
        </CardGridItemLink>
      ) : (
        <Title role="text" aria-label={label}>
          {title}
        </Title>
      )}
      {priceBadge}
      {colorBadge}
    </StoreItemCardRoot>
  );
}

export default LegacyStoreItemCard;

const Title = styled.span`
  font-family: ${(p) => p.theme.fonts.display};
`;

const StoreItemCardRoot = styled(CardGridItem)<{
  $checked?: boolean;
  $sizes: {
    minSize: number;
    fontSize: number;
    lineHeight: number;
    priceSize: number;
    borderRadius: number;
    padding: string;
  };
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  transition: opacity 0.3s ease-out;
  font-size: ${(p) => p.$sizes.fontSize}px;
  border-radius: ${(p) => p.$sizes.borderRadius}px;
  color: #222;

  background-clip: content-box;

  ${(p) =>
    !p.$checked
      ? ''
      : css`
          box-shadow:
            0 0 0 2px ${(p) => p.theme.chatBgColor},
            0 0 0 3px ${(p) => p.theme.cardGridItemSelectedBorderColor};

          &:focus-within {
            box-shadow:
              0 0 0 2px ${(p) => p.theme.chatBgColor},
              0 0 0 3px ${(p) => p.theme.cardGridItemSelectedBorderColor},
              0 0 2px 4px ${(p) => p.theme.outlineColor};
          }
        `}

  & > div {
    padding: ${(p) => p.$sizes.padding};
  }

  & ${CardGridItemLink}, & ${Title} {
    line-height: ${(p) => p.$sizes.lineHeight}px;
    opacity: ${(p) => (p.disabled ? 0.3 : 1)};
    transition: opacity 0.3s ease-out;
    font-family: ${(p) => p.theme.fonts.display};
  }
`;

const ColorBadge = styled.div<{ $color: string }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${(p) => '#' + p.$color};
  position: absolute;
  right: 5px;
  bottom: 5px;
`;

function LegacyPriceBadge({
  itemSize,
  itemBought,
  price,
}: {
  itemSize: number;
  itemBought: boolean;
  price: StoreItem['price'];
}) {
  const sizes = SIZES.find((s) => itemSize > s.minSize)!;

  if (itemBought) {
    return (
      <LegacyPriceBadgeRoot aria-hidden="true" $fontSize={sizes.priceSize}>
        <StyledCheckIcon />
      </LegacyPriceBadgeRoot>
    );
  }

  return (
    <LegacyPriceBadgeRoot aria-hidden="true" $fontSize={sizes.priceSize}>
      <StyledPriceIcon price={price} />
      {price.amount}
    </LegacyPriceBadgeRoot>
  );
}

const LegacyPriceBadgeRoot = styled.div<{ $fontSize: number }>`
  background: ${(p) => p.theme.priceTagBgColor};
  color: ${(p) => p.theme.fgColor};
  border-radius: 8px;
  font-size: ${(p) => p.$fontSize}px;
  padding: 1px 4px;
  height: 16px;
  display: flex;
  align-items: center;
  margin-left: -5px;
  box-shadow: 0px 0px 3px ${(p) => p.theme.shadowColor};
`;

const StyledPriceIcon = styled(PriceIcon)`
  width: 9px;
  margin-right: 3px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  color: ${(p) => p.theme.successFgColor};
`;

const StoreCategoryContext = React.createContext<{
  requestCategoryTree: (categoryKey: string) => void;
  storeCategories: { [categoryKey: string]: CategoryTree } | null;
}>({
  requestCategoryTree: () => {},
  storeCategories: null,
});

/**
 * Makes sure each category tree is requested only once
 */
export function StoreCategoryProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const dispatch = useDispatch();

  const statuses = React.useRef<{
    [categoryKey: string]: 'idle' | 'requested' | 'received';
  }>({});

  const storeCategories = useSelector((state) => state.store.storeCategories);

  const requestCategoryTree = React.useCallback(
    (categoryKey: string) => {
      if (
        statuses.current[categoryKey] !== 'requested' &&
        statuses.current[categoryKey] !== 'received'
      ) {
        statuses.current[categoryKey] = 'requested';

        dispatch(getCategoryTreeNode(categoryKey)).then(() => {
          statuses.current[categoryKey] = 'received';
        });
      }
    },
    [dispatch],
  );

  return (
    <StoreCategoryContext.Provider
      value={{ requestCategoryTree, storeCategories }}
    >
      {children}
    </StoreCategoryContext.Provider>
  );
}
