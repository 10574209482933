import { replace } from 'connected-react-router';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { setOnboardingStatus } from '../../actions/signup';
import { getCategoryTreeNode } from '../../actions/store';
import { AccentButton, Routes } from '../../components/Buttons';
import CircleSpinner from '../../components/CircleSpinner';
import SlidingTabs from '../../components/SlidingTabs';
import StoreCategoryGrid from '../../components/StoreCategoryGrid';
import {
  aggregatedAvatarAtom,
  avatarProgressAtom,
  currentAvatarAtom,
} from '../../core/atoms';
import useAvatarSettings from '../../core/useAvatarSettings';
import {
  CategoryTreeNode,
  CustomizationVariationItem,
} from '../../types/models';
import { ApiError } from '../../utils/apiError';
import useApi from '../../utils/useApi';
import useSubmitStatus from '../../utils/useSubmitStatus';
import { AuthFormWithAvatar, SubmitButton } from './AuthLayout';
import {
  Customization,
  avatarIdAtom,
  customizationAtom,
  interestsAtom,
  mergeVariations,
} from './atoms';
import useCompleteSignup from './useCompleteSignup';

function findCategoryByKey(
  key: keyof Customization,
  category: CategoryTreeNode,
): CategoryTreeNode | null {
  if (category.key?.toLowerCase() === key.toLowerCase()) return category;

  if (category.children) {
    for (let cat of category.children) {
      let found = findCategoryByKey(key, cat);

      if (found) return found;
    }
  }

  return null;
}

const TAB_LABELS = {
  hair: 'Hair',
  skin: 'Skin',
  eye: 'Eyes',
};

type Tab = keyof Customization;

export default function SignupReplikaLook() {
  const dispatch = useDispatch();

  const completeSignup = useCompleteSignup();

  const avatarId = useAtomValue(avatarIdAtom);
  const variations = useAtomValue(aggregatedAvatarAtom).variations;
  const interests = useAtomValue(interestsAtom);

  const submitStatus = useSubmitStatus();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!avatarId) return;

    try {
      submitStatus.setStatus('submitting');

      await completeSignup({
        avatarId,
        activeVariations: variations ?? [],
        interests,
      });

      submitStatus.setStatus('success');
    } catch (e) {
      submitStatus.setStatus('error', e as Error);
      console.error(e);
      if (e instanceof ApiError) {
        // logEvent(MetricsEvents.ReplikaNameError, { error: e.technicalMessage });
      }
    }

    dispatch(replace(Routes.SignupSubscription));
    dispatch(setOnboardingStatus('subscription'));
  };

  const [tab, setTab] = useState<Tab>('hair');

  const getCategory = useCallback(
    () => getCategoryTreeNode('customization'),
    [],
  );

  const rootCategory = useApi(
    (state) => state.store.storeCategories['customization'] ?? null,
    getCategory,
  );

  const tabCategory = useMemo(
    () => rootCategory && findCategoryByKey(tab, rootCategory),
    [tab, rootCategory],
  );

  const [cameraSlot, setCameraSlot] = useState<
    'desktop_onboarding' | 'desktop_onboarding_face'
  >('desktop_onboarding_face');

  useAvatarSettings({
    hidden: false,
    background: 'onboarding',
    cameraSlot,
    baseBundleSet: 'onboarding',
  });

  const avatarProgress = useAtomValue(avatarProgressAtom);

  if (!tabCategory || avatarProgress < 1) {
    return (
      <StyledAuthForm>
        <StyledCircleSpinner lineWidth={30} />
      </StyledAuthForm>
    );
  }

  return (
    <StyledAuthForm
      onSubmit={handleSubmit}
      innerStyle={{ overflow: 'initial' }}
    >
      {tabCategory && <SignupReplikaLookTab category={tabCategory} tab={tab} />}

      <StyledSlidingTabs>
        <SlidingTabs.Container
          tabs={['hair', 'skin', 'eye']}
          selected={tab}
          onSelect={(newTab) => {
            if (newTab === tab) return;
            if (newTab === 'skin') {
              setCameraSlot('desktop_onboarding');
            } else {
              setCameraSlot('desktop_onboarding_face');
            }
            setTab(newTab);
          }}
          getLabel={(tab) => TAB_LABELS[tab]}
        />
      </StyledSlidingTabs>
      <SubmitButton type="submit" showSpinner={submitStatus.isSubmitting()}>
        Continue
      </SubmitButton>
    </StyledAuthForm>
  );
}

const StyledCircleSpinner = styled(CircleSpinner)`
  margin: 0 auto;
  width: 150px;
  height: 150px;
`;

function SignupReplikaLookTab({
  category,
  tab,
}: {
  category: CategoryTreeNode;
  tab: Tab;
}) {
  const avatarId = useAtomValue(avatarIdAtom);

  const [customization, setCustomization] = useAtom(customizationAtom);
  const [avatar, setAvatar] = useAtom(currentAvatarAtom);

  const selectedVariations = useMemo(() => {
    return [customization.eye, customization.hair, customization.skin].filter(
      Boolean,
    );
  }, [customization]);

  return (
    <StyledStoreCategoryGrid
      onlyFreeItems
      itemCount={16}
      key={category.id}
      category={category}
      avatarId={avatarId}
      selectedVariations={selectedVariations}
      onVariationsChange={(variations) => {
        const variation = (variations as CustomizationVariationItem[]).find(
          (v) =>
            tab === 'skin'
              ? v.unity_category === 'style'
              : tab === 'eye'
                ? v.unity_category === 'eyes'
                : v.unity_category === tab,
        );
        const newCustomization = {
          ...customization,
          [tab]: variation,
        };
        setCustomization(newCustomization);
        setAvatar({
          ...avatar,
          variations: mergeVariations(
            newCustomization,
            avatar.variations ?? [],
          ),
        });
      }}
    />
  );
}

const StyledAuthForm = styled(AuthFormWithAvatar)`
  position: relative;
  align-items: center;

  & > ${AccentButton} {
    width: 100%;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    margin-left: min(50%, 100vw - 600px);
  }
`;

const StyledSlidingTabs = styled(SlidingTabs.Root)`
  max-width: 100vw;
  align-self: stretch;
  margin-inline: 15px;
` as typeof SlidingTabs.Root;

const StyledStoreCategoryGrid = styled(StoreCategoryGrid)`
  flex: 1 1 auto;

  @media ${(p) => p.theme.breakpoints.tablet} {
    min-width: 586px;
  }
`;
