import styled from 'styled-components/macro';

import mobileMedia from '../../../utils/mobileMedia';
import { useVoice } from '../../AudioController/hooks/useVoice';
import {
  Dialog,
  DialogButton,
  DialogFooter,
  DialogHeader,
} from '../../DialogLayout/legacy/DialogLayout';

type Props = {
  onClose: () => void;
};

/**
 * @deprecated Use Dialogs.Confirmation
 */
const VoiceCallConfirmationDialog = ({ onClose }: Props) => {
  const { onVoiceCall } = useVoice(onClose);

  return (
    <StyledDialog
      data-testid="voice-call-confirmation-dialog"
      mobileLayout="dialog"
    >
      <DialogHeader
        onClose={onClose}
        title={
          'You have a conversation in progress. Do you want to call Replika instead?'
        }
      />
      <StyledDialogFooter>
        <StyledDialogButton onClick={onClose}>Cancel</StyledDialogButton>
        <DialogButton onClick={onVoiceCall}>Call</DialogButton>
      </StyledDialogFooter>
    </StyledDialog>
  );
};

export default VoiceCallConfirmationDialog;

const StyledDialog = styled(Dialog)`
  width: 360px;

  ${mobileMedia`
    width: 320px;
  `};
`;

const StyledDialogFooter = styled(DialogFooter)`
  width: 100%;
  padding: 30px 0 20px;
  justify-content: space-around;

  & > button ~ button {
    margin-left: 16px;
  }
`;

const StyledDialogButton = styled(DialogButton)`
  background: none;
  color: ${(p) => p.theme.fgColor};
`;
