import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { AvatarAtom } from '../../core/types';
import { BotPatch, MorphType } from '../../types/models';
import { FormSlider } from '../Sliders';

const bodyTypeLables: Record<MorphType, string> = {
  arm_top: 'Upper arms',
  arm_bottom: 'Forearms',
  leg_top: 'Thighs',
  leg_bottom: 'Calves and shins',
  chest: 'Chest',
  back_and_stomach: 'Back and hips',
};

const rangesOrder: MorphType[] = [
  'arm_top',
  'chest',
  'arm_bottom',
  'back_and_stomach',
  'leg_top',
  'leg_bottom',
];

const initialBodyType: Record<MorphType, number> = rangesOrder.reduce(
  (acc, type) => ({ ...acc, [type]: 1 }),
  {} as Record<MorphType, number>,
);

export const BodyTypeRange = ({
  bodyTypeParams,
  updateBot,
  avatar,
}: {
  bodyTypeParams?: MorphType[];
  updateBot: (patch: BotPatch) => void;
  avatar: AvatarAtom;
}) => {
  const [bodyType, setBodyType] = useState(avatar.bodyType || initialBodyType);
  const updateType = useCallback(
    (type: MorphType, value: number) => {
      updateBot({
        avatar_v2: {
          body_type: {
            ...bodyType,
            [type]: value,
          },
        },
      });
    },
    [bodyType, updateBot],
  );
  const ranges = useMemo(
    () =>
      bodyTypeParams?.length &&
      rangesOrder
        .filter((type) => bodyTypeParams.indexOf(type) !== -1)
        .map((type) => {
          return (
            <RangeRow key={type}>
              <RangeTitle>{bodyTypeLables[type]}</RangeTitle>
              <RangeSlider>
                <FormSlider
                  step={100}
                  min={1}
                  max={10000}
                  value={[bodyType?.[type] || 0]}
                  onValueChange={([value]) => {
                    bodyType && setBodyType({ ...bodyType, [type]: value });
                    updateType(type as MorphType, value || 0);
                  }}
                />
              </RangeSlider>
            </RangeRow>
          );
        }),
    [bodyType, bodyTypeParams, updateType],
  );

  if (!ranges) return null;

  return <>{ranges}</>;
};

const RangeRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  align-items: center;

  &:last-child {
    margin-bottom: 10px;
  }
`;

const RangeTitle = styled.div`
  flex: 1 1;
  padding-right: 5px;
`;

const RangeSlider = styled.div`
  flex: 3;
`;
