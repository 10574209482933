import styled from 'styled-components/macro';
import { SolidButton } from '../Buttons';

type Props = {
  enabled: ('new' | 'purchased')[];
  filter: 'new' | 'purchased' | null;
  onClick: (filter: 'new' | 'purchased' | null) => void;
};

export default function ItemFilters({ onClick, filter, enabled }: Props) {
  if (!enabled.length) {
    return null;
  }
  return (
    <ItemFiltersRoot>
      {enabled.includes('purchased') && (
        <PurchasedButton
          active={filter === 'purchased'}
          onClick={() => onClick(filter === 'purchased' ? null : 'purchased')}
        >
          <FilterLabel>Purchased</FilterLabel>
        </PurchasedButton>
      )}
      {enabled.includes('new') && (
        <NewButton
          active={filter === 'new'}
          onClick={() => onClick(filter === 'new' ? null : 'new')}
        >
          <FilterLabel>New</FilterLabel>
        </NewButton>
      )}
    </ItemFiltersRoot>
  );
}

const ItemFiltersRoot = styled.div`
  display: flex;
  gap: 5px;
  padding-block: 5px;
`;

const FilterLabel = styled.span``;

const PurchasedButton = styled(SolidButton)`
  height: 40px;
  border-radius: 18px;
  padding: 8px 15px 8px 12px;
  white-space: nowrap;

  &:before {
    content: '';
    display: inline-block;
    vertical-align: -1px;
    margin-right: 6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    opacity: 0.2;
    transition:
      opacity 0.15s ease-out,
      background 0.15s ease-out;
  }

  &[data-active='true'] {
    color: #c1ffc0;
  }

  &[data-active='true']:before {
    opacity: 1;
    border: 0;
    width: 12px;
    height: 12px;
    background: linear-gradient(180.01deg, #e4faa5 0.01%, #0fbf1a 99.99%);
    box-shadow: 0px 0px 5px #acea90;
  }
`;

const NewButton = styled(PurchasedButton)`
  &[data-active='true'] {
    color: #fef978;
  }

  &[data-active='true']:before {
    background: linear-gradient(180.01deg, #fffa79 0.01%, #efcc13 99.99%);
    box-shadow: 0px 0px 6.39175px rgba(255, 250, 121, 0.9);
  }
`;
