import * as React from 'react';

export default function useHorizontalScroll<T extends HTMLElement>(
  dependencyList: any[] = [],
  elRef?: React.RefObject<T>,
  disabled?: boolean,
) {
  const innerRef = React.useRef<T>(null);

  const ref = elRef ?? innerRef;

  const pos = React.useRef({ direction: 1, target: 0 });

  React.useEffect(() => {
    if (!ref.current || disabled) return;

    const el = ref.current;

    const handleWheel = (e: WheelEvent) => {
      if (el.scrollWidth <= el.clientWidth) return;

      if (Math.abs(e.deltaY) > Math.abs(e.deltaX)) {
        e.preventDefault();
        const direction = e.deltaY > 0 ? 1 : -1;

        // For touchpad, ur custom scrolling logic works better
        // with smooth scrolling disabled
        var isTouchPad = e['wheelDeltaY']
          ? e['wheelDeltaY'] === -3 * e.deltaY
          : e.deltaMode === 0;
        if (isTouchPad) {
          el.style.scrollBehavior = 'auto';
        } else {
          el.style.scrollBehavior = 'smooth';
        }

        // Note that mouse scrolling sends constant delta in the event.
        // Touchpad is more nuanced.
        const delta = isTouchPad
          ? e.deltaY
          : e.deltaY > 0
            ? Math.max(40, e.deltaY)
            : Math.min(-40, e.deltaY);
        const t = pos.current;
        let from = el.scrollLeft;
        // In case of smooth scrolling, scrollLeft in not updated immediately,
        // so trying to increment it frequently will result in slow scrolling.
        // We have to remember the target position from previous event and
        // calculate current position relative to it.
        // If the direction was changed, we can forget about previous target.
        if (
          t.direction === direction &&
          t.target * direction > from * direction
        ) {
          from = t.target;
        }
        t.target = Math.max(
          0,
          Math.min(el.scrollWidth - el.clientWidth, from + delta),
        );
        t.direction = direction;
        el.scrollLeft = t.target;
      }
    };

    el.addEventListener('wheel', handleWheel, { capture: true });

    return () => {
      el.removeEventListener('wheel', handleWheel);
    };
    // eslint-disable-next-line local-rules/exhaustive-deps
  }, [disabled, ref.current, ...dependencyList]);

  return ref;
}
