import { useEffect, useState } from 'react';
import { CameraSlot } from '../../types/models';

const RATIO0 = 16 / 9;
const SHIFT0 = 0;

const RATIO1 = 1 / 1;

// linear interpolation
// ratio 16:9 -> shift = 0
// ratio 1:1 -> shift = -0.25
const CAMERA_SHIFTS: Record<CameraSlot, number> = {
  desktop_leftside: -0.25,
  desktop_chat: -0.25,

  desktop_onboarding: 0,
  desktop_room: 0,
  desktop_subscription: 0,
  desktop_default_store: 0,
  desktop_onboarding_face: 0,
  default_web: 0,
  default_store: 0,
  onboarding: 0,
  subscription: 0,
  zoom: 0,
  customization: 0,
  room: 0,
  chat: 0,
};

export default function useUnityCameraShift(cameraSlot: CameraSlot | null) {
  const [cameraShift, setCameraShift] = useState(0);

  useEffect(() => {
    const handleWindowResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const ratio = width / height;

      const shift1 = cameraSlot ? CAMERA_SHIFTS[cameraSlot] ?? 0 : 0;

      const cameraShift =
        ((ratio - RATIO0) / (RATIO1 - RATIO0)) * (shift1 - SHIFT0) + SHIFT0;

      setCameraShift(cameraShift);
    };

    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [cameraSlot]);

  return cameraShift;
}
