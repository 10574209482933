import { ComponentProps, ReactElement, ReactNode } from 'react';
import flattenFragments from '../TransitionRoutes/flattenFragments';
import useRouteTransition from '../TransitionRoutes/useRouteTransition';
import matchModalRoutes from './matchModalRoutes';

type ModalRouteElement = ReactElement<ComponentProps<typeof ModalRoute>>;

export default function ModalRouteMatch({ children }) {
  const { location } = useRouteTransition();
  const truthyChildren = flattenFragments<ModalRouteElement>(children);
  const match = truthyChildren.find((child) => {
    return matchModalRoutes(
      location,
      child.props.path,
      child.props.state,
      child.props.exact,
    );
  });
  return <>{match?.props.children ?? null}</>;
}

type ModalRouteProps = {
  path: string;
  state?: Object;
  children: ReactNode;
  exact?: boolean;
};

export function ModalRoute(props: ModalRouteProps) {
  return null;
}
