import { useLayoutEffect, useRef, useState } from 'react';

export default function LevelUpPopupBg({ className }: { className?: string }) {
  const [width, setWidth] = useState(0);
  const ref = useRef<SVGSVGElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      setWidth(ref.current.clientWidth);
    }
  }, []);

  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="74"
      fill="none"
      viewBox={`0 0 ${width} 74`}
      className={className}
    >
      <g opacity=".5">
        <mask
          id="LevelUpPopupBg_svg__b"
          width={width}
          height="74"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
        >
          <path fill="url(#LevelUpPopupBg_svg__a)" d="M0 0h520v74H0z" />
        </mask>
        <g mask="url(#LevelUpPopupBg_svg__b)">
          <path fill="url(#LevelUpPopupBg_svg__c)" d="M0 0h520v74H0z" />
        </g>
      </g>
      <defs>
        <linearGradient
          id="LevelUpPopupBg_svg__a"
          x1={width}
          x2="0"
          y1="37"
          y2="37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6DED72" stop-opacity="0" />
          <stop offset=".19" stop-color="#6DED72" />
          <stop offset=".82" stop-color="#6DED72" />
          <stop offset="1" stop-color="#6DED72" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="LevelUpPopupBg_svg__c"
          x1={width / 2}
          x2={width / 2}
          y1="74"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6DED72" stop-opacity="0" />
          <stop offset=".495" stop-color="#6DED72" />
          <stop offset="1" stop-color="#6DED72" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
