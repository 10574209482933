import { useLocation } from 'react-router';
import { ModalRoutes } from '../types/enums';

export default function toModalRoute<
  S extends Record<string, string | undefined>,
>(path: ModalRoutes, state?: S) {
  return {
    search: `?modal=${path}`,
    state,
  };
}

export function useModalRoute() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const modalRoute = searchParams.get('modal');

  if (!modalRoute) {
    return null;
  }

  return Object.values(ModalRoutes).includes(modalRoute)
    ? (modalRoute as ModalRoutes)
    : null;
}
