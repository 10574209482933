import styled from 'styled-components/macro';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { IconButton } from '../Buttons';

export default function DialogCloseButton({
  onClose,
  inDialog,
  className,
}: {
  className?: string;
  inDialog?: boolean;
  onClose: () => void;
}) {
  return (
    <DialogCloseButtonRoot
      className={className}
      onClick={onClose}
      type="button"
      label="close popup"
      data-indialog={inDialog}
    >
      <StyledCloseIcon />
    </DialogCloseButtonRoot>
  );
}

const DialogCloseButtonRoot = styled(IconButton)`
  position: absolute;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 14px;
  color: ${(p) => p.theme.fgColor};
  background: ${(p) => p.theme.navigationBgColor};

  & > svg {
    width: 26px;
    height: 26px;
  }

  right: 15px;
  top: 15px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    right: 15px;
    top: 15px;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  display: block;
  width: 16px;
  height: 16px;
`;
