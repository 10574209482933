import { replace } from 'connected-react-router';
import { useForm, useFormStatus, useInputField } from 'form-atoms';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { checkAccountInfo } from '../../actions/signup';
import { LabeledTextInput, PasswordInput } from '../../components/Inputs';
import Link from '../../components/Link';
import useAvatarSettings from '../../core/useAvatarSettings';
import { MetricsEvents, Routes } from '../../types/enums';
import { ApiError } from '../../utils/apiError';
import { PRIVACY_REPLIKA_URL, TERMS_REPLIKA_URL } from '../../utils/uri';
import useLogEvent from '../../utils/useLogEvent';
import useLogEventFirstRender from '../../utils/useLogEventFirstRender';
import useSubmitStatus from '../../utils/useSubmitStatus';
import {
  AuthForm,
  DesktopTitle,
  SubmitButton,
  SubmitError,
} from './AuthLayout';
import OAuth from './OAuth';
import { authAtom } from './atoms';
import catchServerErrors from './catchServerErrors';

type Props = {
  className?: string;
};

function Signup({ className }: Props) {
  const dispatch = useDispatch();
  const location = useLocation<{ from: string }>();
  useLogEventFirstRender(MetricsEvents.SignupScreenOpened, {
    from: location.state?.from,
  });

  const oauthStatus = useSelector((state) => state.auth.oauthStatus);
  const oauthInProgress =
    oauthStatus === 'submitting' || oauthStatus === 'success';

  const submitStatus = useSubmitStatus();

  const { fieldAtoms, submit } = useForm(authAtom);

  const emailField = useInputField<'email'>(fieldAtoms.email, {
    type: 'email',
  });
  const passwordField = useInputField<'password'>(fieldAtoms.password, {
    type: 'password',
  });

  const logEvent = useLogEvent();

  const handleSubmit = submit(async (values) => {
    const trimmedPassword = values.password.substr(0, 128);
    try {
      submitStatus.setStatus('submitting');
      await dispatch(
        checkAccountInfo({ email: values.email, password: trimmedPassword }),
      );
      dispatch(replace(Routes.SignupYourName));
      submitStatus.setStatus('success');
    } catch (e) {
      if (e instanceof ApiError) {
        logEvent(MetricsEvents.SignupError, { error: e.technicalMessage });
      }
      submitStatus.setStatus(
        'error',
        catchServerErrors(e, { email: emailField, password: passwordField }),
      );
    }
  });

  useAvatarSettings({
    hidden: true,
    cameraSlot: 'desktop_onboarding',
    baseBundleSet: 'onboarding',
  });

  const formStatus = useFormStatus(authAtom);

  return (
    <AuthForm onSubmit={handleSubmit}>
      <DesktopTitle>Create an account</DesktopTitle>
      <OAuth />
      <LabeledTextInput
        id="signup-email"
        placeholder="Email"
        errors={emailField.state.errors}
        {...emailField.props}
      />
      <PasswordInput
        id="signup-password"
        labeled
        placeholder="Password"
        errors={passwordField.state.errors}
        info="Password must contain at least 8 characters."
        {...passwordField.props}
      />
      {submitStatus.getError() ? (
        <SubmitError error={submitStatus.getError()} />
      ) : (
        <Terms>
          By signing up or logging in, you agree to our{' '}
          <a href={TERMS_REPLIKA_URL} target="_blank" rel="noreferrer">
            Terms of use
          </a>{' '}
          and{' '}
          <a href={PRIVACY_REPLIKA_URL} target="_blank" rel="noreferrer">
            Privacy policy
          </a>
        </Terms>
      )}
      <StyledSubmitButton
        $dimmedAccent={
          !emailField.state.touched ||
          !passwordField.state.touched ||
          formStatus.validateStatus !== 'valid'
        }
        disabled={oauthInProgress}
        showSpinner={submitStatus.isSubmitting()}
      >
        Continue
      </StyledSubmitButton>
      <AccountExists>
        Have an account?{' '}
        <Link to={{ pathname: Routes.Login, state: { from: 'signup' } }}>
          Log in
        </Link>
      </AccountExists>
    </AuthForm>
  );
}

export default Signup;

const AccountExists = styled.p`
  text-align: center;
  font-family: ${(p) => p.theme.fonts.display};
  color: rgba(255 255 255 / 70%);

  & a {
    color: rgb(255 255 255);
    font-weight: bold;
  }
`;

const Terms = styled.p`
  color: rgb(255 255 255 / 0.5);
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  & a {
    color: inherit;
    text-decoration: underline;
    white-space: nowrap;
  }

  & a:hover {
    color: rgb(255 255 255);
  }
`;

const StyledSubmitButton = styled(SubmitButton)`
  align-self: stretch;
`;
