import { Location } from 'history';
import { matchPath } from 'react-router-dom';

export default function matchPathOrState(
  location: Location,
  pathToMatch: string,
  stateToMatch: Object | undefined,
  exact?: boolean,
) {
  const match = matchPath(location.pathname, { path: pathToMatch, exact });
  if (!match) return null;
  if (!stateToMatch) return match;

  const state = location.state as Object | undefined;
  if (!state) return null;
  return Object.entries(stateToMatch).every(
    ([key, value]) => state[key] === value,
  )
    ? match
    : null;
}
