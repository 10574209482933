import { useEffect, useState } from 'react';
import {
  DeepPartial,
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
  useForm as useReactHookForm,
} from 'react-hook-form';

export default function useForm<T extends FieldValues>({
  defaultValues,
  values,
}: {
  defaultValues: DeepPartial<T>;
  values?: T;
}) {
  const {
    register,
    handleSubmit,
    setError,
    formState,
    clearErrors,
    reset,
    resetField,
    control,
    watch,
    getValues,
    setValue,
    getFieldState,
    unregister,
  } = useReactHookForm<T>({
    mode: 'onTouched',
    defaultValues,
    values,
  });

  const { isDirty, errors, isSubmitting, isSubmitSuccessful } = formState;

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (isDirty) {
      setSuccess(false);
    }
  }, [isDirty]);

  useEffect(() => {
    if (isSubmitting) return;
    if (isSubmitSuccessful) {
      setSuccess(true);
    }
  }, [isSubmitSuccessful, isSubmitting]);

  return {
    isDirty,
    register: ((
      name: FieldPath<T>,
      options?: RegisterOptions<T, FieldPath<T>>,
    ) => {
      const { required, ...rest } = options || {};
      if (required) {
        options = {
          ...rest,
          validate: (value: any, values: any) => {
            if (!value || (typeof value === 'string' && !value.trim())) {
              const res =
                typeof required === 'object' && 'message' in required
                  ? required.message
                  : typeof required === 'boolean'
                    ? 'Field is required'
                    : required;
              return res;
            }
            return typeof rest.validate === 'function'
              ? rest.validate(value, values)
              : undefined;
          },
        };
      }
      return register(name, options);
    }) as UseFormRegister<T>,
    handleSubmit,
    setServerError: (error: any) => {
      if (error.message)
        setError('root', { type: 'custom', message: error.message });
    },
    setError: (name: FieldPath<T> | `root.${string}` | 'root', error: any) => {
      if (error.message)
        setError(name, { type: 'custom', message: error.message });
    },
    clearErrors,
    reset,
    resetField,
    isSubmitSuccessful: success,
    errors,
    firstError: errors.root ?? Object.values(errors)[0],
    isSubmitting,
    control,
    watch,
    getValues,
    setValue,
    getFieldState,
    unregister,
  };
}
