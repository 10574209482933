import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import guid from 'simple-guid';
import styled from 'styled-components/macro';
import { purchaseGems } from '../../actions/store';
import { queueSystemNotification } from '../../actions/ui';
import { AccentButton } from '../../components/Buttons';
import ErrorSuccessMessage from '../../components/ErrorSuccessMessage';
import { StripeControls, StripeForm } from '../../components/Stripe';
import { StripeCardInputRoot } from '../../components/Stripe/StripeCardInput';
import { PaySafeAndSecure } from '../../components/Stripe/StripeControls';
import StripeProvider from '../../components/StripeProvider';
import { GaActions, GaLabels, MetricsEvents, Routes } from '../../types/enums';
import { GemPurchase, StripeSubscriptionStatus } from '../../types/models';
import formatPrice from '../../utils/formatPrice';
import {
  logEvent,
  trackCampaignswellOneTimePaymentEvent,
  trackGaEvent,
} from '../../utils/metrics';
import mobileMedia from '../../utils/mobileMedia';
import processStripePayment from '../../utils/processStripePayment';

type Status = 'init' | 'idle' | 'subscribing' | 'cancelling';

type StripePurchaseBlockProps = {
  item: GemPurchase;
};

function WalletStripeForm({ item }: StripePurchaseBlockProps) {
  const dispatch = useDispatch();
  const source = useLocation<{ source: string }>().state?.source;
  const history = useHistory();
  const [latestInvoiceId, setLatestInvoiceId] = React.useState<string | null>(
    null,
  );
  const [status, setStatus] = React.useState<Status>('init');
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [cardComplete, setCardComplete] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const price = React.useMemo(
    () =>
      typeof item.price === 'number'
        ? { amount: item.price, currency: 'USD' }
        : item.price,
    [item],
  );

  const processPayment = React.useCallback(
    async (paymentMethodId, stripe) => {
      logEvent(MetricsEvents.CurrencyPurchaseButtonTapped, {
        currencyType: 'gems',
        amount: item.gems_count,
      });

      const invoiceId = await processStripePayment({
        paymentMethodId,
        stripe,
        latestInvoiceId,
        priceId: item.product_id,
        dispatch,
        onCreate: (priceId) => {
          const eventId = guid();

          /**
           * GTAG purcahse event
           * @see https://developers.google.com/tag-platform/gtagjs/reference/events#purchase
           */
          trackGaEvent(GaActions.Purchase, {
            label: GaLabels.GemsPurchased,
            value: price.amount,
            currency: price.currency,
            checkout_option: 'stripe',
            transaction_id: eventId,
            items: [
              {
                item_id: item.product_id,
                item_name: 'Gems',
                price: price.amount,
                quantity: item.gems_count,
              },
            ],
          });
          return dispatch(
            purchaseGems(
              priceId,
              paymentMethodId,
              (data: StripeSubscriptionStatus) => {
                trackCampaignswellOneTimePaymentEvent({
                  transaction_id: data?.latest_invoice.id,
                  payment_system: 'Stripe',
                  price: price.amount,
                  currency: price.currency,
                  product_id: item.product_id,
                  quantity: item.gems_count,
                });
              },
            ),
          );
        },
      });

      setLatestInvoiceId(invoiceId);

      return invoiceId;
    },
    [
      latestInvoiceId,
      dispatch,
      item.product_id,
      item.gems_count,
      price.amount,
      price.currency,
    ],
  );

  const onSuccess = () => {
    dispatch(queueSystemNotification('Gems purchased!', 'gem'));
    history.push({
      pathname: Routes.Wallet,
      state: { source },
    });
  };

  const paymentRequestOptions = {
    country: 'US',
    currency:
      typeof item.price === 'number'
        ? 'USD'
        : item.price.currency.toLowerCase(),
    total: {
      label: `${item.gems_count} Replika gems`,
      amount:
        typeof item.price === 'number' ? item.price : item.price.amount * 100,
    },
  };

  const payError = errorMessage || error;

  const showSpinner = status !== 'idle' && status !== 'init';

  return (
    <StripeProvider>
      <StyledStripeForm
        onStatusChange={setStatus}
        onError={setError}
        onSuccess={onSuccess}
        processPayment={processPayment}
        setLatestInvoiceId={setLatestInvoiceId}
      >
        <StyledStripeControls
          onError={setErrorMessage}
          onCardComplete={setCardComplete}
          paymentRequestOptions={paymentRequestOptions}
          processPayment={processPayment}
          placeholder
        />
        {payError && (
          <StyledErrorMeessage hasError>{payError}</StyledErrorMeessage>
        )}
        <BuyButton
          showSpinner={showSpinner}
          type="submit"
          disabled={!cardComplete}
        >
          Buy for {formatPrice(item.price)}
        </BuyButton>
      </StyledStripeForm>
    </StripeProvider>
  );
}

export default WalletStripeForm;

const StyledStripeForm = styled(StripeForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 30px 0 0;
  ${PaySafeAndSecure} {
    width: calc(100% - 32px);

    ${mobileMedia`
      width: 100%;
    `}
  }
  ${StripeCardInputRoot} {
    width: 100%;
    min-width: 400px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 24px;

    ${mobileMedia`
      min-width: auto;
    `}
  }
`;

const StyledStripeControls = styled(StripeControls)`
  ${mobileMedia`
      width: 100%;
  `}
`;

const StyledErrorMeessage = styled(ErrorSuccessMessage)`
  margin: 10px 0;
`;

const BuyButton = styled(AccentButton)`
  margin: 30px 0;
  max-width: 400px;
  transition: width 0.5s ease-in-out;
  width: ${(p) => (p.showSpinner ? '54px' : '100%')};
  ${(p) => p.showSpinner && 'padding: 0;'};

  ${mobileMedia`
      max-width: 100%;
  `}
`;
