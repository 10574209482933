import { Location } from 'history';
import { useContext } from 'react';
import { useLocation } from 'react-router';
import TransitionContext from './TransitionContext';

// Do not use useLocation / useParams inside transitioning routes directly!
// Use this hook instead
export default function useRouteTransition<
  Params extends {} = {},
  State extends {} = {},
>() {
  const defaultLocation = useLocation();
  const { status, timeout, params, location } = useContext(TransitionContext);

  return {
    status,
    timeout,
    params: params as Params,
    location: (location ?? defaultLocation) as Location<State>,
  } as const;
}
