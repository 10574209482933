import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { useLocation } from 'react-router-dom';
import { StripeCardInputRoot } from '../../components/Stripe/StripeCardInput';
import { StripeControlsRoot } from '../../components/Stripe/StripeControls';
import WalletButton from '../../components/WalletButton';
import useGoBack from '../../utils/useGoBack';
import { ModalLayout } from '../modals/ModalScreenLayout';
import WalletItemCard from './WalletItemCard';
import WalletStripeForm from './WalletStripeForm';

function Purchase() {
  const { id } = useLocation<{ id?: string }>().state ?? {};
  const item = useSelector((state) =>
    state.store.persist.gemPurchases.find((item) => item.product_id === id),
  );

  const { goBack } = useGoBack({
    closeOnEscape: true,
  });

  return item ? (
    <ModalLayout.Root layout="right-sidebar">
      <ModalLayout.Header>
        <ModalLayout.HeaderLeft>
          <StyledBackButton onClick={goBack} />
        </ModalLayout.HeaderLeft>
        <ModalLayout.HeaderRight>
          <WalletButton source="store" variant="small" />
          <StyledCloseButton onClick={goBack} />
        </ModalLayout.HeaderRight>
      </ModalLayout.Header>
      <StyledContent>
        <PurchaseSection>
          <PurchaseWrapper>
            <WalletItemCard cardInfo={{ type: 'Gems', item }} standalone />
            <WalletStripeForm item={item} />
            <LegalLink href="https://replika.com/legal/terms" target="_blank">
              View subscription terms
            </LegalLink>
          </PurchaseWrapper>
        </PurchaseSection>
      </StyledContent>
    </ModalLayout.Root>
  ) : null;
}

const PurchaseSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${StripeCardInputRoot} {
    width: 100%;
    min-width: 100%;
  }
  ${StripeControlsRoot} {
    width: 100%;
    flex: 1 1;
  }
`;

const LegalLink = styled.a`
  text-decoration: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  font-family: ${(p) => p.theme.fonts.display};
`;

const PurchaseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledBackButton = styled(ModalLayout.BackButton)`
  width: 34px;
  height: 34px;
  border-radius: 14px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const StyledCloseButton = styled(ModalLayout.CloseButton)`
  width: 34px;
  height: 34px;
  border-radius: 14px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const StyledContent = styled(ModalLayout.Content)`
  justify-content: start;
  padding: 50px 20px 0;
`;

export default Purchase;
