import * as RadixSelect from '@radix-ui/react-select';
import { SwitchProps } from '@radix-ui/react-switch';
import { format, isValid, parseISO } from 'date-fns';
import {
  ComponentPropsWithRef,
  Context,
  ReactNode,
  createContext,
  useContext,
} from 'react';
import {
  Control,
  Controller,
  DeepPartial,
  FieldValues,
  Path,
  UseFormRegister,
  UseFormSetError,
  useFormState,
} from 'react-hook-form';
import styled from 'styled-components/macro';
import { AccentButton, AriaButtonProps } from '../../../components/Buttons';
import DateSelect from '../../../components/DateSelect';
import { DateSelectProps } from '../../../components/DateSelect/DateSelect';
import { FormSelectDropdown } from '../../../components/Form/FormSelect';
import {
  DynamicLabelControl,
  LabeledTextInput,
  PasswordInput,
  TextInput,
} from '../../../components/Inputs';
import * as Select from '../../../components/Select';
import Switch from '../../../components/Switch';
import outlineCss from '../../../components/outlineCss';
import useForm from '../../../utils/useForm';
import { ModalLayout } from '../ModalScreenLayout';

type SettingsContextProps<T extends FieldValues> = {
  register: UseFormRegister<T>;
  isSubmitSuccessful: boolean;
  control: Control<T, any>;
  setError: UseFormSetError<T>;
  clearErrors: () => void;
};

function createSettingsContext<T extends FieldValues>() {
  return createContext<SettingsContextProps<T>>({
    register: () => {
      return {} as any;
    },
    isSubmitSuccessful: false,
    control: {} as any,
    setError: () => {},
    clearErrors: () => {},
  });
}

const SettingsFormContext = createSettingsContext();

function SettingsFormRoot<T extends FieldValues>({
  initialValues,
  onSubmit,
  children,
  submitOnChange,
  fill,
}: {
  initialValues: DeepPartial<T>;
  onSubmit: (data: T) => Promise<T>;
  children: ReactNode;
  submitOnChange?: boolean;
  fill?: boolean;
}) {
  const {
    register,
    handleSubmit,
    setError,
    setServerError,
    clearErrors,
    resetField,
    isSubmitSuccessful,
    control,
  } = useForm<T>({
    defaultValues: initialValues,
  });

  const handleFormSubmit = async (data: T) => {
    try {
      clearErrors();

      const res = await onSubmit(data);

      (Object.keys(data) as Path<T>[]).forEach((key) => {
        resetField(key, {
          defaultValue: res[key as string] ?? undefined,
        });
      });
    } catch (e: any) {
      setServerError(e);
    }
  };

  const Context = SettingsFormContext as Context<SettingsContextProps<T>>;

  const submitHandler = handleSubmit(handleFormSubmit);

  return (
    <Context.Provider
      value={{
        register,
        isSubmitSuccessful,
        control,
        setError,
        clearErrors,
      }}
    >
      <SettingsFormRootRoot
        as="form"
        onSubmit={submitHandler}
        onChange={submitOnChange ? submitHandler : undefined}
        $fill={fill}
      >
        {children}
      </SettingsFormRootRoot>
    </Context.Provider>
  );
}

const SettingsFormRootRoot = styled(ModalLayout.Content)<{ $fill?: boolean }>`
  flex: ${(p) => (p.$fill ? '1 0 auto' : '0 0 auto')};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

function SettingsFormMessage<T extends FieldValues>({
  defaultMessage,
  successMessage = 'Changes saved.',
  ...rest
}: {
  defaultMessage?: ReactNode;
  successMessage?: ReactNode;
} & ComponentPropsWithRef<'div'>) {
  const { isSubmitSuccessful, control } = useContext(
    SettingsFormContext as Context<SettingsContextProps<T>>,
  );
  const { errors } = useFormState({ control });

  const firstError = errors.root ?? Object.values(errors)[0];

  const message = isSubmitSuccessful
    ? successMessage
    : String(firstError?.message ?? '') || defaultMessage;

  return message ? (
    <SettingsFormMessageRoot $hasError={!!firstError} {...rest}>
      {message}
    </SettingsFormMessageRoot>
  ) : null;
}

export const SettingsFormMessageRoot = styled.div<{ $hasError?: boolean }>`
  padding-inline: 10px;
  overflow-x: clip;
  text-align: left;
  font-size: 14px;
  color: ${(p) => (p.$hasError ? '#FE9E98' : '#fff')};
`;

const SettingsFormFields = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SettingsFormFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-block: 10px;
  gap: 20px;
  padding-inline: 10px;
`;

function SettingsFormSubmitButton<T extends FieldValues>(
  props: AriaButtonProps & { hideOnSuccess?: boolean },
) {
  const { control, isSubmitSuccessful } = useContext(
    SettingsFormContext as Context<SettingsContextProps<T>>,
  );
  const { isDirty, isSubmitting } = useFormState({ control });

  if (props.hideOnSuccess && isSubmitSuccessful) {
    return null;
  }

  return (
    <SubmitButton
      showSpinner={isSubmitting}
      disabled={!isDirty}
      type="submit"
      {...props}
    />
  );
}

const SubmitButton = styled(AccentButton)`
  margin-inline: 10px;
`;

function SettingsFormInput<T extends FieldValues>({
  field,
  fieldRequired,
  ...rest
}: ComponentPropsWithRef<typeof LabeledTextInput> & {
  field: Path<T>;
  fieldRequired?: string;
}) {
  const { register, control } = useContext(
    SettingsFormContext as Context<SettingsContextProps<T>>,
  );
  const { errors } = useFormState({ control });

  return (
    <SettingsFormInputRoot
      aria-invalid={!!errors[field]}
      {...register(field, { required: fieldRequired })}
      {...rest}
    />
  );
}

const SettingsFormInputRoot = styled(LabeledTextInput)`
  & > ${TextInput} {
    border-radius: 19px;
  }

  [data-translucentbox='true'] & > ${TextInput} {
    background: rgb(255 255 255 / 10%);
  }
`;

function SettingsFormPasswordInput<T extends FieldValues>({
  field,
  fieldRequired,
  ...rest
}: ComponentPropsWithRef<typeof PasswordInput> & {
  field: Path<T>;
  fieldRequired?: string;
}) {
  const { register, control } = useContext(
    SettingsFormContext as Context<SettingsContextProps<T>>,
  );
  const { errors } = useFormState({ control });

  return (
    <SettingsFormPasswordInputRoot
      aria-invalid={!!errors[field]}
      {...register(field, { required: fieldRequired })}
      {...rest}
    />
  );
}

const SettingsFormPasswordInputRoot = styled(PasswordInput)`
  & ${TextInput} {
    border-radius: 19px;
  }

  [data-translucentbox='true'] & ${TextInput} {
    background: rgb(255 255 255 / 10%);
  }
`;

type SelectOption = {
  value: string;
  label?: string;
};

type SettingsFormSelectProps<T extends FieldValues> =
  RadixSelect.SelectProps & {
    id: string;
    label?: string;
    placeholder?: string;
    field: Path<T>;
    fieldRequired?: string;
    options: SelectOption[];
    className?: string;
    onFocus?: (e: React.FocusEvent<HTMLElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
  };

function SettingsFormSelect<T extends FieldValues>(
  props: SettingsFormSelectProps<T>,
) {
  const {
    field,
    fieldRequired,
    options,
    id,
    placeholder,
    className,
    value,
    onFocus,
    onBlur,
    ...rest
  } = props;
  const { control } = useContext(
    SettingsFormContext as Context<SettingsContextProps<T>>,
  );
  const { errors } = useFormState({ control });

  return (
    <Controller
      name={field}
      control={control}
      rules={{ required: fieldRequired }}
      render={({ field: fieldProps }) => {
        return (
          <DynamicLabelControl
            id={id}
            ref={fieldProps.ref}
            placeholder={placeholder}
            className={className}
            value={fieldProps.value}
            render={({ setHasValue, setFocused, controlRef }) => {
              return (
                <Select.Root
                  value={fieldProps.value}
                  aria-invalid={!!errors[field]}
                  onValueChange={(value) => {
                    setHasValue(value !== '');
                    fieldProps.onChange(value);
                  }}
                  {...rest}
                >
                  <SettingsFormSelectTrigger
                    id={id}
                    onFocus={(e) => {
                      setFocused(true);
                      onFocus?.(e);
                    }}
                    onBlur={(e) => {
                      setFocused(false);
                      fieldProps.onBlur();
                      onBlur?.(e);
                    }}
                  >
                    <Select.Value
                      placeholder={
                        <Select.Placeholder>{placeholder}</Select.Placeholder>
                      }
                    />
                    <Select.Icon>
                      <Select.ArrowDown />
                    </Select.Icon>
                  </SettingsFormSelectTrigger>
                  <Select.Portal>
                    <FormSelectDropdown options={options} />
                  </Select.Portal>
                </Select.Root>
              );
            }}
          />
        );
      }}
    />
  );
}

const SettingsFormSelectTrigger = styled(Select.Trigger)`
  font-family: ${(p) => p.theme.fonts.body};
  height: 54px;
  width: 100%;
  padding: 12px 15px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  border-radius: 19px;

  & > span:first-child {
    transform: translateY(7px);
  }

  &[data-state='open'],
  &:focus-visible:focus {
    position: relative;
    ${outlineCss()}
  }
`;

type SettingsFormDateSelectProps<T extends FieldValues> = DateSelectProps & {
  field: Path<T>;
  fieldRequired?: string | boolean;
};

function SettingsFormDateSelect<T extends FieldValues>(
  props: SettingsFormDateSelectProps<T>,
) {
  const { field, fieldRequired, onChange, ...rest } = props;
  const { control, setError, clearErrors } = useContext(
    SettingsFormContext as Context<SettingsContextProps<T>>,
  );
  const { errors } = useFormState({ control });

  return (
    <Controller
      name={field}
      control={control}
      rules={{ required: fieldRequired }}
      render={({ field: fieldProps }) => {
        return (
          <SettingsFormDateSelectControl
            onChange={(date, isFilled: boolean) => {
              if (!isFilled) return;

              if (date && !isValid(date)) {
                setError(
                  field,
                  new Error('Date does not exist. Enter a valid date'),
                );
              } else {
                clearErrors();
                fieldProps.onChange(
                  date ? format(date, 'yyyy-MM-dd') : undefined,
                );
                onChange?.(date, isFilled);
              }
            }}
            invalid={!!errors[field]}
            defaultDate={
              fieldProps.value != null ? parseISO(fieldProps.value) : undefined
            }
            arrowIcon={<Select.ArrowDown />}
            {...rest}
          />
        );
      }}
    />
  );
}

const SettingsFormDateSelectControl = styled(DateSelect)`
  gap: 10px;

  & [data-datetriggerwrapper='true'] {
    height: 54px;
  }

  & select,
  & button[role='combobox'] {
    padding-block: 25px 9px;
    border-radius: 19px;
    font-family: ${(p) => p.theme.fonts.body};
  }

  [data-translucentbox='true'] & select,
  [data-translucentbox='true'] & button[role='combobox'] {
    background: rgb(255 255 255 / 10%);
  }
`;

// TODO: integrate into form
function SettingsFormSwitch({
  label,
  id,
  ...rest
}: { label: string } & SwitchProps) {
  return (
    <SettingsFormSwitchRoot>
      <SettingsFormLabel htmlFor={id}>{label}</SettingsFormLabel>
      <Switch id={id} {...rest} />
    </SettingsFormSwitchRoot>
  );
}

const SettingsFormSwitchRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 34px;
  padding-inline: 10px;
`;

const SettingsFormLabel = styled.label`
  flex: 1 0 auto;
  font-family: ${(p) => p.theme.fonts.display};
  font-size: 16px;
  line-height: 20px;
`;

const SettingsFormLabelRow = styled(SettingsFormLabel)`
  padding-inline: 10px;
`;

const SettingsFormFill = styled.div`
  flex: 1 0 auto;
`;

const SettingsFormSliderRow = styled.div`
  margin-block: 15px;
  padding-inline: 10px;
`;

export {
  SettingsFormDateSelect as DateSelect,
  SettingsFormFields as Fields,
  SettingsFormFill as Fill,
  SettingsFormFooter as Footer,
  SettingsFormInput as Input,
  SettingsFormLabel as Label,
  SettingsFormLabelRow as LabelRow,
  SettingsFormMessage as Message,
  SettingsFormPasswordInput as PasswordInput,
  SettingsFormRoot as Root,
  SettingsFormSelect as Select,
  SettingsFormSliderRow as SliderRow,
  SettingsFormSubmitButton as SubmitButton,
  SettingsFormSwitch as Switch,
};
