import { useAtom } from 'jotai';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import findCategoryByName from 'src/routes/Store/findCategoryByName';
import { MetricsEvents } from 'src/types/enums';
import useLogEvent from 'src/utils/useLogEvent';
import styled from 'styled-components/macro';
import { updateBot } from '../../actions/profile';
import { basketAtom } from '../../core/atoms';
import StoreCameraSlotContext from '../../routes/Store/StoreCameraSlotContext';
import {
  CameraSlot,
  StoreVoiceCustomizationItem,
  isStoreVoiceItem,
} from '../../types/models';
import { BasketItem } from '../../types/states';
import usePlayAudio from '../AudioController/usePlayAudio';
import { CardGridItemLink } from '../CardGrid';
import { PriceBadge } from '../Cards/StoreItemCard';
import { CustomizationGridItem } from '../CustomizationGrid';
import { useMobileQuery } from '../responsive';
import {
  MobileDialogItemWidth,
  PersonalitySectionHeader,
  StyledCustomizationGrid,
  getGridItemWidth,
} from './StoreCategoryGridSection';
import usePaginatedItems from './usePaginatedItems';
import { flattenSections } from './utils';

const ChangeVoiceGrid = ({ avatarId }: { avatarId?: string }) => {
  const cameraSlot = 'default_store';
  const logEvent = useLogEvent();
  const audioInterface = usePlayAudio();
  const voiceId = useSelector((state) => state.profile.persist.bot?.voice_id);
  const [selectedVoiceId, setSelectedVoiceId] = useState(voiceId || '');

  const handleSelectVoice = useCallback(
    (item) => {
      const { internal_voice_id = '', voice_sample_url = '' } =
        item.variations[0] || {};
      setSelectedVoiceId(internal_voice_id);
      audioInterface.play(voice_sample_url);
      logEvent(MetricsEvents.VoiceSamplePlayed);
    },
    [audioInterface, logEvent],
  );
  const flattenVoiceSections = useSelector((state) => {
    const { customization } = state.store.storeCategories;
    const voiceSections =
      customization && findCategoryByName('Voice', customization);
    return voiceSections && flattenSections(voiceSections);
  });
  const items = usePaginatedItems({
    categoryKey: 'voice',
    avatarId,
  })[0] as StoreVoiceCustomizationItem[];

  const groupedCategoryItems = useMemo(() => {
    return flattenVoiceSections?.map((category) => {
      return {
        title: category.long_name,
        items: items.filter((item) => item.category_id === category.id),
      };
    });
  }, [flattenVoiceSections, items]);

  const isMobile = useMobileQuery();
  const dispatch = useDispatch();
  const setAvatarSettings = useContext(StoreCameraSlotContext);
  const [basket, setBasket] = useAtom(basketAtom);

  const itemWidth = isMobile ? MobileDialogItemWidth : getGridItemWidth(false);
  const itemHeight = itemWidth * 1.25;

  const handleVoiceChange = (item: StoreVoiceCustomizationItem) => {
    const { internal_voice_id = '' } = item.variations[0] || {};
    setAvatarSettings({
      cameraSlot: (isMobile
        ? cameraSlot
        : 'desktop_' + cameraSlot) as CameraSlot,
    });
    const price = item.price.amount;
    const isBought = !!item.variations[0]?.bought_count;

    if (!price || isBought) {
      dispatch(
        updateBot({
          voice_id: internal_voice_id,
        }),
      );
    }

    const [voiceInBasket] =
      basket.filter(({ storeItem }) => isStoreVoiceItem(storeItem)) || [];

    if ((!voiceInBasket && !price) || voiceInBasket?.storeItem.id === item.id) {
      return;
    }

    let newBasket: BasketItem[] = basket.slice();

    if (voiceInBasket) {
      newBasket = basket.filter(
        ({ storeItem }) => !isStoreVoiceItem(storeItem),
      );
    }

    if (price && !isBought) {
      newBasket.push({ storeItem: item });
    }

    setBasket(newBasket);

    return;
  };

  return groupedCategoryItems?.length ? (
    <div>
      <PersonalitySectionHeader>Voice</PersonalitySectionHeader>
      {groupedCategoryItems.map(({ title, items }) => {
        return (
          <div style={{ height: 'auto', position: 'relative' }}>
            <PersonalitySectionHeader
              style={{ fontSize: '14px', lineHeight: 1.2 }}
            >
              {title}
            </PersonalitySectionHeader>
            <StyledCustomizationGrid
              minItemHeight={itemHeight}
              minItemWidth={itemWidth}
              effectInputs={[items]}
            >
              {items.map((item) => {
                const variation = item.variations[0];
                const selected =
                  variation?.internal_voice_id === selectedVoiceId;
                const handleSelect = () => {
                  handleVoiceChange(item);
                  handleSelectVoice(item);
                };
                const handleScrollSelect = () => {
                  if (selected) return;
                  handleSelect();
                };
                return (
                  <StyledCustomizationGridItem
                    itemId={item.id}
                    checked={selected}
                    size={itemWidth}
                    key={item.id}
                    onScrollSelect={handleScrollSelect}
                    onClick={handleSelect}
                    backgroundImageUrl={item.preview_url}
                    name={item.title}
                  >
                    {!!item.price.amount && (
                      <PriceBadge
                        itemBought={!!variation?.bought_count}
                        price={item.price}
                      />
                    )}
                  </StyledCustomizationGridItem>
                );
              })}
            </StyledCustomizationGrid>
            <div />
          </div>
        );
      })}
    </div>
  ) : null;
};

export default ChangeVoiceGrid;

const StyledCustomizationGridItem = styled(CustomizationGridItem)`
  min-height: ${(p) => (p.size ?? 0) * 1.25}px;
  aspect-ratio: 100 / 125;

  & > img {
    width: calc(100% - 16px);
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    bottom: 0;
    top: initial;
  }

  ${CardGridItemLink} {
    width: 100%;
  }
`;
