import * as A from '../types/actions';
import { AsyncActionTypes } from '../types/enums';
import { Media } from '../types/models';
import { DA } from '../types/redux';
import { apiAction } from '../utils/asyncAction';
import fetchOptions from '../utils/fetchOptions';
import { API_BASE_URL } from '../utils/uri';

export const getMedia =
  (mediaType: 'music' = 'music'): DA<Media[]> =>
  async (dispatch, getState) => {
    const params = { mediaType };

    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetMedia>(AsyncActionTypes.GetMedia, dispatch, params, {
      onRequest: () => fetch(`${API_BASE_URL}/media/${mediaType}`, fetchOpts),
    });
  };
