import { useAtomValue } from 'jotai';
import { useSelector } from 'react-redux';
import { autoModeAtom } from '../../core/atoms';
import getWebglVersion from '../../utils/getWebglVersion';
import StaticModeHint from './StaticModeHint';

type AvatarModeControlsProps = {
  className?: string;
  hidePopup?: boolean;
};

export default function AvatarModeControls(props: AvatarModeControlsProps) {
  const incompatibleWebgl = getWebglVersion() !== 2;

  const avatarMode = useSelector((state) => state.profile.persist.avatarMode);
  const autoMode = useAtomValue(autoModeAtom);

  if (incompatibleWebgl) {
    return null;
  }

  if (avatarMode === 'auto' && autoMode === 'static') {
    return <StaticModeHint />;
  }

  return null;
}
