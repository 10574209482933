import commonPart from '../themes/common.json';
import darkPart from '../themes/dark.json';
import defaultPart from '../themes/default.json';
import { Themes } from '../types/enums';
import { Theme } from '../types/theme';

const defaultTheme = { ...commonPart, ...defaultPart };
const darkTheme = { ...commonPart, ...darkPart };

export default function getTheme(name: Themes): Theme {
  switch (name) {
    case Themes.Default:
      return defaultTheme;
    case Themes.Dark:
      return darkTheme;
  }
}
