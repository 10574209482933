import { useForm, useInputField } from 'form-atoms';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPersonalBot } from '../../actions/profile';
import { preSignup, setOnboardingStatus } from '../../actions/signup';
import { authAtom, nameAtom, pronounsAtom } from './atoms';

export default function usePreSignup() {
  const dispatch = useDispatch();

  const { fieldAtoms: authAtoms } = useForm(authAtom);

  const emailField = useInputField<'email'>(authAtoms.email, { type: 'email' });
  const passwordField = useInputField<'password'>(authAtoms.password, {
    type: 'password',
  });
  const nameField = useInputField<'text'>(nameAtom, { type: 'text' });

  const pronoun = useAtomValue(pronounsAtom);

  const authToken = useSelector((state) => state.auth.persist.authToken);

  return useCallback(
    async (recaptchaToken: string) => {
      if (!authToken) {
        await dispatch(
          preSignup({
            email: emailField.props.value,
            user_first_name: nameField.props.value,
            password: passwordField.props.value,
            replika_gender: 'non-binary',
            replika_name: 'Replika',
            pronoun,
            captcha_token: recaptchaToken,
          }),
        );
      }

      dispatch(setOnboardingStatus('postsignup'));

      // We need to get bot id to update bot later in postsignup
      return dispatch(getPersonalBot(true));
    },
    [
      emailField.props.value,
      passwordField.props.value,
      nameField.props.value,
      dispatch,
      authToken,
      pronoun,
    ],
  );
}
