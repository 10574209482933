import useGoBack from '../../../utils/useGoBack';
import useRouteTransition from '../TransitionRoutes/useRouteTransition';

export default function useGoBackModal(fallback?: string) {
  const { location, status } = useRouteTransition();

  const { goBack } = useGoBack({
    fallbackRoute: fallback ? { path: fallback, modal: true } : undefined,
    location,
  });

  const { goBack: closeGoBack, navLevel } = useGoBack({
    anchor: 'modal',
    inTransition: status !== 'entered' && status !== 'unmounted',
    closeOnEscape: true,
    location,
  });

  return { goBack, closeGoBack, navLevel };
}
