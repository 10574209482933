import { getDefaultStore } from 'jotai';
import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { isValidUrl } from 'src/utils/url';
import { PromptEnvironment } from '../../features/AskReplika/models';
import { Themes } from '../../types/enums';
import {
  CustomizationVariationItem,
  PetVariationItem,
  RoomVariationItem,
  StoreCustomizationVariationItem,
} from '../../types/models';
import { AvatarMode } from '../../types/states';
import createLogger from '../../utils/createLogger';
import { getEnvironment } from '../../utils/environment';
import { getSessionSetting } from '../../utils/session';
import { backgroundAtom } from '../atoms';
import { UnityBackground } from '../types';
import { BACKGROUNDS } from './consts';
import { UnityBridges } from './enums';
import { ChangeCameraMode, UnityCommand, UnityInstance } from './types';
import * as uc from './unityCommands';
import * as unityCommands from './unityCommands';

const DISABLE_BATCH = false;

export const DEBUG_LEVEL: number =
  getSessionSetting('debug_level') ??
  (getEnvironment() === 'localhost' ? 3 : 0);

export const unityLogger = createLogger('Unity Debug', {
  isEnabled: () => DEBUG_LEVEL >= 1,
});

export function mockAudioContext() {
  (window as any)['AudioContext'] = function MockAudioContext() {
    return {
      state: 'disabled',
      listener: {
        setPosition() {},
        setOrientation() {},
      },
    };
  };
}

function unitySend(
  unityInstance: UnityInstance,
  messageType: string,
  message: any,
) {
  if (DEBUG_LEVEL >= 1) {
    if (messageType === 'Execute') {
      // eslint-disable-next-line
      unityLogger.debug(
        messageType,
        message.command,
        message.id,
        message.parameters,
      );
    } else if (messageType === 'ExecuteBatch') {
      // eslint-disable-next-line
      unityLogger.debug(
        messageType,
        message.map((p) => p.command),
        message,
      );
    } else {
      // eslint-disable-next-line
      unityLogger.debug(messageType, message);
    }
  }
  // console.log(command);
  // console.log(JSON.stringify(params, null, 2))
  try {
    unityInstance.SendMessage(
      UnityBridges.Main,
      messageType,
      JSON.stringify(message),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export function execute(unityInstance: UnityInstance, message: UnityCommand) {
  unitySend(unityInstance, 'Execute', message);
}

export function executeBatch(
  unityInstance: UnityInstance,
  batch: readonly UnityCommand[],
) {
  try {
    if (DISABLE_BATCH) {
      for (const command of batch) {
        unitySend(unityInstance, 'Execute', command);
      }
    } else {
      unitySend(unityInstance, 'ExecuteBatch', batch);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export function enableDebugLogs(unityInstance: UnityInstance, enable: boolean) {
  unitySend(unityInstance, 'EnableDebugLogs', enable);
}

export function setupMetaJson(unityInstance: UnityInstance, meta: object) {
  unitySend(unityInstance, 'SetupMetaJson', meta);
}

export function getVariations(
  variations: CustomizationVariationItem[] = [],
  roomItems: RoomVariationItem[] = [],
) {
  const items = variations ?? [];
  return [...items, ...roomItems];
}

type UnityBundle = { path: string; bundle_name: string };

export function getBundles(
  baseBundles: UnityBundle[],
  items: (
    | StoreCustomizationVariationItem
    | RoomVariationItem
    | PetVariationItem
  )[] = [],
) {
  const itemBundles = items.reduce(
    (arr, item) => [
      ...arr,
      ...item.web_bundles.map((b) => {
        return { path: b.bundle_url, bundle_name: b.id?.toLowerCase() };
      }),
    ],
    [] as UnityBundle[],
  );

  return uniqBy([...baseBundles, ...itemBundles], (b) => b.bundle_name);
}

const QUALITY_LEVEL = {
  animatedLow: 0,
  animatedMedium: 1,
  animatedHigh: 2,
  static: 2,
};

const FRAME_RATES = {
  animatedLow: 20,
  animatedMedium: 40,
  animatedHigh: -1,
  static: -1,
};

export function setQuality(avatarMode: AvatarMode) {
  const fpsCommand = unityCommands.setFrameRate(FRAME_RATES[avatarMode]);
  const qualityCommand = unityCommands.changeQuality(QUALITY_LEVEL[avatarMode]);

  return [fpsCommand, qualityCommand];
}

export enum EngineStatus {
  Error = -1,
  Initial = 0,
  EngineLoaded = 1, // unity engine loaded and initialized
  BundleReady = 2, // base bundle and item bundles loaded
  AvatarReady = 3, // avatar created, ready for customization
  BodyMorphReady = 4, // body morphs set
  CustomizationReady = 5, // customization and items set
  Greeting = 6,
  Ready = 7, // animation enabled
}

export function useMemoized<T>(
  factory: () => T,
  isEqual: (v1: T, v2: T) => boolean,
): T {
  const [value, setValue] = useState<T>(factory);

  // eslint-disable-next-line local-rules/exhaustive-deps
  useEffect(() => {
    const newValue = factory();
    if (!isEqual(value, newValue)) {
      setValue(newValue);
    }
  });

  return value;
}

export function useDebouncedValue<T>(value: T, delay = 50) {
  const [debouncedValue, setvalue] = useState(value);
  useEffect(() => {
    const to = setTimeout(() => {
      setvalue(value);
    }, delay);

    return () => clearTimeout(to);
  }, [value, delay]);
  return debouncedValue;
}

export function startUnityRoleplay(
  unityInstance: UnityInstance,
  promptEnvironment: PromptEnvironment,
  gender?: 'male' | 'female',
) {
  const {
    unity_info: {
      avatar_items,
      roleplay_id,
      avatar_strip_exclude_categories,
      background_url,
      roleplay_bundle,
    },
  } = promptEnvironment;

  const items = avatar_items[gender ?? 'other'];
  const uniqBundlesMap = new Map();

  items.forEach((current) => {
    current.web_bundles?.forEach(({ id, bundle_url }) => {
      uniqBundlesMap.set(bundle_url, {
        path: bundle_url,
        bundle_name: id,
      });
    });
  });

  if (roleplay_bundle) {
    uniqBundlesMap.set(roleplay_bundle.bundle_url, {
      path: roleplay_bundle.bundle_url,
      bundle_name: roleplay_bundle.id,
    });
  }

  const bundles = Array.from(uniqBundlesMap.values());
  const commands: (UnityCommand | undefined | '')[] = [
    unityCommands.loadBundles(bundles),
    background_url && unityCommands.changeBackground(background_url),
    unityCommands.changeRoleplay(roleplay_id ?? ''),
    unityCommands.strip(avatar_strip_exclude_categories),
    unityCommands.dress(items.map((i) => i.unity_id)),
  ];

  executeBatch(unityInstance, commands.filter((c) => !!c) as UnityCommand[]);

  if (background_url) {
    const store = getDefaultStore();
    store.set(backgroundAtom, background_url);
  }
}

export function stopUnityRoleplay(
  unityInstance: UnityInstance,
  activeVariationsIds: string[],
) {
  executeBatch(unityInstance, [
    unityCommands.changeBackground(null),
    unityCommands.changeRoleplay(null),
    unityCommands.strip(),
    unityCommands.dress(activeVariationsIds),
  ]);
}

export function getBackgroundUrl(
  background: UnityBackground,
  themeName: Themes,
) {
  if (isValidUrl(background)) {
    return background;
  }

  const url = background && BACKGROUNDS[background][themeName];

  return url ? window.location.origin + url : null;
}

export function addChangeCameraSlotCommand(
  queue: UnityCommand[],
  slot: string,
  mode: ChangeCameraMode,
  before: boolean,
) {
  const cameraSlotCommand = uc.changeCameraSlot(slot, mode);

  const backgroundCommand = queue.find(
    (command) =>
      command.command === 'change_background' &&
      command.parameters['path'] === null,
  );

  // if we switch back to room, change camera slot before changing background
  // (so that background doesn't flash)
  if (backgroundCommand) {
    const backgroundCommandIndex = queue.findIndex(
      (c) => c === backgroundCommand,
    );

    if (backgroundCommandIndex !== -1) {
      queue = [...queue];
      queue.splice(backgroundCommandIndex, 0, cameraSlotCommand);
      return queue;
    }
  }

  return before ? [cameraSlotCommand, ...queue] : [...queue, cameraSlotCommand];
}
