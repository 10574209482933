import { useSetAtom } from 'jotai';
import { useLayoutEffect } from 'react';
import { useThemeName } from '../utils/withThemeName';
import {
  avatarBaseBundleSetAtom,
  avatarHiddenAtom,
  backgroundAtom,
  behaviourAtom,
  behaviourStateAtom,
  cameraSlotAtom,
  dayTimeAtom,
  themeNameAtom,
  unityPlaceAtom,
} from './atoms';
import { AvatarSettings } from './types';

// FIXME: probably not worth it.
// Refactor Signup to use AuthLayout with <CanvasView avatarSettings />, similar to FullscreenLayout

export default function useAvatarSettings({
  children,
  cameraSlot,
  background,
  baseBundleSet,
  daytime,
  hidden,
  behaviour,
  behaviourState,
  place,
}: AvatarSettings) {
  const setCameraSlot = useSetAtom(cameraSlotAtom);
  const setBackground = useSetAtom(backgroundAtom);
  const setBaseBundleSet = useSetAtom(avatarBaseBundleSetAtom);
  const setDayTime = useSetAtom(dayTimeAtom);
  const setAvatarHidden = useSetAtom(avatarHiddenAtom);
  const setBehaviour = useSetAtom(behaviourAtom);
  const setBehaviourState = useSetAtom(behaviourStateAtom);
  const setThemeName = useSetAtom(themeNameAtom);
  const setUnityPlace = useSetAtom(unityPlaceAtom);

  const themeName = useThemeName();

  useLayoutEffect(() => {
    if (cameraSlot == null) return;
    setCameraSlot(cameraSlot);
  }, [setCameraSlot, cameraSlot]);

  useLayoutEffect(() => {
    if (background === undefined) return;
    setBackground(background);
  }, [setBackground, background]);

  useLayoutEffect(() => {
    if (baseBundleSet == null) return;
    setBaseBundleSet(baseBundleSet);
  }, [setBaseBundleSet, baseBundleSet]);

  useLayoutEffect(() => {
    if (daytime == null) return;
    setDayTime(daytime);
  }, [setDayTime, daytime]);

  useLayoutEffect(() => {
    if (hidden == null) return;
    setAvatarHidden(hidden);
  }, [setAvatarHidden, hidden]);

  useLayoutEffect(() => {
    if (behaviour === undefined) return;
    setBehaviour(behaviour);
  }, [setBehaviour, behaviour]);

  useLayoutEffect(() => {
    if (behaviourState === undefined) return;
    setBehaviourState(behaviourState);
  }, [setBehaviourState, behaviourState]);

  useLayoutEffect(() => {
    if (themeName === undefined) return;
    setThemeName(themeName);
  }, [setThemeName, themeName]);

  useLayoutEffect(() => {
    setUnityPlace(place ?? null);
  }, [place, setThemeName, themeName]);

  return <>{children}</>;
}
