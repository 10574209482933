import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import {
  getDialogModels,
  getPersonalBot,
  setDialogModel,
} from '../../../actions/profile';
import RadioButtonSelector from '../../../components/RadioButtonSelector';
import useApi from '../../../utils/useApi';
import useSubmitStatus from '../../../utils/useSubmitStatus';
import { SettingsFormMessageRoot } from './SettingsForm';
import * as SettingsLayout from './SettingsLayout';

export function SettingsVersionHistory() {
  const dispatch = useDispatch();

  const activeId = useSelector(
    (state) => state.profile.persist.bot?.dialog_version.id,
  );

  const dialogVersions = useApi(
    (state) => state.profile.dialogVersions ?? [],
    getDialogModels,
    {
      memoDeepEqual: true,
    },
  );

  const submitStatus = useSubmitStatus();

  const handleChange = async (id: string) => {
    submitStatus.setStatus('submitting');

    try {
      await dispatch(setDialogModel(id));
      await dispatch(getPersonalBot());
      submitStatus.setStatus('success');
    } catch (e) {
      submitStatus.setStatus('error', e as Error);
    }
  };

  return (
    <SettingsLayout.Root>
      <SettingsLayout.Header title="Version history" />
      <SettingsLayout.Content fill>
        <VersionHistorySelector
          id="version-history-selector"
          value={activeId}
          variants={dialogVersions.map((model) => ({
            value: model.id,
            label: model.name,
          }))}
          onChange={handleChange}
          disabled={submitStatus.isSubmitting()}
        />
        <SettingsFormMessageRoot>
          {submitStatus.getError()}
        </SettingsFormMessageRoot>
      </SettingsLayout.Content>
    </SettingsLayout.Root>
  );
}

const VersionHistorySelector = styled(RadioButtonSelector)`
  padding: 10px 30px;
`;
