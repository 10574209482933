import { useSelector } from 'react-redux';
import isWebglAvailable from '../isWebglAvailable';

export const useUnity3dDisabled = () => {
  const avatarMode = useSelector((state) => state.profile.persist.avatarMode);

  return (
    avatarMode === 'no3d' ||
    avatarMode === 'disabledOnError' ||
    !isWebglAvailable()
  );
};
