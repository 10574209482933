export default function formatPrice(
  price: number | { amount: number; currency: string },
  type: 'Gems' | 'Coins' | null = 'Gems',
) {
  if (!price) return '';

  if (typeof price === 'number') {
    return type === 'Gems' ? `$ ${price}` : `${price}`;
  }

  let currency;

  switch (price.currency) {
    case 'USD':
      currency = '$';
      break;
    case 'EUR':
      currency = '€';
      break;
    default:
      currency = price.currency;
  }

  return `${currency}${price.amount}`;
}
