import { useState } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as HamburgerIcon } from '../../icons/Hamburger.svg';
import { IconButton } from '../Buttons';
import { MultiColorBadge } from '../Cards/StoreItemCard';
import * as ContentPanel from '../FullscreenLayout/ContentPanel';
import { Mobile, useMobileQuery } from '../responsive';
import ItemFilters from './ItemFilters';

type StoreCategoryGridHeaderProps = {
  enabledFilters: ('new' | 'purchased')[];
  activeFilter: 'new' | 'purchased' | null;
  onFilterChange: (filter: 'new' | 'purchased' | null) => void;
  variationSelector?: React.ReactNode;
  right?: React.ReactNode;
};

export default function StoreCategoryGridHeader({
  enabledFilters,
  activeFilter,
  onFilterChange,
  variationSelector,
  right,
}: StoreCategoryGridHeaderProps) {
  const [selectorOpen, setSelectorOpen] = useState(false);

  const isMobile = useMobileQuery();

  if (selectorOpen && isMobile) {
    return (
      <StoreCategoryGridHeaderRoot
        showCloseButton="never"
        showBackButton="never"
        hideMenuButton
        title={variationSelector}
        right={
          <HamburgerButton onClick={() => setSelectorOpen(false)}>
            <HamburgerIcon />
          </HamburgerButton>
        }
      />
    );
  }

  return (
    <StoreCategoryGridHeaderRoot
      showCloseButton="never"
      showBackButton={isMobile ? 'never' : 'always'}
      hideMenuButton
      title={
        <ItemFilters
          enabled={enabledFilters}
          filter={activeFilter}
          onClick={onFilterChange}
        />
      }
      right={
        <>
          {variationSelector && (
            <Mobile>
              <WheelButton onClick={() => setSelectorOpen(true)}>
                <ColorBadge />
              </WheelButton>
            </Mobile>
          )}
          {right}
        </>
      }
    />
  );
}

const StoreCategoryGridHeaderRoot = styled(ContentPanel.HeaderWithControls)`
  border-bottom: 0;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 0;

  & > ${ContentPanel.HeaderLeft} {
    flex: 0 1 auto;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding: 12px 15px;
  }
`;

const WheelButton = styled(IconButton)`
  flex: 0;
  width: 34px;
  height: 34px;
  padding: 5px;
  border-radius: 14px;
`;

const HamburgerButton = styled(WheelButton)`
  background: rgba(255 255 255 / 10%);
`;

const ColorBadge = styled(MultiColorBadge)`
  width: 24px;
  height: 24px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 24px;
    height: 24px;
  }
`;
