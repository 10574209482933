import styled from 'styled-components/macro';
import { StoreItem } from '../types/models';
import imageSourceSet from '../utils/imageSourceSet';
import { MAIN_PAGE_URL_BASE } from '../utils/uri';

const ICONS = {
  gem: GemIcon,
  coin: CoinIcon,
};

export function GemIcon(props: React.ImgHTMLAttributes<HTMLImageElement>) {
  return (
    <img
      {...props}
      alt={props.alt ?? 'gem'}
      {...imageSourceSet(`${MAIN_PAGE_URL_BASE}/gem_v2.png`)}
    />
  );
}

export function CoinIcon(props: React.ImgHTMLAttributes<HTMLImageElement>) {
  return (
    <img
      {...props}
      alt={props.alt ?? 'coin'}
      {...imageSourceSet(`${MAIN_PAGE_URL_BASE}/coin_v2.png`)}
    />
  );
}

export function PriceIcon({
  currency,
  className,
  alt,
}: {
  currency: StoreItem['price']['currency'];
  className?: string;
  alt?: string;
}) {
  const Icon = ICONS[currency];

  return (
    <Icon className={className} alt={alt ?? currency} width="16" height="16" />
  );
}

type SumPrice = {
  coins_count: number;
  gems_count: number;
};

export default function PriceTag({
  price,
  className,
  alt,
}: {
  price: StoreItem['price'] | SumPrice;
  className?: string;
  alt?: string;
}) {
  if (!('amount' in price)) {
    return (
      <span className={className} aria-label={alt}>
        {price.coins_count ? (
          <>
            <TagPriceIcon currency="coin" /> {price.coins_count}
          </>
        ) : null}{' '}
        {price.gems_count ? (
          <>
            <TagPriceIcon currency="gem" /> {price.gems_count}
          </>
        ) : null}
      </span>
    );
  }

  return (
    <span className={className}>
      <TagPriceIcon currency={price.currency} alt={alt} /> {price.amount}
    </span>
  );
}

const TagPriceIcon = styled(PriceIcon)`
  height: 1.4em;
  width: 1.4em;
  vertical-align: -0.4em;
`;
