import { differenceInHours } from 'date-fns';
import { ShownSalesMap } from '../types/states';

const TIMEOUT_HOURS = 2;

export const isSaleShouldBeShown = (
  id: string,
  shownSalesMap: ShownSalesMap | null,
) => {
  const lastShownMs = shownSalesMap?.[id];
  return (
    !lastShownMs || differenceInHours(Date.now(), lastShownMs) >= TIMEOUT_HOURS
  );
};
