import { css } from 'styled-components/macro';
import { RIGHT_PANEL_WIDTH_THRESHOLD } from './constants';

/**
 * @deprecated Use theme.breakpoints.
 */
const mobileMedia = (strings, ...interpolations) => css`
  @media (max-width: 767px) {
    ${css(strings, ...interpolations)};
  }
`;

/**
 * @deprecated Use theme.breakpoints.
 */
export const dialogMobileMedia = (strings, ...interpolations) => css`
  @media (max-width: 767px) {
    ${css(strings, ...interpolations)};
  }
`;

/**
 * @deprecated Use theme.breakpoints.
 */
export const rightPanelMobileMedia = (strings, ...interpolations) => css`
  @media (max-width: ${RIGHT_PANEL_WIDTH_THRESHOLD}px) {
    ${css(strings, ...interpolations)};
  }
`;

export default mobileMedia;
