import styled from 'styled-components/macro';

const NoWrap = styled.span<{ $maxWidth?: string }>`
  display: inline-block;
  max-width: ${(p) => (p.$maxWidth ? p.$maxWidth : '180px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
`;

export default NoWrap;
