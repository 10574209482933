import { getPrices } from '../actions/subscriptions';
import useApi from './useApi';

export default function useDefaultPrice() {
  const params = useApi(
    (state) => state.subscriptions.persist.stripeSubscriptionParams,
    getPrices,
    { memoDeepEqual: true },
  );

  if (!params) return null;

  const defaultSub =
    params.available_subscriptions.find((sub) => sub.is_default) ??
    params.available_subscriptions[0];

  if (!defaultSub) return null;

  const price =
    defaultSub.price.currency === 'USD'
      ? `$${defaultSub.price.amount}`
      : `${defaultSub.price.amount} ${defaultSub.price.currency}`;

  if (defaultSub.period) {
    return `${price}/${defaultSub.period.period_timeunit}`;
  }

  return price;
}
