export function createReplikaDB() {
  return new Promise<IDBDatabase>((resolve) => {
    const req = indexedDB.open('replika', 2);
    req.onupgradeneeded = (event) => {
      const db = req.result;
      if (!db.objectStoreNames.contains('images')) {
        db.createObjectStore('images');
      }

      if (!db.objectStoreNames.contains('apiCache')) {
        db.createObjectStore('apiCache');
      }
    };
    req.onsuccess = (event) => {
      resolve(req.result);
    };
  });
}

export function saveImageToDB(db: IDBDatabase, key: string, blob: Blob) {
  return new Promise<void>((resolve) => {
    const tr = db.transaction(['images'], 'readwrite');

    const req = tr.objectStore('images').put(blob, key);

    req.onsuccess = () => {
      resolve(undefined);
    };
  });
}

export function readImageFromDB(db: IDBDatabase, key: string) {
  return new Promise<Blob | null>((resolve) => {
    const tr = db.transaction(['images'], 'readonly');

    const req = tr.objectStore('images').get(key);

    req.onsuccess = () => {
      resolve(req.result);
    };

    req.onerror = () => {
      resolve(null);
    };
  });
}

export function deleteImageFromDB(db: IDBDatabase, key: string) {
  return new Promise<void>((resolve) => {
    const tr = db.transaction(['images'], 'readwrite');

    const req = tr.objectStore('images').delete(key);

    req.onsuccess = () => {
      resolve(undefined);
    };
  });
}

export function saveApiCacheToDB(db: IDBDatabase, key: string, data: any) {
  return new Promise<void>((resolve) => {
    const tr = db.transaction(['apiCache'], 'readwrite');

    const req = tr.objectStore('apiCache').put(data, key);

    req.onsuccess = () => {
      resolve(undefined);
    };
  });
}

export function readApiCacheFromDB(db: IDBDatabase, key: string) {
  return new Promise<any | null>((resolve) => {
    const tr = db.transaction(['apiCache'], 'readonly');

    const req = tr.objectStore('apiCache').get(key);

    req.onsuccess = () => {
      resolve(req.result);
    };

    req.onerror = () => {
      resolve(null);
    };
  });
}

export function deleteApiCacheFromDB(db: IDBDatabase, key: string) {
  return new Promise<void>((resolve) => {
    const tr = db.transaction(['apiCache'], 'readwrite');

    const req = tr.objectStore('apiCache').delete(key);

    req.onsuccess = () => {
      resolve(undefined);
    };
  });
}

export function getApiCacheKeysFromDB(db: IDBDatabase) {
  return new Promise<string[]>((resolve) => {
    const tr = db.transaction(['apiCache'], 'readwrite');

    const req = tr.objectStore('apiCache').getAllKeys();

    req.onsuccess = () => {
      resolve(req.result.map((k) => k.toString()));
    };
  });
}
