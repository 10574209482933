import { FeedbackAction as A } from '../types/actions';
import { ActionTypes, AsyncActionTypes, WsEventNames } from '../types/enums';
import { FeedbackType } from '../types/models';
import { DA } from '../types/redux';
import { wsAction } from '../utils/asyncAction';
import { sendMessage } from '../utils/websocket';

type FeedbackPayload = {
  session_id: string;
  feedback: FeedbackType;
  feedback_text?: string;
  feedback_view: 'Popup';
};

export const sendFeedback =
  (
    sessionId: string,
    feedback: FeedbackType,
    feedbackView: 'Popup',
    feedbackText?: string,
  ): DA<{}> =>
  async (dispatch, getState) => {
    const state = getState();
    const { chatId } = state.ws.persist;

    if (!chatId) {
      console.error('no chat id');
      throw Error('no chat id');
    }

    const params = { chatId, sessionId, feedback, feedbackText, feedbackView };
    let feedbackObj: FeedbackPayload = {
      feedback,
      session_id: sessionId,
      feedback_view: feedbackView,
    };

    if (feedbackText) {
      feedbackObj = {
        ...feedbackObj,
        feedback_text: feedbackText,
      };
    }

    return wsAction(AsyncActionTypes.WsSendFeedback, dispatch, params, {
      onRequest: () =>
        sendMessage(
          {
            event_name: WsEventNames.ConversationFeedback,
            payload: feedbackObj,
          },
          state,
        ),
    });
  };

export const closeFeedbackPopup = (): A => ({
  type: ActionTypes.CloseFeedbackPopup,
});

export const dismissFeedback =
  (sessionId: string, feedbackView: 'Popup'): DA<{}> =>
  async (dispatch, getState) => {
    const state = getState();
    const { chatId } = state.ws.persist;

    if (!chatId) {
      throw Error('no chat id');
    }

    const params = { chatId, sessionId, feedbackView };

    return wsAction(AsyncActionTypes.WsDismissFeedback, dispatch, params, {
      onRequest: () =>
        sendMessage(
          {
            event_name: WsEventNames.ConversationFeedback,
            payload: {
              session_id: sessionId,
              close_action: 'Close',
              feedback_view: feedbackView,
            },
          },
          state,
        ),
    });
  };
