//TODO: extract this.
export const lipsyncWorkletObjectUrl = URL.createObjectURL(
  new Blob(
    [
      `(function(){
    class LipsyncProcessor extends AudioWorkletProcessor {
      lipsync = null;
      constructor (options) {
        super();
  
      }
      process(inputs, outputs) {
        var input = inputs[0];
        var output = outputs[0];
  
        if (!input[0]) return true;
  
        for (var channel = 0; channel < output.length; ++channel) {
          output[channel].set(input[channel]);
        }
  
        var byteArr = new Uint8Array(inputs[0][0].buffer);
  
        this.port.postMessage(byteArr);
  
        return true; 
      }
    }
    registerProcessor('lipsync-processor', LipsyncProcessor);
  })()`,
    ],
    { type: 'application/javascript' },
  ),
);
