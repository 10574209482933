import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { dismissSystemNotification } from '../../actions/ui';
import {
  BunchOfCoinsIcon,
  BunchOfGemsIcon,
} from '../../components/WalletButton/WalletIcons';
import { ReactComponent as CheckIcon } from '../../icons/Check.svg';
import { ReactComponent as NoConnectionIcon } from '../../icons/NoConnection.svg';
import { ReactComponent as WarningIcon } from '../../icons/Warning.svg';
import { SystemNotification } from '../../types/models';
import ChatPopup from './ChatPopup';

type DismissCallback = (id: string) => void;

type Props = {
  className?: string;
  notifications: SystemNotification[];
  onClose: () => void;
};

const TIMEOUT = 5000;

type IconType = Pick<SystemNotification, 'icon'>;

type IconList = IconType[keyof IconType];

function SystemNotificationPopup({ className, notifications, onClose }: Props) {
  const dispatch = useDispatch();
  const [currentMessage, setCurrentMessage] = React.useState<string | null>(
    null,
  );
  const [currentIcon, setCurrentIcon] = React.useState<IconList>('check');

  const dismissRef = React.useRef<undefined | DismissCallback>(undefined);

  React.useEffect(() => {
    dismissRef.current = (id: string) => {
      if (id) {
        dispatch(dismissSystemNotification(id));
      }
    };
  }, [dispatch]);

  const firstNotification: SystemNotification | undefined = notifications[0];
  React.useEffect(() => {
    if (firstNotification) {
      setCurrentMessage(firstNotification.message);
      setCurrentIcon(firstNotification.icon);
    }

    const t = setTimeout(() => {
      if (firstNotification && dismissRef.current) {
        dismissRef.current(firstNotification.id);
      }
    }, TIMEOUT);

    return () => clearTimeout(t);
  }, [firstNotification]);

  let icon: React.ReactNode = null;
  switch (currentIcon) {
    case 'check':
      icon = <CheckIcon />;
      break;
    case 'gem':
      icon = <StyledGemIcon />;
      break;
    case 'coin':
      icon = <StyledCoinIcon />;
      break;
    case 'warning':
      icon = <WarningIcon />;
      break;
    case 'no-connection':
      icon = <NoConnectionIcon />;
      break;
  }

  return (
    <SystemNotificationPopupRoot
      className={className}
      aria-hidden={notifications.length === 0}
    >
      <SystemNotificationContent>
        <NotificationIcon>{icon}</NotificationIcon>

        <NotificationText>{currentMessage}</NotificationText>
      </SystemNotificationContent>
    </SystemNotificationPopupRoot>
  );
}

export default SystemNotificationPopup;

const SystemNotificationPopupRoot = styled(ChatPopup)`
  padding: 10px 16px 10px 12px;
  color: ${(p) => p.theme.solidButtonFgColor};
  background: #000000;
  border-radius: 19px;
  min-height: 44px;
  min-width: 320px;
  font-size: 13px;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;
  font-family: ${(p) => p.theme.fonts.display};
  margin-inline: 15px;

  & > svg {
    width: 10px;
    margin-right: 6px;
    color: ${(p) => p.theme.successFgColor};
  }
`;

const SystemNotificationContent = styled.span`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const NotificationIcon = styled.span`
  flex: 0 0 0%;
`;

const NotificationText = styled.span`
  overflow: hidden;
  white-space: normal;

  @media ${(p) => p.theme.breakpoints.tablet} {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const StyledGemIcon = styled(BunchOfGemsIcon)`
  width: 44px;
  height: 44px;
`;

const StyledCoinIcon = styled(BunchOfCoinsIcon)`
  width: 44px;
  height: 44px;
`;
