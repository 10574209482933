import { keyBy } from 'lodash';

export default function mergeBy<T>(
  source: T[],
  target: T[],
  identifier: string,
) {
  const sourceMap = keyBy(source, identifier);
  let replaceMap: { [key: string]: T } = {};
  let append: T[] = [];

  for (let item of target) {
    if (!sourceMap[item[identifier]]) {
      append.push(item);
    } else {
      replaceMap[item[identifier]] = item;
    }
  }

  const updatedSource = (source ?? []).map(
    (item) => replaceMap[item[identifier]] ?? item,
  );

  return [...updatedSource, ...append];
}
