import { useStoreCategories, useStoreItemLink } from '../../../core/Store';
import {
  StoreCustomizationVariationItem,
  StoreItem,
} from '../../../types/models';
import { BasketItem } from '../../../types/states';
import { AccentButton, SolidButton } from '../../Buttons';
import { DialogFooter } from '../../DialogLayout';
import Link from '../../Link';

type ClaimedItemContentProps = {
  claimedItem?: StoreItem;
  avatarLocked: boolean;
  selectedVariations: StoreCustomizationVariationItem[];
  basket: BasketItem[];
  variationId?: string;
  onClose: () => void;
  actionText?: string;
  closeText?: string;
};

export default function ClaimedItemFooter({
  claimedItem,
  avatarLocked,
  variationId,
  onClose,
  selectedVariations,
  basket,
  actionText = 'Go to Store',
  closeText = 'Check it later',
}: ClaimedItemContentProps) {
  const rootCategories = useStoreCategories();

  const itemLink = useStoreItemLink(claimedItem, rootCategories, variationId);

  const defaultFooter = (
    <DialogFooter>
      <AccentButton type="button" onClick={onClose}>
        {closeText}
      </AccentButton>
    </DialogFooter>
  );

  if (!itemLink) return defaultFooter;

  switch (claimedItem?.variation_type) {
    case 'Color':
    case 'Print': {
      return (
        <DialogFooter>
          {!avatarLocked ? (
            <SolidButton as={Link} to={itemLink} onClick={onClose}>
              {actionText}
            </SolidButton>
          ) : null}
          <AccentButton type="button" onClick={onClose}>
            {closeText}
          </AccentButton>
        </DialogFooter>
      );
    }

    case 'Dialog': {
      return (
        <DialogFooter>
          <SolidButton as={Link} to={itemLink} onClick={onClose}>
            {actionText}
          </SolidButton>
          <AccentButton type="button" onClick={onClose}>
            {closeText}
          </AccentButton>
        </DialogFooter>
      );
    }

    case 'Room': {
      return (
        <DialogFooter>
          <SolidButton as={Link} to={itemLink} onClick={onClose}>
            {actionText}
          </SolidButton>
          <AccentButton type="button" onClick={onClose}>
            {closeText}
          </AccentButton>
        </DialogFooter>
      );
    }

    default:
      return defaultFooter;
  }
}
