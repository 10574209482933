import { take } from 'lodash';
import * as React from 'react';
import { Media } from '../../types/models';
import createLogger from '../../utils/createLogger';
import { captureError } from '../../utils/initSentry';
import isMobileClient from '../../utils/isMobileClient';

type RadioProps = {
  enabled: boolean;
  music: Media[];
  volume: number;
};

const logger = createLogger('Radio');

const FADE_AUDIO_TIMEOUT = 300;
const FADE_STEP = 0.15;

const VOLUME_CHANGE_SUPPORTED = !isMobileClient('ios');

function Radio({ enabled, music, volume = 1 }: RadioProps) {
  const [track, setTrack] = React.useState<Media | null>(null);

  const audioRef = React.useRef<HTMLAudioElement | null>(null);

  const [muted, setMuted] = React.useState(false);

  React.useEffect(() => {
    if (!isMobileClient()) return;

    const handleFocus = () => {
      setMuted(false);
    };
    const handleBlur = () => {
      setMuted(true);
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  const stateRef = React.useRef<{
    enabled: boolean;
    lastPlayed: string[];
    currentTrack: string | null;
    volume: number;
  }>({
    enabled,
    lastPlayed: [],
    currentTrack: null,
    volume: Math.max(0, Math.min(volume, 1)),
  });

  const playRequested = React.useRef(false);

  const tryPlayAudio = React.useCallback(() => {
    if (!audioRef.current) return;
    if (!stateRef.current.enabled) {
      logger.debug('Ignore disabled radio');
      return;
    }

    const paused = audioRef.current.paused;

    logger.debug('try to play: ', audioRef.current.src);

    audioRef.current
      ?.play()
      .then(() => {
        playRequested.current = false;
        if (!audioRef.current) return;

        if (paused) {
          fadeInAudio(audioRef.current, stateRef.current.volume);
        } else {
          audioRef.current.volume = stateRef.current.volume;
        }
      })
      .catch((e) => {
        logger.debug('request audio after interaction');
        if (e.name !== 'AbortError') playRequested.current = true;
        console.error(e);
      });
  }, []);

  React.useEffect(() => {
    if (!audioRef.current) {
      logger.debug('create element');
      audioRef.current = document.createElement('audio');
    }
  }, []);

  // Manage track queue
  React.useEffect(() => {
    if (!audioRef.current) return;

    const handleEnded = (e) => {
      if (!audioRef.current) return;

      const tracks = music.filter(
        (m) => stateRef.current.lastPlayed.indexOf(m.id) === -1,
      );
      const nextTrack =
        tracks[Math.floor(tracks.length * Math.random())] ?? music[0] ?? null;
      setTrack(nextTrack);
    };

    audioRef.current.addEventListener('ended', handleEnded);

    if (!audioRef.current.src && music[0]) {
      setTrack(music[0]);
    } else if (
      audioRef.current.src &&
      music[0] &&
      !music.find(({ url }) => url === audioRef.current?.src)
    ) {
      setTrack(music[0]);
    }

    return () => {
      audioRef.current?.removeEventListener('ended', handleEnded);
    };
  }, [music]);

  // Play requested track after user interaction
  React.useEffect(() => {
    const handleInteraction = () => {
      if (audioRef.current && playRequested.current) {
        audioRef.current.volume = 0;

        logger.debug('Play requested track after interaction');
        tryPlayAudio();
      }
    };
    document.body.addEventListener('mousemove', handleInteraction);
    document.body.addEventListener('scroll', handleInteraction);
    document.body.addEventListener('keydown', handleInteraction);
    document.body.addEventListener('click', handleInteraction);

    return () => {
      document.body.removeEventListener('mousemove', handleInteraction);
      document.body.removeEventListener('scroll', handleInteraction);
      document.body.removeEventListener('keydown', handleInteraction);
      document.body.removeEventListener('click', handleInteraction);
      audioRef.current?.pause();
      audioRef.current = null;
    };
  }, [tryPlayAudio]);

  React.useEffect(() => {
    let normalizedVolume = Math.max(0, Math.min(volume, 1));
    stateRef.current.volume = normalizedVolume;

    try {
      if (audioRef.current && enabled) {
        audioRef.current.volume = normalizedVolume;
      }
    } catch (e) {
      captureError(e);
    }
  }, [volume, enabled]);

  const musicEnabled = enabled && !muted;

  // fade in/out audio on enabled change
  React.useEffect(() => {
    if (!audioRef.current) return;
    if (!stateRef.current.currentTrack) return;
    if (stateRef.current.enabled === musicEnabled) return;

    logger.debug(
      'Enabled changed',
      stateRef.current.enabled,
      ' -> ',
      musicEnabled,
    );

    stateRef.current.enabled = musicEnabled;

    const ac = new AbortController();

    if (musicEnabled) {
      audioRef.current.volume = 0;
      logger.debug('Play current track with fadein', stateRef.current.volume);
      tryPlayAudio();
    } else {
      fadeOutAudio(audioRef.current, ac.signal)
        .then(() => {
          logger.debug('Pause track after fadeout');
          audioRef.current?.pause();
        })
        .catch(() => {});
    }

    return () => {
      ac.abort();
    };
  }, [musicEnabled, tryPlayAudio]);

  React.useEffect(() => {
    if (!audioRef.current || !track) return;
    const state = stateRef.current;

    if (audioRef.current.src !== track.url || music.length === 1) {
      logger.debug('Set track', track.url);
      audioRef.current.src = track.url;

      logger.debug('Play current track');
      tryPlayAudio();
    }

    state.lastPlayed = take([...state.lastPlayed, track.id], 2);
    state.currentTrack = track.id;

    return () => {
      state.currentTrack = null;
    };
  }, [music.length, track, tryPlayAudio]);

  // for debugging:
  // return <audio ref={audioRef} controls style={{ position: 'fixed', top: 0, left: 0, zIndex: 999}} />;
  return null;
}

export default Radio;

function fadeInAudio(
  audio: HTMLAudioElement,
  volume = 1,
  signal?: AbortSignal,
) {
  if (!VOLUME_CHANGE_SUPPORTED) {
    return Promise.resolve();
  }
  let currentVolume = audio.volume;
  return new Promise<void>((resolve, reject) => {
    function loop() {
      if (signal?.aborted) {
        return;
      }
      currentVolume = Math.min(1, currentVolume + FADE_STEP);
      audio.volume = currentVolume;
      if (currentVolume >= volume) {
        resolve();
      } else {
        setTimeout(loop, FADE_AUDIO_TIMEOUT * FADE_STEP);
      }
    }
    setTimeout(loop, FADE_AUDIO_TIMEOUT * FADE_STEP);

    signal?.addEventListener('abort', () => {
      reject(new DOMException('Aborted', 'AbortError'));
    });
  });
}

function fadeOutAudio(audio: HTMLAudioElement, signal?: AbortSignal) {
  if (!VOLUME_CHANGE_SUPPORTED) {
    return Promise.resolve();
  }
  let currentVolume = audio.volume;
  return new Promise<void>((resolve, reject) => {
    function loop() {
      if (signal?.aborted) {
        return;
      }
      currentVolume -= FADE_STEP;
      audio.volume = Math.max(0, currentVolume - FADE_STEP);
      if (currentVolume === 0) {
        resolve();
      } else {
        setTimeout(loop, FADE_AUDIO_TIMEOUT * FADE_STEP);
      }
    }

    setTimeout(loop, FADE_AUDIO_TIMEOUT * FADE_STEP);

    signal?.addEventListener('abort', () => {
      reject(new DOMException('Aborted', 'AbortError'));
    });
  });
}
