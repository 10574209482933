import * as React from 'react';
import styled from 'styled-components/macro';

import { requestWallet } from '../../../actions/store';
import ErrorSuccessMessage from '../../../components/ErrorSuccessMessage';
import { Credit, StoreItem } from '../../../types/models';
import useApi from '../../../utils/useApi';
import LegacyStoreItemCard from '../../Cards/LegacyStoreItemCard';
import {
  Dialog,
  DialogBody,
  DialogHeader,
} from '../../DialogLayout/legacy/DialogLayout';
import StoreItemDialogFooter from './StoreItemDialogFooter';

type Props = {
  onClose: () => void;
  item: StoreItem;
  itemTypeName: string;
  itemTypeDescription: string;
};

function getCurrencyNeeded(price: StoreItem['price'], credit: Credit | null) {
  if (!credit) return null;

  if (price.currency === 'coin' && price.amount > credit.coins_count) {
    return {
      currency: 'coin',
      amount: price.amount - credit.coins_count,
    } as const;
  }

  if (price.currency === 'gem' && price.amount > credit.gems_count) {
    return {
      currency: 'gem',
      amount: price.amount - credit.gems_count,
    } as const;
  }

  return null;
}

function PurchaseStoreItemDialog({
  onClose,
  item,
  itemTypeName,
  itemTypeDescription,
}: Props) {
  const [error, setError] = React.useState<string | null>(null);

  const credit = useApi((state) => state.store.persist.credit, requestWallet, {
    memoDeepEqual: true,
  });

  const variation = item.variations[0];
  const itemBought = variation && variation.bought_count > 0;

  const currencyNeeded = itemBought
    ? null
    : getCurrencyNeeded(item.price, credit);

  return (
    <StyledDialog
      data-testid="purchase-store-item-dialog"
      mobileLayout="action-sheet"
    >
      <DialogHeader
        onClose={onClose}
        title={itemBought ? itemTypeName : 'Purchase'}
      />
      <DialogBody>
        <Description>{itemTypeDescription}</Description>
        <LegacyStoreItemCard item={item} standalone />
        <ItemDescription>{item.description}</ItemDescription>
        {error && <ErrorSuccessMessage hasError>{error}</ErrorSuccessMessage>}
        <StoreItemDialogFooter
          item={item}
          itemTypeName={itemTypeName}
          onError={setError}
          currencyNeeded={currencyNeeded}
          onClose={onClose}
        />
      </DialogBody>
    </StyledDialog>
  );
}

export default PurchaseStoreItemDialog;

const StyledDialog = styled(Dialog)`
  position: absolute;
  height: 100svh;
  top: 0;
  left: 0;
  width: 100%;

  @media ${(p) => p.theme.breakpoints.tablet} {
    position: relative;
    height: auto;
    min-width: 360px;
  }
`;

const Description = styled.p`
  text-align: center;
  margin: 20px 0 30px;
  font-size: 16px;
  line-height: 21px;
  width: 260px;
`;

const ItemDescription = styled.p`
  text-align: center;
  margin: 30px 0 0;
  font-size: 16px;
  line-height: 21px;
  width: 260px;
`;
