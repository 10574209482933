import { capitalize } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { buyUserItems, switchUserItem } from '../../../actions/store';
import { queueSystemNotification } from '../../../actions/ui';
import { AccentButton } from '../../../components/Buttons';
import PriceTag from '../../../components/PriceTag';
import { Routes } from '../../../types/enums';
import { StoreItem } from '../../../types/models';
import SwitchButton from './SwitchButton';

function PurchaseItemFooter({
  item,
  onError,
  onClose,
  itemTypeName,
  className,
}: {
  item: StoreItem;
  onError: (message: string | null) => void;
  onClose: () => void;
  itemTypeName: string;
  className?: string;
}) {
  const dispatch = useDispatch();

  const [purchaseState, setPurchaseState] = useState<'idle' | 'purchasing'>(
    'idle',
  );

  const handleBuyItemClick = async () => {
    const variation = item.variations[0];
    onError(null);
    if (variation && purchaseState === 'idle') {
      setPurchaseState('purchasing');
      try {
        await dispatch(buyUserItems([{ id: variation.id, count: 1 }]));

        dispatch(
          queueSystemNotification(
            capitalize(`${itemTypeName} updated`),
            'check',
          ),
        );
        onClose();
      } catch (e) {
        if (e instanceof Error) {
          onError(e.message);
        }
      } finally {
        setPurchaseState('idle');
      }
    }
  };

  return (
    <AccentButton
      className={className}
      showSpinner={purchaseState !== 'idle'}
      onClick={handleBuyItemClick}
    >
      Buy for <PriceTag price={item.price} />
    </AccentButton>
  );
}

function BoughtItemFooter({
  item,
  itemTypeName,
  onItemChange,
  onError,
  onClose,
  className,
}: {
  item: StoreItem;
  itemTypeName: string;
  onItemChange: (item: StoreItem) => void;
  onError: (message: string | null) => void;
  onClose: () => void;
  className?: string;
}) {
  const dispatch = useDispatch();

  const [switchState, setSwitchState] = useState<'idle' | 'switching'>('idle');

  let itemEnabled = true;
  switch (item.variation_type) {
    case 'Dialog':
      itemEnabled = !!item.variations[0]?.enabled;
      break;
  }

  const handleSwitchItemClick = async () => {
    onError(null);
    const variation = item.variations[0];
    if (variation && switchState === 'idle') {
      setSwitchState('switching');
      try {
        const [item] = await dispatch(
          switchUserItem(variation.id, !itemEnabled),
        );
        if (item) {
          onItemChange(item);
        }

        dispatch(
          queueSystemNotification(
            capitalize(`${itemTypeName} updated`),
            'check',
          ),
        );
      } catch (e) {
        if (e instanceof Error) {
          onError(e.message);
        }
      } finally {
        setSwitchState('idle');
      }
    }
  };

  return (
    <SwitchButton
      className={className}
      checked={itemEnabled}
      onCheckedChange={handleSwitchItemClick}
    >
      {itemEnabled ? 'Enabled' : 'Disabled'}
    </SwitchButton>
  );
}

function ExtraCoinsNeededFooter({
  currencyNeeded,
  onClose,
  className,
}: {
  currencyNeeded: StoreItem['price'];
  onClose: () => void;
  className?: string;
}) {
  return (
    <>
      <ExtraNeeded className={className}>
        You need <PriceTag price={currencyNeeded} /> extra
      </ExtraNeeded>
      <AccentButton
        to={{
          pathname: Routes.Wallet,
          state: {
            source: 'store',
          },
        }}
      >
        Get more coins and gems
      </AccentButton>
    </>
  );
}

function DialogStoreItemFooter(props: {
  className?: string;
  currencyNeeded: null | StoreItem['price'];
  item: StoreItem;
  onError: (message: string | null) => void;
  onClose: () => void;
  itemTypeName: string;
}) {
  const { currencyNeeded, onError, onClose, itemTypeName, className } = props;
  const [item, setItem] = useState(props.item);
  const variation = item.variations[0];
  const itemBought = variation && variation.bought_count > 0;

  if (currencyNeeded) {
    return (
      <ExtraCoinsNeededFooter
        className={className}
        currencyNeeded={currencyNeeded}
        onClose={onClose}
      />
    );
  } else if (itemBought) {
    return (
      <BoughtItemFooter
        className={className}
        item={item}
        itemTypeName={itemTypeName}
        onError={onError}
        onClose={onClose}
        onItemChange={setItem}
      />
    );
  } else {
    return (
      <PurchaseItemFooter
        className={className}
        item={item}
        itemTypeName={itemTypeName}
        onError={onError}
        onClose={onClose}
      />
    );
  }
}

export default DialogStoreItemFooter;

const ExtraNeeded = styled.div`
  font-size: 12px;
  font-family: ${(p) => p.theme.fonts.display};
  margin-bottom: 20px;
`;
