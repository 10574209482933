export function initSession() {
  const params = new URLSearchParams(window.location.search);
  const length = Array.from(params.keys()).length;

  for (let key of [
    'no3d',
    'noaxe',
    'debug',
    'debug_audio',
    'local_eggine',
    'local_bundles',
  ] as const) {
    if (params.get(key) === '' || params.get(key) === 'true') {
      setSessionSetting(key, true);
      params.delete(key);
    } else if (params.get(key) === 'false') {
      setSessionSetting(key, false);
      params.delete(key);
    }
  }

  for (let key of ['debug_level', 'price_num', 'unity_version'] as const) {
    if (params.has(key)) {
      setSessionSetting(key, parseInt(params.get(key)!, 10));
      params.delete(key);
    }
  }

  for (let key of ['utm_campaign', 'dialog', 'userid', 'redirect'] as const) {
    if (params.has(key)) {
      setSessionSetting(key, params.get(key)!);

      // don't mess with utm_campaign
      if (key !== 'utm_campaign') {
        params.delete(key);
      }
    }
  }

  if (Array.from(params.keys()).length !== length) {
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${params}`,
    );
  }
}

type SessionSettings = {
  // dev settings
  no3d: boolean;
  noaxe: boolean;
  debug: boolean;
  debug_audio: boolean;
  local_eggine: boolean;
  local_bundles: boolean;

  price_num: number;
  debug_level: number;
  unity_version: number;

  // app settings
  isFreshSignup: false;
  redirectTo: string;
  utm_campaign: string;
  dialog: string;
  userid: string;
  redirect: string;
};

export function getSessionSetting<T extends keyof SessionSettings>(
  key: T,
): SessionSettings[T] | null {
  const value = sessionStorage.getItem('replika.' + key);

  return value === null ? null : (JSON.parse(value) as SessionSettings[T]);
}

export function setSessionSetting<T extends keyof SessionSettings>(
  key: T,
  value: SessionSettings[T],
) {
  sessionStorage.setItem('replika.' + key, JSON.stringify(value));
}

export function clearSessionSetting<T extends keyof SessionSettings>(key: T) {
  sessionStorage.removeItem('replika.' + key);
}
