import * as React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components/macro';
import { requestWallet } from '../../actions/store';
import { ModalRoutes, Routes } from '../../types/enums';
import { WalletScreenOpenedSource } from '../../types/states';
import toModalRoute from '../../utils/toModalRoute';
import useApi from '../../utils/useApi';
import { SolidButton, TranslusentIconButton } from '../Buttons';
import {
  ButtonVariant,
  CoinIconWithAnimation,
  GemIconWithAnimation,
} from './WalletIcons';

interface WalletButtonProps
  extends React.ComponentPropsWithoutRef<typeof SolidButton> {
  variant?: ButtonVariant;
  source: WalletScreenOpenedSource | null;
  noTooltip?: boolean;
}

export const CLAIM_ANIMATION_TIMEOUT = 1200;

function WalletButton({
  variant = 'medium',
  source,
  noTooltip,
  ...props
}: WalletButtonProps) {
  const credit = useApi((state) => state.store.persist.credit, requestWallet, {
    memoDeepEqual: true,
  });

  const location = useLocation();

  return (
    <WalletButtonRoot
      $variant={variant}
      {...props}
      {...(location.pathname !== Routes.Wallet
        ? {
            to: toModalRoute(ModalRoutes.Wallet, {
              source: source?.toString(),
            }),
          }
        : {})}
      disabled={props.disabled || location.pathname === Routes.Wallet}
      tooltipOptions={
        noTooltip
          ? undefined
          : {
              id: 'wallet-button-tooltip',
              overlay: 'Wallet',
              mouseEnterDelay: 1,
              placement: 'left',
            }
      }
    >
      <GemIconWithAnimation variant={variant} />
      {variant !== 'mobile' && (credit?.gems_count ?? 0)}
      <CoinIconWithAnimation variant={variant} />
      {variant !== 'mobile' && (credit?.coins_count ?? 0)}
    </WalletButtonRoot>
  );
}

export default WalletButton;

const WalletButtonRoot = styled(TranslusentIconButton)<{
  $variant: ButtonVariant;
}>`
  font-family: ${(p) => p.theme.fonts.display};
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: ${(p) => (p.$variant === 'mobile' ? '10px' : '10px 15px')};
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  gap: 5px;
  color: ${(p) => p.theme.fgColor};

  &:link {
    text-decoration: none;
  }

  &[aria-disabled='true'] {
    pointer-events: none;
    opacity: 1;
  }

  height: 34px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    height: ${(p) => (p.$variant === 'medium' ? '44px' : '34px')};
  }

  & img {
    width: 20px;
    height: 20px;
  }
`;
