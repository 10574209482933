import { format, parse } from 'date-fns';
import { range } from 'lodash';
import { DateSelectOptionsConfig, DateState } from './models';

export const getDateSelectOptions = (
  minDate: Date,
  maxDate: Date,
): DateSelectOptionsConfig => {
  return {
    dayOptions: range(1, 32).map((value) => ({
      label: value.toString().padStart(2, '0'),
      value: value.toString(),
    })),
    monthOption: range(1, 13).map((value) => ({
      label: format(new Date(2000, value - 1, 1), 'MM'),
      value: value.toString(),
    })),
    yearOptions: range(
      maxDate.getFullYear(),
      minDate.getFullYear() - 1,
      -1,
    ).map((value) => ({
      label: value.toString(),
      value: value.toString(),
    })),
  };
};

export const getParsedDate = ({ day, month, year }: DateState) =>
  parse(`${year}-${month}-${day}`, 'yyyy-M-d', new Date());
