import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { queueSystemNotification } from '../../actions/ui';

const staticModeHintShownAtom = atom(false);
staticModeHintShownAtom.debugLabel = 'staticModeHintShownAtom';

export default function StaticModeHint() {
  const [staticModeHintShown, setStaticModeHintShown] = useAtom(
    staticModeHintShownAtom,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (staticModeHintShown) return;

    dispatch(
      queueSystemNotification(
        'The app is running slowly on your device. Consider switching to "Static" mode in Settings.',
        'warning',
      ),
    );

    setStaticModeHintShown(true);
  }, [staticModeHintShown]);

  return null;
}
