import { StoreItem } from '../types/models';

export default function getPriceLabel(price: StoreItem['price']) {
  switch (price.currency) {
    case 'gem':
      return price.amount === 1
        ? `${price.amount} gem`
        : `${price.amount} gems`;
    case 'coin':
      return price.amount === 1
        ? `${price.amount} coin`
        : `${price.amount} coins`;
  }
}
