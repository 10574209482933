import * as React from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import theme from '../themes/common.json';
import { RIGHT_PANEL_WIDTH_THRESHOLD } from '../utils/constants';

type Props = {
  children: React.ReactNode;
};

export const Mobile = ({ children }: Props) => {
  const match = useMediaQuery({ query: theme.breakpoints.tablet });

  return !match ? <>{children}</> : null;
};

export const Desktop = ({ children }: Props) => (
  <MediaQuery query={theme.breakpoints.tablet}>{children}</MediaQuery>
);

export const DialogMobile = ({ children }: Props) => {
  const match = useMediaQuery({ query: theme.breakpoints.tablet });

  return !match ? <>{children}</> : null;
};

export const DialogDesktop = ({ children }: Props) => (
  <MediaQuery query={theme.breakpoints.tablet}>{children}</MediaQuery>
);

export const RightPanelMobile = ({ children }: Props) => (
  <MediaQuery maxWidth={RIGHT_PANEL_WIDTH_THRESHOLD}>{children}</MediaQuery>
);

export const RightPanelDesktop = ({ children }: Props) => (
  <MediaQuery minWidth={RIGHT_PANEL_WIDTH_THRESHOLD + 1}>{children}</MediaQuery>
);

export function useMobileQuery() {
  return !useMediaQuery({ query: theme.breakpoints.tablet });
}

export function useLaptopQuery() {
  return useMediaQuery({ query: theme.breakpoints.laptop });
}

export function useDesktopQuery() {
  return useMediaQuery({ query: theme.breakpoints.tablet });
}
