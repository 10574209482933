import { ChangeAvatarId } from 'src/routes/ReplikaProfile/ChangeAvatar/ChangeAvatar';
import { CategoryTreeNode } from '../../types/models';

export function flattenSections(
  category: CategoryTreeNode,
): CategoryTreeNode[] {
  if (category.children.length === 0) {
    return [category];
  } else {
    return category.children.reduce(
      (acc, child) => [...acc, ...flattenSections(child)],
      [] as CategoryTreeNode[],
    );
  }
}

export function buildTitlePathMap(
  category: CategoryTreeNode,
  parentPath: string[] = [],
): { [id: string]: string[] } {
  const path = [...parentPath, category.long_name];
  const map = {
    [category.id]: path,
  };
  if (category.children.length === 0) {
    return map;
  } else {
    return category.children.reduce(
      (acc, child) => ({ ...acc, ...buildTitlePathMap(child, path) }),
      map,
    );
  }
}

export function buildMenuItems(
  category: CategoryTreeNode,
  ids: string[],
  includeRangeSection: boolean,
) {
  return category.children
    .map((child) => {
      let children = child.children;
      const includes =
        isAvatarChangeSection(child) ||
        (includeRangeSection && isSectionWithRange(child)) ||
        ids.includes(child.id);
      if (!includes) {
        children = children.filter((c) => ids.includes(c.id));
      }

      const submenu = {
        value: child.id,
        label: child.name,
        items: children.map((grandChild) => ({
          value: grandChild.id,
          label: grandChild.name,
        })),
      };
      return includes || submenu.items.length > 0 ? submenu : null;
    })
    .filter(Boolean);
}

export function isSectionWithRange(section: CategoryTreeNode) {
  return section.key === 'body_type' || section.key === 'age';
}

export const isAvatarChangeSection = (section: CategoryTreeNode) =>
  section.id === ChangeAvatarId;
