export default function isMobileClient(platform?: 'ios') {
  if (platform === 'ios') {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }

  return (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform) ||
    /Mobi/.test(navigator.userAgent)
  );
}
