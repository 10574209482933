import '@total-typescript/ts-reset';

import browserUpdate from 'browser-update';
import 'focus-visible';
import polyfills from './polyfills';

import {
  ConnectedRouter,
  connectRouter,
  routerMiddleware,
} from 'connected-react-router';
import { LocationDescriptor, createBrowserHistory } from 'history';
import { useAtom } from 'jotai';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Store, applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { SWRConfig } from 'swr';
import App from './App';
import {
  checkDeviceId,
  initMetrics,
  initPersist,
  initRouting,
} from './actions/init';
import { setTheme } from './actions/ui';
import apiCacheProvider from './core/api/apiCacheProvider';
import { apiCredsAtom } from './core/api/atoms';
import swrFetcher from './core/api/swrFetcher';
import { initFeatures } from './core/featureFlags';
import * as reducers from './reducers';
import { unregister } from './registerServiceWorker';
import { Routes, Themes } from './types/enums';
import { RootState } from './types/states';
import { isEnvironment } from './utils/environment';
import initSentry from './utils/initSentry';
import isDebugEnabled from './utils/isDebugEnabled';
import isKeyCombination from './utils/isKeyCombination';
import { initRecaptcha } from './utils/recaptcha';
import { getSessionSetting, initSession } from './utils/session';
import { getThemeName } from './utils/withThemeName';

initSession();
initFeatures();

browserUpdate({
  required: { i: 12 },
});

if (process.env.NODE_ENV !== 'production' && !getSessionSetting('noaxe')) {
  // react-axe KILLS browser if you try to access it via network (like http://10.0.0.1:3000)
  if (window.location.pathname === 'localhost') {
    // eslint-disable-next-line
    const axe = require('react-axe');
    axe(React, ReactDOM, 1000);
  }
}

initSentry();

initRecaptcha();

const _history = createBrowserHistory<{
  canGoBack?: number;
  anchors?: Record<string, number>;
  anchorsNoModal?: Record<string, number>;
}>();

function callHistoryMethod(method, path, state, stateUpdate) {
  if (typeof path !== 'string') {
    path = {
      ...path,
      state: stateUpdate({ ..._history.location.state, ...path.state }),
    };
  } else {
    state = stateUpdate({ ..._history.location.state, ...state });
  }
  method(path, state);
}

const MAIN_PAGE_ROUTES = [Routes.Chat];

function isModalRoute(path: string | LocationDescriptor<any>) {
  const search = typeof path === 'string' ? path.split('?')[1] : path.search;
  if (!search) return false;

  const params = new URLSearchParams(search);
  return params.has('modal');
}

function updatePushAnchors(
  path: string | LocationDescriptor<any>,
  anchors: Record<string, number> | undefined,
  skipDecrement?: boolean,
) {
  const url = typeof path === 'string' ? path : path.pathname;
  if (MAIN_PAGE_ROUTES.indexOf(url as Routes) !== -1) {
    // reset anchors state on main page
    return;
  }
  if (!anchors) return;

  if (!skipDecrement) {
    anchors = { ...anchors };
    for (let anchor in anchors) {
      anchors[anchor]--;
    }
  }

  return anchors;
}

function updateReplaceAnchors(
  path: string | LocationDescriptor<any>,
  anchors: Record<string, number> | undefined,
) {
  const url = typeof path === 'string' ? path : path.pathname;
  if (MAIN_PAGE_ROUTES.indexOf(url as Routes) !== -1) {
    if (typeof path === 'string' || !path.search) {
      // reset anchors state on main page
      return;
    }
  }
  return anchors;
}

const history = {
  ..._history,
  push: (path, state) => {
    callHistoryMethod(_history.push, path, state, (state) => ({
      ...state,
      anchors: updatePushAnchors(path, state?.anchors),
      anchorsNoModal: updatePushAnchors(
        path,
        state?.anchorsNoModal,
        isModalRoute(path),
      ),
      canGoBack: (state?.canGoBack ?? 0) + 1,
    }));
  },
  replace: (path, state) => {
    callHistoryMethod(_history.replace, path, state, (state) => ({
      ...state,
      anchors: updateReplaceAnchors(path, state?.anchors),
      anchorsNoModal: updateReplaceAnchors(path, state?.anchorsNoModal),
      canGoBack: state?.canGoBack ?? 0,
    }));
  },
};

const middleware = routerMiddleware(history);

const getCombinedReducer = (r) =>
  combineReducers<RootState>({
    ...r,
    router: connectRouter(history),
  });

const middlewares = !isDebugEnabled()
  ? [thunk, middleware]
  : [
      thunk,
      createLogger({
        level: {
          prevState: false,
          action: 'debug',
          nextState: 'debug',
          error: 'error',
        },
        collapsed: false,
        duration: true,
        diff: false,
        logger: {
          /* eslint-disable no-console */
          log: console.log,
          warn: console.warn,
          error: console.error,
          debug: console.debug,
          group: console.debug,
          groupCollapsed: console.debug,
          groupEnd: () => {},
          /* eslint-enable no-console */
        },
      }),
      middleware,
    ];

const store: Store = composeWithDevTools(applyMiddleware(...middlewares))(
  createStore,
)(getCombinedReducer(reducers));

if (window.matchMedia) {
  const match = window.matchMedia('(prefers-color-scheme: dark)');
  const handler = (e) => {
    setTheme(e.matches ? Themes.Dark : Themes.Default)(
      store.dispatch,
      store.getState,
    );
  };
  if (match?.addEventListener) {
    match.addEventListener('change', handler);
  } else if (match?.addListener) {
    match.addListener(handler);
  }
}

const SWR_DEFAULT_CONFIG = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  revalidateIfStale: true,
  errorRetryInterval: 1000,
  errorRetryCount: 2,
};

if (!isEnvironment('production')) {
  document.addEventListener('keydown', (e) => {
    if (isKeyCombination(e, { code: 'KeyT', modifiers: { alt: true } })) {
      e.preventDefault();
      const theme = getThemeName(store.getState());
      setTheme(theme === Themes.Default ? Themes.Dark : Themes.Default)(
        store.dispatch,
        store.getState,
      );
    }
  });
}

function SWRProvider({ children }) {
  // We need this to subscribe to storage changes
  useAtom(apiCredsAtom);

  return (
    <SWRConfig
      value={{
        ...SWR_DEFAULT_CONFIG,
        fetcher: swrFetcher,
        provider: apiCacheProvider,
      }}
    >
      {children}
    </SWRConfig>
  );
}

const Root = () => {
  return (
    <SWRProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </SWRProvider>
  );
};

const renderRoot = async () => {
  await polyfills();

  const rootEl = document.getElementById('root');

  if (!rootEl) return;

  store.dispatch<any>(initPersist());
  store.dispatch(checkDeviceId());
  store.dispatch<any>(initMetrics());

  ReactDOM.createRoot(rootEl).render(<Root />);

  setTimeout(() => store.dispatch<any>(initRouting()), 0);
};

window.addEventListener('DOMContentLoaded', renderRoot);

interface WebpackNodeModule extends NodeModule {
  hot: {
    accept: (name: string, handler: () => void) => void;
  };
}

// eslint-disable-next-line
const webpackNodeModule = module as WebpackNodeModule;

if (webpackNodeModule.hot) {
  const rerender = () => {
    const rootEl = document.getElementById('root');
    if (!rootEl) return;
    ReactDOM.createRoot(rootEl).render(
      <SWRProvider>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </Provider>
      </SWRProvider>,
    );
  };

  webpackNodeModule.hot.accept('./App', rerender);

  webpackNodeModule.hot.accept('./reducers', () => {
    // eslint-disable-next-line
    const nextReducers = require('./reducers');
    store.replaceReducer(getCombinedReducer(nextReducers));
  });
}

if (isEnvironment('production', 'testing')) {
  // registerServiceWorker();
} else {
  unregister();
}
