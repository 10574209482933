export const getBufferFromUrl = async (url: string) => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      console.error(`HTTP error, status = ${response.status}`);

      return;
    }

    return await response.arrayBuffer();
  } catch (e) {
    return;
  }
};
