import isKeyCombination from './isKeyCombination';

type KeyboardEventHandler = (e: KeyboardEvent) => void;

let eventQueue: KeyboardEventHandler[] = [];

function handleKeyDown(e: KeyboardEvent) {
  for (const listener of eventQueue) {
    listener(e);
    if (e.defaultPrevented) return;
  }
}

document.addEventListener('keydown', handleKeyDown);

// Last registered event gets priority
export function registerKeydownListener(listener: KeyboardEventHandler) {
  eventQueue.unshift(listener);
}

export function unregisterKeydownListener(listener: KeyboardEventHandler) {
  eventQueue = eventQueue.filter((l) => l !== listener);
}

export function createEscapeKeyHandler(callback: () => void) {
  return (e: KeyboardEvent) => {
    if (isKeyCombination(e, 'Escape')) {
      const shouldIgnore =
        e.target instanceof HTMLElement &&
        e.target.closest('[data-prevent-escape-navigation]') !== null;

      if (!shouldIgnore) {
        e.preventDefault();
        callback();
      }
    }
  };
}
