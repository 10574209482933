import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { setMessageReaction } from '../../../actions/chat';
import { MessageActionType } from '../../../types/models';
import useFocusGroup from '../../../utils/useFocusGroup';
import { REACTIONS, ReactionEmoji } from '../../ReactionEmoji';
import LinkButton from '../../legacy/LinkButton';

type Props = {
  className?: string;
  messageId: string;
  onClose: () => void;
  permittedActions?: MessageActionType[];
  onReactionClick?: () => void;
};

export default function ReactionDialogBody({
  messageId,
  className,
  onClose,
  permittedActions = [],
  onReactionClick,
}: Props) {
  const reactions = REACTIONS.filter(
    (r) => permittedActions.indexOf(r.name) !== -1,
  );
  const ref = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useFocusGroup(
    {
      getGroupElements() {
        return ref.current ? ref.current.querySelectorAll('button') : [];
      },
      options: {
        wrap: true,
      },
      focusNodeIndex: 0,
    },
    [reactions.length],
  );

  return (
    <Reactions
      ref={ref}
      role="list"
      className={className}
      data-prevent-escape-navigation
    >
      {reactions.map((r, idx) => (
        <ReactionButton
          tabIndex={idx === 0 ? 0 : -1}
          key={r.label}
          onClick={() => {
            dispatch(setMessageReaction(messageId, r.name));
            onReactionClick?.();
            onClose();
          }}
        >
          <Emoji aria-hidden="true" $reaction={r} />
          <Label>{r.label}</Label>
        </ReactionButton>
      ))}
    </Reactions>
  );
}

const Emoji = styled(ReactionEmoji)`
  margin-right: 10px;
  width: 27px;
  height: 27px;
`;

const Reactions = styled.div`
  width: 100%;
  margin-left: 10px;
  color: ${(p) => p.theme.fgColor};
`;

const ReactionButton = styled(LinkButton)`
  display: flex;
  align-items: center;
  text-decoration: none;

  & + & {
    margin-top: 13px;
  }

  &:hover {
    text-decoration: none;
  }
`;

const Label = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
`;
