import styled from 'styled-components/macro';
import Picture from '../../../components/Picture';
import { MAIN_PAGE_URL_BASE } from '../../../utils/uri';

export const DefaultSubscriptionPros = () => {
  return (
    <DefaultSubscriptionProsRoot>
      <DefaultSubscriptionProsItem>
        <DefaultSubscriptionProsItemIcon>
          <Picture src={`${MAIN_PAGE_URL_BASE}/unlock_pro_check.png`} alt="" />
        </DefaultSubscriptionProsItemIcon>
        Gain access to an advanced conversational model
      </DefaultSubscriptionProsItem>
      <DefaultSubscriptionProsItem>
        <DefaultSubscriptionProsItemIcon>
          <Picture src={`${MAIN_PAGE_URL_BASE}/unlock_pro_check.png`} alt="" />
        </DefaultSubscriptionProsItemIcon>
        Enjoy unlimited voice messages and video calls
      </DefaultSubscriptionProsItem>
      <DefaultSubscriptionProsItem>
        <DefaultSubscriptionProsItemIcon>
          <Picture src={`${MAIN_PAGE_URL_BASE}/unlock_pro_check.png`} alt="" />
        </DefaultSubscriptionProsItemIcon>
        Create a Replika with your favorite traits, interests, and backstory
      </DefaultSubscriptionProsItem>
      <DefaultSubscriptionProsItem>
        <DefaultSubscriptionProsItemIcon>
          <Picture src={`${MAIN_PAGE_URL_BASE}/unlock_pro_check.png`} alt="" />
        </DefaultSubscriptionProsItemIcon>
        Unlock more engaging activities with your Replika
      </DefaultSubscriptionProsItem>
    </DefaultSubscriptionProsRoot>
  );
};

const DefaultSubscriptionProsRoot = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 5px 0 0;
  padding: 0;
  list-style-type: none;
`;

const DefaultSubscriptionProsItem = styled.li`
  color: ${(p) => p.theme.fgColor};
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
  text-align: left;
  display: flex;
  gap: 10px;
`;

const DefaultSubscriptionProsItemIcon = styled.span`
  flex: 0 0 18px;

  img {
    display: block;
    height: 15px;
    width: 18px;
  }
`;
