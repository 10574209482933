import styled from 'styled-components/macro';
import { MAIN_PAGE_URL_BASE } from '../../../utils/uri';
import useSubmitStatus from '../../../utils/useSubmitStatus';
import { AccentButton, DarkSolidButton } from '../../Buttons';
import { Dialog, DialogBody } from '../../DialogLayout/legacy/DialogLayout';
import Picture from '../../Picture';

type Props = {
  content: {
    title: string | React.ReactNode;
    description: string | React.ReactNode;
    secondaryText?: string;
    primaryText?: string;
    width?: string;
  };
  onSecondaryClick?: () => void | Promise<void>;
  onPrimaryClick?: () => void | Promise<void>;
  onClose: () => void;
};

const ConfirmationDialog = ({
  content: { title, description, secondaryText, primaryText = 'Ok', width },
  onSecondaryClick,
  onPrimaryClick,
  onClose,
}: Props) => {
  const primarySubmitStatus = useSubmitStatus();
  const handlePrimaryClick = async () => {
    primarySubmitStatus.setStatus('submitting');
    await onPrimaryClick?.();
    primarySubmitStatus.setStatus('success');
    onClose();
  };

  const secondarySubmitStatus = useSubmitStatus();
  const handleSecondaryClick = async () => {
    secondarySubmitStatus.setStatus('submitting');
    await onSecondaryClick?.();
    secondarySubmitStatus.setStatus('success');
    onClose();
  };

  return (
    <StyledDialog mobileLayout="dialog" $width={width}>
      <StyledDialogBody>
        <AttentionImg src={`${MAIN_PAGE_URL_BASE}/attention.png`} />
        <Title data-initialfocus tabIndex={-1}>
          {title}
        </Title>
        <Description>{description}</Description>

        <Controls>
          {secondaryText && (
            <SecondaryButton
              type="button"
              onClick={handleSecondaryClick}
              showSpinner={secondarySubmitStatus.isSubmitting()}
            >
              {secondaryText}
            </SecondaryButton>
          )}

          <PrimaryButton
            type="button"
            onClick={handlePrimaryClick}
            showSpinner={primarySubmitStatus.isSubmitting()}
          >
            {primaryText}
          </PrimaryButton>
        </Controls>
      </StyledDialogBody>
    </StyledDialog>
  );
};

export default ConfirmationDialog;

const StyledDialog = styled(Dialog)<{ $width?: string }>`
  border-radius: 24px;
  width: ${(p) => p.$width || '315px'};
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(25px);
`;

const StyledDialogBody = styled(DialogBody)`
  padding: 0 30px 30px;
`;

const AttentionImg = styled(Picture)`
  margin-top: 20px;
  width: 120px;
  height: 120px;
`;

const Title = styled.h3`
  margin: 10px 0 0;
  text-align: center;
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  color: #fff;
`;

const Description = styled.p`
  margin: 10px 0 0;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
`;

const Controls = styled.div`
  margin: 30px 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SecondaryButton = styled(DarkSolidButton)`
  width: 100%;
`;

const PrimaryButton = styled(AccentButton)`
  width: 100%;
`;
