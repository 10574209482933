import { useEffect, useState } from 'react';
import { Daytime } from './types';

// @see https://github.com/lukalabs/luka-ios/blob/develop/Replika/General%20Entities/3DAvatar/Avatar3D%2BService.swift
function getCurrentDaytime(date: Date): Daytime {
  const hour = date.getHours();

  if (hour < 6 || hour >= 20) {
    return 'night';
  } else if (hour < 11) {
    return 'morning';
  } else if (hour >= 17) {
    return 'evening';
  } else {
    return 'day';
  }
}

export default function useCurrentDaytime() {
  const [dayTime, setDayTime] = useState(getCurrentDaytime(new Date()));

  useEffect(() => {
    let timeout;

    function runAtNextMinute() {
      const date = new Date();
      const timeoutInSeconds =
        date.getMinutes() === 59 ? 60 - date.getSeconds() : 10;

      timeout = setTimeout(() => {
        setDayTime(getCurrentDaytime(new Date()));
        runAtNextMinute();
      }, timeoutInSeconds * 1000);
    }

    runAtNextMinute();

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return dayTime;
}
