import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import AriaReadable from '../../components/AriaReadable';
import { Routes } from '../../types/enums';
import useInAuth from '../useInAuth';

type Props = RouteComponentProps & {};

const NAVIGATION_MAP = {
  [Routes.AuthMain]: 'intro page',
  [Routes.Login]: 'Login',
  [Routes.SignupCreateAccount]: 'Create your replika',
  [Routes.SignupChooseYourAIFriend]: 'customize your Replika image',
  [Routes.SignupChooseReplikaName]: 'choose a name for your Replika',
  [Routes.SignupAlmostThere]: 'finishing up',
};

/**
 * This component is used for accessibility navigation status
 * Status updates automatically when route is changed
 *
 * For a custom status use A11yNotificationConsumer from A11yNotifier
 */
const A11yNavigationNotifier = ({ history }: Props) => {
  const [a11yMessage, setA11yMessage] = React.useState<string | null>(null);
  const inAuth = useInAuth();

  React.useEffect(() => {
    const unlisten = history.listen((location) => {
      if (!inAuth && NAVIGATION_MAP[location.pathname]) {
        setA11yMessage(NAVIGATION_MAP[location.pathname]);
      }
    });

    return () => unlisten();
  }, [history, inAuth]);

  return (
    <AriaReadable role="alert" aria-live="polite" aria-atomic="true">
      {a11yMessage && `Navigated to ${a11yMessage}`}
    </AriaReadable>
  );
};

export default withRouter(A11yNavigationNotifier);
