import { partition } from 'lodash';
import {
  isCustomizationVariationItem,
  isPetVariationItem,
  isRoomVariationItem,
  StoreItem,
  StoreVariationItem,
} from '../types/models';
import { BasketItem } from '../types/states';

const INCOMPATIBLE_CATEGORIES = {
  absolute_outfit: ['outfit', 'top', 'bottom', 'footwear'],
  outfit: ['absolute_outfit', 'bottom', 'top'],
  top: ['absolute_outfit', 'outfit'],
  bottom: ['absolute_outfit', 'outfit'],
  footwear: ['absolute_outfit'],
} as const;

const AVATAR_LOOK_CATEGORIES = ['style', 'hair', 'eyes'];

const REMOVE_INCOMPATIBLE_VARIATIONS_DISABLED = false;

export function isVariationInStoreItem(variationId: string, item: StoreItem) {
  return item.variations.some((v) => v.id === variationId);
}

export function areVariationsCompatible(
  v1: StoreVariationItem,
  v2: StoreVariationItem,
) {
  if (isRoomVariationItem(v1) && isRoomVariationItem(v2)) {
    return v1.slot_id !== v2.slot_id;
  }

  // only one pet is allowed for now
  if (isPetVariationItem(v1) && isPetVariationItem(v2)) {
    return false;
  }

  if (!isCustomizationVariationItem(v1) || !isCustomizationVariationItem(v2)) {
    return true;
  }

  if (!v1.unity_category || !v2.unity_category) {
    // We don't know for sure so leave it to unity engine to decide
    return true;
  }

  if (v1.unity_category === v2.unity_category) {
    // same category is never compatible
    return false;
  }

  if (v1.unity_category in INCOMPATIBLE_CATEGORIES) {
    return (
      INCOMPATIBLE_CATEGORIES[v1.unity_category].indexOf(v2.unity_category) ===
      -1
    );
  } else {
    return true;
  }
}

export function equalVariations(variations1, variations2) {
  if (variations1.length !== variations2.length) return false;
  let ids1 = variations1.map((v) => v.id).sort();
  let ids2 = variations2.map((v) => v.id).sort();

  return !ids1.find((id, idx) => ids2[idx] !== id);
}

export function getLookVariations<T extends StoreVariationItem>(
  variations: T[],
) {
  return variations.filter((v) => {
    if (!isCustomizationVariationItem(v)) return false;
    return AVATAR_LOOK_CATEGORIES.indexOf(v.unity_category) !== -1;
  });
}

export function getAccessoriesVariations<T extends StoreVariationItem>(
  variations: T[],
) {
  return variations.filter((v) => {
    if (!isCustomizationVariationItem(v)) return false;
    return AVATAR_LOOK_CATEGORIES.indexOf(v.unity_category) === -1;
  });
}

export function filterIncompatibleVariations<T extends StoreVariationItem>(
  variation: T,
  variations: T[],
  basket: BasketItem[] = [],
) {
  if (REMOVE_INCOMPATIBLE_VARIATIONS_DISABLED) {
    return [variations, basket] as const;
  }

  const [compatibleVariations, incompatibleVariations] = partition(
    variations,
    (v) => areVariationsCompatible(variation, v),
  );
  basket = basket.filter(
    (i) =>
      !incompatibleVariations.find((v) =>
        isVariationInStoreItem(v.id, i.storeItem),
      ),
  );

  return [compatibleVariations, basket] as const;
}

export function refillVariations<T extends StoreVariationItem>(
  variations: T[],
  defaultVariations: T[],
) {
  const compatibleDefaultVariations = variations.reduce((acc, v1) => {
    return acc.filter((v2) => areVariationsCompatible(v1, v2));
  }, defaultVariations);

  return compatibleDefaultVariations.length > 0
    ? [...compatibleDefaultVariations, ...variations]
    : variations;
}

export function mergeBoughtVariations<T extends StoreVariationItem>(
  variations: T[],
  newVariations: T[],
) {
  let boughtVariations = newVariations.filter((v) => v.bought_count > 0);

  const boughtIds = variations.map((v) => v.id);

  let variationsToAdd = boughtVariations.filter(
    (v) => boughtIds.indexOf(v.id) === -1,
  );

  if (variationsToAdd.length === 0) {
    return variations;
  }

  let mergedVariations = variationsToAdd.reduce(
    (acc, v1) => {
      return acc.filter((v2) => areVariationsCompatible(v1, v2)).concat(v1);
    },
    [...variations],
  );

  return mergedVariations;
}

export function mergeReceivedVariations<T extends StoreVariationItem>(
  selectedVariations: T[],
  receivedVariations: T[],
) {
  const variationsToBuy = selectedVariations.filter(
    (v) => v.bought_count === 0,
  );
  const compatibleVariations = variationsToBuy.reduce((acc, v1) => {
    return acc.filter((v2) => areVariationsCompatible(v1, v2));
  }, receivedVariations);
  return [...compatibleVariations, ...variationsToBuy];
}

export const variationTypeComparator = (
  a?: StoreItem['variation_type'],
  b?: StoreItem['variation_type'],
) => {
  if (a && (b === 'Room' || b === 'RoomStyle')) {
    return ['Room', 'RoomStyle'].includes(a);
  }

  return a === b;
};
