import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import {
  closeFeedbackPopup,
  dismissFeedback,
  sendFeedback,
} from '../../actions/feedback';
import { queueSystemNotification } from '../../actions/ui';
import feedbackReactionsImg from '../../icons/feedback_reactions.png';
import { FeedbackType } from '../../types/models';
import AriaButton from '../legacy/AriaButton';
import ChatPopup from './ChatPopup';
import PopupCloseButton from './PopupCloseButton';

type Props = {
  className?: string;
};

function FeedbackPopup(props: Props) {
  const dispatch = useDispatch();

  const sessionId = useSelector(
    (state) => state.feedback.feedbackData?.sessionId,
  );
  const feedbackView = useSelector(
    (state) => state.feedback.feedbackData?.feedbackView,
  );

  const { className } = props;

  const handleFeedback = (feedback: FeedbackType) => {
    if (sessionId && feedbackView) {
      dispatch(sendFeedback(sessionId, feedback, feedbackView));
    }
    dispatch(closeFeedbackPopup());
    dispatch(queueSystemNotification('Thank you for your feedback!'));
  };

  const handleDismiss = () => {
    if (sessionId && feedbackView) {
      dispatch(dismissFeedback(sessionId, feedbackView));
    } else {
      dispatch(closeFeedbackPopup());
    }
  };

  const feedbackEmojis = (
    <FeedbackPopupReactions>
      <FeedbackPopupButton
        label="Positive"
        onClick={() => {
          handleFeedback('Positive');
        }}
      />

      <FeedbackPopupButton
        label="Neutral"
        onClick={() => {
          handleFeedback('Neutral');
        }}
      />

      <FeedbackPopupButton
        label="Negative"
        onClick={() => {
          handleFeedback('Negative');
        }}
      />
    </FeedbackPopupReactions>
  );

  return (
    <FeedbackPopupRoot className={className}>
      <FeedbackCloseButton onClick={handleDismiss} />
      <FeedbackPopupTitle>
        How does this conversation make you feel?
      </FeedbackPopupTitle>
      {feedbackEmojis}
    </FeedbackPopupRoot>
  );
}

export default FeedbackPopup;

const FeedbackPopupRoot = styled(ChatPopup)`
  width: 100%;
  max-width: 360px;
  margin-inline: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(25px);
  border-radius: 24px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 300px;
  }
`;

const FeedbackPopupTitle = styled.div`
  padding-right: 20px;
  font-family: ${(p) => p.theme.fonts.display};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${(p) => p.theme.fgColor};
  margin-bottom: 10px;
`;

const FeedbackPopupReactions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

const getButtonBackgroundPosition = (props: { label: FeedbackType }) => {
  switch (props.label) {
    case 'Positive':
      return '60px 0';
    case 'Neutral':
      return '140px 0';
    default:
      return '0 0';
  }
};

const FeedbackPopupButton = styled(AriaButton)<{ label: FeedbackType }>`
  border: 0;
  padding: 0;
  background-color: transparent;
  background-image: url(${feedbackReactionsImg});
  background-size: auto 100%;
  background-position: ${getButtonBackgroundPosition};
  width: 60px;
  height: 60px;
  cursor: pointer;

  &:active {
    transform: scale(0.9);
    outline: none;
  }

  &:focus {
    outline: none;
  }
`;

const FeedbackCloseButton = styled(PopupCloseButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 10px;
  left: auto;
  right: 10px;
  width: 28px;
  height: 28px;
  border-radius: 11px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20);
`;
