import styled from 'styled-components/macro';

/**
 * Basic text block used for alert messages for errors in form
 */
const AriaAlertText = styled.span.attrs({
  role: 'alert',
  'aria-live': 'assertive',
})``;

export default AriaAlertText;
