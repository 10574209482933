import styled from 'styled-components/macro';

const DialogFooter = styled.div`
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
  padding: 30px;
`;

export default DialogFooter;
