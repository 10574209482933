import { useSetAtom } from 'jotai';
import { useLayoutEffect } from 'react';
import { MetricsEvents } from '../types/enums';
import getWebglVersion from '../utils/getWebglVersion';
import useLogEventFirstRender from '../utils/useLogEventFirstRender';
import { useUnity3dDisabled } from './AvatarUnity/useUnity3dDisabled';
import { avatarStateAtom } from './atoms';

type Props = {
  children?: React.ReactNode;
  enabled?: boolean;
  force2d?: boolean;
};

export default function AvatarProvider({ children, enabled, force2d }: Props) {
  const setAvatarState = useSetAtom(avatarStateAtom);
  const disabled3d = useUnity3dDisabled();
  const avatarAvailable = !force2d && !disabled3d;

  const isWebGLSupported = getWebglVersion() === 2;

  useLogEventFirstRender(
    MetricsEvents.UnityWebglNotSupported,
    {
      isWebGLSupported,
    },
    !isWebGLSupported,
  );

  useLayoutEffect(() => {
    if (!enabled) return;
    setAvatarState(avatarAvailable ? '3d' : '2d');

    return () => {
      setAvatarState('disabled');
    };
  }, [setAvatarState, enabled, avatarAvailable]);

  return <>{children}</>;
}
