import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { MetricsEvents } from '../types/enums';
import { AnalyticsEventProperties, logEvent } from './metrics';

export default function useLogEventFirstRender(
  event: MetricsEvents,
  eventProperties: AnalyticsEventProperties = {},
  condition: boolean = true,
) {
  const userId = useSelector((state) => state.auth.persist.userId);
  const firstRenderRef = useRef(true);

  return useEffect(() => {
    if (!firstRenderRef.current || !condition) return;

    logEvent(event, eventProperties, userId);
    firstRenderRef.current = false;

    // eslint-disable-next-line local-rules/exhaustive-deps
  }, [userId, condition]);
}
