import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { logEvent } from '@amplitude/analytics-browser';
import { replace } from 'connected-react-router';
import { setActiveDialog } from '../../../actions/ui';
import {
  AgeGateStatus,
  Dialogs,
  MetricsEvents,
  Routes,
} from '../../../types/enums';
import { HELP_REPLIKA_URL, TERMS_REPLIKA_URL } from '../../../utils/uri';
import ConfirmationDialog from '../ConfirmationDialog';

type AgeGateDialogProps = {
  status: AgeGateStatus | undefined;
  onPrimaryClick?: () => void;
};

function AgeGateDialog({ status, onPrimaryClick }: AgeGateDialogProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (status === AgeGateStatus.RequestAge) {
      logEvent(MetricsEvents.SetDOBPopUpShown);
    }
  }, [status]);

  const handleSecondaryClick = () => {
    window.open(TERMS_REPLIKA_URL, '_blank');
  };

  const handlePrimaryClick = () => {
    if (typeof onPrimaryClick === 'function') {
      onPrimaryClick();
      return;
    }

    if (status === AgeGateStatus.Locked) {
      window.open(HELP_REPLIKA_URL, '_blank');
      return;
    }

    dispatch(replace(Routes.Chat));

    dispatch(
      setActiveDialog({
        type: Dialogs.AgeRange,
        onSubmit: () => dispatch(setActiveDialog(null)),
      }),
    );
  };

  return (
    <ConfirmationDialog
      content={{
        title:
          status === AgeGateStatus.Locked
            ? "You're not old enough to\u00a0use\u00a0Replika"
            : 'Confirm your age',
        description:
          status === AgeGateStatus.Locked
            ? 'You do not meet the minimum age requirement. You must be over 18 to use Replika.'
            : 'We need to make sure that you meet the minimum age requirement. Select your age to continue using Replika.',
        secondaryText: 'Terms of service',
        primaryText:
          status === AgeGateStatus.Locked ? 'Contact Support' : 'Confirm age',
      }}
      onClose={() => null}
      onSecondaryClick={handleSecondaryClick}
      onPrimaryClick={handlePrimaryClick}
    />
  );
}

export default AgeGateDialog;
