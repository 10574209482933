import { useRef } from 'react';
import styled from 'styled-components/macro';
import { MetricsEvents } from '../../../../types/enums';
import { MemoryPerson } from '../../../../types/models';
import useApi from '../../../../utils/useApi';
import useFocusGroup from '../../../../utils/useFocusGroup';
import useLogEvent from '../../../../utils/useLogEvent';
import { getMemoryRelations } from '../../actions';
import PersonListItem from './PersonListItem';

type Props = {
  persons: MemoryPerson[];
  selectableMode?: boolean;
  personsSelected?: string[];
  onPersonsSelectedChange: (personsSelected: string[]) => void;
  children?: React.ReactNode;
};

export default function PersonList({
  persons,
  selectableMode = false,
  personsSelected = [],
  onPersonsSelectedChange,
  children,
}: Props) {
  const logEvent = useLogEvent();

  const relations = useApi(
    (state) => state.memory.persist.relations,
    getMemoryRelations,
    { memoDeepEqual: true },
  );

  const rootElRef = useRef<HTMLUListElement>(null);

  useFocusGroup(
    {
      getGroupElements: () => {
        return (
          rootElRef.current?.getElementsByTagName(
            selectableMode ? 'button' : 'a',
          ) ?? []
        );
      },
      options: {
        keybindings: {
          next: { key: 'ArrowRight' },
          prev: { key: 'ArrowLeft' },
        },
        wrap: true,
      },
    },
    [persons],
  );

  return (
    <PersonListRoot ref={rootElRef}>
      {persons.map((person, idx) => {
        const selected = personsSelected?.includes(person.id);
        const relationName =
          relations.find((relation) => relation.id === person.relation_id)
            ?.name ?? 'Other';

        return (
          <PersonListItem
            key={person.id}
            selected={selected}
            relationName={relationName}
            person={person}
            selectableMode={selectableMode}
            tabIndex={idx === 0 ? 0 : -1}
            onSelect={(id) => {
              if (selectableMode) {
                if (selected) {
                  onPersonsSelectedChange(
                    personsSelected.filter((personId) => personId !== id),
                  );
                } else {
                  onPersonsSelectedChange([...personsSelected, id]);
                }
              } else {
                logEvent(MetricsEvents.MemoryItemTapped);
              }
            }}
          />
        );
      })}
      {children}
    </PersonListRoot>
  );
}

const PersonListRoot = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, 84px);
  gap: 10px;
`;
