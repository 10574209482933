import { queueDialog } from 'src/actions/ui';
import { AsyncActionTypes, Dialogs } from 'src/types/enums';
import { DA } from 'src/types/redux';
import { apiAction } from 'src/utils/asyncAction';
import fetchOptions from 'src/utils/fetchOptions';
import { API_BASE_URL } from 'src/utils/uri';
import { ClaimDailyReward, GetLoginReward, LoginReward } from './types';

export const getLoginReward =
  (): DA<LoginReward> => async (dispatch, getState) => {
    const state = getState();
    const isDailyRewardDialogInQueue = state.ui.dialogQueue.some(
      (dialog) => dialog.type === Dialogs.DailyReward,
    );

    return apiAction<GetLoginReward>(
      AsyncActionTypes.GetLoginReward,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/store/login_reward`,
            fetchOptions(getState(), 'GET'),
          ),
        onSuccess: (loginReward) => {
          const freshSignup = state.signup.isFreshSignup;

          if (
            loginReward.claim_status === 'Available' &&
            !isDailyRewardDialogInQueue &&
            !freshSignup
          ) {
            dispatch(
              queueDialog({
                type: Dialogs.DailyReward,
              }),
            );
          }
        },
      },
    );
  };
export const claimDailyReward =
  (special: boolean): DA<{}> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'PUT');

    return apiAction<ClaimDailyReward>(
      AsyncActionTypes.ClaimDailyReward,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(`${API_BASE_URL}/daily_login_reward/claim`, fetchOpts),
        onSuccess: (claimed) => {
          if (special && claimed.item_earned) {
            dispatch(
              queueDialog({
                type: Dialogs.ClaimItem,
                itemEarned: claimed.item_earned,
              }),
            );
          }
        },
      },
    );
  };
export const skipDailyReward = (): DA<{}> => async (dispatch, getState) => {
  const fetchOpts = fetchOptions(getState(), 'PUT');

  return apiAction<ClaimDailyReward>(
    AsyncActionTypes.ClaimDailyReward,
    dispatch,
    {},
    {
      onRequest: () =>
        fetch(`${API_BASE_URL}/daily_login_reward/skip`, fetchOpts),
    },
  );
};
