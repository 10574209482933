type KeyCode =
  | 'Enter'
  | 'Escape'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'ArrowUp'
  | 'ArrowDown'
  | 'Space'
  | 'KeyT'
  | 'KeyF';

type KeyCombination =
  | KeyCode
  | {
      code: KeyCode;
      modifiers: { alt?: boolean; ctrl?: boolean; shift?: boolean };
    };

/**
 * Note the difference:
 * event.code is the physical key pressed (e.g. "KeyA")
 * event.key is the logical key pressed (e.g. "a" in English, "ф" in Russian)
 * event.keyCode is deprecated (corresponds to event.code)
 */
export default function isKeyCombination(
  event: KeyboardEvent | React.KeyboardEvent,
  keys: KeyCombination | KeyCombination[],
) {
  keys = Array.isArray(keys) ? keys : [keys];
  for (const key of keys) {
    if (typeof key === 'string') {
      if (event.code === key) {
        return true;
      }
    } else {
      if (event.code === key.code) {
        if (key.modifiers.alt && !event.altKey) {
          continue;
        }
        if (key.modifiers.ctrl && !event.ctrlKey) {
          continue;
        }
        if (key.modifiers.shift && !event.shiftKey) {
          continue;
        }
        return true;
      }
    }
  }
  return false;
}
