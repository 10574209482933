import type {
  CreateSubscriptionActions,
  OnApproveActions,
  OnApproveData,
  PayPalScriptOptions,
} from '@paypal/paypal-js/types';
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import styled from 'styled-components/macro';
import { SubscriptionListing } from '../../types/models';
import { PAYPAL_DISABLED_FUNDING_OPTIONS } from '../../utils/constants';
import { captureError } from '../../utils/initSentry';
import CircleSpinner from '../CircleSpinner';

const ConnectedButtons = ({
  planId,
  userId,
  onSuccess,
  onStatusChange,
  onError,
}: {
  planId: string;
  userId: string;
  onSuccess: (id: string, planId: string) => Promise<{} | void>;
  onStatusChange: (status: 'idle' | 'subscribing') => void;
  onError: (message: string) => void;
}) => {
  const [{ isPending }] = usePayPalScriptReducer();

  const createSubscription = async (
    data: Record<string, unknown>,
    actions: CreateSubscriptionActions,
  ) => {
    onStatusChange('subscribing');

    return actions.subscription
      .create({
        plan_id: planId,
        custom_id: userId,
      })
      .then((orderId) => {
        return orderId;
      });
  };

  const handleApprove = async (
    data: OnApproveData,
    actions: OnApproveActions,
  ) => {
    onStatusChange('idle');

    return actions!.subscription!.get().then(() => {
      if (data.subscriptionID) {
        onSuccess(data.subscriptionID, planId);
      }
    });
  };

  const handleCancel = async () => onStatusChange('idle');

  const handleError = async (err: Record<string, unknown>) => {
    captureError(err);

    onStatusChange('idle');

    if (err.message && typeof err.message === 'string') {
      onError(err.message);
    } else {
      const message =
        err.message &&
        typeof err.message === 'object' &&
        err.message.hasOwnProperty('message') &&
        typeof err.message.message === 'string'
          ? err.message.message
          : 'Error while sending request';

      onError(message);
    }
  };

  return (
    <>
      {isPending ? <StyledSpinner /> : null}
      <PayPalButtons
        forceReRender={[planId]}
        createSubscription={createSubscription}
        style={{
          label: 'paypal',
          color: 'gold',
          shape: 'pill',
        }}
        onApprove={handleApprove}
        onCancel={handleCancel}
        onError={handleError}
      />
    </>
  );
};

const PayPalSubscription = ({
  paypalClientId,
  selectedSubscription,
  userId,
  onSuccess,
  onStatusChange,
  onError,
}: {
  paypalClientId: string;
  selectedSubscription: SubscriptionListing;
  userId: string;
  onSuccess: (id: string, planId: string) => Promise<{} | void>;
  onStatusChange: (status: 'idle' | 'subscribing') => void;
  onError: (message: string) => void;
}) => {
  const initialOptions: PayPalScriptOptions = {
    'client-id': paypalClientId,
    currency: 'USD',
    components: 'buttons',
    intent: 'subscription',
    vault: true,
    'disable-funding': PAYPAL_DISABLED_FUNDING_OPTIONS,
  };

  const planId = selectedSubscription.paypal_product_id;

  return (
    <PayPalScriptProvider options={initialOptions}>
      <ConnectedButtons
        planId={planId}
        userId={userId}
        onSuccess={onSuccess}
        onStatusChange={onStatusChange}
        onError={onError}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalSubscription;

const StyledSpinner = styled(CircleSpinner)`
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  color: ${(p) => p.theme.dimmedFgColor};
`;
