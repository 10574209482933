import { useState } from 'react';
import styled from 'styled-components/macro';
import { AvatarAtom } from '../../core/types';
import { BotPatch } from '../../types/models';
import { FormSlider } from '../Sliders';

export const AgeRange = ({
  avatar,
  updateBot,
}: {
  updateBot: (patch: BotPatch) => void;
  avatar: AvatarAtom;
}) => {
  const [age, setAge] = useState(avatar?.age || 0);

  return (
    <RangeRow>
      <RangeTitle>Younger</RangeTitle>
      <RangeSlider $grow={5}>
        <FormSlider
          step={1}
          min={0}
          max={100}
          value={[(age || 0) * 100]}
          onValueChange={([value]) => {
            const botAge = (value || 0) / 100;
            setAge(botAge);
            updateBot({
              avatar_v2: {
                age: botAge,
              },
            });
          }}
        />
      </RangeSlider>
      <RangeTitleRight>Older</RangeTitleRight>
    </RangeRow>
  );
};

const RangeRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 30px;
`;

const RangeTitle = styled.div`
  flex: 1 1;
  padding-right: 5px;
`;

const RangeSlider = styled.div<{ $grow?: number }>`
  flex: 4 4;
`;

const RangeTitleRight = styled(RangeTitle)`
  text-align: right;
`;
