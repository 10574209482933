import styled from 'styled-components/macro';

const UnreadBadge = styled.div`
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: linear-gradient(180deg, #fffa79 0, #efd445 100%);
`;

export default UnreadBadge;
