import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import {
  CenteredDialogBodyForm,
  Dialog,
  DialogButton,
  DialogFooter,
  DialogHeader,
} from '../../DialogLayout/legacy/DialogLayout';
// import useLogEvent from '../../../utils/useLogEvent';
import { updateUserProfile } from '../../../actions/profile';
import { PronounsValues } from '../../../types/enums';
import PronounSelector from '../../legacy/PronounSelector';

type Props = {
  onClose: () => void;
};

const ReboardingAddPronounDialog = ({ onClose }: Props) => {
  // const logEvent = useLogEvent();
  // const [eventLogged, setEventLogged] = React.useState(false);
  // React.useEffect(() => {
  //   !eventLogged && logEvent(MetricsEvents.MeetButtonShown);
  //   setEventLogged(true);

  // }, [logEvent, eventLogged]);

  const [pronoun, setPronoun] = React.useState<PronounsValues | undefined>(
    undefined,
  );

  const bot = useSelector((state) => state.profile.persist.bot);
  const botName = bot?.name ?? 'Your Replika';

  const dispatch = useDispatch();

  return (
    <Dialog data-testid="reboarding-add-pronoun-dialog">
      <DialogHeader />
      <StyledDialogBody
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(updateUserProfile({ pronoun })).then(() => {
            onClose();
          });
        }}
      >
        <Title>Let Replika know your pronouns</Title>
        <Description>
          {botName} needs to know your pronouns to address you correctly in the
          conversation, diaries, and memory.
        </Description>
        <PronounSelector value={pronoun} onChange={setPronoun} />
        <DialogFooter>
          <DialogButton type="submit" disabled={!pronoun}>
            Done
          </DialogButton>
        </DialogFooter>
      </StyledDialogBody>
    </Dialog>
  );
};

export default ReboardingAddPronounDialog;

const StyledDialogBody = styled(CenteredDialogBodyForm)`
  max-width: 360px;
`;

const Title = styled.h3``;

const Description = styled.p`
  margin-bottom: 30px;
  padding: 0 30px;
`;
