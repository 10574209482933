import { Location } from 'history';
import { createContext } from 'react';
import { TransitionStatus } from 'react-transition-state';

const TransitionContext = createContext<{
  status: TransitionStatus;
  timeout: number;
  params: {};
  location: Location | null;
  instant?: boolean;
}>({
  status: 'unmounted',
  timeout: 0,
  params: {},
  location: null,
});

export default TransitionContext;
