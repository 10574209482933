import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import { buyUserItems, switchUserItem } from '../../../actions/store';
import { queueSystemNotification } from '../../../actions/ui';
import { PriceIcon } from '../../../components/legacy/WalletIcons';
import { Routes } from '../../../types/enums';
import { StoreItem } from '../../../types/models';
import getPriceLabel from '../../../utils/getPriceLabel';
import {
  DialogButton,
  DialogFooter,
} from '../../DialogLayout/legacy/DialogLayout';

type ItemPrice = {
  currency: 'coin' | 'gem';
  amount: number;
};

function PurchaseDialogFooter({
  item,
  onError,
  onClose,
  itemTypeName,
}: {
  item: StoreItem;
  onError: (message: string | null) => void;
  onClose: () => void;
  itemTypeName: string;
}) {
  const dispatch = useDispatch();

  const [purchaseState, setPurchaseState] = React.useState<
    'idle' | 'purchasing'
  >('idle');

  const handleBuyItemClick = async () => {
    const variation = item.variations[0];
    onError(null);
    if (variation && purchaseState === 'idle') {
      setPurchaseState('purchasing');
      try {
        await dispatch(buyUserItems([{ id: variation.id, count: 1 }]));

        dispatch(queueSystemNotification(`${itemTypeName} updated`, 'check'));
        onClose();
      } catch (e) {
        if (e instanceof Error) {
          onError(e.message);
        }
      } finally {
        setPurchaseState('idle');
      }
    }
  };

  return (
    <DialogFooter>
      <DialogButton
        disabled={purchaseState !== 'idle'}
        onClick={handleBuyItemClick}
      >
        Buy for{' '}
        <span aria-label={getPriceLabel(item.price)}>
          <StyledPriceIcon price={item.price} />
          {item.price.amount}
        </span>
      </DialogButton>
    </DialogFooter>
  );
}

function BoughtItemFooter({
  item,
  itemTypeName,
  onError,
  onClose,
}: {
  item: StoreItem;
  itemTypeName: string;
  onError: (message: string | null) => void;
  onClose: () => void;
}) {
  const dispatch = useDispatch();

  const [switchState, setSwitchState] = React.useState<'idle' | 'switching'>(
    'idle',
  );

  let itemEnabled = true;
  switch (item.variation_type) {
    case 'Dialog':
      itemEnabled = !!item.variations[0]?.enabled;
      break;
  }

  const handleSwitchItemClick = async () => {
    onError(null);
    const variation = item.variations[0];
    if (variation && switchState === 'idle') {
      setSwitchState('switching');
      try {
        await dispatch(switchUserItem(variation.id, !itemEnabled));

        dispatch(queueSystemNotification(`${itemTypeName} updated`, 'check'));
        onClose();
      } catch (e) {
        if (e instanceof Error) {
          onError(e.message);
        }
      } finally {
        setSwitchState('idle');
      }
    }
  };

  return (
    <DialogFooter>
      <DialogButton
        disabled={switchState !== 'idle'}
        onClick={handleSwitchItemClick}
      >
        {itemEnabled ? 'Disable' : 'Enable'}
      </DialogButton>
    </DialogFooter>
  );
}

function ExtraCoinsNeededFooter({
  currencyNeeded,
  onClose,
}: {
  currencyNeeded: ItemPrice;
  onClose: () => void;
}) {
  return (
    <>
      <ExtraNeeded>
        You need extra <StyledPriceIcon price={currencyNeeded} />
        {currencyNeeded.amount}
      </ExtraNeeded>
      <DialogFooter style={{ paddingTop: 20 }}>
        <DialogButton
          onClick={onClose}
          to={{
            pathname: Routes.Wallet,
            state: {
              source: 'store',
            },
          }}
        >
          Get more coins and gems
        </DialogButton>
      </DialogFooter>
    </>
  );
}

function StoreItemDialogFooter({
  currencyNeeded,
  onError,
  item,
  onClose,
  itemTypeName,
}: {
  currencyNeeded: null | ItemPrice;
  item: StoreItem;
  onError: (message: string | null) => void;
  onClose: () => void;
  itemTypeName: string;
}) {
  const variation = item.variations[0];
  const itemBought = variation && variation.bought_count > 0;

  if (currencyNeeded) {
    return (
      <ExtraCoinsNeededFooter
        currencyNeeded={currencyNeeded}
        onClose={onClose}
      />
    );
  } else if (itemBought) {
    return (
      <BoughtItemFooter
        item={item}
        itemTypeName={itemTypeName}
        onError={onError}
        onClose={onClose}
      />
    );
  } else {
    return (
      <PurchaseDialogFooter
        item={item}
        itemTypeName={itemTypeName}
        onError={onError}
        onClose={onClose}
      />
    );
  }
}

export default StoreItemDialogFooter;

const StyledPriceIcon = styled(PriceIcon)`
  width: 12px;
  margin: 0 3px;
`;

const ExtraNeeded = styled.div`
  margin-top: 20px;
  color: ${(p) => p.theme.dimmedFgColor};
`;
