import { AuthType } from 'src/types/models';

export const DEFAULT_SUBSCRIPTION_TITLE = 'Unlock Replika PRO';
export const DEFAULT_SUBSCRIPTION_SUBTITLE =
  'Get access to the advanced model, unlimited voice messages, image generation, activities, and more.';

export const AUTH_TYPES_WITH_PASSWORDS: AuthType[] = [
  'email',
  'single_use_token',
];
