/**
 * Returns the generation date (accurate up to the second) that objectId was generated
 * @see https://www.mongodb.com/docs/manual/reference/method/ObjectId.getTimestamp/#mongodb-method-ObjectId.getTimestamp
 * @see https://steveridout.com/mongo-object-time/
 */
const dateFromObjectId = function (objectId: string): Date {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

export default dateFromObjectId;
