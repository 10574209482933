import { useLocation } from 'react-router';
import styled from 'styled-components/macro';
import NavigationPanel from '../../features/NavigationPanel';
import { ReactComponent as BackIcon } from '../../icons/ArrowBack.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as HamburgerIcon } from '../../icons/Hamburger.svg';
import HeaderProfile from '../../routes/Main/HeaderProfile';
import { ModalRoutes } from '../../types/enums';
import { WalletScreenOpenedSource } from '../../types/states';
import toModalRoute from '../../utils/toModalRoute';
import { AnchorType } from '../../utils/useGoBack';
import {
  ButtonWithSpinnerProps,
  Routes,
  TranslusentIconButton,
} from '../Buttons';
import { MAX_PAGE_WIDTH } from '../FullscreenLayout';
import { XpBarWithMood } from '../XpBarWithMood';

const AppHeaderRoot = styled.header<{
  $appearance?: 'dimmed' | 'hidden';
}>`
  position: relative;
  z-index: 10;
  margin: 0 auto;
  width: 100%;
  padding: 15px 15px 0;
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  transition: opacity 0.3s ease-out;
  opacity: ${(p) =>
    p.$appearance === 'hidden' ? 0 : p.$appearance === 'dimmed' ? 0.3 : 1};
  pointer-events: ${(p) => (p.$appearance === 'hidden' ? 'none' : 'inherit')};

  /* fixes overflow issue on android */
  @media (max-height: 400px) {
    display: none;
  }

  max-width: ${MAX_PAGE_WIDTH}px;

  color: ${(p) => p.theme.fgColor};

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding: 20px 20px 0;
  }
`;

const AppHeaderLeft = styled.div`
  display: flex;
  gap: 10px;
  flex: 1 1 0%;
  max-width: 100%;
`;

const AppHeaderRight = styled(AppHeaderLeft)`
  text-align: right;
  justify-content: flex-end;
  flex: 1 0 0%;
`;

const AppHeaderTitle = styled.h1`
  flex: 1;
  margin: 10px;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  white-space: nowrap;

  @media ${(p) => p.theme.breakpoints.tablet} {
    font-size: 28px;
    line-height: 32px;
  }
`;

const AppHeaderMenu = styled(NavigationPanel)`
  height: auto;
  margin-inline: 10px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 26px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 36px;
  }
`;

function AppHeaderCloseButton(props: ButtonWithSpinnerProps) {
  return (
    <TranslusentIconButton label="close" {...props}>
      <StyledCloseIcon />
    </TranslusentIconButton>
  );
}

function AppHeaderBackButton(props: ButtonWithSpinnerProps) {
  return (
    <TranslusentIconButton label="back" {...props}>
      <BackIcon />
    </TranslusentIconButton>
  );
}

const SettingsButton = styled(TranslusentIconButton)`
  flex-shrink: 0;
  & img {
    height: 28px;
    width: 28px;
  }
`;

function AppHeaderSettingsButton({ source }: { source?: string }) {
  return (
    <SettingsButton to={toModalRoute(ModalRoutes.Settings)} label="settings">
      <HamburgerIcon />
    </SettingsButton>
  );
}

function AppHeaderProfileButton({
  anchor = 'default',
}: {
  anchor?: AnchorType | null;
}) {
  return <HeaderProfile to={Routes.Chat} />;
}

/**
 * The default app header implementation.
 * If you need a custom header, you can use AppHeader.Root and other components directly,
 * using this component as a reference.
 */
function AppHeaderDefault({
  anchor = null,
  source,
  appearance,
  headerRight,
  ...rest
}: {
  anchor?: AnchorType | null;
  source?: WalletScreenOpenedSource;
  appearance?: 'dimmed' | 'hidden';
  headerRight?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>) {
  const location = useLocation();
  const isChat = location.pathname === Routes.Chat;

  return (
    <>
      <AppHeaderRoot {...rest} $appearance={appearance}>
        <AppHeaderLeft>
          <AppHeaderProfileButton anchor={anchor} />
          {isChat && <XpBarWithMood />}
        </AppHeaderLeft>
        <AppHeaderMenu />
        <AppHeaderRight>
          {headerRight}
          <AppHeaderSettingsButton />
        </AppHeaderRight>
      </AppHeaderRoot>
    </>
  );
}

export {
  AppHeaderBackButton as BackButton,
  AppHeaderCloseButton as CloseButton,
  AppHeaderLeft as Left,
  AppHeaderMenu as Menu,
  AppHeaderProfileButton as ProfileButton,
  AppHeaderRight as Right,
  AppHeaderRoot as Root,
  AppHeaderSettingsButton as SettingsButton,
  AppHeaderTitle as Title,
  AppHeaderDefault as default,
};
