import murmurhash from 'murmurhash';
import { VoiceSampleStreamPayload } from '../../types/websocket';
import { captureError } from '../../utils/initSentry';

const EVENT_CHUNKS_WAITING_TIMEOUT = 1000;
type EventsMapItem = {
  id: string;
  meta: VoiceSampleStreamPayload['meta'];
  events: VoiceSampleStreamPayload[];
  timeoutId?: NodeJS.Timeout;
};

type ProcessBufferCallback = (typedArray: Uint8Array, eventId: string) => void;

export class VoiceCallBufferClass {
  private lastProcessedEventTimestamp: number = Number.NEGATIVE_INFINITY;
  private lastProcessedEventId: string | null = null;
  private eventsQueueMap: Map<string, EventsMapItem> = new Map();
  private chunksWaitingTimeout: number;

  constructor(chunksWaitingTimeout: number) {
    this.chunksWaitingTimeout = chunksWaitingTimeout;
  }

  handleEvent(
    event: VoiceSampleStreamPayload,
    onProcess: ProcessBufferCallback,
  ) {
    if (event.meta.timestamp < this.lastProcessedEventTimestamp) {
      captureError(
        `VoiceCall: Wrong order. Last processed ID - ${this.lastProcessedEventId}, current ID - ${event.id}`,
      );
      return;
    }

    this.addEventToQueueMap(event, onProcess);

    const messageData = this.eventsQueueMap.get(event.id);
    if (
      this.eventsQueueMap.size === 1 &&
      messageData?.events.length === event.meta.total_chunks
    ) {
      this.prepareAndProcessVoiceBuffer(
        messageData.events
          .sort((a, b) => a.meta.chunk_index - b.meta.chunk_index)
          .map((item) => item.data),
        messageData,
        onProcess,
      );
      this.removeEventFromQueueMap(messageData.id);
    }
  }

  private addEventToQueueMap(
    event: VoiceSampleStreamPayload,
    onProcess: ProcessBufferCallback,
  ) {
    if (this.eventsQueueMap.has(event.id) && event.meta.total_chunks > 1) {
      const current = this.eventsQueueMap.get(event.id)!;

      this.eventsQueueMap.set(event.id, {
        ...current,
        events: [...current.events, event],
      });
    } else {
      let timeoutId = this.setRemovalTimeout(event, onProcess);

      this.eventsQueueMap.set(event.id, {
        id: event.id,
        meta: event.meta,
        events: [event],
        timeoutId,
      });
    }
  }

  private setRemovalTimeout(
    event: VoiceSampleStreamPayload,
    onProcess: ProcessBufferCallback,
  ) {
    return setTimeout(() => {
      const eventsToProcess: EventsMapItem[] = [];

      for (let item of Array.from(this.eventsQueueMap.values())) {
        if (
          item.meta.timestamp < this.lastProcessedEventTimestamp ||
          (item.id === event.id &&
            item.events.length !== item.meta.total_chunks)
        ) {
          this.removeEventFromQueueMap(item.id);

          captureError(
            item.meta.timestamp < this.lastProcessedEventTimestamp
              ? `VoiceCall: Wrong order. Last processed ID - ${this.lastProcessedEventId}, current ID - ${event.id}`
              : `VoiceCall: Not all chunks loaded after ${this.chunksWaitingTimeout}ms for ID - ${item.id}`,
          );

          continue;
        }

        if (item.events.length === item.meta.total_chunks) {
          eventsToProcess.push(item);

          this.removeEventFromQueueMap(item.id);
        }
      }

      eventsToProcess
        .sort((a, b) => a.meta.timestamp - b.meta.timestamp)
        .forEach((messageData) => {
          this.prepareAndProcessVoiceBuffer(
            messageData.events
              .sort((a, b) => a.meta.chunk_index - b.meta.chunk_index)
              .map((item) => item.data),
            messageData,
            onProcess,
          );
        });
    }, this.chunksWaitingTimeout);
  }

  private removeEventFromQueueMap(eventId: string) {
    const item = this.eventsQueueMap.get(eventId);

    if (item) {
      clearTimeout(item.timeoutId);
      this.eventsQueueMap.delete(item.id);
    }
  }

  private async prepareAndProcessVoiceBuffer(
    chunks: string[],
    event: EventsMapItem,
    onProcess: ProcessBufferCallback,
  ) {
    this.lastProcessedEventTimestamp = event.meta.timestamp;
    this.lastProcessedEventId = event.id;

    try {
      const typedArray = await getTypedArrayFromBase64Chunks(chunks);
      const checksum = murmurhash.v3(typedArray);
      // Server doesn't have UInt32 type, so we convert in from Int32
      const checksumFromServer = event.meta.checksum >>> 0;

      if (checksum !== checksumFromServer) {
        captureError(
          `VoiceCall: Wrong checksum for ID - ${event.id}. Received: ${event.meta.checksum}, calculated: ${checksum}`,
        );

        return;
      }

      onProcess(typedArray, event.id);
    } catch (e) {
      captureError(`VoiceCall: buffer preparation error for ID - ${event.id}`);
    }
  }
}

export function concatUint8Arrays(list: Uint8Array[]): Uint8Array {
  if (!Array.isArray(list)) return new Uint8Array(0);

  const typedArrays = list.filter((item) => item instanceof Uint8Array);

  const size = typedArrays.reduce((acc, cur) => acc + cur.length, 0);

  const result = new Uint8Array(size);

  let i = 0;

  for (let chunk of typedArrays) {
    result.set(chunk, i);

    i += chunk.length;
  }

  return result;
}

export async function getTypedArrayFromBase64Chunks(
  chunks: string[],
): Promise<Uint8Array> {
  try {
    const typedArrays = await Promise.all(
      chunks.map((chunkData) =>
        fetch(`data:application/octet-binary;base64,${chunkData}`)
          .then((res) => res.arrayBuffer())
          .then((buffer) => new Uint8Array(buffer)),
      ),
    );

    return concatUint8Arrays(typedArrays);
  } catch (e) {
    throw e;
  }
}

export const VoiceCallBuffer = new VoiceCallBufferClass(
  EVENT_CHUNKS_WAITING_TIMEOUT,
);
