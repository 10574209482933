import * as A from '../types/actions';
import { ToError, ToRequest, ToSuccess } from '../types/asyncActions';
import {
  ActionTypes,
  AsyncActionTypes,
  GenderValues,
  PronounsValues,
} from '../types/enums';
import { Reducer } from '../types/redux';
import { SignupState } from '../types/states';
import applyReducer from '../utils/applyReducer';

const clientLocale = navigator.languages?.length
  ? navigator.languages[0]
  : navigator.language;

const DEFAULT_STATE: SignupState = {
  signupStatus: 'initial',
  accountInfoCheckStatus: 'initial',
  accountInfoCheckError: null,
  name: '',
  email: '',
  password: '',
  replikaName: null,
  signupError: undefined,
  inputError: undefined,
  invalidEmailCounter: 0,
  uploadedUserpicUrl: undefined,
  gender: GenderValues.Female,
  pronoun: PronounsValues.She,
  clientLocale,
  birthday: undefined,
  signupInProgress: false,
  avatar: undefined,
  isFreshSignup: undefined,
  persist: {
    onboardingStatus: 'initial',
  },
};

type R<X extends A.AuthAction | A.InitAction> = Reducer<SignupState, X>;
type RRequest<X> = Reducer<SignupState, ToRequest<X>>;
type RError<X> = Reducer<SignupState, ToError<X>>;
type RSuccess<X> = Reducer<SignupState, ToSuccess<X>>;

const accountInfoCheckRequest: RRequest<A.CheckAccountInfo> = (state) => {
  return {
    ...state,
    accountInfoCheckStatus: 'submitting',
    accountInfoCheckError: null,
  };
};

const accountInfoCheckError: RError<A.CheckAccountInfo> = (state, action) => ({
  ...state,
  accountInfoCheckStatus: 'error',
  accountInfoCheckError: action.error,
});

const accountInfoCheckSuccess: RSuccess<A.CheckAccountInfo> = (state) => ({
  ...state,
  accountInfoCheckStatus: 'success',
});

const signupRequest: RRequest<A.Signup> = (state) => ({
  ...state,
  signupStatus: 'submitting',
});

const signupError: RError<A.Signup> = (state, action) => ({
  ...state,
  signupStatus: 'error',
  signupError: action.error.message,
});

const logoutSuccess: RSuccess<A.Logout> = (state) => ({
  ...DEFAULT_STATE,
});

const logoutError: RError<A.Logout> = (state) => ({
  ...DEFAULT_STATE,
});

const deleteAccountSuccess: RSuccess<A.DeleteAccount> = (state) => ({
  ...DEFAULT_STATE,
});

const signupSuccess: RSuccess<A.Signup> = (state, action) => ({
  ...state,
  signupStatus: 'success',
});

const updateSignupState: R<A.UpdateSignupState> = (state, action) => {
  return {
    ...state,
    ...(action.signupInput || {}),
  };
};

const setOnboardingStatus: R<A.SetOnboardingStatus> = (state, action) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      onboardingStatus: action.status,
    },
  };
};

const setFreshSignup: R<A.SetFreshSignup> = (state) => {
  return {
    ...state,
    isFreshSignup: true,
  };
};

export default function signup(
  state: SignupState = DEFAULT_STATE,
  action: A.AnyAction,
) {
  return applyReducer(
    'signup',
    {
      [ActionTypes.UpdateSignupState]: updateSignupState,
      [AsyncActionTypes.Logout]: {
        success: logoutSuccess,
        error: logoutError,
      },
      [AsyncActionTypes.DeleteAccount]: {
        success: deleteAccountSuccess,
      },
      [AsyncActionTypes.CheckAccountInfo]: {
        request: accountInfoCheckRequest,
        error: accountInfoCheckError,
        success: accountInfoCheckSuccess,
      },
      [AsyncActionTypes.Signup]: {
        request: signupRequest,
        error: signupError,
        success: signupSuccess,
      },
      [ActionTypes.SetOnboardingStatus]: setOnboardingStatus,
      [ActionTypes.SetFreshSignup]: setFreshSignup,
    },
    state,
    action,
  );
}
