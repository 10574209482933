import imageSourceSet from '../utils/imageSourceSet';

export type ModernImageFormat = 'avif' | 'webp';

type Props = React.ComponentPropsWithoutRef<'img'> & {
  src: string;
  maxResolution?: number;
  formats?: ModernImageFormat[];
};

function Picture({
  src,
  maxResolution = 3,
  alt = '',
  formats = ['avif', 'webp'],
  ...rest
}: Props) {
  return (
    <picture>
      {formats.indexOf('avif') !== -1 && (
        <source
          srcSet={imageSourceSet(src, maxResolution, 'avif').srcSet}
          type="image/avif"
        />
      )}
      {formats.indexOf('webp') !== -1 && (
        <source
          srcSet={imageSourceSet(src, maxResolution, 'webp').srcSet}
          type="image/webp"
        />
      )}
      <source srcSet={imageSourceSet(src, maxResolution).srcSet} />
      <img alt={alt} src={src} {...rest} />
    </picture>
  );
}

export default Picture;
