import styled from 'styled-components/macro';
import { MAIN_PAGE_URL_BASE } from '../../../utils/uri';
import useSubmitStatus from '../../../utils/useSubmitStatus';
import { AccentButton } from '../../Buttons';
import { Dialog, DialogBody } from '../../DialogLayout/legacy/DialogLayout';
import Picture from '../../Picture';

type Props = {
  content: {
    imgSrc?: string;
    imgShadow?: string;
    imgBgGradient?: string;
    title: string | React.ReactNode;
    description: string | React.ReactNode;
    confirmText?: string;
  };
  onConfirmClick?: () => void | Promise<void>;
  onClose: () => void;
};

const HintDialog = ({
  content: {
    imgSrc = `${MAIN_PAGE_URL_BASE}/attention.png`,
    imgShadow,
    title,
    description,
    confirmText = 'Got it',
  },

  onConfirmClick,
  onClose,
}: Props) => {
  const primarySubmitStatus = useSubmitStatus();
  const handlePrimaryClick = async () => {
    primarySubmitStatus.setStatus('submitting');
    await onConfirmClick?.();
    primarySubmitStatus.setStatus('success');
    onClose();
  };

  return (
    <StyledDialog mobileLayout="dialog">
      <StyledDialogBody>
        <HintImg src={imgSrc} />
        {imgShadow && <Shadow $shadowColor={imgShadow} />}

        <Title data-initialfocus tabIndex={-1}>
          {title}
        </Title>
        <Description>{description}</Description>

        <Controls>
          <PrimaryButton
            type="button"
            onClick={handlePrimaryClick}
            showSpinner={primarySubmitStatus.isSubmitting()}
          >
            {confirmText}
          </PrimaryButton>
        </Controls>
      </StyledDialogBody>
    </StyledDialog>
  );
};

export default HintDialog;

const StyledDialog = styled(Dialog)`
  position: relative;
  min-width: 295px;
  max-width: 335px;
  margin: 0 10px;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(25px);
`;

const StyledDialogBody = styled(DialogBody)`
  padding: 0 20px 20px;
`;

const HintImg = styled(Picture)`
  margin-top: 30px;
  width: 84px;
  height: 84px;
`;

const Title = styled.h3`
  margin: 10px 0 0;
  text-align: center;
  font-size: 28px;
  line-height: 32px;
  font-weight: 400;
  color: #fff;
`;

const Description = styled.div`
  margin: 30px 10px 0;
  text-align: center;
`;

const Controls = styled.div`
  margin: 40px 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PrimaryButton = styled(AccentButton)`
  width: 100%;
`;

const Shadow = styled.div<{ $shadowColor: string }>`
  position: absolute;
  content: '';
  top: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0;
  border-radius: 50%;
  background: radial-gradient(
    circle at 50% 50%,
    ${(p) => p.$shadowColor} 0%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: -1;

  width: 84px;
  height: 84px;
  filter: blur(5px);
  box-shadow: 0px 0px 42px ${(p) => p.$shadowColor};
`;
