import { ReactNode, Ref, forwardRef, useContext, useState } from 'react';
import styled from 'styled-components/macro';
import DialogContext from './DialogContext';

export const DialogScroll = forwardRef(function DialogScroll(
  { children }: { children: ReactNode },
  ref: Ref<HTMLDivElement>,
) {
  const { onScrollClick } = useContext(DialogContext);

  const [preventClick, setPreventClick] = useState(false);

  return (
    <DialogScrollRoot
      id="dialog-scroll"
      data-hidescrollbar
      onClick={(e) => {
        if (!preventClick) {
          onScrollClick(e);
        } else {
          setPreventClick(false);
        }
      }}
      onPointerDown={(e) => {
        if (
          e.target instanceof HTMLElement &&
          e.target.id !== 'dialog-scroll'
        ) {
          setPreventClick(true);
        }
      }}
    >
      {children}
    </DialogScrollRoot>
  );
});

// FIXME: do we still need this fix?
export const ActionSheetDialogScroll = styled(DialogScroll)`
  bottom: 0;
  /* fixes weird Chrome bug */
  overflow-y: hidden;
`;

const DialogScrollRoot = styled.div`
  flex: 1 0 auto;
  position: absolute;
  z-index: 1;
  overflow-y: auto;
  max-height: 100svh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;

  /* for box shadow */
  padding-block: 30px;
`;
