import styled from 'styled-components/macro';

const DialogHeaderRoot = styled.div`
  flex: 0 0 auto;
  font-size: 20px;
  position: relative;
  align-items: center;

  padding: 24px 30px 10px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding: 28px 30px 0;
  }
`;

const DialogHeaderTitle = styled.h3.attrs({
  tabIndex: -1,
  'data-initialfocus': true,
})<{ $closeable?: boolean }>`
  margin: ${(p) => (p.$closeable ? '0 30px 0 30px' : 0)};
  text-align: center;
  color: ${(p) => p.theme.fgColor};
  font-size: 20px;
  line-height: 20px;
  font-family: ${(p) => p.theme.fonts.display};
`;

const DialogHeaderSubtitle = styled.h4`
  display: block;
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  text-align: center;
  margin: 14px 0;
  width: 100%;
  font-family: ${(p) => p.theme.fonts.body};
`;

export {
  DialogHeaderRoot as Root,
  DialogHeaderSubtitle as Subtitle,
  DialogHeaderTitle as Title,
};
