import { CategoryTreeNode } from '../../types/models';

export default function findCategoryByName(
  name: string,
  category: CategoryTreeNode,
): CategoryTreeNode | null {
  if (category.name.toLowerCase() === name.toLowerCase()) return category;

  if (category.children) {
    for (let cat of category.children) {
      let found = findCategoryByName(name, cat);

      if (found) return found;
    }
  }

  return null;
}
