import * as A from '../types/actions';
import { ToSuccess } from '../types/asyncActions';
import { ActionTypes, AsyncActionTypes } from '../types/enums';
import { Reducer } from '../types/redux';
import { SubscriptionState } from '../types/states';
import applyReducer from '../utils/applyReducer';

const DEFAULT_STATE: SubscriptionState = {
  persist: {
    version: 2,
    subscription: null,
    features: [],
    reboardingDialogShown: false,
    stripeLatestInvoiceId: null,
    stripeCustomerId: null,
    stripeSubscriptionParams: null,
    shownSalesMap: {},
  },
  saleScreen: null,
  stripeConfig: null,
  stripeEnabled: false,
  paypalConfig: null,
  subscriptionEventData: null,
};

type R<X extends A.SubscriptionAction> = Reducer<SubscriptionState, X>;
type RSuccess<X> = Reducer<SubscriptionState, ToSuccess<X>>;

const setSubscription: RSuccess<A.GetSubscriptions> = (state, { result }) => ({
  ...state,
  persist: {
    ...state.persist,
    subscription: result.subscription || null,
    features: result.features || [],
  },
});

const setReboardingDialogShown: R<A.SetReboardingDialogShown> = (state) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      reboardingDialogShown: true,
    },
  };
};

const resetState = (state: SubscriptionState) => ({
  ...DEFAULT_STATE,
  persist: {
    ...DEFAULT_STATE.persist,
    reboardingDialogShown: state.persist.reboardingDialogShown,
    shownSalesMap: state.persist.shownSalesMap,
  },
});

const setStripeConfig: RSuccess<A.GetStripeConfig> = (state, { result }) => ({
  ...state,
  stripeConfig: result,
});

const setPayPalConfig: RSuccess<A.GetPayPalConfig> = (state, { result }) => ({
  ...state,
  paypalConfig: result,
});

const setCustomerId: RSuccess<A.CreateStripeCustomer> = (
  state,
  { result },
) => ({
  ...state,
  persist: {
    ...state.persist,
    stripeCustomerId: result.customer_id,
  },
});

const setStripePrices: RSuccess<A.GetStripePrices> = (state, { result }) => ({
  ...state,
  persist: {
    ...state.persist,
    stripeSubscriptionParams: result,
  },
});

const setLatestInvoiceId: R<A.SetStripeLatestInvoiceId> = (
  state,
  { invoiceId },
) => ({
  ...state,
  persist: {
    ...state.persist,
    stripeLatestInvoiceId: invoiceId,
  },
});

const setStripeEnabled: R<A.SetStripeEnabled> = (state) => ({
  ...state,
  stripeEnabled: true,
});

const setSubscriptionEventData: R<A.SetSubscriptionEventData> = (
  state,
  { subscriptionEventData },
) => ({
  ...state,
  subscriptionEventData,
});

const saleScreenReceived: R<A.SaleScreenReceived> = (
  state,
  { saleScreen },
) => ({
  ...state,
  saleScreen,
});

const saleScreenShown: R<A.SaleScreenShown> = (state, { id }) => ({
  ...state,
  persist: {
    ...state.persist,
    shownSalesMap: {
      ...state.persist.shownSalesMap,
      [id]: Date.now(),
    },
  },
});

export default function subscriptions(
  state: SubscriptionState = DEFAULT_STATE,
  action: A.AnyAction,
) {
  return applyReducer(
    'subscriptions',
    {
      [AsyncActionTypes.GetSubscriptions]: {
        success: setSubscription,
      },
      [ActionTypes.SetReboardingDialogShown]: setReboardingDialogShown,
      [AsyncActionTypes.Logout]: {
        success: resetState,
        error: resetState,
      },
      [AsyncActionTypes.DeleteAccount]: {
        success: resetState,
      },
      [AsyncActionTypes.GetStripeConfig]: {
        success: setStripeConfig,
      },
      [AsyncActionTypes.GetPayPalConfig]: {
        success: setPayPalConfig,
      },
      [AsyncActionTypes.CreateStripeCustomer]: {
        success: setCustomerId,
      },
      [AsyncActionTypes.GetStripePrices]: {
        success: setStripePrices,
      },
      [ActionTypes.SetStripeLatestInvoiceId]: setLatestInvoiceId,
      [ActionTypes.SetStripeEnabled]: setStripeEnabled,
      [ActionTypes.SetSubscriptionEventData]: setSubscriptionEventData,
      [ActionTypes.WsSaleScreenReceived]: saleScreenReceived,
      [ActionTypes.SaleScreenShown]: saleScreenShown,
    },
    state,
    action,
  );
}
