import { LocationDescriptor } from 'history';
import * as React from 'react';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components/macro';
import { AvatarSettings } from '../../core/types';
import useAvatarSettings from '../../core/useAvatarSettings';
import { ReactComponent as BackIcon } from '../../icons/ArrowBack.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ZIndices } from '../../types/enums';
import mobileMedia from '../../utils/mobileMedia';
import { MAIN_PAGE_URL_BASE } from '../../utils/uri';
import AriaAlertText from '../AriaAlertText';
import { ButtonWithSpinnerProps, TranslusentIconButton } from '../Buttons';
import CanvasView from '../CanvasView';
import ChatPopups from '../ChatPopups';
import Dialogs from '../Dialogs';
import Picture from '../Picture';
import WalletButton from '../WalletButton';
import IconButton from '../legacy/IconButton';
import LinkButton from '../legacy/LinkButton';
import SolidButton from '../legacy/SolidButton';
import { useMobileQuery } from '../responsive';
import AvatarModeControls from './AvatarModeControls';
import Footer from './Footer';

type Props = {
  className?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode | undefined;
  children?: React.ReactNode;
  onLogout?: () => void;
  onBack?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  'data-testid'?: string;
  beforeContent?: React.ReactNode;
  backTo?: LocationDescriptor<any>;
  closeTo?: LocationDescriptor<any>;
  backLabel?: string;
  closeLabel?: string;
  fullWidth?: boolean;
  legacy?: boolean;
  walletButton?: boolean;
};

type ErrorProps = {
  hasError: boolean;
  [key: string]: any;
};

export const MAX_PAGE_WIDTH = 1700;

const ErrorRoot = styled.div<{
  $hasError?: boolean;
  $type: 'submit' | 'field';
}>`
  margin-top: ${(p) =>
    p.$hasError ? `${p.$type === 'submit' ? 20 : 10}px` : 0};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  text-align: left;
  color: ${(p) => p.theme.errorFgColor};
  font-size: 12px;
  max-height: ${(p) => (p.$hasError ? '100px' : '0px')};
  transition:
    max-height 0.2s ease-out,
    margin-top 0.2s ease-out;
`;

export const SubmitError = ({ hasError, children, ...rest }: ErrorProps) => {
  return (
    <ErrorRoot $hasError={hasError} $type="submit" {...rest}>
      <AriaAlertText>{children}</AriaAlertText>
    </ErrorRoot>
  );
};

export const FieldError = ({ hasError, children, ...rest }: ErrorProps) => {
  return (
    <ErrorRoot $hasError={hasError} $type="field" {...rest}>
      <AriaAlertText>{children}</AriaAlertText>
    </ErrorRoot>
  );
};

const FullscreenPageRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 100vh;
  height: 100svh;
  min-height: 100vh;
  min-height: 100svh;
  background: ${(p) => p.theme.bgColor};

  ${mobileMedia`
    justify-content: flex-start;
  `};
`;

export const FullscreenForm = styled.form`
  margin-top: 10px;
  width: 355px;
  overflow-y: auto;
  padding: 5px;

  ${mobileMedia`
    width: 100%;
    padding: 0;
    overflow-y: initial;
  `};
`;

export const SubmitButton = styled(SolidButton)`
  margin: 30px auto 0;
  width: auto;
  display: block;

  ${mobileMedia`
    width: 100%;
  `};
`;

export const FullscreenHeader = styled.header`
  position: relative;
  z-index: 10;
  margin: 0 auto;
  width: 100%;
  padding: 20px 20px 0;
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;

  /* fixes overflow issue on android */
  @media (max-height: 400px) {
    display: none;
  }

  max-width: ${MAX_PAGE_WIDTH}px;

  color: ${(p) => p.theme.fgColor};

  ${mobileMedia`
    padding: 15px 15px 0;
  `};
`;

export const FullscreenFooter = styled(Footer)`
  /* fixes overflow issue on android */
  min-width: 320px;
  width: 100%;
  height: 40px;
  flex: 0 0 auto;
  padding: 0 32px;

  ${mobileMedia`
    height: auto;
    margin: 50px 0 20px;
    padding: 0;
  `};

  @media (max-height: 400px) {
    display: none;
  }
`;

const FullscreenTitles = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FullscreenTitle = styled.h1`
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  text-align: center;
  color: ${(p) => p.theme.fgColor};
  white-space: pre-wrap;

  ${mobileMedia`
    font-size: 24px;
    line-height: 32px;
    margin-top: 4px;
  `};
`;

export const FullscreenSubtitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.56;
  letter-spacing: -0.2px;
  text-align: center;
  color: ${(p) => p.theme.fgColor};
  max-width: 456px;
  margin: 20px 0 0;
  white-space: pre-wrap;

  @media (max-width: 450px) {
    padding: 0 10px;
    width: 100%;
  }

  @media (max-width: 320px) {
    padding: 0 10px;
    width: 300px;
  }

  ${mobileMedia`
    color: ${(p) => p.theme.lightFgColor};
    font-weight: normal;
  `};
`;

const FormContent = styled.main<{ $fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(100vh - 90px);
  width: ${(p) => (p.$fullWidth ? '100%' : 'auto')};

  ${mobileMedia`
    padding: ${(p) => (p.$fullWidth ? 0 : '0 15px')};
    width: 100%;
    max-width: ${(p) => (p.$fullWidth ? '100vw' : '340px')};
    max-height: 999vh;
  `};
`;

const LegacyHeaderBlockLeft = styled.div`
  flex: 1 0 20%;
`;

const LegacyHeaderBlockRight = styled(LegacyHeaderBlockLeft)`
  text-align: right;
  display: flex;
  justify-content: right;
`;

const HeaderBlockLeft = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;
`;

const HeaderBlockRight = styled(HeaderBlockLeft)`
  text-align: right;
  justify-content: flex-end;
`;

export const FullscreenBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// this is a mess, I'm sorry :(
export const LegacyFullscreenPage = ({
  className,
  title,
  subtitle,
  children,
  onLogout,
  'data-testid': dataTestId,
  beforeContent,
  onClose,
  backTo,
  onBack,
  backLabel,
  closeTo,
  fullWidth,
  legacy = true,
  walletButton,
  closeLabel = 'Close',
}: Props) => (
  <FullscreenPageRoot data-testid={dataTestId} className={className}>
    <FullscreenHeader
      style={{
        minHeight: backTo || closeTo || onClose || onBack ? 60 : 'auto',
      }}
    >
      {(onBack || backTo) &&
        (legacy ? (
          <LegacyBackButton label={backLabel} to={backTo} onClick={onBack}>
            <BackIcon />
          </LegacyBackButton>
        ) : (
          <BackButton label={backLabel} to={backTo} onClick={onBack}>
            <BackIcon />
          </BackButton>
        ))}
      <LegacyHeaderBlockLeft>
        <div />
      </LegacyHeaderBlockLeft>
      <LegacyHeaderBlockRight>
        {onLogout && (
          <LinkButton
            onClick={onLogout}
            data-testid={dataTestId && `${dataTestId}-logout-button`}
          >
            Log out
          </LinkButton>
        )}
        {walletButton && <WalletButton source={null} />}
        {(onClose || closeTo) &&
          (legacy ? (
            <LegacyCloseButton
              onClick={onClose}
              to={closeTo}
              label={closeLabel}
              data-testid={dataTestId && `${dataTestId}-close-button`}
            >
              <CloseIcon />
            </LegacyCloseButton>
          ) : (
            <CloseButton
              onClick={onClose}
              to={closeTo}
              label={closeLabel}
              data-testid={dataTestId && `${dataTestId}-close-button`}
            >
              <CloseIcon />
            </CloseButton>
          ))}
      </LegacyHeaderBlockRight>
    </FullscreenHeader>
    <FullscreenBody>
      {beforeContent}
      <FormContent $fullWidth={fullWidth}>
        {title && (
          <FullscreenTitles>
            {title && (
              <FullscreenTitle
                data-testid={dataTestId && `${dataTestId}-title`}
              >
                {title}
              </FullscreenTitle>
            )}
            {subtitle && (
              <FullscreenSubtitle
                data-testid={dataTestId && `${dataTestId}-subtitle`}
              >
                {subtitle}
              </FullscreenSubtitle>
            )}
          </FullscreenTitles>
        )}
        {children}
      </FormContent>
    </FullscreenBody>
    <div />
  </FullscreenPageRoot>
);

export function UnityFullscreenPopups() {
  return <UnityFullscreenPopupsRoot includePopups={['systemNotification']} />;
}

const UnityFullscreenPopupsRoot = styled(ChatPopups)`
  position: fixed;
  left: 50%;
  top: 0;
  height: 100%;
  z-index: ${ZIndices.Popup};
`;

export const BaseButton = styled(IconButton)`
  top: 30px;
  right: 30px;
  width: 54px;
  height: 54px;
  color: ${(p) => p.theme.fgColor};

  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(25px);
  border-radius: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 20px;
    height: 20px;
  }

  ${mobileMedia`
      width: 34px;
      height: 34px;
      border-radius: 14px;
      top: 15px;
      right: 15px;
  `}
`;

export const BaseIconButton = styled(IconButton)`
  width: 44px;
  height: 44px;
  border-radius: 19px;
  background: ${(p) => p.theme.navigationBgColor};
  backdrop-filter: blur(25px);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  top: 24px;

  & > svg {
    color: #fff;
  }

  ${mobileMedia`
      width: 34px;
      height: 34px;
      border-radius: 14px;
      top: 15px;
      right: 15px;
  `}
`;

const LegacyCloseButton = styled(BaseButton)`
  position: absolute;
  margin-left: 0;
`;

const CloseButton = styled(TranslusentIconButton)`
  margin-left: 10px;
`;

const LegacyBackButton = styled(BaseButton)`
  position: absolute;
  z-index: 1;
  left: 24px;
  top: 36px;
  color: ${(p) => p.theme.fgColor};
`;

const BackButton = styled(TranslusentIconButton)`
  color: ${(p) => p.theme.fgColor};
`;

type FullscreenPageAvatarViewProps = {
  avatarFallback?: ReactNode | ((mobile: boolean) => ReactNode);
  avatarFallbackUrl?: string;
  avatarSettings?: AvatarSettings | ((mobile: boolean) => AvatarSettings);
  avatarFallbackBackgroundUrl?: string;
};

export const FullscreenWithAvatar = styled(FullscreenPageRoot)<{
  $background: 'default' | 'subscription' | 'onboarding' | 'deleteAccount';
}>`
  position: relative;
  width: 100%;
  background: ${(p) => p.theme.fullscreenBgColors[p.$background]};
  color: #fff;
`;

export const FullscreenBodyWithAvatar = styled.div`
  flex: 1;
  width: 100%;
  min-height: 0;

  @media ${(p) => p.theme.breakpoints.tablet} {
    max-height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;

    max-width: ${MAX_PAGE_WIDTH}px;
  }
`;

export function FullscreenPageAvatarView(props: FullscreenPageAvatarViewProps) {
  let {
    avatarFallback,
    avatarFallbackBackgroundUrl,
    avatarFallbackUrl = `${MAIN_PAGE_URL_BASE}/fallback-subscription.png`,
    avatarSettings,
  } = props;

  const isMobile = useMobileQuery();

  const as =
    typeof avatarSettings === 'function'
      ? avatarSettings(isMobile)
      : avatarSettings;
  let af =
    typeof avatarFallback === 'function'
      ? avatarFallback(isMobile)
      : avatarFallback;

  useAvatarSettings(as ?? {});

  const avatarStatus = useSelector((state) => state.avatars.avatarStatus);

  const visibleAvatar = avatarStatus === 'ready' || avatarStatus === 'updating';
  const renderedAvatar = avatarStatus !== 'idle';

  if (!af) {
    af = <FallbackImage src={avatarFallbackUrl} width="1103" height="2773" />;
  }

  const fallbackStyle = {
    backgroundImage:
      avatarFallbackBackgroundUrl && `url(${avatarFallbackBackgroundUrl})`,
  };

  return (
    <>
      {renderedAvatar && (
        <AvatarCanvasView
          priority={1}
          id="subscription-avatar"
          $visible={visibleAvatar && !as?.hidden}
          canvasId="avatar-canvas"
        >
          <FallbackWrapper style={fallbackStyle} $delayed>
            {af}
          </FallbackWrapper>
        </AvatarCanvasView>
      )}
      {!visibleAvatar && !as?.hidden ? (
        <FallbackWrapper style={fallbackStyle} $delayed={renderedAvatar}>
          {af}
        </FallbackWrapper>
      ) : null}
    </>
  );
}

const AvatarCanvasView = styled(CanvasView)<{ $visible: boolean }>`
  opacity: ${(p) => (p.$visible ? 1 : 0)};
  position: fixed;
  z-index: 0;
  line-height: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100svh;
`;

// disable pointer-events on mobile to forward events to unity
export const FullscreenContent = styled.div`
  z-index: 1;
  min-width: 50%;
  min-height: 0;

  width: 100%;
  max-width: initial;
  padding-bottom: 24px;

  pointer-events: none;
  & > * {
    pointer-events: all;
  }

  [aria-hidden='true'] & > * {
    pointer-events: none;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    pointer-events: all;
    max-width: 590px;
    max-height: 100%;
    padding-bottom: 0;

    [aria-hidden='true'] & {
      pointer-events: none;
    }

    &:after {
      /* compensate header, but collapse if content is long enough */
      content: '';
      display: block;
      flex: 1 1 auto;
      max-height: 60px;
    }
  }
`;

const fadein = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const FallbackWrapper = styled.div<{ $delayed: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100vh;
  height: 100svh;
  overflow: hidden;
  animation: ${fadein} 0.5s ease-out ${(p) => (p.$delayed ? '1s' : '0s')} both;
  background-size: cover;
`;

const FallbackImage = styled(Picture)`
  position: absolute;
  transform: translateX(-50%);
  max-width: 600px;
  width: auto;
  top: 12vh;
  left: calc(50% - 12vh);
  height: 110vh;

  @media ${(p) => p.theme.breakpoints.tablet} {
    top: 18vh;
    left: calc(50% - 35vh);
    height: 100svh;
  }
`;

const FullscreenHeaderTitle = styled.h1`
  flex: 1;
  margin: 10px;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  white-space: nowrap;

  @media ${(p) => p.theme.breakpoints.tablet} {
    font-size: 28px;
    line-height: 32px;
  }
`;

const FullscreenDialogs = styled(Dialogs)`
  z-index: ${ZIndices.Dialog};
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 26px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 36px;
  }
`;

const FullscreenAvatarModeControls = styled(AvatarModeControls)`
  position: absolute;
  left: 15px;
  bottom: 15px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    left: 30px;
    bottom: 30px;
  }
`;

function FullscreenCloseButton(props: ButtonWithSpinnerProps) {
  return (
    <TranslusentIconButton label="close" {...props}>
      <StyledCloseIcon />
    </TranslusentIconButton>
  );
}

function FullscreenBackButton(props: ButtonWithSpinnerProps) {
  return (
    <TranslusentIconButton label="back" {...props}>
      <BackIcon />
    </TranslusentIconButton>
  );
}

export {
  FullscreenAvatarModeControls as AvatarModeControls,
  FullscreenPageAvatarView as AvatarView,
  FullscreenBackButton as BackButton,
  FullscreenBodyWithAvatar as Body,
  FullscreenCloseButton as CloseButton,
  FullscreenContent as Content,
  FullscreenDialogs as Dialogs,
  FullscreenHeader as Header,
  HeaderBlockLeft as HeaderLeft,
  HeaderBlockRight as HeaderRight,
  FullscreenHeaderTitle as HeaderTitle,
  TranslusentIconButton as IconButton,
  FullscreenBody as LegacyBody,
  FormContent as LegacyFormContent,
  FullscreenPageRoot as LegacyRoot,
  UnityFullscreenPopups as Popups,
  FullscreenWithAvatar as Root,
  FullscreenSubtitle as Subtitle,
  FullscreenTitle as Title,
  FullscreenTitles as Titles,
};
