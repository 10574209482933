import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import findParentCategory from '../../routes/Store/findParentCategory';
import { Routes } from '../../types/enums';
import {
  isCustomizationItem,
  isDialogItem,
  isStoreVoiceItem,
  StoreItem,
} from '../../types/models';
import toQueryString from '../../utils/toQueryString';
import { StoreCategories } from './useStoreCategories';

export function useStoreItemLink(
  storeItem: StoreItem | undefined,
  categories?: StoreCategories,
  variationId?: string,
): string | null {
  const { customization, dialog, room } = categories || {};

  return useMemo(() => {
    if (!customization || !dialog || !room || !storeItem) {
      return null;
    }

    const search =
      '?' +
      toQueryString({
        item: storeItem.id,
        category: storeItem.category_id,
        variation: variationId,
      });

    if (isDialogItem(storeItem) || isStoreVoiceItem(storeItem)) {
      return generatePath(Routes.ReplikaProfile) + search;
    }

    if (isCustomizationItem(storeItem)) {
      const parentCategory = findParentCategory(
        storeItem.category_id,
        customization,
      );
      return (
        generatePath(Routes.ReplikaProfileStore, {
          tab: parentCategory?.name?.toLowerCase() || '',
        }) + search
      );
    }

    const parentCategory = findParentCategory(storeItem.category_id, room);

    return parentCategory ? generatePath(Routes.RoomStore) + search : '';
  }, [customization, dialog, room, storeItem, variationId]);
}
