import { atomWithStorage } from 'jotai/utils';
import { StorageKeys } from '../../../types/enums';

export const photoStudioLastAvatarHash = atomWithStorage<string | null>(
  StorageKeys.PHOTO_STUDIO_LAST_AVATAR_HASH,
  null,
  undefined,
  {
    getOnInit: true,
  },
);
