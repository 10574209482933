import styled from 'styled-components/macro';
import { Bot } from '../types/models';

type Props = {
  bot: Bot | undefined;
  className?: string;
  children: React.ReactNode;
};

const XpBar = ({ bot, className, children }: Props) => {
  const xp = bot?.stats.score;
  const currLvlXp = bot?.stats.current_level.score_milestone;
  const nextLvlXp = bot?.stats.next_level.score_milestone;
  const lvlEnabled = bot?.levels_enabled;

  const xpPercentGained =
    xp !== undefined && currLvlXp !== undefined && nextLvlXp !== undefined
      ? Math.round(((xp - currLvlXp) / (nextLvlXp - currLvlXp)) * 100)
      : 0;

  return lvlEnabled ? (
    <XpBarRoot className={className} $xp={xpPercentGained}>
      {children}
    </XpBarRoot>
  ) : null;
};

export default XpBar;

const XpBarRoot = styled.div<{ $xp: number }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: ${(p) => p.theme.navigationBgColor};
  border-radius: 24px;
  color: ${(p) => p.theme.fgColor};
  font-family: ${(p) => p.theme.fonts.display};
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  overflow: hidden;

  @supports (backdrop-filter: blur(25px)) {
    backdrop-filter: blur(25px);
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    margin: auto;
    display: block;
    width: ${(p) => p.$xp}%;
    height: 100%;
    transition: width 0.3s ease-out;
    background: linear-gradient(270deg, #a6eca4 0%, rgba(166, 236, 164, 0) 100%),
      rgba(182, 255, 153, 0.2);
    box-shadow: inset 0px 0px 5px rgba(166, 236, 164, 0.5);
    opacity: 0.5;
    z-index: 1;
    overflow: hidden;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(${(p) => p.$xp}% - 1px);
    transition: left 0.3s ease-out;
    margin: auto;
    display: block;
    width: 1px;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(166, 236, 164, 0) 0%,
      #a6eca4 50%,
      rgba(166, 236, 164, 0) 100%
    );
    border-radius: 28px;
    z-index: 2;
    overflow: hidden;
  }
`;
