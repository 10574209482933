import * as RXPopover from '@radix-ui/react-popover';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import styled from 'styled-components/macro';
import { UnstyledLink } from '../../components/Link';
import { MetricsEvents } from '../../types/enums';
import { logEvent } from '../../utils/metrics';
import {
  AppNavigationLinkName,
  AppNavigationLinkWithElement,
  AppNavigationLinkWithImg,
} from './NavigationLinks';
import { showQueueAtom, shownAtomFamily } from './atoms';

const POPUP_TIMEOUT = 5000;

type Props = {
  link: AppNavigationLinkWithImg | AppNavigationLinkWithElement;
  offset: number;
  side: 'top' | 'bottom';
  children: React.ReactNode;
  count: number;
};

function NavigationPopover({ link, offset, side, children, count }: Props) {
  const [showQueue, setShowQueue] = useAtom(showQueueAtom);
  const [shown, setShown] = useAtom(shownAtomFamily(link.name));

  const popover = getPopover(link.name);

  useEffect(() => {
    if (shown) return;

    if (count && !showQueue.includes(link.name)) {
      const event = getEvent(link.name, count);
      if (event[0]) logEvent(...event);
      setShowQueue([...showQueue, link.name]);
    }
  }, [count, link.name, shown, showQueue]);

  const open = popover != null && showQueue[0] === link.name && !shown;

  useEffect(() => {
    if (!open) return;

    const to = setTimeout(() => {
      setShown(true);
      setShowQueue(showQueue.slice(1));
    }, POPUP_TIMEOUT);

    return () => {
      clearTimeout(to);
    };
  }, [link.name, open, showQueue]);

  if (!popover) return <>{children}</>;

  return (
    <RXPopover.Root open={open}>
      <RXPopover.Trigger asChild>{children}</RXPopover.Trigger>

      <PopoverContent
        sideOffset={offset}
        side={side}
        align="center"
        onInteractOutside={(e) => e.preventDefault()}
      >
        <PopoverBody
          to={link.route}
          onClick={() => {
            setShown(true);
          }}
        >
          <PopoverInformation>{popover.title(count)}</PopoverInformation>
          <PopoverDescription>{popover.description}</PopoverDescription>
        </PopoverBody>
        <PopoverArrow />
      </PopoverContent>
    </RXPopover.Root>
  );
}

export default NavigationPopover;

const PopoverContent = styled(RXPopover.Content)`
  padding: 10px 15px;
  color: ${(p) => p.theme.fgColor};
  background: rgba(0 0 0 / 15%);
  backdrop-filter: blur(25px);
  border-radius: 24px;
  cursor: pointer;

  @media ${(p) => p.theme.breakpoints.tablet} {
    background: rgba(0 0 0 / 10%);
  }
`;

const PopoverArrow = styled(RXPopover.Arrow)`
  fill: rgba(0 0 0 / 15%);

  @media ${(p) => p.theme.breakpoints.tablet} {
    fill: rgba(0 0 0 / 10%);
  }
`;

const PopoverBody = styled(UnstyledLink)`
  text-align: center;
  &:hover {
    text-decoration: none;
  }
`;

const PopoverInformation = styled.h2`
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  font-family: ${(p) => p.theme.fonts.display};
  color: #fff966;
`;

const PopoverDescription = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  font-family: ${(p) => p.theme.fonts.body};
  color: rgba(255 255 255 / 70%);
`;

function getPopover(name: AppNavigationLinkName) {
  switch (name) {
    case 'memory':
      return {
        title(count: number) {
          return count > 1 ? `${count} new memories` : `${count} new memory`;
        },
        description: 'Choose which ones to keep',
      };

    case 'diary':
      return {
        title(count: number) {
          return count > 1 ? `${count} entries` : `${count} entry`;
        },
        description: 'Read Replika thoughts',
      };
  }

  return null;
}

function getEvent(name: AppNavigationLinkName, count: number) {
  switch (name) {
    case 'memory':
      return [
        MetricsEvents.MemoryNewFactsBadgeShown,
        { 'new counter': count },
      ] as const;
  }

  return [null, null] as const;
}
