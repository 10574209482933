import FocusTrapReact, { Props as FocusTrapProps } from 'focus-trap-react';
import { useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { devDialogOpenAtom } from '../core/App/DevHelperDialog';

export default function FocusTrap(props: FocusTrapProps) {
  const devDialogOpen = useAtomValue(devDialogOpenAtom);
  const [radixPopupOpen, setRadixPopupOpen] = useState(false);
  const preventReturnFocusRef = useRef(false);

  useEffect(() => {
    // When Radix popover is open, it adds a focus guard to the body
    const observer = new MutationObserver(() => {
      const popup = document.querySelector('body > [data-radix-focus-guard]');
      setRadixPopupOpen(!!popup);
      preventReturnFocusRef.current = !!popup;
    });

    observer.observe(document.body, { childList: true });

    return () => observer.disconnect();
  }, []);

  let focusTrapProps: FocusTrapProps = {
    active: !devDialogOpen && !radixPopupOpen,
    ...props,
    focusTrapOptions: {
      setReturnFocus(node) {
        // Returning focus to previous element when Radix popover is open causes closing of said popover
        return preventReturnFocusRef.current ? false : node;
      },
      ...props.focusTrapOptions,
    },
  };

  return <FocusTrapReact {...focusTrapProps} />;
}
