import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { LipsyncPhoneme } from '../../../types/websocket';
import { unityInstanceAtom } from '../../atoms';
import { UnityBridges } from '../enums';
import { UnityInstance } from '../types';
import { unityLogger } from '../unityUtils';
import { lipsyncWorkletObjectUrl } from './LipsyncWorklet';
import { UnityLipsyncCommads } from './enums';

export class AvatarLipsyncClass {
  constructor(private unityInstance: UnityInstance | null) {}

  async prepareAndGetLipsyncWorklet(
    context: AudioContext,
  ): Promise<AudioWorkletNode | null> {
    if (!context) return null;

    unityLogger.debug(
      UnityBridges.Lipsync,
      'Lipsync Worklet preparation started',
    );

    await context.audioWorklet.addModule(lipsyncWorkletObjectUrl);

    const worklet = new AudioWorkletNode(context, 'lipsync-processor', {});

    worklet.port.onmessage = (event) => {
      this.putAudioSampleData(event.data);
    };

    unityLogger.debug(
      UnityBridges.Lipsync,
      'Lipsync Worklet preparation finished',
    );

    return worklet;
  }

  private putAudioSampleData(data: AllowSharedBufferSource) {
    const str = new TextDecoder('UTF-16').decode(data);

    unityLogger.debug(
      UnityBridges.Lipsync,
      UnityLipsyncCommads.PutAudioSampleData,
      str,
    );

    this.unityInstance?.SendMessage(
      UnityBridges.Lipsync,
      UnityLipsyncCommads.PutAudioSampleData,
      str,
    );
  }

  setupPhonemes(phonemes: LipsyncPhoneme[] | null) {
    const data = JSON.stringify(phonemes);

    unityLogger.debug(
      UnityBridges.Lipsync,
      UnityLipsyncCommads.SetupPhonemes,
      data,
    );

    this.unityInstance?.SendMessage(
      UnityBridges.Lipsync,
      UnityLipsyncCommads.SetupPhonemes,
      data,
    );
  }

  clearPhonemes() {
    this.setupPhonemes(null);
  }

  setLipsyncPosition(position: number) {
    unityLogger.debug(
      UnityBridges.Lipsync,
      UnityLipsyncCommads.SetLipsyncPosition,
      position,
    );

    this.unityInstance?.SendMessage(
      UnityBridges.Lipsync,
      UnityLipsyncCommads.SetLipsyncPosition,
      position,
    );
  }
}

export const useAvatarLipsync = () => {
  const unityInstance = useAtomValue(unityInstanceAtom);

  return useMemo(() => new AvatarLipsyncClass(unityInstance), [unityInstance]);
};
