import styled from 'styled-components/macro';
import mobileMedia from '../../utils/mobileMedia';

const ChatPopup = styled.div`
  background-color: ${(p) => p.theme.dialogBgColor};
  box-shadow: 0 5px 20px 0 ${(p) => p.theme.shadowColor};
  color: ${(p) => p.theme.fgColor};
  border-radius: 8px;

  ${mobileMedia`
    font-size: 0.8em;
    line-height: 1.5em;
  `};
`;

export default ChatPopup;
