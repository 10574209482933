import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components/macro';
import { getCategoryTreeNode } from '../../actions/store';
import {
  CategoryTree,
  DialogVariationItem,
  StoreCustomizationItem,
  StoreItem,
  StoreVariationItem,
  isColorVariation,
  isRoomItem,
} from '../../types/models';
import getPriceLabel from '../../utils/getPriceLabel';
import { MAIN_PAGE_URL_BASE } from '../../utils/uri';
import { CardGridItem, CardGridItemLink } from '../CardGrid';
import CircleSpinner from '../CircleSpinner';
import Picture from '../Picture';
import PriceTag from '../PriceTag';
import { outlineCardCss } from '../outlineCss';

type Props = {
  item: StoreItem | null;
  className?: string;
  standalone?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onScrollSelect?: (variationId?: string) => void;
  to?: string;
  tabIndex?: number;
  size?: number;
  checked?: boolean;
  onRemove?: () => void;
  noFocusWithin?: boolean;
  variationId?: string;
  claimed?: boolean;
  noPriceBadge?: boolean;
  noNewBadge?: boolean;
  noColorBadge?: boolean;
  selectedVariationIndex?: number;
  showSpinner?: boolean;
  noTitle?: boolean;
  noAdditionalIcons?: boolean;
};

function isDialogVariationItem(
  item: StoreVariationItem,
): item is DialogVariationItem {
  return item.hasOwnProperty('enabled');
}

function isDialogItem(item: StoreItem) {
  return (
    item.variation_type === 'Dialog' || item.variation_type === 'ChatSkill'
  );
}

function showItemTitle(item: StoreItem) {
  return (
    item.variation_type === 'Dialog' ||
    item.variation_type === 'ChatSkill' ||
    item.variation_type === 'Voice'
  );
}

function hasVariations(item: StoreItem): item is StoreCustomizationItem {
  return (item as StoreCustomizationItem).variations !== undefined;
}

function StoreItemCard({
  className,
  item,
  onClick,
  onScrollSelect,
  to,
  standalone,
  tabIndex,
  size = 152,
  checked,
  onRemove,
  noFocusWithin,
  variationId,
  claimed,
  noPriceBadge,
  noNewBadge,
  noColorBadge,
  noAdditionalIcons,
  showSpinner,
  noTitle,
}: Props) {
  if (!item) {
    return <CardGridItem legacy={false} size={size} />;
  }

  const itemVariations = hasVariations(item) ? item.variations : [];

  const variation = itemVariations.find(
    (variation) => variation.id === variationId,
  );
  const itemBought = variation
    ? variation.bought_count > 0
    : itemVariations.some((variation) => variation.bought_count > 0);
  const hasEnabledVariation = itemVariations.some(
    (variation) => isDialogVariationItem(variation) && variation.enabled,
  );

  const colorBadge =
    variation && isColorVariation(variation) && !noColorBadge ? (
      <ColorBadge $color={variation.color} />
    ) : null;

  const isColorModificationIconVisible =
    !colorBadge &&
    itemVariations.length > 1 &&
    itemVariations.some((v) => isColorVariation(v));
  const isInteractableRoomItemIconVisible =
    isRoomItem(item) && item.variations.some((item) => !!item?.interactive);
  const isAdditionalIconsVisible =
    !noAdditionalIcons &&
    (!!colorBadge ||
      isColorModificationIconVisible ||
      isInteractableRoomItemIconVisible);

  const priceBadge = noPriceBadge ? null : (
    <PriceBadge itemBought={itemBought} price={item.price} />
  );

  const newBadge = !item.is_new || noNewBadge ? null : <NewBadge />;

  const label = itemBought ? '' : `${item.title}, ${getPriceLabel(item.price)}`;

  let disabled = false;
  switch (item.variation_type) {
    case 'Dialog':
      disabled = itemBought && !claimed && !hasEnabledVariation;
      break;
    default:
      disabled = false;
      break;
  }

  const title = showItemTitle(item) ? item.title : '';

  const Root = isDialogItem(item) ? DialogItemCardRoot : StoreItemCardRoot;

  const round = item.variation_type === 'Skin' || item.variation_type === 'Eye';

  return (
    <Root
      legacy={false}
      itemId={item.id}
      id={'store-item-' + item.id}
      key={item.id}
      className={className}
      backgroundImageUrl={{ jpg: item.icon_url, webp: item.preview_url }}
      standalone={standalone}
      size={size}
      disabled={disabled}
      $checked={checked}
      stretchContent
      onRemove={onRemove}
      onScrollSelect={onScrollSelect}
      noFocusWithin={noFocusWithin}
      $round={round}
    >
      {!noTitle && (onClick || to) ? (
        <TitleLink
          tabIndex={tabIndex}
          onClick={onClick}
          to={to}
          aria-label={label}
          aria-checked={checked}
        >
          {title}
        </TitleLink>
      ) : (
        <Title role="text" aria-label={label}>
          {title}
        </Title>
      )}
      {priceBadge}
      {newBadge}
      {isAdditionalIconsVisible && (
        <AdditionalIcons>
          {colorBadge}

          {isColorModificationIconVisible && <MultiColorBadge />}

          {isInteractableRoomItemIconVisible && (
            <AdditionalStoreItemIcon>
              <Picture src={`${MAIN_PAGE_URL_BASE}/interactive_icon.png`} />
            </AdditionalStoreItemIcon>
          )}
        </AdditionalIcons>
      )}

      {showSpinner && (
        <StyledSpinner
          lineWidth={6}
          style={{ width: '24px', height: '24px' }}
        />
      )}
    </Root>
  );
}

export default StoreItemCard;

const fadein = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledSpinner = styled(CircleSpinner)`
  position: absolute;
  bottom: 5px;
  right: 5px;
  opacity: 0;
  animation: ${fadein} 0.2s 0.4s ease-in-out both;
`;

const Title = styled.span`
  font-family: ${(p) => p.theme.fonts.display};

  font-size: 12px;
  line-height: 12px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    font-size: 16px;
    line-height: 20px;
  }
`;

const TitleLink = styled(CardGridItemLink)`
  font-family: ${(p) => p.theme.fonts.display};

  font-size: 12px;
  line-height: 12px;

  &.focus-visible:focus,
  &:focus-visible:focus {
    box-shadow: none;
    outline: none;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    font-size: 16px;
    line-height: 20px;
  }
`;

const StoreItemCardRoot = styled(CardGridItem)<{
  $checked?: boolean;
  $round: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  transition: opacity 0.3s ease-out;
  border-radius: ${(p) => (p.$round ? '50%' : '9px')};
  color: #fff;
  overflow: initial;

  ${(p) =>
    p.$checked &&
    outlineCardCss({
      outlineOpacity: '100%',
      size: '3px',
      offset: '-3px',
    })};

  &:focus-within {
    ${(p) =>
      p.$checked &&
      outlineCardCss({
        outlineOpacity: '100%',
        size: '3px',
        offset: '-3px',
      })};
  }

  &:has(:focus-visible) {
    ${outlineCardCss({
      outlineOpacity: '100%',
      size: '3px',
      offset: '-3px',
    })};
  }

  &:before {
    z-index: -2;
    position: absolute;
    pointer-events: none;
    content: '';
    display: ${(p) => (p.$checked ? 'block' : 'none')};
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: ${(p) => (p.$round ? '50%' : '9px')};
  }

  & > picture > img {
    border-radius: ${(p) => (p.$round ? '50%' : '9px')};
  }

  background-clip: content-box;

  & ${TitleLink}, & ${Title} {
    opacity: ${(p) => (p.disabled ? 0.3 : 1)};
    transition: opacity 0.3s ease-out;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    border-radius: ${(p) => (p.$round ? '50%' : '14px')};

    & > picture > img {
      border-radius: ${(p) => (p.$round ? '50%' : '14px')};
    }

    &:before {
      border-radius: ${(p) => (p.$round ? '50%' : '14px')};
    }
  }
`;

const ColorBadge = styled.div<{ $color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(p) => '#' + p.$color};

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 15px;
    height: 15px;
  }
`;

const DialogItemCardRoot = styled(StoreItemCardRoot)`
  min-height: ${(p) => (p.size ?? 0) * 1.25}px;
  aspect-ratio: 100 / 125;

  & ${TitleLink}, & ${Title} {
    align-self: center;
  }

  & > picture > img {
    object-fit: contain;
    object-position: 50% 100%;
    left: 8px;
    top: 8px;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
  }
`;

export function PriceBadge({
  itemBought,
  price,
}: {
  itemBought: boolean;
  price: StoreItem['price'];
}) {
  if (itemBought) {
    return <ItemBoughtBadge />;
  }

  return <StyledPriceTag price={price} />;
}

const StyledPriceTag = styled(PriceTag)`
  position: absolute;
  pointer-events: none;
  background: rgba(188, 188, 188, 0.8);
  font-family: ${(p) => p.theme.fonts.display};
  display: flex;
  gap: 2px;
  align-items: center;

  height: 16px;
  border-radius: 19px;
  padding: 2px 4px 2px 2px;
  font-size: 12px;
  left: 2px;
  bottom: 2px;

  & > img {
    width: 12px;
    height: 12px;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    gap: 4px;
    height: 26px;
    padding: 2px 6px 2px 2px;
    font-size: 20px;
    left: 4px;
    bottom: 4px;

    & > img {
      width: 22px;
      height: 22px;
    }
  }
`;

const ItemBoughtBadge = styled.div`
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    at 100% 0%,
    rgba(120, 221, 124, 0) 78.3%,
    rgba(120, 221, 124, 0.8) 96.32%,
    rgba(119, 221, 123, 0) 100%
  );
  border-radius: 9px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 6px;
    bottom: 6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: linear-gradient(180.01deg, #e4faa5 0.01%, #0fbf1a 99.99%);
    box-shadow: 0px 0px 7.98479px #acea90;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    border-radius: 14px;

    &:before {
      width: 15px;
      height: 15px;
      left: 8px;
      bottom: 8px;
    }
  }
`;

const NewBadge = styled.div`
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 9px;

  background: radial-gradient(
    at 0% 100%,
    rgba(255, 250, 121, 0) 78.3%,
    rgba(255, 250, 121, 0.8) 96.32%,
    rgba(255, 250, 121, 0) 100%
  );

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    top: -2px;
    right: -2px;
    background: linear-gradient(180.01deg, #fffa79 0.01%, #efcc13 99.99%);
    box-shadow: 0px 0px 5px rgba(255, 250, 121, 0.9);
    border-radius: 50%;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    border-radius: 14px;

    &:after {
      width: 15px;
      height: 15px;
      top: -3px;
      right: -3px;
    }
  }
`;

const AdditionalIcons = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  height: 10px;
  pointer-events: none;
  user-select: none;

  display: flex;
  flex-direction: row-reverse;
  gap: 3px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    bottom: 8px;
    right: 8px;
    height: 15px;
    gap: 5px;
  }
`;

const AdditionalStoreItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  width: 10px;
  pointer-events: none;
  user-select: none;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 15px;
    height: 15px;
  }

  img {
    width: 100%;
    display: block;
  }
`;

const MultiColorBadgeRoot = styled(AdditionalStoreItemIcon)`
  width: 10px;
  height: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  overflow: hidden;
  pointer-events: none;
  user-select: none;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 15px;
    height: 15px;
  }

  img {
    width: 100%;
    display: block;
  }
`;

export const MultiColorBadge = ({ className }: { className?: string }) => {
  return (
    <MultiColorBadgeRoot className={className}>
      <Picture src={`${MAIN_PAGE_URL_BASE}/multi_variations_icon.png`} />
    </MultiColorBadgeRoot>
  );
};

const StoreCategoryContext = React.createContext<{
  requestCategoryTree: (categoryKey: string) => void;
  storeCategories: { [categoryKey: string]: CategoryTree } | null;
}>({
  requestCategoryTree: () => {},
  storeCategories: null,
});

/**
 * Makes sure each category tree is requested only once
 */
export function StoreCategoryProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const dispatch = useDispatch();

  const statuses = React.useRef<{
    [categoryKey: string]: 'idle' | 'requested' | 'received';
  }>({});

  const storeCategories = useSelector((state) => state.store.storeCategories);

  const requestCategoryTree = React.useCallback(
    (categoryKey: string) => {
      if (
        statuses.current[categoryKey] !== 'requested' &&
        statuses.current[categoryKey] !== 'received'
      ) {
        statuses.current[categoryKey] = 'requested';

        dispatch(getCategoryTreeNode(categoryKey)).then(() => {
          statuses.current[categoryKey] = 'received';
        });
      }
    },
    [dispatch],
  );

  return (
    <StoreCategoryContext.Provider
      value={{ requestCategoryTree, storeCategories }}
    >
      {children}
    </StoreCategoryContext.Provider>
  );
}
