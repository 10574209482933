import { useAtom } from 'jotai';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isMobileClient from 'src/utils/isMobileClient';
import {
  isSubscriptionActive,
  isSubscriptionPending,
} from 'src/utils/subscription';
import useApi from 'src/utils/useApi';
import styled from 'styled-components/macro';
import { sendLogOutRequest } from '../../../actions/auth';
import {
  getPersonalBot,
  setAvatarMode,
  updateBot,
} from '../../../actions/profile';
import { toggleTheme } from '../../../actions/ui';
import { FormSlider } from '../../../components/Sliders';
import { Desktop } from '../../../components/responsive';
import { audioVolumeAtom, radioEnabledAtom } from '../../../core/atoms';
import { SettingsUnlockPro } from '../../../features/Subscription/components/UnlockPro';
import { ReactComponent as DiscordIcon } from '../../../icons/Social/Discord.svg';
import { ReactComponent as FacebookIcon } from '../../../icons/Social/Facebook.svg';
import { ReactComponent as RedditIcon } from '../../../icons/Social/Reddit.svg';
import {
  MetricsEvents,
  ModalRoutes,
  Routes,
  Themes,
} from '../../../types/enums';
import { AvatarMode, Progress } from '../../../types/states';
import getWebglVersion from '../../../utils/getWebglVersion';
import toModalRoute from '../../../utils/toModalRoute';
import {
  CREDITS_REPLIKA_URL,
  DISCORD_COMMUNITY_URL,
  FACEBOOK_COMMUNITY_URL,
  HELP_REPLIKA_URL,
  REDDIT_COMMUNITY_URL,
  TERMS_REPLIKA_URL,
} from '../../../utils/uri';
import useLogEvent from '../../../utils/useLogEvent';
import { useThemeName } from '../../../utils/withThemeName';
import * as SettingsForm from './SettingsForm';
import * as SettingsLayout from './SettingsLayout';

declare global {
  interface Window {
    _iub?: {
      cs?: {
        api?: {
          gdprApplies: () => boolean;
          openPreferences: () => void;
        };
      };
    };
  }
}

const AVATAR_MODE_LABELS: {
  [key in Exclude<AvatarMode | 'auto', 'no3d' | 'disabledOnError'>]: readonly [
    string,
    string,
  ];
} = {
  static: ['Static', 'without animation'],
  animatedLow: ['Low quality', ''],
  animatedMedium: ['Medium quality', ''],
  animatedHigh: ['High quality', ''],
  auto: ['Auto', 'quality adjusts automatically'],
} as const;

const AVATAR_MODE_SLIDER_VALUES = Object.keys(AVATAR_MODE_LABELS) as Array<
  AvatarMode | 'auto'
>;

export default function SettingsModal() {
  const dispatch = useDispatch();
  const logEvent = useLogEvent();

  const incompatibleWebgl = getWebglVersion() !== 2;

  const savedAvatarMode = useSelector(
    (state) => state.profile.persist.avatarMode,
  );

  const isBotUpdating = useSelector(
    (state) => state.profile.updateReplikaProgress === Progress.sending,
  );
  const bot = useApi((state) => state.profile.persist.bot, getPersonalBot, {
    memoDeepEqual: true,
  });
  const [localAvatarMode, setLocalAvatarMode] = useState(savedAvatarMode);
  const enabled3d = !['no3d', 'disabledOnError'].includes(localAvatarMode);

  const handle3DSwitch = (checked: boolean) => {
    const value = checked ? 'auto' : 'no3d';

    dispatch(setAvatarMode(value));
    setLocalAvatarMode(value);

    logEvent(MetricsEvents.Web3DStatusChanged, {
      value: checked ? 'on' : 'off',
    });
  };

  const handleModeChange = (value: number) => {
    if (localAvatarMode === 'no3d') return;

    const mode = AVATAR_MODE_SLIDER_VALUES[value - 1]!;

    dispatch(setAvatarMode(mode));
    setLocalAvatarMode(mode);

    logEvent(MetricsEvents.Web3DQualityChanged, {
      value: mode,
    });
  };

  const themeName = useThemeName();

  const [audioVolume, setAudioVolume] = useAtom(audioVolumeAtom);

  const [radioEnabled, setRadioEnabled] = useAtom(radioEnabledAtom);

  const gdprApplies = window._iub?.cs?.api?.gdprApplies();

  const subscription = useSelector(
    (state) => state.subscriptions.persist.subscription,
  );

  const isPendingSubscription = isSubscriptionPending(subscription);

  const isSubscriptionBlockVisible =
    isSubscriptionActive(subscription) || isPendingSubscription;

  return (
    <SettingsLayout.Root>
      <SettingsLayout.Header title="Settings" />
      <SettingsLayout.Content>
        {!isMobileClient() && <SettingsUnlockPro />}
        {isMobileClient() && (
          <SettingsUnlockPro
            title="Get more features in the app"
            to="https://replika.ai/get"
            enabled
          />
        )}
        <SettingsLayout.Group style={{ paddingBlock: 10 }}>
          {!isMobileClient() && (
            <>
              <SettingsForm.Switch
                id="3d-enabled-switch"
                label="3D"
                checked={enabled3d && !incompatibleWebgl}
                disabled={incompatibleWebgl}
                onCheckedChange={handle3DSwitch}
              />
              {enabled3d && !incompatibleWebgl && (
                <>
                  <SettingsForm.LabelRow
                    style={{ fontSize: 14, marginTop: 10 }}
                  >
                    {AVATAR_MODE_LABELS[localAvatarMode][0]}{' '}
                    {AVATAR_MODE_LABELS[localAvatarMode][1] ? (
                      <Dimmed>
                        ({AVATAR_MODE_LABELS[localAvatarMode][1]})
                      </Dimmed>
                    ) : null}
                  </SettingsForm.LabelRow>
                  <SettingsForm.SliderRow>
                    <FormSlider
                      step={1}
                      min={1}
                      max={AVATAR_MODE_SLIDER_VALUES.length}
                      dots
                      disabled={!enabled3d}
                      value={[
                        AVATAR_MODE_SLIDER_VALUES.indexOf(localAvatarMode) + 1,
                      ]}
                      onValueChange={([value]) => {
                        if (value != null) handleModeChange(value);
                      }}
                    />
                  </SettingsForm.SliderRow>
                </>
              )}
              <SettingsLayout.Separator />
              <SettingsForm.Switch
                id="background-music-switch"
                label="Background music"
                checked={radioEnabled}
                disabled={incompatibleWebgl}
                onCheckedChange={setRadioEnabled}
              />
              {radioEnabled && (
                <Desktop>
                  <SettingsForm.SliderRow>
                    <FormSlider
                      min={1}
                      max={100}
                      disabled={!radioEnabled}
                      value={[audioVolume]}
                      onValueChange={([value]) => {
                        if (value != null) setAudioVolume(value);
                        setRadioEnabled(value === 0 ? false : true);
                      }}
                    />
                  </SettingsForm.SliderRow>
                </Desktop>
              )}

              <SettingsLayout.Separator />
            </>
          )}

          <SettingsForm.Switch
            id="dark-theme-switch"
            label="Dark theme"
            checked={themeName === Themes.Dark}
            onCheckedChange={() => dispatch(toggleTheme())}
          />
          <SettingsForm.Switch
            id="show-leveling-switch"
            label="Show leveling"
            checked={bot?.levels_enabled}
            onCheckedChange={() => {
              const isEnabled = !bot?.levels_enabled;
              logEvent(
                isEnabled
                  ? MetricsEvents.ShowLevelingEnabled
                  : MetricsEvents.ShowLevelingDisabled,
              );
              dispatch(updateBot({ levels_enabled: isEnabled }));
            }}
            disabled={!bot || isBotUpdating}
          />
        </SettingsLayout.Group>

        <SettingsLayout.Separator />

        <SettingsLayout.LinkGroup>
          <SettingsLayout.Item
            title="My profile"
            to={toModalRoute(ModalRoutes.SettingsMyProfile)}
            actionIcon="arrow"
          />
          <SettingsLayout.Item
            title="Version history"
            to={toModalRoute(ModalRoutes.SettingsVersionHistory)}
            actionIcon="arrow"
          />
          {gdprApplies && (
            <>
              <SettingsLayout.Item
                onClick={() => {
                  window._iub?.cs?.api?.openPreferences();
                }}
                title="Cookie settings"
                actionIcon="arrow"
              />
            </>
          )}
          {isSubscriptionBlockVisible && (
            <>
              <SettingsLayout.Item
                to={Routes.Subscription}
                title="Subscription"
                actionIcon={
                  isPendingSubscription
                    ? 'subscription-pending'
                    : 'subscription-pro'
                }
              />
            </>
          )}
        </SettingsLayout.LinkGroup>

        <SettingsLayout.Separator />
        <SettingsLayout.LinkGroup>
          <SettingsLayout.Item
            to={HELP_REPLIKA_URL}
            title="Help center"
            actionIcon="link"
          />

          <SettingsLayout.Item
            to={TERMS_REPLIKA_URL}
            title="Terms & Privacy"
            actionIcon="link"
          />

          <SettingsLayout.Item
            to={CREDITS_REPLIKA_URL}
            title="Credits"
            actionIcon="link"
          />
        </SettingsLayout.LinkGroup>

        <SettingsLayout.Separator />

        <Subtitle>Join our community</Subtitle>

        <SettingsLayout.LinkGroup>
          <SettingsLayout.Item
            to={REDDIT_COMMUNITY_URL}
            title={
              <>
                <RedditIcon />
                Reddit
              </>
            }
            actionIcon="link"
            onClick={() =>
              logEvent(MetricsEvents.CommunityLinkTapped, {
                link: 'reddit',
                source: 'settings screen',
              })
            }
          />

          <SettingsLayout.Item
            to={DISCORD_COMMUNITY_URL}
            title={
              <>
                <DiscordIcon />
                Discord
              </>
            }
            actionIcon="link"
            onClick={() =>
              logEvent(MetricsEvents.CommunityLinkTapped, {
                link: 'discord',
                source: 'settings screen',
              })
            }
          />

          <SettingsLayout.Item
            to={FACEBOOK_COMMUNITY_URL}
            title={
              <>
                <FacebookIcon />
                Facebook
              </>
            }
            actionIcon="link"
            onClick={() =>
              logEvent(MetricsEvents.CommunityLinkTapped, {
                link: 'facebook',
                source: 'settings screen',
              })
            }
          />
        </SettingsLayout.LinkGroup>

        <SettingsLayout.Separator />

        <SettingsLayout.LinkGroup>
          <SettingsLayout.Item
            onClick={() => {
              dispatch(sendLogOutRequest(true));
            }}
            title="Log out"
          />
        </SettingsLayout.LinkGroup>
      </SettingsLayout.Content>
    </SettingsLayout.Root>
  );
}

const Dimmed = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: ${(p) => p.theme.superDimmedFgColor};
`;

const Subtitle = styled.h3`
  color: rgb(255 255 255 / 60%);
  font-size: 14px;
  padding-inline: 20px;
  margin-block: 10px 10px;
  font-family: ${(p) => p.theme.fonts.display};
`;
