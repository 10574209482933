import { PopupboxManager } from 'react-popupbox';
import PopupBoxContent, { Image } from '../components/PopupboxContent';
import {
  createEscapeKeyHandler,
  registerKeydownListener,
  unregisterKeydownListener,
} from './eventQueue';
import './imagePopupbox.css';

// TODO: (maybe) throw away react-popupbox and implement our own lightbox

type Options = {
  images: Image[];
  index?: number;
  fullscreen?: boolean;
  popupId?: string;
  customFooter?: (props: { image }) => JSX.Element;
  onUnlockClick?: () => void;
};

type PopupId = string | null;

let _isPopupboxOpen = false;
let _popupId: PopupId = null;

const escapeKeyHandler = createEscapeKeyHandler(closePopupbox);

export function isPopupboxOpen(id: PopupId = null) {
  return id ? _isPopupboxOpen && _popupId === id : _isPopupboxOpen;
}

export function closePopupbox() {
  if (_isPopupboxOpen) {
    PopupboxManager.close();
    unregisterKeydownListener(escapeKeyHandler);
    _isPopupboxOpen = false;
    _popupId = null;
  }
}

export function openPopupbox(options: Options) {
  if (options.images.length === 0) return;

  const content = (
    <PopupBoxContent
      key={options.index + ':' + options.images[0]?.url}
      images={options.images}
      index={options.index}
      onClose={closePopupbox}
      fullscreen={options.fullscreen}
      customFooter={options.customFooter}
      onUnlockClick={options.onUnlockClick}
    />
  );

  PopupboxManager.open({
    content,
    config: {
      fadeIn: true,
      fadeInSpeed: 100,
      fadeOutSpeed: 100,
      overlayOpacity: 1,
      escClose: false,
    },
  });

  _isPopupboxOpen = true;
  _popupId = options.popupId ?? null;
  registerKeydownListener(escapeKeyHandler);
}
