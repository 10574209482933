type Environment = 'production' | 'dev' | 'localhost';

const LOCAL_NETWORK_IP_RE =
  /(^127\.)|(^10\.)|(^172\.1[6-9]\.)|(^172\.2[0-9]\.)|(^172\.3[0-1]\.)|(^192\.168\.)/;

export function getEnvironment(hostname?: string): Environment {
  if (!hostname) {
    hostname =
      typeof window !== 'undefined'
        ? window.location.hostname
        : 'my.replika.com';
  }

  if (hostname === 'localhost' || LOCAL_NETWORK_IP_RE.test(hostname)) {
    return 'localhost';
  } else if (hostname.indexOf('my.dev-') === 0) {
    return 'dev';
  } else {
    return 'production';
  }
}

export function isEnvironment(env: Environment, hostname?: string): boolean {
  return env === getEnvironment(hostname);
}
