import { forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { LinkProps, Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import outlineCss from './outlineCss';

export type { LinkProps };

export const UnstyledLink = forwardRef(function UnstyledLink(
  props: LinkProps,
  ref: React.Ref<HTMLAnchorElement>,
) {
  const { to, ...rest } = props;
  const pathname =
    typeof to === 'string'
      ? to
      : typeof to === 'function'
        ? undefined
        : to.pathname;

  const external = pathname?.startsWith('http');

  return (
    <RouterLink
      ref={ref}
      to={external ? { pathname } : to}
      target={external ? '_blank' : undefined}
      {...rest}
    />
  );
});

const Link = styled(UnstyledLink)`
  color: inherit;
  transition: box-shadow 0.15s ease-out;
  border-radius: 2px;

  &:focus-visible:focus {
    ${outlineCss({ offset: '2px' })}
  }
`;

export default Link;
