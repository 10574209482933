import { useAtomValue } from 'jotai';
import { useSelector } from 'react-redux';
import { unityRoleplayIdAtom } from '../../core/atoms';
import { HelpOption, Media, Suggestion } from '../../types/models';
import { AskReplikaCategories } from './enums';
import { Category, Prompt, PromptEnvironment } from './models';

export const getSelectedCategoryId = (
  categories: Category[] | undefined,
  id: string | undefined,
) => {
  if (!id || !categories) return null;

  for (let i = 0; i < categories.length; i++) {
    const c = categories[i];
    const s = c?.subcategories;

    if (c?.id === id) return c?.id;

    if (Array.isArray(s) && s.length > 0) {
      for (let j = 0; j < s!.length; j++) {
        const sub = s[j];

        if (sub?.id === id) return c?.id;
      }
    }
  }

  return null;
};

/**
 * To calculate the new scroll position, we need to find the distance to scroll to intersect with the scroll percentage.
 * This is similar to the classic school problem "Two moving bodies in opposite directions" and is solved using the relative speed formula.
 *
 * Va - Speed of the scroll percantage.
 * Vb - Speed of the category node.
 * Vr - Relative speed.
 * S - Distance between 2 elements.
 * Sb - Distance required to scroll the category node to intersect with the scroll percantage.
 *
 * @param distanceBetweenTwoElements Distance between 2 elements.
 * @param scrollableNode Scrollable node.
 *
 * @returns Distance required to scroll the category node to intersect with the scroll percantage.
 */
export const getNewScrollTopPosition = (
  distanceBetweenTwoElements: number,
  scrollableNode: HTMLElement,
) => {
  const scrollableNodeMaximumScrollTop =
    scrollableNode.scrollHeight - scrollableNode.clientHeight;

  const Vb = distanceBetweenTwoElements;
  const Va =
    (Vb / scrollableNodeMaximumScrollTop) * scrollableNode.clientHeight;
  const S = distanceBetweenTwoElements;
  const Vr = Va + Vb;
  const T = S / Vr;
  const Sb = Math.floor(Vb * T);

  return Sb;
};

export const horizontalScrollToCategory = (
  listNode: HTMLDivElement | null,
  categoryId: string,
) => {
  if (!listNode) return;

  const node = listNode.querySelector(`[data-category-button="${categoryId}"]`);

  if (!node) return;

  const middle = Math.round(listNode.clientWidth / 2);
  const listLeft = listNode.getBoundingClientRect().left;
  const nodeRect = node.getBoundingClientRect();
  const nodeLeft = nodeRect.left - listLeft;

  const scrollLeftValue =
    listNode.scrollLeft + nodeLeft - middle + Math.round(nodeRect.width / 2);

  listNode.scrollTo({
    behavior: 'smooth',
    left: scrollLeftValue < 0 ? 0 : scrollLeftValue,
    top: 0,
  });
};

export const ADDITIONAL_CATEGORIES_NAMES: Record<AskReplikaCategories, string> =
  {
    [AskReplikaCategories.Prompts]: 'Prompts',
  };

export const CRISIS = {
  id: 'crisis',
  description: "I'm in crisis",
};

export const isPrompt = (
  child: Prompt | Suggestion | HelpOption,
): child is Prompt => {
  return (child as Prompt).long_description !== undefined;
};

export const isUnityRoleplayPrompt = (
  environment?: PromptEnvironment,
): boolean =>
  environment?.unity_info?.mode === 'roleplay' &&
  !!environment?.unity_info.roleplay_id;

export const usePromptEnvironmentMusic = (): Media[] | null => {
  const unityRoleplayId = useAtomValue(unityRoleplayIdAtom);
  const promptEnvironment = useSelector((state) => state.prompts.environment);

  return unityRoleplayId && promptEnvironment?.music_url
    ? [
        {
          url: promptEnvironment.music_url,
          enabled: true,
          id: promptEnvironment.music_url,
          name: promptEnvironment.music_url,
          order: 0,
        },
      ]
    : null;
};

export const shuffleWithPriority = (prompts: Prompt[] = []) => {
  const prioritySum = prompts.reduce(
    (acc, current) => acc + current.priority!,
    0,
  );

  return prompts.sort(
    (a, b) =>
      calculateRealPriority(a, prioritySum) -
      calculateRealPriority(b, prioritySum),
  );
};

const calculateRealPriority = (prompt: Prompt, sum: number) =>
  (prompt.priority! / sum) * Math.random();
