import styled from 'styled-components/macro';
import reactionIcons from '../icons/reactions.png';
import { MessageActionType } from '../types/models';

type Reaction = {
  emoji: string;
  icon: string;
  offset: number;
  label: string;
  name: MessageActionType;
};

export const REACTIONS: Reaction[] = [
  { emoji: '😍', icon: reactionIcons, offset: 0, label: 'Love', name: 'Love' },
  {
    emoji: '😃',
    icon: reactionIcons,
    offset: 20,
    label: 'Funny',
    name: 'Funny',
  },
  {
    emoji: '😐',
    icon: reactionIcons,
    offset: 40,
    label: 'Meaningless',
    name: 'Meaningless',
  },
  {
    emoji: '😬',
    icon: reactionIcons,
    offset: 60,
    label: 'Offensive',
    name: 'Offensive',
  },
];

export const UPVOTE_REACTION: Reaction = {
  emoji: '👍',
  icon: reactionIcons,
  offset: 80,
  name: 'Upvote',
  label: 'Thumbs Up',
};

export const DOWNVOTE_REACTION: Reaction = {
  emoji: '👎',
  icon: reactionIcons,
  offset: 100,
  name: 'Downvote',
  label: 'Thumbs Down',
};

export const ReactionEmoji = styled.div<{ $reaction: Reaction }>`
  display: inline-block;
  background-size: 600% 100%;
  background-image: url('${(p) => p.$reaction.icon}');
  background-position: ${(p) => p.$reaction.offset}% 0px;
`;
