import { atomWithStorage } from 'jotai/utils';

export default function atomWithSessionStorage<T>(
  key: string,
  defaultValue: T,
) {
  const storedValue = sessionStorage.getItem(key);

  return atomWithStorage<T>(
    key,
    storedValue != null ? (JSON.parse(storedValue) as T) : defaultValue,
    {
      getItem(key) {
        const storedValue = sessionStorage.getItem(key);

        return storedValue != null
          ? (JSON.parse(storedValue) as T)
          : defaultValue;
      },
      setItem(key, value) {
        sessionStorage.setItem(key, JSON.stringify(value));
      },
      removeItem(key) {
        sessionStorage.removeItem(key);
      },
    },
  );
}
