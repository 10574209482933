import styled from 'styled-components/macro';
import { AccentButton, SolidButton } from '../../components/Buttons';
import { MetricsEvents, Routes } from '../../types/enums';
import { logEvent } from '../../utils/metrics';
import { PRIVACY_REPLIKA_URL, TERMS_REPLIKA_URL } from '../../utils/uri';
import useLogEventFirstRender from '../../utils/useLogEventFirstRender';

type Props = {
  className?: string;
};

const TITLE = 'The AI companion\nwho cares';

function Welcome({ className }: Props) {
  useLogEventFirstRender(MetricsEvents.WelcomeScreenOpened);

  return (
    <Main>
      <Title>{TITLE}</Title>
      <Content>
        <SolidButton
          type="button"
          to={{ pathname: Routes.Login, state: { from: 'welcome' } }}
        >
          Log in
        </SolidButton>
        <AccentButton
          type="submit"
          to={{ pathname: Routes.Signup, state: { from: 'welcome' } }}
        >
          Create an account
        </AccentButton>
        <Disclaimer>
          By signing up, you agree to our{' '}
          <a
            href={TERMS_REPLIKA_URL}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => logEvent(MetricsEvents.TermsOfServiceOpend)}
          >
            Terms of use
          </a>{' '}
          and{' '}
          <a
            href={PRIVACY_REPLIKA_URL}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => logEvent(MetricsEvents.PrivacyPolicyOpened)}
          >
            Privacy policy
          </a>
        </Disclaimer>
      </Content>
    </Main>
  );
}

export default Welcome;

const Title = styled.h1`
  margin: 32px 0;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  white-space: pre-wrap;

  @media ${(p) => p.theme.breakpoints.tablet} {
    font-size: 52px;
    line-height: 56px;
    margin: max(-64px, -5vh) 0 min(128px, 10vh);
  }
`;

const Main = styled.main`
  position: relative;
  z-index: 1;
  padding: 0 16px;
  min-height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100vw;

  @media ${(p) => p.theme.breakpoints.tablet} {
    min-width: auto;
    flex: 1 0 auto;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 350px;
`;

const Disclaimer = styled.p`
  margin: 32px 0 24px;
  font-size: 14px;
  line-height: 18px;
  color: ${(p) => p.theme.dimmedFgColor};
  text-align: center;

  @media (orientation: landscape) {
    margin: 16px 0;
  }

  & > a {
    color: inherit;
    text-decoration: underline ${(p) => p.theme.dimmedUnderlineColor};
    text-underline-offset: 2px;
    transition: text-decoration 0.25s ease-out;
  }

  & > a:focus-visible:focus {
    box-shadow: none;
    outline: 2px solid ${(p) => p.theme.outlineColor};
    outline-offset: 2px;
    border-radius: 2px;
  }
`;
