import { replace } from 'connected-react-router';
import { useAtom, useSetAtom } from 'jotai';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import {
  CustomizationGrid,
  CustomizationGridContainer,
  CustomizationGridItem,
} from '../../components/CustomizationGrid';
import { useMobileQuery } from '../../components/responsive';
import useAvatarSettings from '../../core/useAvatarSettings';
import { GenderValues, MetricsEvents, Routes } from '../../types/enums';
import useLogEventFirstRender from '../../utils/useLogEventFirstRender';
import { AuthFormWithAvatar, DesktopTitle } from './AuthLayout';
import { OnboardingSubmitButton } from './SignupYourNameAndPronouns';
import { replikaPronounsAtom, stylizedAvatarIdAtom } from './atoms';
import { femaleAvatars, stylizedAvatars } from './consts';

export default function SignupChooseAvatar() {
  const dispatch = useDispatch();
  const setReplikaPronouns = useSetAtom(replikaPronounsAtom);
  const [stylizedAvatarId, setStylizedAvatarId] = useAtom(stylizedAvatarIdAtom);

  useLogEventFirstRender(MetricsEvents.OnboardingAvatarScreen);

  const handleSelect = (key: string) => {
    setStylizedAvatarId(key);
  };

  const handleSubmit = async () => {
    setReplikaPronouns(
      !!femaleAvatars[stylizedAvatarId]
        ? GenderValues.Female
        : GenderValues.Male,
    );

    dispatch(replace(Routes.SignupReplikaNameAndPronouns));
  };

  const isMobile = useMobileQuery();

  useAvatarSettings({
    hidden: false,
    background: 'onboarding',
    cameraSlot: 'desktop_onboarding',
    baseBundleSet: 'onboarding',
  });

  const itemWidth = 65;

  return (
    <AuthFormWithAvatar
      onSubmit={handleSubmit}
      innerStyle={{ paddingBottom: isMobile ? 15 : 40 }}
    >
      <DesktopTitle>Choose avatar</DesktopTitle>
      <Container>
        <ChooveAvatarCustomizationGrid
          $width={itemWidth}
          minItemWidth={itemWidth}
          effectInputs={[]}
        >
          {Object.keys(stylizedAvatars).map((key) => {
            return (
              <CustomizationGridItem
                checked={key === stylizedAvatarId}
                size={itemWidth}
                key={key}
                backgroundImageUrl={stylizedAvatars[key].preview}
                onClick={() => {
                  handleSelect(key);
                }}
              />
            );
          })}
        </ChooveAvatarCustomizationGrid>
      </Container>
      <OnboardingSubmitButton>Continue</OnboardingSubmitButton>
    </AuthFormWithAvatar>
  );
}

const Container = styled(CustomizationGridContainer)`
  @media ${(p) => p.theme.breakpoints.tablet} {
    background: transparent;
    max-width: 540px;
    margin: -15px 0;
  }
`;

const ChooveAvatarCustomizationGrid = styled(CustomizationGrid)<{
  $width: number;
}>`
  gap: 5px;
  grid-template-columns: ${(p) => `repeat(4, minmax(${p.$width}px, 1fr))`};
  grid-auto-rows: ${(p) => `minmax(${p.$width}px, 1fr)`};

  @media ${(p) => p.theme.breakpoints.tablet} {
    grid-template-columns: ${(p) => `repeat(5, minmax(${p.$width}px, 1fr))`};
    padding: 15px;
  }
`;
