import { useCallback, useMemo, useRef } from 'react';
import { useAvatarLipsync } from '../../core/AvatarUnity/AvatarLipsync/AvatarLipsync';
import { useUnity3dDisabled } from '../../core/AvatarUnity/useUnity3dDisabled';
import { LipsyncPhoneme } from '../../types/websocket';
import { getBufferFromUrl } from './utils';

export default function usePlayAudio(options?: {
  onPlay: () => void;
  onEnd: () => void;
}) {
  const lipsyncEnabled = !useUnity3dDisabled();
  const avatarLipsync = useAvatarLipsync();
  const audioSourceRef = useRef<AudioBufferSourceNode | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const rAFRef = useRef<number>(-1);

  const processTime = useCallback(
    (phonemes?: LipsyncPhoneme[]) => {
      if (!lipsyncEnabled || !phonemes) {
        cancelAnimationFrame(rAFRef.current);

        return;
      }

      const lastPhonemeEnd = phonemes[phonemes.length - 1]?.end || 0;
      const currentTime = audioContextRef.current?.currentTime || 0;

      if (currentTime > lastPhonemeEnd) {
        cancelAnimationFrame(rAFRef.current);

        return;
      }

      avatarLipsync.setLipsyncPosition(currentTime || 0.0);

      rAFRef.current = requestAnimationFrame(() => processTime(phonemes));
    },
    [avatarLipsync, lipsyncEnabled],
  );

  const stop = useCallback(() => {
    if (!audioSourceRef.current) return;

    audioSourceRef.current?.disconnect();
    audioContextRef.current?.close();
    cancelAnimationFrame(rAFRef.current);
    avatarLipsync.clearPhonemes();

    audioSourceRef.current = null;
    audioContextRef.current = null;

    options?.onEnd();
  }, [avatarLipsync, options]);

  const play = useCallback(
    async (
      audioResource: string | ArrayBuffer,
      phonemes?: LipsyncPhoneme[],
    ) => {
      if (audioSourceRef.current) {
        stop();
      }
      options?.onPlay();

      const buffer =
        typeof audioResource === 'string'
          ? await getBufferFromUrl(audioResource)
          : audioResource;

      if (!buffer) {
        return;
      }

      audioContextRef.current = new AudioContext();
      await audioContextRef.current.resume();

      const audioChain: (AudioWorkletNode | AudioContext['destination'])[] = [];

      if (lipsyncEnabled) {
        if (phonemes) {
          avatarLipsync.setupPhonemes(phonemes);
        } else {
          const worklet = await avatarLipsync.prepareAndGetLipsyncWorklet(
            audioContextRef.current,
          );

          if (worklet) {
            audioChain.push(worklet);
          }
        }
      }

      const decodedData = await audioContextRef.current.decodeAudioData(buffer);

      // TODO: WTF?!
      // const channelData = decodedData.getChannelData(0);
      // audioWorkletRef.current.port.postMessage(channelData);

      audioSourceRef.current = audioContextRef.current.createBufferSource();
      audioSourceRef.current.buffer = decodedData;
      audioSourceRef.current.onended = stop;

      audioChain.push(audioContextRef.current.destination);
      audioChain.reduce((acc, current) => {
        acc.connect(current);

        return acc;
      }, audioSourceRef.current);

      audioSourceRef.current?.start(0);
      if (phonemes) {
        rAFRef.current = requestAnimationFrame(() => processTime(phonemes));
      }
    },
    [options, lipsyncEnabled, stop, avatarLipsync, processTime],
  );

  return useMemo(() => ({ play, stop }), [play, stop]);
}
