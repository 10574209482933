import * as React from 'react';

export default function useFocusVisibleWithin<T extends HTMLElement>(
  disabled?: boolean,
): [React.RefObject<T>, boolean] {
  const ref = React.useRef<T>(null);
  const [focusVisibleWithin, setFocusVisibleWithin] = React.useState(false);

  React.useEffect(() => {
    if (!ref.current || disabled) return;
    const el = ref.current;
    const handleFocusChange = () => {
      const fv =
        !!el.querySelector('.focus-visible:focus') ||
        !!el.querySelector(':focus-visible:focus');
      setFocusVisibleWithin(fv);
    };
    el.addEventListener('focus', handleFocusChange, true);
    el.addEventListener('blur', handleFocusChange, true);

    return () => {
      el.removeEventListener('focus', handleFocusChange, true);
      el.removeEventListener('blur', handleFocusChange, true);
    };
  }, [disabled]);

  return [ref, focusVisibleWithin];
}
