import { useContext } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import DialogContext from './DialogContext';
import DialogFocusTrap from './DialogFocusTrap';
import { ActionSheetDialogScroll, DialogScroll } from './DialogScroll';

type DialogProps = {
  className?: string;
  'data-testid'?: string;
  children: React.ReactNode;
  mobileLayout?: 'fullscreen' | 'centered' | 'action-sheet';
};

export const APPEAR_TIMEOUT = 300;

const APPEARING_ANIMATION = keyframes`
  from {
    transform: translate(0, 20%);
    opacity: 0;
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
`;

const HIDING_ANIMATION = keyframes`
  from {
    transform: translate(0, 0);
    opacity: 1;
  }

  to {
    transform: translate(0, 20%);
    opacity: 0;
  }
`;

export default function Dialog(props: DialogProps) {
  const { mobileLayout = 'fullscreen' } = props;
  const { isActive } = useContext(DialogContext);

  let Scroll = DialogScroll;
  let Root = DialogRoot;

  if (mobileLayout === 'action-sheet') {
    Scroll = ActionSheetDialogScroll;
    Root = ActionSheetDialogRoot;
  } else if (mobileLayout === 'centered') {
    Root = CenteredDialogRoot;
  } else if (mobileLayout === 'fullscreen') {
    Root = FullscreenDialogRoot;
  }

  return (
    <DialogFocusTrap>
      <Scroll>
        <Root
          role="dialog"
          aria-modal="true"
          tabIndex={-1} // fallback focus
          $isActive={isActive}
          {...props}
        />
      </Scroll>
    </DialogFocusTrap>
  );
}

const DialogRoot = styled.div<{
  $isActive: boolean;
}>`
  animation: ${(p) => (p.$isActive ? APPEARING_ANIMATION : HIDING_ANIMATION)}
    ${APPEAR_TIMEOUT}ms ease-in-out both;
  pointer-events: ${(p) => (p.$isActive ? 'initial' : 'none')};
  user-select: ${(p) => (p.$isActive ? 'inherit' : 'none')};

  background: ${(p) => p.theme.dialogBgColor};
  box-shadow: 0 5px 20px 0 ${(p) => p.theme.shadowColor};
  color: ${(p) => p.theme.fgColor};
  border-radius: 24px;
  overflow: hidden;

  flex: 0 0 auto;
  display: flex;
  flex-direction: column;

  @media ${(p) => p.theme.breakpoints.tablet} {
    max-width: 100vw;
  }
`;

const ActionSheetDialogRoot = styled(DialogRoot)`
  margin: 0vh;
  bottom: 0;
  border-radius: 8px 8px 0 0;
  min-width: auto;
  width: 100vw;

  @media ${(p) => p.theme.breakpoints.tablet} {
    margin: 10vh 0;
  }
`;

const CenteredDialogRoot = styled(DialogRoot)`
  max-width: 90vw;
`;

const FullscreenDialogRoot = styled(DialogRoot)`
  max-height: initial;
  left: 0;
  top: 0;
  border-radius: 0;
  margin: 0vh;
  min-width: auto;
  width: 100vw;

  height: 100svh;
  animation: none;

  @media ${(p) => p.theme.breakpoints.tablet} {
    margin: 10vh 0;
    height: auto;
    animation: ${(p) => (p.$isActive ? APPEARING_ANIMATION : HIDING_ANIMATION)}
      ${APPEAR_TIMEOUT}ms ease-in-out both;
    border-radius: 24px;
  }
`;
