import useMergedRef from '@react-hook/merged-ref';
import * as React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components/macro';
import { ReactComponent as IconClosedEye } from '../icons/ClosedEye.svg';
import { ReactComponent as IconEye } from '../icons/Eye.svg';
import { AriaButton } from './Buttons';
import outlineCss, { errorOutlineCss } from './outlineCss';

//
// TextInput
//

// hasIcon prop is used for positioning autofill key symbol in Safari
// (so it doesn't overlap with our custom icon, like show/hide password toggle)
export const TextInput = styled.input<{ $hasIcon?: boolean }>`
  -webkit-appearance: none;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  transition: background 0.15s ease-out;
  height: 50px;
  padding: 14px 16px 16px;
  font-size: 16px;
  font-weight: 400;
  color: ${(p) => p.theme.fgColor};
  border: 0;

  background-color: rgb(0 0 0 / 0.15);
  backdrop-filter: blur(50px);
  border-radius: 24px;

  &::-webkit-input-placeholder {
    color: ${(p) => p.theme.placeholderColor};
    opacity: 1;
    transition: opacity 0.2s ease-out;
  }
  &:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  &::-moz-placeholder {
    color: ${(p) => p.theme.placeholderColor};
    opacity: 1;
    transition: opacity 0.2s ease-out;
  }
  &:focus::-moz-placeholder {
    opacity: 0;
  }
  &::-ms-input-placeholder {
    color: ${(p) => p.theme.placeholderColor};
    opacity: 1;
    transition: opacity 0.2s ease-out;
  }
  &:focus::-ms-input-placeholder {
    opacity: 0;
  }

  &::-webkit-textfield-decoration-container {
    margin-right: ${(p) => (p.$hasIcon ? '30px' : 0)};
  }

  &::-ms-clear {
    margin-right: ${(p) => (p.$hasIcon ? '30px' : 0)};
  }

  &::-ms-reveal {
    display: none;
  }

  &[aria-invalid='true'] {
    ${errorOutlineCss()}
  }

  &:focus {
    outline: none;
  }

  &:autofill,
  &:-internal-autofill-selected {
    font-size: 16px !important;
  }

  &:focus-visible:focus {
    position: relative;
    ${outlineCss()}
  }

  &::-webkit-credentials-auto-fill-button,
  &::-webkit-caps-lock-indicator {
    background-color: ${(p) => p.theme.fgColor};
  }

  &:autofill::-webkit-credentials-auto-fill-button,
  &:autofill::-webkit-caps-lock-indicator {
    background-color: inherit;
  }
`;

export const TextArea = styled(TextareaAutosize)`
  padding: 10px;
  font-size: 16px;
  line-height: 24px;
  resize: vertical;
  width: 100%;
  border: 1px solid ${(p) => p.theme.borderColor};
  border-radius: 4px;

  background-color: ${(p) => p.theme.chatBgColor};
  color: ${(p) => p.theme.fgColor};

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    color: ${(p) => p.theme.placeholderColor};
    opacity: 1;
    transition: opacity 0.2s ease-out;
  }
  &:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  &::-moz-placeholder {
    color: ${(p) => p.theme.placeholderColor};
    opacity: 1;
    transition: opacity 0.2s ease-out;
  }
  &:focus::-moz-placeholder {
    opacity: 0;
  }
  &::-ms-input-placeholder {
    color: ${(p) => p.theme.placeholderColor};
    opacity: 1;
    transition: opacity 0.2s ease-out;
  }
  &:focus::-ms-input-placeholder {
    opacity: 0;
  }
`;

//
// ValidatedTextInput
//

type ValidatedTextInputProps = React.ComponentPropsWithRef<typeof TextInput> & {
  id: string;
  errors?: string[];
};

export const ValidatedTextInput = React.forwardRef<
  HTMLInputElement,
  ValidatedTextInputProps
>(function LabeledTextInput({ className, id, errors, ...rest }, ref) {
  return (
    <div className={className}>
      <TextInput
        id={id}
        ref={ref}
        aria-describedby={`${id}-errors`}
        {...rest}
      />
      <FieldErrors errors={errors} id={`${id}-errors`} />
    </div>
  );
});

type DynamicLabelRenderProps = {
  setHasValue: (hasValue: boolean) => void;
  setFocused: (focused: boolean) => void;
  controlRef: React.Ref<{ value: string }>;
};

type DynamicLabelControlProps = {
  id: string;
  errors?: string[];
  info?: string;
  placeholder?: string;
  className?: string;
  value?: string;
  render: (props: DynamicLabelRenderProps) => React.ReactNode;
};

export const DynamicLabelControl = React.forwardRef<
  { value: string },
  DynamicLabelControlProps
>(function DynamicLabelControl(
  { placeholder, className, value, id, errors, info, render },
  ref,
) {
  const [focused, setFocused] = React.useState(false);
  const controlRef = React.useRef<{ value: string }>(null);
  const [hasValue, setHasValue] = React.useState(!!value);

  const mergedRef = useMergedRef(controlRef, ref);

  // Check actual value in case the input is controlled outside of this component
  React.useLayoutEffect(() => {
    if (!controlRef.current || controlRef.current.value == null) return;
    const isElementWithValue =
      controlRef.current instanceof HTMLInputElement ||
      controlRef.current instanceof HTMLTextAreaElement;
    if (!isElementWithValue) return;

    setHasValue(!!controlRef.current.value);
  }, []);

  return (
    <LabelRoot className={className}>
      <Label htmlFor={id} $collapsed={focused || hasValue}>
        {placeholder}
      </Label>
      {render({ setHasValue, setFocused, controlRef: mergedRef })}
      <FieldErrors errors={errors} info={info} id={`${id}-errors`} />
    </LabelRoot>
  );
});

//
// Text input with dynamic label
//

type LabeledTextInputProps = React.ComponentPropsWithRef<typeof TextInput> & {
  id: string;
  errors?: string[];
  info?: string;
};

export const LabeledTextInput = React.forwardRef<
  HTMLInputElement,
  LabeledTextInputProps
>(function LabeledTextInput(
  {
    placeholder,
    className,
    value,
    id,
    onFocus,
    onBlur,
    onChange,
    errors,
    info,
    ...rest
  },
  ref,
) {
  return (
    <DynamicLabelControl
      id={id}
      ref={ref}
      errors={errors}
      info={info}
      placeholder={placeholder}
      className={className}
      value={value}
      render={({ setHasValue, setFocused, controlRef }) => (
        <LabeledTextInputField
          id={id}
          ref={controlRef}
          value={value}
          onFocus={(e) => {
            setFocused(true);
            onFocus?.(e);
          }}
          onBlur={(e) => {
            setFocused(false);
            onBlur?.(e);
          }}
          onChange={(e) => {
            setHasValue(!!e.target.value);
            onChange?.(e);
          }}
          aria-describedby={`${id}-errors`}
          {...rest}
        />
      )}
    />
  );
});

function FieldErrors({
  id,
  errors = [],
  info,
}: {
  id: string;
  errors?: string[];
  info?: string;
}) {
  return info || errors.length > 0 ? (
    <FieldErrorsRoot id={id}>
      {errors.map((error, idx) => (
        <FieldError key={idx}>{error}</FieldError>
      ))}
      {!!info && errors.length === 0 && <FieldInfo>{info}</FieldInfo>}
    </FieldErrorsRoot>
  ) : null;
}

const FieldErrorsRoot = styled.div`
  font-size: 14px;
  line-height: 16px;
  padding: 5px 15px;
`;

const FieldError = styled.p`
  color: #fe9e98;
  margin: 0;
`;

const FieldInfo = styled.p`
  opacity: 0.7;
  margin: 0;
`;

const LabelRoot = styled.div`
  position: relative;
  min-height: 55px;
`;

export const Label = styled.label<{ $collapsed: boolean }>`
  position: absolute;
  z-index: 1;
  top: ${(p) => (p.$collapsed ? 8 : 19)}px;
  left: 16px;
  color: ${(p) => p.theme.placeholderColor};
  font-size: ${(p) => (p.$collapsed ? 12 : 16)}px;
  line-height: 15px;
  user-select: none;
  pointer-events: none;
  transition:
    top 0.2s ease-out,
    color 0.2s ease-out,
    font-size 0.2s ease-out;

  &:has(+ input:autofill) {
    top: 8px;
    font-size: 12px;
    color: fieldtext;
  }

  &:has(+ input:-internal-autofill-selected),
  &:has(+ input:-webkit-autofill) {
    top: 8px;
    font-size: 12px;
    color: fieldtext;
  }
`;

const LabeledTextInputField = styled(TextInput)`
  position: relative;
  height: 54px;
  padding: 27px 16px 12px;
  font-size: 16px;

  &::-webkit-credentials-auto-fill-button,
  &::-webkit-caps-lock-indicator {
    transform: translateY(-6px);
  }
`;

//
// Text input with static label
//

type StaticallyLabledTextInputProps = React.ComponentPropsWithRef<
  typeof TextInput
> & {
  className: string;
  staticLabel: string;
  id: string;
};

export const StaticallyLabledTextInput = React.forwardRef<
  HTMLInputElement,
  StaticallyLabledTextInputProps
>(function StaticallyLabledTextInput(
  { className, staticLabel, id, ...rest },
  ref,
) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const mergedRef = useMergedRef(inputRef, ref);

  const [labelWidth, setLabelWidth] = React.useState(0);
  const staticLabelRef = React.useRef<HTMLLabelElement>(null);

  React.useEffect(() => {
    if (staticLabelRef.current) {
      setLabelWidth(staticLabelRef.current.clientWidth);
    }
  }, []);

  return (
    <StaticallyLabledTextInputRoot className={className}>
      <StaticLabel ref={staticLabelRef} htmlFor={id}>
        {staticLabel}
      </StaticLabel>
      <StaticallyLabledTextInputField
        id={id}
        ref={mergedRef}
        {...rest}
        $padding={labelWidth}
      />
    </StaticallyLabledTextInputRoot>
  );
});

const StaticallyLabledTextInputRoot = styled.div`
  position: relative;
  width: 100%;
`;

const StaticLabel = styled.label`
  position: absolute;
  padding: 0 15px;
  bottom: 22px;
  left: 0;
  font-family: ${(p) => p.theme.fonts.display};
  font-size: 16px;
  line-height: 20px;
  color: rgba(255 255 255 / 70%);
`;

const StaticallyLabledTextInputField = styled(TextInput)<{ $padding: number }>`
  height: 64px;
  padding: 22px 15px;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  font-family: ${(p) => p.theme.fonts.display};
  padding-left: ${(p) => `${p.$padding}px`};
  background-color: rgba(255 255 255 / 10%);
  backdrop-filter: none;

  &::-webkit-input-placeholder {
    color: rgba(255 255 255 / 20%);
  }
  &::-moz-placeholder {
    color: rgba(255 255 255 / 20%);
  }
  &::-ms-input-placeholder {
    color: rgba(255 255 255 / 20%);
  }
`;

//
// PasswordInput
//

type PasswordInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  'data-testid'?: string;
  labeled?: boolean;
  id: string;
  errors?: string[];
  info?: string;
};

export const PasswordInput = React.forwardRef<
  HTMLInputElement,
  PasswordInputProps
>(function PasswordInput(props, ref) {
  const [passwordRevealed, setPasswordRevealed] = React.useState(false);

  const {
    value,
    name,
    id,
    onChange,
    'data-testid': dataTestId,
    labeled,
    className,
    style,
    errors,
    info,
    ...rest
  } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [hasIcon, setHasIcon] = React.useState(
    typeof value === 'string' && value.length > 0,
  );

  React.useLayoutEffect(() => {
    if (!inputRef.current) return;
    setHasIcon(inputRef.current.value.length > 0);
  }, []);

  const InputControl = labeled ? LabeledTextInput : TextInput;

  const mergedRef = useMergedRef(inputRef, ref);

  return (
    <PasswordInputRoot className={className} style={style}>
      <InputControl
        className="password-input"
        autoComplete="off"
        data-testid={dataTestId}
        name={name}
        id={id}
        value={value}
        onChange={(e) => {
          setHasIcon(e.target.value.length > 0);
          onChange?.(e);
        }}
        $hasIcon={hasIcon}
        ref={mergedRef}
        {...rest}
        type={passwordRevealed ? 'text' : 'password'}
      />
      <ShowPasswordToggle
        $labeled={labeled}
        type="button"
        onClick={() => setPasswordRevealed(!passwordRevealed)}
        label={passwordRevealed ? 'Hide password' : 'Show password'}
        active={passwordRevealed}
        data-testid={dataTestId}
        $visible={hasIcon}
      >
        {passwordRevealed ? (
          <IconClosedEye aria-hidden="true" />
        ) : (
          <IconEye aria-hidden="true" />
        )}
      </ShowPasswordToggle>
      <FieldErrors errors={errors} info={info} id={`${id}-errors`} />
    </PasswordInputRoot>
  );
});

const PasswordInputRoot = styled.div`
  position: relative;

  &:has(input:autofill) > button {
    display: block;
    color: fieldtext;
  }

  &:has(input:-internal-autofill-selected) > button,
  &:has(input:-webkit-autofill) > button {
    display: block;
    color: fieldtext;
  }

  &:has(input:autofill) > button:focus-visible:focus {
    outline-color: fieldtext;
  }

  &:has(input:-internal-autofill-selected) > button:focus-visible:focus,
  &:has(input:-webkit-autofill) > button:focus-visible:focus {
    outline-color: fieldtext;
  }
`;

const ShowPasswordToggle = styled(AriaButton)<{
  $labeled?: boolean;
  $visible: boolean;
}>`
  position: absolute;
  top: ${(p) => (p.$labeled ? 15 : 13)}px;
  right: 15px;
  display: ${(p) => (p.$visible ? 'block' : 'none')};
  border-radius: 4px;

  margin: 0;
  padding: 0;
  border: 0;
  background: none;

  width: 24px;
  height: 24px;
  opacity: 0.7;
  color: ${(p) => p.theme.fgColor};

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  & > svg {
    width: 100%;
    height: 100%;
  }

  &:focus-visible:focus {
    ${outlineCss({ offset: '2px' })}
  }
`;

export const TransparentInput = styled(TextInput)`
  font-family: ${(p) => p.theme.fonts.display};
  width: 100%;
  padding: 0;
  font-size: 28px;
  line-height: 32px;
  height: 36px;
  text-align: center;
  background: transparent;
  border: none;
  outline: none;
  border-radius: 0;

  &[aria-invalid='true'] {
    outline: none;
    border-bottom: 1px solid rgba(254 158 152 / 90%);
  }

  &:focus-visible:focus {
    outline: none;
    border-bottom: none;
  }

  &:focus::-webkit-input-placeholder {
    opacity: 0.5;
  }
  &:focus::-moz-placeholder {
    opacity: 0.5;
  }
  &:focus::-ms-input-placeholder {
    opacity: 0.5;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 450px;
    font-size: 52px;
    line-height: 56px;
    height: 64px;
  }
`;
