import ls from 'local-storage';
import { Action } from 'redux';
import {
  AnyAction,
  InitPersist,
  ResetPersist,
  UpdatePersist,
} from '../types/actions';
import {
  AsyncActionStatus,
  ToError,
  ToRequest,
  ToSuccess,
} from '../types/asyncActions';
import { ActionTypes } from '../types/enums';
import { Reducer } from '../types/redux';
import { State } from '../types/states';

// proper state types after https://github.com/Microsoft/TypeScript/pull/13288 is resolved
// UPD: it's not gonna be resolved :(

/**
 * If reducer state contains `persist` property, its content will be automatically synced with localstorage
 *
 * If there is a `version` property in `persist` (i.e. `state.persist.version`),
 * the persist state is treated as versioned: it's reset to default state when `version` is changed
 * instead of being merged with current state (useful when storage structure needs to be changed)
 */

type AsyncReducerMap<S, A extends Action = AnyAction> = {
  request?: Reducer<S, ToRequest<A>>;
  error?: Reducer<S, ToError<A>>;
  success?: Reducer<S, ToSuccess<A>>;
};

type RootReducer<S, A extends Action = AnyAction> = {
  [T in A['type']]?: Reducer<S, A> | AsyncReducerMap<S, A>;
};

type AsyncAction = Action & {
  status: AsyncActionStatus;
};

type ReducerStateWithPersist<S, P extends {}> = S & {
  persist: P;
};

type VersionedReducerState<S, P extends {}> = S & {
  persist: P & { version: number };
};

const restoreStateFromLS = <
  S,
  P extends {},
  State extends ReducerStateWithPersist<S, P> = ReducerStateWithPersist<S, P>,
>(
  reducerName: string,
  state: State,
  replacePersist: boolean = false,
): State => {
  const storedState: P | undefined = ls.get(reducerName);
  if (storedState && typeof storedState === 'object') {
    return {
      ...state,
      persist: replacePersist
        ? storedState
        : { ...state.persist, ...storedState },
    };
  }

  return state;
};

const saveStateToLS = <S, P extends {}>(
  reducerName: string,
  state: ReducerStateWithPersist<S, P>,
) => {
  ls.set(reducerName, state.persist);

  window.dispatchEvent(
    new StorageEvent('reduxPersistUpdate', {
      key: reducerName,
      newValue: JSON.stringify(state.persist),
    }),
  );
};

function isInitPersistAction(action: Action): action is InitPersist {
  return action.type === ActionTypes.InitPersist;
}

function isUpdatePersistAction(action: Action): action is UpdatePersist {
  return action.type === ActionTypes.UpdatePersist;
}

function isResetPersistAction(action: Action): action is ResetPersist {
  return action.type === ActionTypes.ResetPersist;
}

function isAsyncAction(action: Action): action is AsyncAction {
  return action.hasOwnProperty('status');
}

function isStateWithPersist<
  S extends {} = State[keyof State],
  P extends {} = {},
>(state: S): state is ReducerStateWithPersist<S, P> {
  return state.hasOwnProperty('persist');
}

function isVersionedState<S extends {} = State[keyof State], P extends {} = {}>(
  state: S,
): state is VersionedReducerState<S, P> {
  return isStateWithPersist(state) && state.persist.hasOwnProperty('version');
}

function restoreState<
  T extends keyof State,
  S extends {} = State[T],
  A extends Action = Action,
>(reducerName: T, state: S, action: A) {
  let restoredState = state;

  if (isInitPersistAction(action) && isStateWithPersist(state)) {
    if (isVersionedState(state)) {
      const _restoredState = restoreStateFromLS(reducerName, state, true);

      const storedVersion = _restoredState.persist.version;
      const currentVersion = state.persist.version;

      if (storedVersion === currentVersion) {
        restoredState = _restoredState;
      } else {
        console.warn(
          `'${reducerName}' state version mismatch: ${storedVersion} != ${currentVersion}; restoring skipped`,
        );
      }
    } else {
      restoredState = restoreStateFromLS(reducerName, state);
    }
  }

  if (isUpdatePersistAction(action) && action.reducerName === reducerName) {
    if (action.persist && typeof action.persist === 'object') {
      restoredState = { ...restoredState, persist: action.persist };
    }
  }

  return restoredState;
}

export default function applyReducer<
  T extends keyof State,
  A extends Action = Action,
>(
  reducerName: T,
  reducers: RootReducer<State[T], A>,
  state: State[T],
  action: A,
): State[T] {
  Object.keys(reducers).forEach(
    (a) => !a && console.error('Undefined reducer found!'),
  );

  const restoredState = restoreState(reducerName, state, action);

  const reducerOrMap = reducers[action.type];
  let reducer: Reducer<State[T], A> | undefined;

  if (reducerOrMap && typeof reducerOrMap !== 'function') {
    if (isAsyncAction(action)) {
      reducer = reducerOrMap[action.status];
    }
  } else {
    reducer = reducerOrMap;
  }

  const newState = reducer ? reducer(restoredState, action) : restoredState;

  if (isResetPersistAction(action)) {
    if (action.ignoredReducers.indexOf(reducerName) === -1) {
      ls.remove(reducerName);
    }
  } else if (
    !isInitPersistAction(action) &&
    restoredState !== newState &&
    isStateWithPersist(newState)
  ) {
    saveStateToLS(reducerName, newState);
  }

  return newState;
}
