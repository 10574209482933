import * as React from 'react';
import styled from 'styled-components/macro';
import FeedbackPopup from './FeedbackPopup';
import NotificationPopup from './NotificationPopup';
import SystemNotificationPopup from './SystemNotificationPopup';
import { PopupType, useActivePopup } from './useActivePopup';

type Props = {
  className?: string;
  includePopups?: PopupType[];
};

function ChatPopups(props: Props) {
  const { className, includePopups } = props;

  let { activePopup, notifications, systemNotifications, pause } =
    useActivePopup(includePopups);

  return (
    <ChatPopupsRoot className={className}>
      <FeedBackAnimatedPopupRoot isActive={activePopup === 'feedback'}>
        <FeedbackPopup />
      </FeedBackAnimatedPopupRoot>
      <AnimatedPopup isActive={activePopup === 'notification'}>
        <NotificationPopup
          notification={
            !activePopup ? undefined : notifications[0]?.notification
          }
          id={notifications[0]?.id}
          onClose={() => pause(2000)}
        />
      </AnimatedPopup>
      <AnimatedPopup
        isActive={activePopup === 'systemNotification'}
        mobileBottom
      >
        <SystemNotificationPopup
          notifications={
            activePopup === 'systemNotification' ? systemNotifications : []
          }
          onClose={() => pause(2000)}
        />
      </AnimatedPopup>
    </ChatPopupsRoot>
  );
}

export default ChatPopups;

function AnimatedPopup({
  isActive,
  children,
  mobileBottom,
  className,
}: {
  isActive: boolean;
  children: React.ReactNode;
  mobileBottom?: boolean;
  className?: string;
}) {
  const [isHidden, setIsHidden] = React.useState(!isActive);

  React.useEffect(() => {
    if (isActive) {
      setIsHidden(false);
    } else {
      const to = setTimeout(() => setIsHidden(true), 500);
      return () => clearTimeout(to);
    }
    return;
  }, [isActive]);

  return (
    <AnimatedPopupRoot
      className={className}
      aria-hidden={!isActive}
      $isActive={isActive}
      $isHidden={isHidden}
      $mobileBottom={mobileBottom}
    >
      {children}
    </AnimatedPopupRoot>
  );
}

const AnimatedPopupRoot = styled.div<{
  $isActive: boolean;
  $isHidden: boolean;
  $mobileBottom?: boolean;
}>`
  visibility: ${(p) => (p.$isHidden ? 'hidden' : 'visible')};

  transform: translate(-50%, ${(p) => (p.$isActive ? '20px' : '-200px')});
  max-width: 100vw;
  position: absolute;
  top: 0;
  transition: transform 0.5s ease-in-out;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: auto;
  }
`;

const FeedBackAnimatedPopupRoot = styled(AnimatedPopup)`
  top: auto;
  bottom: 75px;
  width: 100vw;
  padding-inline: 20px;
  transform: translate(calc(-50vw), ${(p) => (p.isActive ? 0 : '220px')});

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: auto;
    padding-inline: 0;
    bottom: 0;
    margin: 0 0 20px 20px;
  }
`;

const ChatPopupsRoot = styled.div``;
