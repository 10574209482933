import { pick, sortBy, sortedUniqBy, takeRight } from 'lodash';
import * as A from '../types/actions';
import { ToError, ToRequest, ToSuccess } from '../types/asyncActions';
import { ActionTypes, AsyncActionTypes } from '../types/enums';
import { Message } from '../types/models';
import { Reducer } from '../types/redux';
import { ChatState } from '../types/states';
import applyReducer from '../utils/applyReducer';

const messageSorter = (m: Message) => m.meta.timestamp;
const uniqMessage = (m: Message) => m.id;

const sortMessages = (messages: Message[]) =>
  sortedUniqBy(sortBy(messages, messageSorter), uniqMessage);

const DEFAULT_STATE: ChatState = {
  messages: undefined,
  mediaPreviews: {},
  messageReactions: {},
  isRobotTyping: false,
  hasMoreMessages: true,
  messageTokenIdMap: {},
  messageAlerts: [],
  persist: {
    version: 2,
    advancedAiUnlimited: undefined,
    advancedAiStatus: 'initial',
    advancedAiMessageCount: undefined,
    lastMessages: undefined,
    lastMessageReactions: {},
    messageText: '',
    voiceMessages: [],
    globalVoiceMessageState: 'idle',
    lastMessagePlayedId: undefined,
    rememberedMessages: [],
  },
  showBoostAdvancedAiWidget: false,
  voiceModeEnabled: false,
  skippedWidgetId: undefined,
  imagePreviews: {},
  firstInitCompleted: false,
  voiceCallAudioQueue: [],
  voiceCallInProgress: false,
  voiceCallStartTime: undefined,
  lastVoiceMessageTimestamp: undefined,
  suggestions: [],
};

type R<
  X extends
    | A.ChatAction
    | A.InitAction
    | A.IncomingWsAction
    | A.VoiceAction
    | A.MemoryAction,
> = Reducer<ChatState, X>;
type RRequest<X> = Reducer<ChatState, ToRequest<X>>;
type RSuccess<X> = Reducer<ChatState, ToSuccess<X>>;
type RError<X> = Reducer<ChatState, ToError<X>>;

const initMessages: R<A.InitPersist> = (state) => ({
  ...state,
  messages: state.persist.lastMessages,
  messageReactions: state.persist.lastMessageReactions,
});

const updateLastMessages = (state: ChatState) => {
  const lastMessages = takeRight(state.messages);
  const lastMessageIds = lastMessages.map((m) => m.id);

  return {
    ...state,
    persist: {
      ...state.persist,
      lastMessages: takeRight(state.messages, 30),
      lastMessageReactions: pick(state.messageReactions, lastMessageIds),
    },
  };
};

const getHistorySuccess: RSuccess<A.GetHistory> = (
  state,
  { result: h, params: { invalidate } },
) =>
  updateLastMessages({
    ...state,
    messages: sortMessages(
      invalidate ? h.messages : [...(state.messages || []), ...h.messages],
    ),
    messageReactions: h.message_reactions.reduce((acc, r) => {
      acc[r.message_id] = r.reaction;
      return acc;
    }, state.messageReactions),
    hasMoreMessages: h.more,
  });

const suggestionListReceived: R<A.SuggestionListReceived> = (
  state,
  { suggestions },
) => ({
  ...state,
  suggestions: suggestions.map((suggestion) => ({
    ...suggestion,
    disabled: false,
  })),
});

const getChatSuggestionListSuccess: RSuccess<A.GetChatSuggestionList> = (
  state,
  { result: { suggestions } },
) => ({
  ...state,
  suggestions: suggestions.map((suggestion) => ({
    ...suggestion,
    disabled: false,
  })),
});

const startChatSuggestionRequest: RRequest<A.StartChatSuggestion> = (
  state,
  { params },
) => ({
  ...state,
  suggestions: state.suggestions.map((suggestion) =>
    suggestion.id === params.id
      ? { ...suggestion, disabled: true }
      : suggestion,
  ),
});

const startChatSuggestionSuccess: RSuccess<A.StartChatSuggestion> = (
  state,
  { params },
) => ({
  ...state,
  suggestions: state.suggestions.filter(
    (suggestion) => suggestion.id !== params.id,
  ),
});

const startChatSuggestionError: RError<A.StartChatSuggestion> = (
  state,
  { params },
) => ({
  ...state,
  suggestions: state.suggestions.map((suggestion) =>
    suggestion.id === params.id
      ? { ...suggestion, disabled: false }
      : suggestion,
  ),
});

const chatMessageReceived: R<A.ChatMessageReceived> = (state, { message }) => {
  // Remove a reaction for the rerolled message because it has the same id.
  const { [message.id]: removed, ...messageReactions } = state.messageReactions;

  return updateLastMessages({
    ...state,
    messages: sortMessages([
      // Filter out a rerolled message because it has the same id.
      ...(state.messages || []).filter(({ id }) => id !== message.id),
      message,
    ]),
    isRobotTyping: false,
    messageReactions,
  });
};

const voiceRecordReceived: R<A.VoiceRecordReceived> = (
  state,
  { voiceRecord },
) => {
  return state.voiceCallInProgress
    ? {
        ...state,
        voiceCallAudioQueue: [
          ...state.voiceCallAudioQueue,
          {
            ...voiceRecord,
          },
        ],
      }
    : state;
};

const messageReactionReceived: R<A.MessageReactionReceived> = (
  state,
  { messageId, reaction },
) =>
  updateLastMessages({
    ...state,
    messageReactions: {
      ...state.messageReactions,
      [messageId]: reaction,
    },
  });

const messageEmotionReceived: R<A.MessageEmotionReceived> = (
  state,
  { messageId, emotion },
) => ({
  ...state,
  messages: state.messages?.map<Message>((message) =>
    message.id === messageId
      ? Object.assign({}, message, { avatarEmotion: { name: emotion } })
      : message,
  ),
});
const setMessageReactionSuccess: RSuccess<A.SetMessageReaction> = (
  state,
  { params: { messageId, reaction } },
) =>
  updateLastMessages({
    ...state,
    messageReactions: {
      ...state.messageReactions,
      [messageId]: reaction,
    },
  });

const getMediaPreviewSuccess: RSuccess<A.GetMediaPreview> = (
  state,
  { result: mediaPreviews },
) => ({
  ...state,
  mediaPreviews: { ...state.mediaPreviews, ...mediaPreviews },
});

const startTyping: R<A.StartTypingReceived> = (state) => ({
  ...state,
  isRobotTyping: true,
});

const stopTyping: R<A.StopTypingReceived> = (state) => ({
  ...state,
  isRobotTyping: false,
});

const sendMessageSuccess: RSuccess<A.SendMessage> = (
  state,
  { result: message },
) => ({
  ...state,
  messageTokenIdMap: {
    ...state.messageTokenIdMap,
    [message.meta.client_token]: message.id,
  },
  skippedWidgetId: undefined,
});

const setMessageScoreAlert: R<A.BotStatsReceived> = (
  state,
  { messageId, scoreGranted },
) =>
  messageId
    ? {
        ...state,
        messageAlerts: [
          ...state.messageAlerts,
          {
            type: 'score',
            messageId,
            score: scoreGranted,
          },
        ],
      }
    : state;

const setMessageRemembered: R<A.StatementRemembered> = (
  state,
  { messageId, memoryId, memoryType, nature },
) => {
  return {
    ...state,
    messageAlerts: [
      ...state.messageAlerts,
      {
        type: 'memory',
        messageId,
        memoryId,
      },
    ],
    persist: {
      ...state.persist,
      rememberedMessages: [
        ...state.persist.rememberedMessages,
        {
          messageId,
          memoryId,
          memoryType,
          nature,
        },
      ],
    },
  };
};

const removeRememberedMessage: RSuccess<A.DeleteMemory> = (
  state,
  { params },
) => {
  return {
    ...state,
    messageAlerts: state.messageAlerts.filter(
      (alert) => !(alert.type === 'memory' && alert.memoryId === params.id),
    ),
    persist: {
      ...state.persist,
      rememberedMessages: state.persist.rememberedMessages.filter(
        (message) => message.memoryId !== params.id,
      ),
    },
  };
};

const clearChatMessageAlert: R<A.ClearChatMessageAlert> = (state, params) => {
  return {
    ...state,
    messageAlerts: state.messageAlerts.filter(
      (a) => a.type !== params.alertType || a.messageId !== params.messageId,
    ),
  };
};

const resetChat = () => ({ ...DEFAULT_STATE });

const voiceModeChanged: R<A.VoiceModeReceived> = (
  state,
  { voiceModeState },
) => ({
  ...state,
  voiceModeEnabled: voiceModeState === 'enabled',
  voiceCallInProgress:
    voiceModeState === 'disabled' ? false : state.voiceCallInProgress,
});

const updateChatMessageText: R<A.UpdateChatMessageText> = (
  state,
  { messageText },
) => ({
  ...state,
  persist: {
    ...state.persist,
    messageText,
  },
});

const handleSkipWidget: R<A.SkipWidget> = (state, { id }) => ({
  ...state,
  skippedWidgetId: id,
});

const setImagePreview: R<A.UploadChatImagePreview> = (
  state,
  { width, height, src, orientation, clientToken },
) => ({
  ...state,
  imagePreviews: {
    ...state.imagePreviews,
    [clientToken]: {
      width,
      height,
      src,
      orientation,
    },
  },
});

const setFirstInit: R<A.ChatInitialized> = (state) => ({
  ...state,
  firstInitCompleted: true,
});

const changeVoiceMode: RSuccess<A.ChangeVoiceMode> = (
  state,
  { params: { voiceMode } },
) => ({
  ...state,
  voiceCallInProgress: voiceMode === 'enable',
});

const updateVoiceCallAudioQueue: R<A.UpdateVoiceCallAudioQueue> = (
  state,
  { audioQueue },
) => ({
  ...state,
  voiceCallAudioQueue: audioQueue,
});

const saveVoiceCallStartTime: R<A.SaveVoiceCallStartTime> = (
  state,
  { timestamp },
) => ({
  ...state,
  voiceCallStartTime: timestamp,
});

const saveVoiceMessageTime: R<A.SaveVoiceMessageTime> = (
  state,
  { timestamp },
) => ({
  ...state,
  lastVoiceMessageTimestamp: timestamp,
});

const clearVoiceMessageTime: R<A.ClearVoiceMessageTime> = (state) => ({
  ...state,
  lastVoiceMessageTimestamp: undefined,
});

const setVoice: RSuccess<A.GetVoiceMessage> = (state, { result }) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      voiceMessages: [result],
    },
  };
};

const updateGlobalVoiceMessageStatus: R<A.UpdateGlobalVoiceMessageState> = (
  state,
  { status },
) => ({
  ...state,
  persist: {
    ...state.persist,
    globalVoiceMessageState: status,
  },
});

const updateLastPlayedVoiceMessage: R<A.UpdateLastPlayedVoiceMessage> = (
  state,
  { id },
) => ({
  ...state,
  persist: {
    ...state.persist,
    lastMessagePlayedId: id,
  },
});

const setAdvancedAiMessageCount: R<A.AdvancedAiMessagesReceived> = (
  state,
  { availableMessages },
) => {
  return {
    ...state,
    showBoostAdvancedAiWidget: state.persist.advancedAiUnlimited
      ? false
      : availableMessages > 0
        ? false
        : state.showBoostAdvancedAiWidget,
    persist: {
      ...state.persist,
      advancedAiMessageCount: state.persist.advancedAiUnlimited
        ? undefined
        : availableMessages,
    },
  };
};

const getAdvancedAiInfoSuccess: RSuccess<A.GetAdvancedAiInfo> = (
  state,
  { result },
) => {
  return {
    ...state,
    showBoostAdvancedAiWidget: result.is_unlimited
      ? false
      : result.available_messages > 0
        ? false
        : state.showBoostAdvancedAiWidget,
    persist: {
      ...state.persist,
      advancedAiUnlimited: result.is_unlimited,
      advancedAiMessageCount: result.is_unlimited
        ? undefined
        : result.available_messages,
    },
  };
};

const buyAdvancedAiMessagesSuccess: RSuccess<A.BuyAdvancedAiMessages> = (
  state,
  { result },
) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      advancedAiMessageCount: result.available_messages,
    },
  };
};

const setAdvancedAi: R<A.ToggleAdvancedAi> = (state, { status }) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      advancedAiStatus: status,
    },
  };
};

const setBoostAdvancedAiWidget: R<A.ToogleAdvancedAiBoostWidget> = (
  state,
  { show },
) => {
  return {
    ...state,
    showBoostAdvancedAiWidget: show,
  };
};

export default function history(
  state: ChatState = DEFAULT_STATE,
  action: A.AnyAction,
): ChatState {
  return applyReducer(
    'chat',
    {
      [AsyncActionTypes.WsHistory]: {
        success: getHistorySuccess,
      },
      [ActionTypes.SuggestionListReceived]: suggestionListReceived,
      [AsyncActionTypes.WsGetChatSuggestionList]: {
        success: getChatSuggestionListSuccess,
      },
      [AsyncActionTypes.WsStartChatSuggestion]: {
        request: startChatSuggestionRequest,
        success: startChatSuggestionSuccess,
        error: startChatSuggestionError,
      },
      [ActionTypes.WsChatMessageReceived]: chatMessageReceived,
      [ActionTypes.WsVoiceRecordReceived]: voiceRecordReceived,
      [ActionTypes.WsMessageReactionReceived]: messageReactionReceived,
      [ActionTypes.WsMessageEmotionReceived]: messageEmotionReceived,
      [AsyncActionTypes.GetMediaPreview]: {
        success: getMediaPreviewSuccess,
      },
      [AsyncActionTypes.WsSetMessageReaction]: {
        success: setMessageReactionSuccess,
      },
      [ActionTypes.WsStartTypingReceived]: startTyping,
      [ActionTypes.WsStopTypingReceived]: stopTyping,
      [AsyncActionTypes.WsSendMessage]: {
        success: sendMessageSuccess,
      },
      [ActionTypes.BotStatsReceived]: setMessageScoreAlert,
      [AsyncActionTypes.Logout]: {
        success: resetChat,
        error: resetChat,
      },
      [AsyncActionTypes.DeleteAccount]: {
        success: resetChat,
      },
      [ActionTypes.InitPersist]: initMessages,
      [ActionTypes.WsVoiceModeReceived]: voiceModeChanged,
      [ActionTypes.UpdateChatMessageText]: updateChatMessageText,
      [ActionTypes.SkipWidget]: handleSkipWidget,
      [ActionTypes.UploadChatImagePreview]: setImagePreview,
      [ActionTypes.ClearChatMessageAlert]: clearChatMessageAlert,
      [ActionTypes.WsStatementRemembered]: setMessageRemembered,
      [ActionTypes.ChatInitialized]: setFirstInit,
      [AsyncActionTypes.WsChangeVoiceMode]: {
        success: changeVoiceMode,
      },
      [ActionTypes.UpdateVoiceCallAudioQueue]: updateVoiceCallAudioQueue,
      [ActionTypes.SaveVoiceCallStartTime]: saveVoiceCallStartTime,
      [ActionTypes.SaveVoiceMessageTime]: saveVoiceMessageTime,
      [ActionTypes.ClearVoiceMessageTime]: clearVoiceMessageTime,
      [AsyncActionTypes.GetVoiceMessage]: {
        success: setVoice,
      },
      [ActionTypes.UpdateGlobalVoiceMessageState]:
        updateGlobalVoiceMessageStatus,
      [ActionTypes.UpdateLastPlayedVoiceMessage]: updateLastPlayedVoiceMessage,
      [ActionTypes.WsAdvancedAiMessagesReceived]: setAdvancedAiMessageCount,
      [AsyncActionTypes.GetAdvancedAiInfo]: {
        success: getAdvancedAiInfoSuccess,
      },
      [AsyncActionTypes.BuyAdvancedAiMessages]: {
        success: buyAdvancedAiMessagesSuccess,
      },
      [ActionTypes.ToggleAdvancedAi]: setAdvancedAi,
      [ActionTypes.ToogleAdvancedAiBoostWidget]: setBoostAdvancedAiWidget,
      [AsyncActionTypes.DeleteMemory]: {
        success: removeRememberedMessage,
      },
    },
    state,
    action,
  );
}
