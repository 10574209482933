import loadable from '@loadable/component';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import {
  getUnityBinaries,
  getUnityWebEngine,
  setAvatarStatus,
} from '../../actions/avatars';
import { getMedia } from '../../actions/media';
import { setAvatarMode } from '../../actions/profile';
import { useMobileQuery } from '../../components/responsive';
import { usePromptEnvironmentMusic } from '../../features/AskReplika/utils';
import { AvatarMode } from '../../types/states';
import createLogger from '../../utils/createLogger';
import fpsCounter from '../../utils/fpsCounter';
import getUnityBundleVersion from '../../utils/getUnityBundleVersion';
import useApi from '../../utils/useApi';
import { useWindowIsFocused } from '../../utils/windowHooks';
import { AvatarPhotoStudio } from '../AvatarUnity/AvatarPhotoStudio/AvatarPhotoStudio';
import { DEBUG_LEVEL } from '../AvatarUnity/unityUtils';
import { useUnity3dDisabled } from '../AvatarUnity/useUnity3dDisabled';
import useUnityCameraShift from '../AvatarUnity/useUnityCameraShift';
import {
  aggregatedAvatarAtom,
  audioVolumeAtom,
  autoModeAtom,
  avatarBaseBundleSetAtom,
  avatarHiddenAtom,
  avatarPreviewEnabledAtom,
  avatarProgressAtom,
  avatarStateAtom,
  backgroundAtom,
  behaviourAtom,
  behaviourStateAtom,
  cameraSlotAtom,
  dayTimeAtom,
  radioEnabledAtom,
  showLoaderAtom,
  themeNameAtom,
  unityLoadAttemptAtom,
  unityPlaceAtom,
} from '../atoms';
import useInAuth from '../useInAuth';
import Radio from './Radio';

const logger = createLogger('GlobalUnity', {
  isEnabled: () => DEBUG_LEVEL >= 1,
});

const AvatarUnity = loadable(
  () => import(/* webpackChunkName: "AvatarUnity" */ '../AvatarUnity'),
);

const TRACKED_FPS: Partial<Record<AvatarMode, number>> = {};

function decrementMode(mode: AvatarMode, fps: number) {
  // adjust tracked fps, in case there were hiccups of some sorts
  TRACKED_FPS[mode] = TRACKED_FPS[mode] ? (TRACKED_FPS[mode]! + fps) / 2 : fps;

  switch (mode) {
    case 'static':
      return 'static';
    case 'animatedLow':
      logger.debug('switch to static');
      return 'static';
    case 'animatedMedium':
      logger.debug('switch to animatedLow');
      return 'animatedLow';
    default:
      logger.debug('switch to animatedMedium');
      return 'animatedMedium';
  }
}

function incrementMode(mode: AvatarMode, fps: number) {
  // adjust tracked fps, in case there were hiccups of some sorts
  TRACKED_FPS[mode] = TRACKED_FPS[mode] ? (TRACKED_FPS[mode]! + fps) / 2 : fps;

  let newMode: AvatarMode;

  switch (mode) {
    case 'static':
      newMode = 'animatedLow';
      break;

    case 'animatedLow':
      newMode = 'animatedMedium';
      break;

    default:
      newMode = 'animatedHigh';
      break;
  }

  const trackedFps = TRACKED_FPS[newMode];

  // if new fps is known to be low, ignore incrementing
  if (trackedFps && trackedFps < 30) return mode;

  logger.debug('switch to', newMode);

  return newMode;
}

export default function GlobalUnity() {
  const dispatch = useDispatch();
  const bot = useSelector((state) => state.profile.persist.bot);

  const avatarMode = useSelector((state) => state.profile.persist.avatarMode);
  const avatarStatus = useSelector((state) => state.avatars.avatarStatus);

  const [attempt, setAttempt] = useAtom(unityLoadAttemptAtom);

  const avatarPreviewEnabled = useAtomValue(avatarPreviewEnabledAtom);
  const petBehaviour = avatarPreviewEnabled ? 'preview' : 'main';

  useEffect(() => {
    if (attempt >= 3) {
      dispatch(setAvatarMode('no3d'));
      setAttempt(0);
    } else {
      setAttempt((a) => a + 1);
    }

    let unloaded = false;

    const handleUnload = () => {
      if (unloaded) return;
      setAttempt((a) => a - 1);
      unloaded = true;
    };

    window.addEventListener('beforeunload', handleUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
      window.removeEventListener('unload', handleUnload);
    };
    // eslint-disable-next-line local-rules/exhaustive-deps
  }, []);

  // FIXME: remove avatarV2 dependency
  const avatarV2 = bot?.avatar_v2;

  const roomItems = bot?.room_items;
  const pets = bot?.pets;

  const disabled3d = useUnity3dDisabled();

  const avatar = useAtomValue(aggregatedAvatarAtom);

  const avatarOrDefault = useMemo(() => {
    if (!avatarV2) return avatar;
    return {
      type: avatar.type ?? avatarV2.avatar_type,
      age: avatar.age ?? avatarV2.age,
      variations: avatar.variations ?? avatarV2.active_variations,
      bodyType: avatar.bodyType ?? avatarV2.body_type ?? null,
      gender: avatar.gender ?? avatarV2.gender ?? null,
      roomItems: avatar.roomItems ?? roomItems ?? null,
      pets: avatar.pets ?? pets ?? null,
    };
  }, [avatar, avatarV2, roomItems, pets]);

  const showLoader = useAtomValue(showLoaderAtom);
  const background = useAtomValue(backgroundAtom);
  const baseBundleSet = useAtomValue(avatarBaseBundleSetAtom);
  const cameraSlot = useAtomValue(cameraSlotAtom);
  const avatarState = useAtomValue(avatarStateAtom);
  const dayTime = useAtomValue(dayTimeAtom);
  const avatarHidden = useAtomValue(avatarHiddenAtom);
  const behaviour = useAtomValue(behaviourAtom);
  const behaviourState = useAtomValue(behaviourStateAtom);
  const unityPlace = useAtomValue(unityPlaceAtom);
  const themeName = useAtomValue(themeNameAtom);
  const [radioEnabled, setRadioEnabled] = useAtom(radioEnabledAtom);
  const audioVolume = useAtomValue(audioVolumeAtom);

  const windowFocused = useWindowIsFocused();

  const music = useApi((state) => state.media.persist.music, getMedia);

  const promptEnvironmentMusic = usePromptEnvironmentMusic();

  const radioMusicList = promptEnvironmentMusic || music;

  const disabled = !avatarOrDefault.type || disabled3d || avatarState !== '3d';
  const ready =
    avatarStatus === 'ready' ||
    avatarStatus === 'updating' ||
    avatarStatus === 'hidden-update';
  const fpsActive =
    !disabled &&
    !avatarHidden &&
    ready &&
    avatarMode === 'auto' &&
    windowFocused;

  const [autoMode, setAutoMode] = useAtom(autoModeAtom);

  useEffect(() => {
    setAutoMode(avatarMode === 'auto' ? 'animatedMedium' : avatarMode);
  }, [avatarMode]);

  useEffect(() => {
    if (!fpsActive) return;
    return fpsCounter((fps) => {
      logger.debug({ fps });

      if (fps < 20) {
        setAutoMode((m) => {
          let mode =
            m === 'animatedLow' || m === 'static'
              ? ('static' as const)
              : ('animatedLow' as const);
          logger.debug('switch to', mode);
          return mode;
        });
      } else if (fps < 30) {
        setAutoMode((m) => decrementMode(m, fps));
      } else if (fps > 58) {
        setAutoMode((m) => incrementMode(m, fps));
      }
    });
  }, [fpsActive]);

  const handleError = useCallback(
    () => dispatch(setAvatarMode('disabledOnError')),
    [dispatch],
  );

  const setProgress = useSetAtom(avatarProgressAtom);

  const getUnityWebEngineVersion = useCallback(
    () => getUnityWebEngine(getUnityBundleVersion()),
    [],
  );

  const unityBinaries = useApi(
    (state) => state.avatars.persist.unityBinaries,
    getUnityBinaries,
    { memoDeepEqual: true },
  );

  const unityWebEngine = useApi(
    (state) => state.avatars.persist.unityWebEngine,
    getUnityWebEngineVersion,
    { memoDeepEqual: true },
  );

  const voiceCallInProgress = useSelector(
    (state) => state.chat.voiceCallInProgress,
  );

  const inAuth = useInAuth();

  const cameraShift = useUnityCameraShift(cameraSlot);

  const isMobile = useMobileQuery();

  const greetingCameraSlot = isMobile ? 'room' : 'desktop_room';

  let avatarModeToUse: AvatarMode;

  if (avatarMode === 'auto') {
    if (autoMode === 'static') {
      avatarModeToUse = 'animatedLow';
    } else {
      avatarModeToUse = autoMode;
    }
  } else {
    avatarModeToUse = avatarMode;
  }

  if (disabled) return null;

  return (
    <>
      <Radio
        enabled={ready && radioEnabled && !inAuth && !voiceCallInProgress}
        music={radioMusicList}
        volume={audioVolume / 100}
      />
      <StyledAvatarUnity
        avatarStatus={avatarStatus}
        voiceCallInProgress={voiceCallInProgress}
        avatar={avatarOrDefault}
        unityBinaries={unityBinaries}
        unityWebEngine={unityWebEngine}
        showLoader={showLoader}
        background={background}
        baseBundleSet={baseBundleSet}
        attempt={attempt}
        cameraSlot={cameraSlot}
        dayTime={dayTime}
        avatarMode={avatarModeToUse}
        onError={handleError}
        behaviour={behaviour}
        behaviourState={behaviourState}
        unityPlace={unityPlace}
        themeName={themeName}
        radioEnabled={radioEnabled}
        onProgress={(progress) => {
          setProgress(progress);
          if (progress === 1) {
            setAttempt(0);
          }
        }}
        onClick={(objType) => {
          if (objType === 'radio') {
            setRadioEnabled(!radioEnabled);
          }
        }}
        onAvatarStatusChange={(status) => {
          dispatch(setAvatarStatus(status));
        }}
        cameraShift={cameraShift}
        petBehaviour={petBehaviour}
        greetingCameraSlot={greetingCameraSlot}
      />
      <AvatarPhotoStudio avatar={avatarOrDefault} />
    </>
  );
}

const StyledAvatarUnity = styled(AvatarUnity)`
  max-width: 1px;
  max-height: 1px;
  position: fixed;
  left: -10px;
  top: -10px;
  visibility: hidden;
`;
