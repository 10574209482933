const DEFAULT_LOCALE = 'en';

// We are using this format instead of 'PPP' from date-fns.
// 'PPP' formats to an non-standard format like 'March 1st, 2020'
// dateStyle: 'long' formats to 'March 1, 2020', without the ordinal suffix.

const LONG_DATE_FORMAT = new Intl.DateTimeFormat(DEFAULT_LOCALE, {
  dateStyle: 'long',
});

const SHORT_DATE_FORMAT = new Intl.DateTimeFormat(DEFAULT_LOCALE, {
  day: 'numeric',
  month: 'short',
});

const SHORT_MONTH_FORMAT = new Intl.DateTimeFormat(DEFAULT_LOCALE, {
  month: 'short',
  year: '2-digit',
});

// November 23, 2023
export function formatLongDate(date: Date) {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return null;
  }
  return LONG_DATE_FORMAT.format(date);
}

// Nov 23
export function formatShortDate(date: Date) {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return null;
  }
  return SHORT_DATE_FORMAT.format(date);
}

export function formatShortMonthDate(date: Date) {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return null;
  }
  return SHORT_MONTH_FORMAT.format(date);
}
