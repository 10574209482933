import md5 from 'md5';
import styled from 'styled-components/macro';

type Props = {
  src?: string;
  alt?: string;
  'data-adjustbrightness'?: boolean;
} & React.ComponentPropsWithoutRef<'div'>;
const BLUR_IMG_COUNT = 6;

export default function RandomBlurredImage(props: Props) {
  const {
    src = '',
    alt,
    'data-adjustbrightness': adjustBrightness,
    ...rest
  } = props;

  const pseudorandom =
    ((parseInt(md5(src), 16) % BLUR_IMG_COUNT) / (BLUR_IMG_COUNT - 1)) * 100;

  return (
    <RandomBlurredImageRoot {...rest}>
      <div>
        <img
          src="/images/random-blur.jpg"
          alt={alt}
          data-adjustbrightness={adjustBrightness}
          style={{ objectPosition: `${pseudorandom}% 50%` }}
        />
      </div>
    </RandomBlurredImageRoot>
  );
}

const RandomBlurredImageRoot = styled.div`
  position: relative;

  & > div {
    position: absolute;
    inset: -10px;
    filter: blur(10px);
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
