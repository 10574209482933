import { replace } from 'connected-react-router';

import { MutableRefObject } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

export const useScrollToStoreItem = (
  ref: MutableRefObject<HTMLElement | null>,
  itemId?: string,
  onSelect?: (variationId?: string) => void,
) => {
  const search = new URLSearchParams(useLocation().search);
  const searchItemId = search.get('item');
  const variationId = search.get('variation');
  const dispatch = useDispatch();
  const hasItem = !!itemId && itemId === searchItemId;
  if (hasItem && ref.current && onSelect) {
    ref.current.scrollIntoView();
    search.delete('item');
    dispatch(replace({ search: search.toString() }));
    onSelect(variationId || undefined);
  }
};
