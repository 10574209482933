import { useState } from 'react';
import ModalRouteMatch, {
  ModalRoute,
} from 'src/routes/modals/ModalRoutes/ModalRouteMatch';
import { ModalLayout } from 'src/routes/modals/ModalScreenLayout';
import { ModalRoutes } from 'src/types/enums';
import useGoBack from 'src/utils/useGoBack';

import { useFormCloseConfirmation } from '../hooks';
import AddMemoryForm from './AddMemoryForm';

const MemoryRecordAddModal = () => {
  const [isFormDirty, setFormDirty] = useState(false);

  const { goBack } = useGoBack({
    closeOnEscape: true,
  });
  const handleFormClose = useFormCloseConfirmation(goBack);

  return (
    <>
      <ModalLayout.Popups />
      <ModalLayout.Root layout="right-sidebar">
        <ModalLayout.Header>
          <ModalLayout.HeaderLeft />
          <ModalRouteMatch>
            <ModalRoute exact path={ModalRoutes.MemoryRecordAdd}>
              <ModalLayout.HeaderTitle>Add</ModalLayout.HeaderTitle>
            </ModalRoute>
            <ModalRoute exact path={ModalRoutes.MemoryRecordAddImageUpload}>
              <ModalLayout.HeaderTitle>Edit photo</ModalLayout.HeaderTitle>
            </ModalRoute>
          </ModalRouteMatch>

          <ModalLayout.HeaderRight>
            <ModalLayout.SidebarCloseButton
              onClick={isFormDirty ? handleFormClose : goBack}
            />
          </ModalLayout.HeaderRight>
        </ModalLayout.Header>
        <ModalLayout.Content>
          <AddMemoryForm setFormDirty={setFormDirty} />
        </ModalLayout.Content>
      </ModalLayout.Root>
    </>
  );
};

export default MemoryRecordAddModal;
