import { useDispatch } from 'react-redux';
import { setActiveDialog } from '../../../../actions/ui';
import { Dialogs } from '../../../../types/enums';

export const useFormCloseConfirmation = (onConfirm: () => void) => {
  const dispatch = useDispatch();

  return () =>
    dispatch(
      setActiveDialog({
        type: Dialogs.Confirmation,
        content: {
          title: 'Close without saving?',
          description: 'If you close now, all unsaved changes will be lost.',
          secondaryText: 'Close',
          primaryText: 'Go back',
        },
        onSecondaryClick: onConfirm,
        onPrimaryClick: () => {},
      }),
    );
};
