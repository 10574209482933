import * as Sentry from '@sentry/browser';
import { ApiError } from './apiError';
import { RELEASE_NAME } from './constants';
import { getEnvironment } from './environment';

const SENTRY_DSN = 'https://d2482f4f79d54638bcc7beb8c5bc5534@sentry.io/1228111';

// reporting only 1% of any Unity errors
const UNITY_ERRORS_FACTOR = 0.01;

const NO_CONNECTION_ERROR_RE =
  /(Failed to fetch|The Internet connection appears to be offline|The network connection was lost)/;
const WS_ERROR_RE = /(Websocket error)/;

let noConErrorCounter = 0;
let noConErrorTime = 0;

let wsErrorCounter = 0;
let wsErrorTime = 0;

export default function initSentry() {
  if (getEnvironment() === 'localhost') {
    return;
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    release: RELEASE_NAME,
    environment: getEnvironment(),
    ignoreErrors: [
      /Recaptcha not loaded/i,
      /INTERNAL ASSERTION FAILED/i,
      /Load failed/i,
      /Failed to fetch/i,
      /NetworkError/i,
      /The node to be removed is not a child of this node/i,
      /Non-Error promise rejection captured with value: Timeout/i,
      /The database connection is closing/i,
      /Can't find variable: ResizeObserver/i,

      // avoiding spam from blocked users
      'Age gate locked',
      // FIXME: ignore spamming Unity error
      'function signature mismatch',
      'call_indirect to a null table entry',

      // @see https://gist.github.com/impressiver/5092952

      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
    denyUrls: [
      // @see https://gist.github.com/impressiver/5092952

      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    beforeSend(event, hint) {
      let eventToSend: null | Sentry.Event = event;
      const err = hint?.originalException;

      const msg = typeof err === 'string' ? err : err?.message;

      if (err instanceof Error && err.stack) {
        if (
          err.stack.indexOf('wasm-function') !== -1 ||
          err.stack.indexOf('js.gz') !== -1
        ) {
          if (Math.random() > UNITY_ERRORS_FACTOR) {
            eventToSend = null;
          }
        }
      }

      if (msg) {
        const isNoConnError = NO_CONNECTION_ERROR_RE.test(msg);
        const isWsError = WS_ERROR_RE.test(msg);

        if (isNoConnError) {
          if (noConErrorCounter === 0) {
            noConErrorTime = Date.now();
          }

          if (noConErrorCounter >= 10) {
            const time = (Date.now() - noConErrorTime) / 1000;

            noConErrorCounter = 0;
            noConErrorTime = Date.now();

            event.message = `Failed to fetch (10 events in ${time}s)`;
            event.fingerprint = ['failed-to-fetch-grouped'];
          } else {
            noConErrorCounter += 1;
            eventToSend = null;
          }
        } else if (isWsError) {
          if (wsErrorCounter === 0) {
            wsErrorTime = Date.now();
          }

          if (wsErrorCounter >= 10) {
            const time = (Date.now() - wsErrorTime) / 1000;

            wsErrorCounter = 0;
            wsErrorTime = Date.now();

            event.message = `Websocket error (10 events in ${time}s)`;
            event.fingerprint = ['ws-error-grouped'];
          } else {
            wsErrorCounter += 1;
            eventToSend = null;
          }
        }
      }

      return eventToSend;
    },
  });

  Sentry.configureScope((scope) => {
    scope.addEventProcessor(async (event, hint) => {
      // Don't report about API errors
      if (hint && hint.originalException instanceof ApiError) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('captured ApiError', hint.originalException);
        }
        return null;
      }

      return event;
    });
  });
}

export const setUser = (userData: {
  id: string;
  [key: string]: string | undefined;
}) => {
  if (getEnvironment() === 'localhost') {
    // eslint-disable-next-line no-console
    console.debug('[Sentry] set user', userData);
    return;
  }

  Sentry.configureScope((scope) => {
    scope.setUser(userData);
  });
};

export const captureError = (err: unknown, errorInfo?: object) => {
  if (getEnvironment() === 'localhost') {
    // eslint-disable-next-line no-console
    console.debug('[Sentry]', err, errorInfo);
    return;
  }

  Sentry.withScope((scope) => {
    if (errorInfo) {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
    }

    if (err instanceof Error) {
      Sentry.captureException(err);
    } else if (typeof err === 'string') {
      Sentry.captureMessage(err);
    }
  });
};
