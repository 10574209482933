// import guid from 'simple-guid';
import md5 from 'md5';
import { AgeGateStatus } from '../types/enums';
import { RootState } from '../types/states';

export type Options = {
  method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';
  credentials: 'include';
  headers: Record<string, string> | Headers;
  body?: string | FormData | Blob;
};

export type JSONValue =
  | null
  | string
  | number
  | boolean
  | { [key: string]: JSONValue | undefined }
  | JSONValue[];

export type JSONObject = { [key: string]: JSONValue | undefined };

const wrapHeaders = (headers: Record<string, string>) =>
  'Headers' in window ? new Headers(headers) : headers;

/**
 * returns fetch options for basic api request with json response
 */
export const baseFetchOptions = (
  method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH',
  body?: FormData | Blob | JSONObject,
  customHeaders: Record<string, string> = {},
): Options => {
  const defaultHeaders: Record<string, string> = {
    Accept: 'application/json',
  };

  if (method === 'GET') {
    return {
      method,
      credentials: 'include',
      headers: wrapHeaders({ ...defaultHeaders, ...customHeaders }),
    };
  } else if (body instanceof FormData || body instanceof Blob) {
    return {
      method,
      credentials: 'include',
      headers: wrapHeaders({ ...defaultHeaders, ...customHeaders }),
      body,
    };
  } else {
    return {
      method,
      credentials: 'include',
      headers: wrapHeaders({
        ...defaultHeaders,
        'Content-Type': 'application/json',
        ...customHeaders,
      }),
      body: JSON.stringify(body || {}),
    };
  }
};

/**
 * returns fetch options with authentification headers
 */
export function fetchOptionsWithAuth(
  authOptions: {
    deviceId?: string | null;
    authToken?: string | null;
    userId?: string | null;
    ageLock: boolean;
  },
  method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH',
  body?: FormData | Blob | JSONObject,
  customHeaders?: Record<string, string>,
  options: { bypassAgeLock?: boolean } = {},
): Options {
  const { deviceId, authToken, userId, ageLock } = authOptions;

  if (!deviceId) {
    throw new Error('No device id');
  }

  if (ageLock && !options.bypassAgeLock) {
    throw new Error('Age gate locked');
  }

  let headers: Record<string, string> = {
    'X-Device-Id': deviceId,
    'X-Device-Type': 'web',
    // TODO: only required for auth methods
    'X-Timestamp-Hash': md5('time_covfefe_prefix=2020_' + deviceId),
  };

  if (authToken) {
    headers['X-Auth-Token'] = authToken;
  }

  if (userId) {
    headers['X-User-Id'] = userId;
  }

  if (customHeaders) {
    headers = {
      ...headers,
      ...customHeaders,
    };
  }

  return baseFetchOptions(method, body, headers);
}

export default function fetchOptions(
  state: RootState,
  method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH',
  body?: FormData | Blob | JSONObject,
  customHeaders?: Record<string, string>,
  options: { bypassAgeLock?: boolean } = {},
) {
  const { deviceId, authToken, userId } = state.auth.persist;
  const userProfile = state.profile.persist.userProfile;

  const authOptions = {
    deviceId,
    authToken,
    userId,
    ageLock: userProfile?.birthday_status === AgeGateStatus.Locked,
  };

  return fetchOptionsWithAuth(
    authOptions,
    method,
    body,
    customHeaders,
    options,
  );
}
