import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setOnboardingStatus } from '../../actions/signup';
import { useResetAvatar } from '../../core/atoms';
import { MetricsEvents } from '../../types/enums';
import useLogEvent from '../../utils/useLogEvent';
import { useResetAuthAtoms } from './atoms';

export const usePrepareMainRouteAfterOnboarding = () => {
  const dispatch = useDispatch();
  const resetAuthAtoms = useResetAuthAtoms();
  const resetAvatar = useResetAvatar();
  const logEvent = useLogEvent();

  return useCallback(() => {
    dispatch(setOnboardingStatus('initial'));
    resetAuthAtoms();
    resetAvatar();
    logEvent(MetricsEvents.OnboardingFinished);
  }, [dispatch, logEvent, resetAuthAtoms, resetAvatar]);
};
