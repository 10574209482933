import { replace } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { updateUserProfile } from '../../actions/profile';
import { Routes } from '../../components/Buttons';
import AgeGateDialog from '../../components/Dialogs/AgeGateDialog';
import { InlineDialog } from '../../components/Dialogs/InlineDialog';
import RadioButtonSelector from '../../components/RadioButtonSelector';
import Scrollable from '../../components/Scrollable';
import useAvatarSettings from '../../core/useAvatarSettings';
import { AgeGateStatus, MetricsEvents } from '../../types/enums';
import { UserProfileAgeRange } from '../../types/models';
import {
  logEvent,
  setUserProperties,
  trackCampaignswellAgeEvent,
} from '../../utils/metrics';
import { HELP_REPLIKA_URL } from '../../utils/uri';
import useSubmitStatus from '../../utils/useSubmitStatus';
import {
  AuthForm,
  DesktopHeader,
  DesktopSubtitle,
  DesktopTitle,
} from './AuthLayout';
import { OnboardingSubmitButton } from './SignupYourNameAndPronouns';
import { ageRanges } from './consts';

export default function SignupYourAge() {
  const dispatch = useDispatch();
  const bdStatus = useSelector(
    (state) => state.profile.persist.userProfile?.birthday_status,
  );
  const ageRange = useSelector(
    (state) => state.profile.persist.userProfile?.age_range,
  );

  const [coppaBlocked, setCoppaBlocked] = useState(
    bdStatus === AgeGateStatus.Locked || bdStatus === AgeGateStatus.RequestAge,
  );

  const [age, setAge] = useState<UserProfileAgeRange | undefined>(ageRange);

  const submitStatus = useSubmitStatus();

  useEffect(() => {
    if (
      bdStatus &&
      bdStatus !== AgeGateStatus.Success &&
      submitStatus.status.type === 'error'
    ) {
      setCoppaBlocked(true);
    }
  }, [bdStatus, submitStatus]);

  useAvatarSettings({
    hidden: true,
    background: 'onboarding',
    cameraSlot: 'desktop_onboarding',
    baseBundleSet: 'onboarding',
  });

  useEffect(() => {
    logEvent(MetricsEvents.AgeRangeScreenOpened, {
      source: 'onboarding',
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!age) return;

    try {
      submitStatus.setStatus('submitting');

      const profile = await dispatch(
        updateUserProfile(
          {
            age_range: age,
          },
          false,
        ),
      );

      if (profile.birthday_status !== AgeGateStatus.Success) {
        logEvent(MetricsEvents.YoungsterError, {
          age: age,
          cause: 'onboarding',
        });
        submitStatus.setStatus('error');
      } else {
        logEvent(MetricsEvents.AgeRangeSet, {
          age: age,
        });

        dispatch(replace(Routes.SignupChooseAvatar));
        submitStatus.setStatus('success');
      }

      setUserProperties({
        age_range: age,
      });

      trackCampaignswellAgeEvent(age);
    } catch (e) {
      submitStatus.setStatus(
        'error',
        'Something happened. Please try again later',
      );
    }
  };

  const handleCancelDialog = () => {
    if (bdStatus === AgeGateStatus.Locked) {
      window.open(HELP_REPLIKA_URL, '_blank');
      return;
    }

    setCoppaBlocked(false);
  };

  return (
    <>
      <InlineDialog isActive={coppaBlocked}>
        <AgeGateDialog status={bdStatus} onPrimaryClick={handleCancelDialog} />
      </InlineDialog>
      <StyledAuthForm
        onSubmit={handleSubmit}
        // this is needed for popup visibility in Safari
        innerStyle={{ overflowY: 'visible' }}
      >
        <DesktopHeader>
          <DesktopTitle>How old are you?</DesktopTitle>
          <DesktopSubtitle>
            {
              'We need this information to make your experience\nmore relevant & safe.'
            }
          </DesktopSubtitle>
        </DesktopHeader>

        <AgeRangeScrollableWrapper>
          <AgeRangeSelector
            id="user-age"
            value={age}
            onChange={(value: string) => setAge(value as UserProfileAgeRange)}
            variants={ageRanges}
          />
        </AgeRangeScrollableWrapper>

        <OnboardingSubmitButton
          showSpinner={submitStatus.isSubmitting()}
          disabled={!age}
        >
          Continue
        </OnboardingSubmitButton>
      </StyledAuthForm>
    </>
  );
}

const StyledAuthForm = styled(AuthForm)`
  align-items: center;

  & input {
    text-align: center;
    font-family: ${(p) => p.theme.fonts.display};
  }
`;

const AgeRangeSelector = styled(RadioButtonSelector)`
  width: 100%;

  @media ${(p) => p.theme.breakpoints.tablet} {
    max-width: 350px;
  }
`;

const AgeRangeScrollableWrapper = styled(Scrollable)`
  flex: 1 1 0%;
  overflow-y: auto;

  margin: -15px;
  padding: 15px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    margin: -32px;
    padding: 32px;
  }
`;
