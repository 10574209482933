import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import {
  PaymentRequest,
  PaymentRequestOptions,
  Stripe,
} from '@stripe/stripe-js';
import * as React from 'react';
import styled from 'styled-components/macro';

type Props = {
  className?: string;
  stripe: Stripe;
  onError: (message: string | null) => void;
  onHeightChange?: (height: number) => void;
  paymentRequestOptions: PaymentRequestOptions | null;
  processPayment: (paymentId: string, stripe: Stripe) => Promise<string | null>;
};

function StripePaymentButton({
  className,
  stripe,
  onError,
  onHeightChange,
  paymentRequestOptions,
  processPayment,
}: Props) {
  const [paymentRequest, setPaymentRequest] =
    React.useState<PaymentRequest | null>(null);

  const paymentMethodHandlerRef = React.useRef<null | Function>(null);

  React.useEffect(() => {
    paymentMethodHandlerRef.current = async (ev) => {
      try {
        const invoiceId = await processPayment(ev.paymentMethod.id, stripe);
        if (invoiceId) {
          const message = 'Your card was declined.';
          onError(message);
        }

        if (invoiceId) {
          ev.complete('fail');
        } else {
          ev.complete('success');
        }
      } catch (e) {
        ev.complete('fail');
      }
    };
  }, [stripe, onError, processPayment]);

  React.useEffect(() => {
    if (!paymentRequestOptions) return;

    if (paymentRequest) {
      paymentRequest.update({
        currency: paymentRequestOptions.currency,
        total: paymentRequestOptions.total,
      });
    } else {
      const pr = stripe.paymentRequest({
        requestPayerPhone: false,
        requestShipping: false,
        requestPayerName: true,
        requestPayerEmail: true,
        ...paymentRequestOptions,
      });

      pr.canMakePayment().then((canMake) => {
        if (canMake) {
          setPaymentRequest(pr);
          onHeightChange?.(720);
          pr.on('paymentmethod', (ev) => {
            if (paymentMethodHandlerRef.current) {
              paymentMethodHandlerRef.current(ev);
            }
          });
        } else {
          onHeightChange?.(640);
          console.warn('Stripe payment request is not available');
        }
      });
    }
  }, [paymentRequest, stripe, onHeightChange, paymentRequestOptions]);

  if (!paymentRequest) return null;

  return (
    <>
      <PaymentRequestContainer>
        <PaymentRequestButtonElement
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                theme: 'dark',
              },
            },
          }}
        />
      </PaymentRequestContainer>
      <OrCard>or use a card</OrCard>
    </>
  );
}

export default StripePaymentButton;

const PaymentRequestContainer = styled.div`
  width: 100%;
  max-width: 250px;
`;

const OrCard = styled.p`
  margin: 10px 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  line-height: 18px;
`;
