import { useCallback, useMemo, useState } from 'react';

type SubmitStatus =
  | {
      type: 'idle';
    }
  | {
      type: 'submitting';
    }
  | {
      type: 'success';
    }
  | {
      type: 'error';
      error: string | null;
    };

export default function useSubmitStatus() {
  const [status, setSubmitStatus] = useState<SubmitStatus>({ type: 'idle' });

  const setStatus = useCallback(
    (statusType: SubmitStatus['type'], error?: string | Error | null) => {
      if (statusType === 'error') {
        setSubmitStatus({
          type: statusType,
          error: error instanceof Error ? error.message : error ?? '',
        });
      } else {
        setSubmitStatus((s) =>
          s.type === statusType ? s : { type: statusType },
        );
      }
    },
    [],
  );

  const getError = useCallback(() => {
    if (status.type === 'error') {
      return status.error;
    }
    return null;
  }, [status]);

  const isSubmitting = useCallback(() => {
    return status.type === 'submitting';
  }, [status.type]);

  return useMemo(
    () => ({ status, setStatus, getError, isSubmitting }),
    [status, setStatus, getError, isSubmitting],
  );
}
