import parseColor from 'parse-css-color';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components/macro';

// WebGL implementation was replaced with 2D for two reasons:
// - 2D canvas is cheaper to initialize
// - native CSS animation is not affected by Unity loading

type Props = {
  duration?: number;
  lineWidth?: number;
} & React.HTMLAttributes<HTMLDivElement>;

const DEFAULT_PERCENT_WIDTH = 0.08;

export default function CircleSpinner({
  duration = 1,
  lineWidth,
  style,
  ...rest
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [color, setColor] = useState({ values: [0, 0, 0], alpha: 0 });

  useEffect(() => {
    if (!ref.current) return;
    try {
      const color = getComputedStyle(ref.current).getPropertyValue('color');
      const parsedColor = parseColor(color);
      if (parsedColor) {
        setColor(parsedColor);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const renderCanvas = useCallback(() => {
    if (!canvasRef.current) return;
    if (color.alpha === 0) return;

    const canvas = canvasRef.current;
    canvas.width = canvas.clientWidth * window.devicePixelRatio;
    canvas.height = canvas.clientHeight * window.devicePixelRatio;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const w = canvas.width;
    const h = canvas.height;

    let lw = lineWidth ?? Math.round(Math.min(w, h) * DEFAULT_PERCENT_WIDTH);

    lw = Math.min(w / 2, h / 2, lw);

    if (w === 0 || h === 0) {
      requestAnimationFrame(renderCanvas);
      return;
    }

    let applyConicGradient = 'createConicGradient' in ctx;

    // As of 29.01.24, Firefox on Windows renders conic gradient with washed-out colors
    // @see https://stackoverflow.com/questions/77240806/washed-out-colors-with-canvas-createconicgradient-in-firefox
    if (/Firefox/.test(navigator.userAgent) && navigator.platform === 'Win32') {
      applyConicGradient = false;
    }

    if (applyConicGradient) {
      renderConicGradientSpinner(ctx, w, h, color.values, lw);
    } else {
      renderFallbackSpinner(ctx, w, h, color.values, lw);
    }

    ctx.fillStyle = `rgb(${color.values.join(' ')})`;
    ctx.beginPath();
    ctx.arc(
      w / 2,
      h / 2 - Math.min(w, h) / 2 + lw / 2,
      lw / 2,
      -Math.PI / 2,
      Math.PI / 2,
    );
    ctx.closePath();
    ctx.fill();
  }, [lineWidth, color.values, color.alpha]);

  useEffect(() => renderCanvas(), [renderCanvas]);

  useEffect(() => {
    if (canvasRef.current === null) return;

    if (window.ResizeObserver) {
      const el = canvasRef.current;
      const observer = new ResizeObserver(renderCanvas);
      observer.observe(el);

      return () => {
        observer.unobserve(el);
      };
    }

    return;
  });

  return (
    <div ref={ref} style={{ ...style, opacity: color.alpha }} {...rest}>
      <RotatingCanvas
        ref={canvasRef}
        style={{ animationDuration: duration + 's' }}
      />
    </div>
  );
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingCanvas = styled.canvas`
  width: 100%;
  height: 100%;
  animation: ${rotate} 1s linear infinite;
`;

function renderConicGradientSpinner(
  ctx: CanvasRenderingContext2D,
  w: number,
  h: number,
  colorValues: number[],
  lineWidth: number,
) {
  const gradient = ctx.createConicGradient(-Math.PI / 2, w / 2, h / 2);
  gradient.addColorStop(0, `rgb(${colorValues.join(' ')} / 0%)`);
  gradient.addColorStop(1, `rgb(${colorValues.join(' ')} / 100%)`);
  ctx.fillStyle = gradient;

  ctx.clearRect(0, 0, w, h);

  const outerRadius = Math.min(w, h) / 2;
  const innerRadius = outerRadius - lineWidth;

  ctx.beginPath();
  ctx.arc(w / 2, h / 2, outerRadius, 0, 2 * Math.PI);
  ctx.arc(w / 2, h / 2, innerRadius, 0, 2 * Math.PI, true);
  ctx.closePath();
  ctx.fill();
}

function renderFallbackSpinner(
  ctx: CanvasRenderingContext2D,
  w: number,
  h: number,
  colorValues: number[],
  lineWidth: number,
) {
  ctx.clearRect(0, 0, w, h);

  const segments = 200;
  const outerRadius = Math.min(w, h) / 2;
  const innerRadius = outerRadius - lineWidth;
  const startAngle = -Math.PI / 2;
  const deltaAngle = (2 * Math.PI) / segments + 0.001;

  for (let i = 0; i < segments; i++) {
    ctx.fillStyle = `rgb(${colorValues.join(' ')} / ${i / segments})`;

    const angle = startAngle + (i / segments) * Math.PI * 2;

    ctx.beginPath();
    ctx.arc(w / 2, h / 2, outerRadius, angle, angle + deltaAngle);
    ctx.arc(w / 2, h / 2, innerRadius, angle + deltaAngle, angle, true);
    ctx.closePath();
    ctx.fill();
  }
}
