import { Credit, StoreItem } from '../types/models';

export default function getCurrencyNeeded(
  price: StoreItem['price'],
  credit: Credit | null,
) {
  if (!credit) return null;

  if (price.currency === 'coin' && price.amount > credit.coins_count) {
    return {
      currency: 'coin',
      amount: price.amount - credit.coins_count,
    } as const;
  }

  if (price.currency === 'gem' && price.amount > credit.gems_count) {
    return {
      currency: 'gem',
      amount: price.amount - credit.gems_count,
    } as const;
  }

  return null;
}
