import { initializeApp } from 'firebase/app';
import type { AuthError } from 'firebase/auth';
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithPopup,
} from 'firebase/auth';
import { OauthSignInMethod } from '../types/models';
import { isEnvironment } from './environment';

const firebaseConfig = {
  apiKey: 'AIzaSyDZzLXFGXlmT4enkbeCq3JaY4hgN-Sqh0E',
  authDomain: 'replika-auth.firebaseapp.com',
  projectId: 'replika-auth',
  storageBucket: 'replika-auth.appspot.com',
  messagingSenderId: '881304740189',
  appId: isEnvironment('production')
    ? '1:881304740189:web:98e78641aa341cf16f88b4'
    : '1:881304740189:web:d057086ede322f296f88b4',
};

const app = initializeApp(firebaseConfig);
let auth = getAuth(app);

export const isAuthError = (err: AuthError | unknown): err is AuthError => {
  return typeof err === 'object' && err != null && 'code' in err;
};

export const signIn = async (oauthSignInMethod: OauthSignInMethod) => {
  const providers = {
    google: new GoogleAuthProvider(),
    apple: new OAuthProvider('apple.com'),
  };
  const provider = providers[oauthSignInMethod];

  const result = await signInWithPopup(auth, provider);
  return result.user.getIdToken();
};
