let webglVersion: number | null | undefined = undefined;

export default function getWebglVersion() {
  if (typeof webglVersion === 'undefined') {
    const canvas = document.createElement('canvas');
    if (canvas.getContext('webgl2')) {
      webglVersion = 2;
    } else if (canvas.getContext('webgl')) {
      webglVersion = 1;
    } else {
      webglVersion = null;
    }
  }

  return webglVersion;
}
