import * as RadixSwitch from '@radix-ui/react-switch';
import styled from 'styled-components/macro';
import outlineCss from './outlineCss';

export default function Switch(props: RadixSwitch.SwitchProps) {
  const { children, ...rest } = props;
  return <SwitchRoot {...rest}>{children ?? <StyledThumb />}</SwitchRoot>;
}

const SwitchRoot = styled(RadixSwitch.Root)`
  all: unset;
  height: 24px;
  width: 50px;
  position: relative;
  border-radius: 12px;
  cursor: pointer;

  &:focus-visible:focus {
    ${outlineCss({ offset: '2px' })}
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    opacity: 0.3;
    border-radius: 12px;
  }

  &[data-state='checked']:before {
    background: linear-gradient(90deg, #e4faa5 0%, #77dc7b 80%),
      linear-gradient(0deg, #c1ffc0, #c1ffc0), #ffffff;
    opacity: 1;
  }

  transition: background 0.15s ease-out;
`;

const StyledThumb = styled(RadixSwitch.Thumb)`
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  top: 1px;
  left: 1px;

  &[data-state='checked'] {
    left: 27px;
  }

  transition: left 0.15s ease-out;
`;

export { StyledThumb as Thumb };
