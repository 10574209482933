import { getDefaultStore, useAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import {
  IncomingWebsocketMessage,
  OutcomingWebsocketMessage,
} from '../../types/websocket';
import {
  addWsHandler,
  removeWsHandler,
  sendMessageWithAuth,
} from '../../utils/websocket';
import { apiCredsAtom, wsCacheAtom } from './atoms';

type OutToIn<Name extends OutcomingWebsocketMessage['event_name']> = Extract<
  IncomingWebsocketMessage,
  { event_name: Name }
>;

function isInMessage<Name extends OutcomingWebsocketMessage['event_name']>(
  message: IncomingWebsocketMessage,
  name: Name,
): message is OutToIn<Name> {
  return message.event_name === name;
}

export default function useWs<
  OWM extends OutcomingWebsocketMessage,
  Name extends OWM['event_name'],
>(
  name: Name,
  payload: OWM['payload'] = {},
  opts?: { listen?: boolean; invalidateCache?: boolean },
) {
  const { listen = true, invalidateCache = false } = opts ?? {};
  const payloadHash = JSON.stringify(payload);
  const key = `${name}:${payloadHash}`;

  const [response, setResponse] = useAtom(wsCacheAtom(key));

  const message = useMemo(() => {
    return { event_name: name, payload } as OutcomingWebsocketMessage;
    // eslint-disable-next-line local-rules/exhaustive-deps
  }, [name, payloadHash]);

  const hasResponse = response != null;

  useEffect(() => {
    if (hasResponse && !invalidateCache) {
      return;
    }

    const apiCreds = getDefaultStore().get(apiCredsAtom);

    sendMessageWithAuth(message, apiCreds).then((response) => {
      if (isInMessage(response, name)) {
        setResponse(response);
      }
    });
  }, [message, name, setResponse, hasResponse, invalidateCache]);

  useEffect(() => {
    if (!listen) {
      return;
    }

    const handler = (message: IncomingWebsocketMessage) => {
      if (isInMessage(message, name)) {
        setResponse(message);
      }
    };

    addWsHandler(handler);

    return () => {
      removeWsHandler(handler);
    };
  }, [name, message, listen]);

  if (response == null) return null;

  return response.payload as OutToIn<Name>['payload'];
}

// loosely synonimous to useSWRMutation
export function useWsMutation<
  OWM extends OutcomingWebsocketMessage,
  Name extends OWM['event_name'],
>(name: Name) {
  const trigger = useCallback(
    (payload: OWM['payload']) => {
      const apiCreds = getDefaultStore().get(apiCredsAtom);

      return sendMessageWithAuth(
        { event_name: name, payload } as OWM,
        apiCreds,
      );
    },
    [name],
  );

  return { trigger };
}
