import { CategoryTreeNode } from '../../types/models';

export default function findCategoryName(
  id: string,
  category: CategoryTreeNode | null,
): string | null {
  if (!category) return null;

  if (category.id === id) return category.name.toLowerCase();

  if (category.children) {
    for (let cat of category.children) {
      let found = findCategoryName(id, cat);

      if (found != null) return found;
    }
  }

  return null;
}
