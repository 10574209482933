import { useSelector } from 'react-redux';
import { GenderValues } from '../types/enums';
import { RelationshipStatusId } from '../types/models';

export default function useRelationshipStatusName() {
  const relationshipStatuses = useSelector(
    (state) => state.profile.persist.relationshipStatuses,
  );
  const botGender = useSelector((state) => state.profile.persist.bot?.gender);

  return (newStatus: RelationshipStatusId) => {
    const relationshipStatus = relationshipStatuses.find(
      (status) => status.id === newStatus,
    );

    if (!relationshipStatus) return newStatus;

    const relationshipStatuName = botGender
      ? relationshipStatus.name[botGender]
      : relationshipStatus.name[GenderValues.NonBinary];

    return relationshipStatuName.toLowerCase();
  };
}
