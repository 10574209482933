import * as React from 'react';
import styled from 'styled-components/macro';

import imageSourceSet from '../../utils/imageSourceSet';
import { MAIN_PAGE_URL_BASE } from '../../utils/uri';

export type ButtonVariant = 'medium' | 'small' | 'mobile';

type IconProps = React.ComponentPropsWithoutRef<'img'> & {
  variant?: ButtonVariant;
};

type IconWithAnimationProps = IconProps & {
  animateTimeout?: number;
};

const GEM_SOURCE_SET = imageSourceSet(`${MAIN_PAGE_URL_BASE}/gem_new.png`);
const COIN_SOURCE_SET = imageSourceSet(`${MAIN_PAGE_URL_BASE}/coin_new.png`);

export function GemIcon({ variant, ...rest }: IconProps) {
  return (
    <IconBase
      {...rest}
      $variant={variant}
      alt={rest.alt ?? 'gem'}
      {...GEM_SOURCE_SET}
    />
  );
}

export function GemIconWithAnimation({
  animateTimeout = 0,
  variant,
  ...rest
}: IconWithAnimationProps) {
  return (
    <AnimationWrapper>
      <IconBase
        {...rest}
        $variant={variant}
        alt={rest.alt ?? 'gem'}
        {...GEM_SOURCE_SET}
        $timeout={animateTimeout}
        style={{ zIndex: 1, position: 'relative' }}
      />
    </AnimationWrapper>
  );
}

export function BunchOfGemsIcon({ variant, ...props }: IconProps) {
  return (
    <IconBase
      {...props}
      $variant={variant}
      alt={props.alt ?? 'gems'}
      {...imageSourceSet(`${MAIN_PAGE_URL_BASE}/gems_new.png`)}
    />
  );
}

export function BunchOfCoinsIcon({ variant, ...props }: IconProps) {
  return (
    <IconBase
      {...props}
      $variant={variant}
      alt={props.alt ?? 'coins'}
      {...imageSourceSet(`${MAIN_PAGE_URL_BASE}/coins_10.png`)}
    />
  );
}

export function CoinIcon({ variant, ...rest }: IconProps) {
  return (
    <IconBase
      {...rest}
      $variant={variant}
      alt={rest.alt ?? 'coin'}
      {...COIN_SOURCE_SET}
    />
  );
}

export function CoinIconWithAnimation({
  animateTimeout = 0,
  variant,
  ...rest
}: IconWithAnimationProps) {
  return (
    <AnimationWrapper>
      <IconBase
        {...rest}
        $variant={variant}
        alt={rest.alt ?? 'coin'}
        {...COIN_SOURCE_SET}
        $timeout={animateTimeout}
        style={{ zIndex: 1 }}
      />
    </AnimationWrapper>
  );
}

const IconBase = styled.img<{
  $timeout?: number;
  $variant?: ButtonVariant;
}>`
  width: ${(p) => (p.$variant === 'medium' ? '24px' : '16px')};
  height: ${(p) => (p.$variant === 'medium' ? '24px' : '16px')};
  font-size: 0;
  position: relative;
`;

const AnimationWrapper = styled.div`
  position: relative;
`;
