import { ReactNode } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components/macro';
import { ReactComponent as BackIcon } from '../../icons/ArrowBack.svg';
import useGoBack, { AnchorType } from '../../utils/useGoBack';
import { Routes, SolidButton, SolidIconButton } from '../Buttons';
import { ContentMenuButton, MobileMenuButton } from '../MobileMenuButton';
import Scrollable from '../Scrollable';
import * as TranslucentBox from '../TranslucentBox';
import outlineCss from '../outlineCss';
import { Desktop, Mobile, useMobileQuery } from '../responsive';

// NB: "overflow: hidden;" can break backdrop-filter
const ContentPanelRoot = styled(TranslucentBox.DesktopPanel)`
  height: 100%;
  width: 100%;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 66%;
    flex: 0 1 66%;
    border-radius: 20px;
    min-width: 620px;
    overflow: hidden;
  }
`;

const ContentPanelInner = styled(TranslucentBox.Content)`
  display: flex;
  flex-direction: column;
`;

const ContentPanelHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  gap: 10px;
  padding: 15px 15px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding: 20px 20px;
    border-bottom: 0.5px solid rgb(255 255 255 / 0.1);
  }
`;

const ContentPanelHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
  gap: 10px;
  min-width: 0;

  margin-block: -5px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    margin-block: 0;
  }
`;

const ContentPanelHeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;
  gap: 10px;
  min-width: 0;
`;

const ContentPanelHeaderTitle = styled.h1`
  margin: 0;
  font-size: 20px;
  text-align: center;
  flex: 1 0 auto;

  @media ${(p) => p.theme.breakpoints.tablet} {
    font-size: 28px;
    text-align: left;

    [data-nestedheader='true'] & {
      font-size: 20px;
    }
  }
`;

const ContentPanelBody = styled.div`
  display: flex;
  flex: 1 1 auto;
  min-height: 0;
`;

const ContentPanelSidePane = styled(Scrollable)`
  flex: 1 0 35%;
  padding-inline: 10px;
  scrollbar-gutter: stable both-edges;
  overflow-y: auto;
  width: 100%;

  @media ${(p) => p.theme.breakpoints.tablet} {
    min-width: 300px;
    max-width: 340px;
    padding-inline: 15px 10px;
  }
`;

const ContentPanelMainPane = styled(Scrollable)`
  flex: 1 1 65%;
  padding-inline: 0;
  overflow-y: auto;
  scrollbar-gutter: stable both-edges;

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding-inline: 0 6px;
  }
`;

/**
 * Convenience component to reduce boilerplate when using ContentPanel.
 */
function ContentPanelWithBackground({ children, ...rest }) {
  return (
    <ContentPanelRoot {...rest}>
      <TranslucentBox.Background />
      <ContentPanelInner>{children}</ContentPanelInner>
    </ContentPanelRoot>
  );
}

const ContentPanelIconButton = styled(SolidIconButton)`
  background: rgb(0 0 0 / 10%);

  [data-translucentbox='true'] & {
    background: rgb(255 255 255 / 10%);
  }

  &[data-active='true'] {
    ${outlineCss()}
    outline-offset: 0px;
  }
`;

const ContentPanelHeaderButton = styled(ContentPanelIconButton)`
  width: 34px;
  height: 34px;
  border-radius: 14px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 34px;
    height: 34px;
  }
`;

/**
 * The default content panel header implementation.
 * If you need a custom header, you can use ContentPanel.Header and other components directly,
 * using this component as a reference.
 */
function ContentPanelHeaderWithControls({
  title,
  mobileTitleAlign,
  right,
  anchor = null,
  titleLabel: label,
  showBackButton = 'always',
  hideMenuButton,
  className,
  style,
}: {
  title?: ReactNode;
  mobileTitleAlign?: 'center' | 'left';
  right?: ReactNode;
  anchor?: AnchorType | null;
  titleLabel?: string;
  showBackButton?: 'always' | 'never' | 'hide-by-default';
  showCloseButton?: 'always' | 'never';
  hideMenuButton?: boolean;
  className?: string;
  style?: React.CSSProperties;
}) {
  const isMobile = useMobileQuery();
  const location = useLocation<{ showBackButton?: boolean }>();
  const { navLevelNoModal, goBack } = useGoBack({
    anchor,
    closeOnEscape: true,
  });

  let desktopLeft: ReactNode = null;
  let mobileLeft: ReactNode = null;
  let mobileCenter: ReactNode = null;

  if (!mobileTitleAlign) {
    mobileTitleAlign = !!right || typeof title !== 'string' ? 'left' : 'center';
  }

  const headerTitle =
    typeof title === 'string' ? (
      <ContentPanelHeaderTitle aria-label={label}>
        {title}
      </ContentPanelHeaderTitle>
    ) : (
      title
    );

  desktopLeft = headerTitle;
  if (mobileTitleAlign === 'center') {
    mobileCenter = headerTitle;
  } else {
    mobileLeft = headerTitle;
  }

  let backButtonVisible = navLevelNoModal > 1;

  if (showBackButton === 'never') {
    backButtonVisible = false;
  } else if (showBackButton === 'hide-by-default') {
    backButtonVisible = !!location.state?.showBackButton;
  }

  const isMobileMemoryOrDiary =
    isMobile &&
    (location.pathname.includes(Routes.Memory) ||
      location.pathname.includes(Routes.Diary));

  return (
    <ContentPanelHeader
      className={className}
      style={style}
      data-nestedheader={backButtonVisible}
    >
      <ContentPanelHeaderLeft>
        {backButtonVisible && (
          <ContentPanelHeaderButton onClick={goBack}>
            <BackIcon />
          </ContentPanelHeaderButton>
        )}
        <Desktop>{desktopLeft}</Desktop>
        <Mobile>{mobileLeft}</Mobile>
      </ContentPanelHeaderLeft>
      <Mobile>{mobileCenter}</Mobile>
      <Mobile>
        <ContentPanelHeaderRight>
          {right}
          {isMobileMemoryOrDiary ? (
            <ContentMenuButton />
          ) : !hideMenuButton ? (
            <MobileMenuButton />
          ) : null}
        </ContentPanelHeaderRight>
      </Mobile>
      {right && (
        <Desktop>
          <ContentPanelHeaderRight>{right}</ContentPanelHeaderRight>
        </Desktop>
      )}
    </ContentPanelHeader>
  );
}

const Background = TranslucentBox.Background;

const ControlButton = styled(SolidButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  padding: 0 15px;
  gap: 5px;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  backdrop-filter: none;
  white-space: nowrap;
`;

export {
  Background,
  ContentPanelBody as Body,
  ControlButton,
  ContentPanelHeader as Header,
  ContentPanelHeaderButton as HeaderButton,
  ContentPanelHeaderLeft as HeaderLeft,
  ContentPanelHeaderRight as HeaderRight,
  ContentPanelHeaderTitle as HeaderTitle,
  ContentPanelHeaderWithControls as HeaderWithControls,
  ContentPanelIconButton as IconButton,
  ContentPanelInner as Inner,
  ContentPanelMainPane as MainPane,
  ContentPanelRoot as Root,
  ContentPanelSidePane as SidePane,
  ContentPanelWithBackground as WithBackground,
};
