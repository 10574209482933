import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStoreItems } from '../../actions/store';
import { StoreItem } from '../../types/models';
import mergeBy from '../../utils/mergeBy';

export default function usePaginatedItems({
  categoryId,
  categoryKey,
  avatarId,
  perPage = 999,
  onlyFree = false,
}: {
  categoryId?: string;
  categoryKey?: string;
  avatarId?: string;
  perPage?: number;
  onlyFree?: boolean;
}) {
  const dispatch = useDispatch();

  const [offset, setOffset] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState<null | number>(null);
  const [loadStatus, setLoadStatus] = React.useState<
    'idle' | 'initialRequest' | 'requested' | 'loading'
  >('idle');

  React.useEffect(() => {
    setOffset(0);
    setTotalCount(null);
    setLoadStatus('initialRequest');
    setLoadedStoreItems([]);
  }, [categoryId]);

  // Note that persist doesn't neccessarily store all the items - only enough items to show on start
  let cachedStoreItems = useSelector(
    (state) => state.store.storeItems[categoryId ?? 'all'] ?? [],
  );

  let [loadedStoreItems, setLoadedStoreItems] = React.useState<StoreItem[]>([]);

  const storeItemsMap = useSelector((state) => state.store.storeItemsMap);

  React.useEffect(() => {
    setLoadedStoreItems((items) => {
      let newItems = [...items];

      for (let i = 0; i < items.length; i++) {
        if (storeItemsMap[items[i]!.id] !== items[i]) {
          newItems[i] = storeItemsMap[items[i]!.id]!;
        }
      }

      return newItems;
    });
  }, [storeItemsMap]);

  const storeItems = React.useMemo(
    () => mergeBy(cachedStoreItems, loadedStoreItems, 'id'),
    [cachedStoreItems, loadedStoreItems],
  );

  const hasMoreItems = totalCount === null || totalCount > storeItems.length;

  React.useEffect(() => {
    const isRequested =
      loadStatus === 'initialRequest' || loadStatus === 'requested';
    if (hasMoreItems && isRequested) {
      const newOffset = offset + perPage;
      setLoadStatus('loading');
      dispatch(
        getStoreItems({
          categoryId,
          categoryKey,
          avatarId,
          limit: perPage,
          offset,
          onlyFree,
        }),
      )
        .then((result) => {
          setLoadStatus('idle');
          setLoadedStoreItems((s) => mergeBy(s, result.items, 'id'));
          setTotalCount(result.total_count);
          if (result.total_count > newOffset) {
            setOffset((o) => o + perPage);
          }
        })
        .catch((e) => setLoadStatus('idle'));
    }
  }, [
    categoryId,
    avatarId,
    offset,
    storeItems.length,
    loadStatus,
    dispatch,
    perPage,
    hasMoreItems,
    categoryKey,
    onlyFree,
  ]);

  const requestMore = React.useCallback(() => {
    setLoadStatus((s) => (s === 'idle' ? 'requested' : s));
  }, []);

  const allItems = mergeBy(cachedStoreItems, storeItems, 'id');

  return [allItems, loadStatus, requestMore, totalCount] as const;
}
