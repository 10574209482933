import guid from 'simple-guid';

import * as A from '../types/actions';
import { ToError, ToRequest } from '../types/asyncActions';
import { ActionTypes, AsyncActionTypes, SidePanel } from '../types/enums';
import { Reducer } from '../types/redux';
import { UiState } from '../types/states';
import applyReducer from '../utils/applyReducer';
import { GDPR_VERSION } from '../utils/constants';

const DEFAULT_STATE: UiState = {
  connectedToInternet: true, // user managed to get main.js, so we assume he/she's online
  isActiveWindow: true,
  imageUpload: {
    inProgress: false,
    src: null,
    width: 0,
    height: 0,
    uploadError: undefined,
    orientation: -1,
  },
  dialogQueue: [],
  persist: {
    version: 2,
    themeNames: {},
    gdprVersion: undefined,
    hints: {
      messageReaction: undefined,
      replikaDay: undefined,
      backstory: undefined,
    },
    showRelationshipStatusButton: false,
  },
  notifications: [],
  systemNotifications: [],
  animation: undefined,
  sidePanel: {
    panel: SidePanel.Initial,
  },
};

type R<X extends A.UiAction | A.ChatAction | A.IncomingWsAction> = Reducer<
  UiState,
  X
>;
type RRequest<X> = Reducer<UiState, ToRequest<X>>;
type RError<X> = Reducer<UiState, ToError<X>>;

const goneOffline: R<A.GoneOffline> = (state) => ({
  ...state,
  connectedToInternet: false,
  imageUpload: {
    ...state.imageUpload,
    uploadError: undefined,
  },
});

const goneOnline: R<A.GoneOnline> = (state) => ({
  ...state,
  connectedToInternet: true,
});

const showUploadProgress: RRequest<A.UploadChatImage> = (state) => ({
  ...state,
  imageUpload: {
    ...state.imageUpload,
    inProgress: true,
    uploadError: undefined,
  },
});

const showUploadProgressPreview: R<A.UploadChatImagePreview> = (
  state,
  { width, height, src, orientation },
) => ({
  ...state,
  imageUpload: {
    ...state.imageUpload,
    inProgress: true,
    width,
    height,
    src,
    uploadError: undefined,
    orientation,
  },
});

const hideUploadProgress: R<A.ChatMessageReceived> = (state) => ({
  ...state,
  imageUpload: {
    ...state.imageUpload,
    inProgress: false,
    width: 0,
    height: 0,
    src: null,
    percent: 0,
    orientation: -1,
  },
});

const uploadError: RError<A.UploadChatImage> = (state, { error }) => ({
  ...state,
  imageUpload: {
    ...state.imageUpload,
    inProgress: false,
    width: 0,
    height: 0,
    percent: 0,
    uploadError: error.message,
    orientation: -1,
  },
});

const clearUploadError: RRequest<A.TextInputDetected> = (state) => ({
  ...state,
  imageUpload: {
    ...state.imageUpload,
    uploadError: undefined,
  },
});

const setActiveWindow: R<A.SetActiveWindow> = (state, { isActiveWindow }) => ({
  ...state,
  isActiveWindow,
});

const setTheme: R<A.SetTheme> = (state, { userId, themeName }) => ({
  ...state,
  persist: {
    ...state.persist,
    themeNames: {
      ...state.persist.themeNames,
      [userId]: themeName,
    },
  },
});

const setActiveDialog: R<A.SetActiveDialog> = (state, { options }) => ({
  ...state,
  dialogQueue: options
    ? [options, ...state.dialogQueue.slice(1)]
    : state.dialogQueue.slice(1),
});

const queueDialog: R<A.QueueDialog> = (state, { options }) => ({
  ...state,
  dialogQueue: [...state.dialogQueue, options],
});

const closeDialog: R<A.CloseDialog> = (state, { dialogType }) => {
  const index = state.dialogQueue.findIndex((d) => d.type === dialogType);
  if (index === -1) return state;
  let dialogQueue = state.dialogQueue.slice();
  dialogQueue.splice(index, 1);

  return {
    ...state,
    dialogQueue,
  };
};

const acceptGdpr: R<A.AcceptGdpr> = (state) => ({
  ...state,
  persist: {
    ...state.persist,
    gdprVersion: GDPR_VERSION,
  },
});

const setHintStatus: R<A.SetHintStatus> = (state, { hint, status }) => ({
  ...state,
  persist: {
    ...state.persist,
    hints: {
      ...state.persist.hints,
      [hint]: status,
    },
  },
});

const resetUi = (state) => ({
  ...DEFAULT_STATE,
  persist: state.persist,
});

const setShowRelationshipStatusButton: R<A.SetShowRelationshipStatusButton> = (
  state,
  { showRelationshipStatusButton },
) => ({
  ...state,
  persist: {
    ...state.persist,
    showRelationshipStatusButton,
  },
});

const queueNotifications: R<A.NotificationsReceived> = (
  state,
  { notifications },
) => ({
  ...state,
  notifications: [
    ...state.notifications,
    ...notifications.map((n) => ({
      id: guid(),
      notification: n,
    })),
  ],
});

const queueNotification: R<A.QueueNotification> = (
  state,
  { notification },
) => ({
  ...state,
  notifications: [
    ...state.notifications,
    {
      id: guid(),
      notification,
    },
  ],
});

const dismissNotification: R<A.DismissNotification> = (state, { id }) => ({
  ...state,
  notifications: state.notifications.filter((n) => n.id !== id),
});

const queueSystemNotification: R<A.QueueSystemNotification> = (
  state,
  { message, icon },
) => ({
  ...state,
  systemNotifications: [
    ...state.systemNotifications,
    { id: guid(), message, icon },
  ],
});

const dismissSystemNotification: R<A.DismissSystemNotification> = (
  state,
  { id },
) => ({
  ...state,
  systemNotifications: state.systemNotifications.filter((n) => n.id !== id),
});

const setActiveSidePanel: R<A.SetActiveSidePanel> = (
  state,
  { panel, cause },
) => ({
  ...state,
  sidePanel: {
    panel,
    cause,
  },
});

export default function ui(
  state: UiState = DEFAULT_STATE,
  action: A.AnyAction,
) {
  return applyReducer(
    'ui',
    {
      [ActionTypes.GoneOffline]: goneOffline,
      [ActionTypes.GoneOnline]: goneOnline,
      [AsyncActionTypes.UploadChatImage]: {
        request: showUploadProgress,
        error: uploadError,
      },
      [ActionTypes.UploadChatImagePreview]: showUploadProgressPreview,
      [ActionTypes.WsChatMessageReceived]: hideUploadProgress,
      [ActionTypes.SetActiveWindow]: setActiveWindow,
      [ActionTypes.SetTheme]: setTheme,
      [AsyncActionTypes.WsTextInputDetected]: {
        request: clearUploadError,
      },
      [ActionTypes.WsVoiceModeReceived]: clearUploadError,
      [ActionTypes.SetActiveDialog]: setActiveDialog,
      [ActionTypes.QueueDialog]: queueDialog,
      [ActionTypes.CloseDialog]: closeDialog,
      [ActionTypes.AcceptGdpr]: acceptGdpr,
      [ActionTypes.SetHintStatus]: setHintStatus,
      [AsyncActionTypes.Logout]: {
        success: resetUi,
        error: resetUi,
      },
      [AsyncActionTypes.DeleteAccount]: {
        success: resetUi,
      },
      [ActionTypes.SetShowRelationshipStatusButton]:
        setShowRelationshipStatusButton,
      [ActionTypes.BotStatsReceived]: queueNotifications,
      [ActionTypes.DismissNotification]: dismissNotification,
      [ActionTypes.WsNotificationsReceived]: queueNotifications,
      [ActionTypes.QueueNotification]: queueNotification,
      [ActionTypes.QueueSystemNotification]: queueSystemNotification,
      [ActionTypes.DismissSystemNotification]: dismissSystemNotification,
      [ActionTypes.SetActiveSidePanel]: setActiveSidePanel,
    },
    state,
    action,
  );
}
