import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export type PopupType = 'feedback' | 'notification' | 'systemNotification';

const pausedPopupsAtom = atom<false | number>(false);

export function useActivePopup(includePopups?: PopupType[]) {
  const [paused, setPaused] = useAtom(pausedPopupsAtom);
  useEffect(() => {
    if (!paused) return;
    const t = setTimeout(() => setPaused(false), paused);
    return () => clearTimeout(t);
  }, [paused]);

  const feedbackStatus = useSelector((state) => state.feedback.feedbackStatus);
  const feedbackView = useSelector(
    (state) => state.feedback.feedbackData?.feedbackView,
  );
  const notifications = useSelector((state) => state.ui.notifications);
  const systemNotifications = useSelector(
    (state) => state.ui.systemNotifications,
  );

  // just making sure we are not showing feedback too early
  const userMessageCount = useSelector((state) => {
    const messages = state.chat.messages || [];
    return messages.filter((m) => m.meta.nature === 'Customer').length;
  });

  let activePopup: null | PopupType = null;

  const canShowFeedback =
    feedbackStatus === 'active' &&
    feedbackView === 'Popup' &&
    userMessageCount > 3;

  if (!paused) {
    if (notifications.length > 0) {
      activePopup = 'notification';
    } else if (canShowFeedback) {
      activePopup = 'feedback';
    } else if (systemNotifications.length > 0) {
      activePopup = 'systemNotification';
    }
  }

  if (activePopup && includePopups && !includePopups.includes(activePopup)) {
    if (!isBorderlessNotification(notifications[0]?.notification.type)) {
      activePopup = null;
    }
  }

  const notificationType =
    activePopup === 'notification' ? notifications[0]?.notification.type : null;

  return {
    activePopup,
    notifications,
    systemNotifications,
    notificationType,
    pause: setPaused,
  };
}

export function isBorderlessNotification(
  notificationType: string | null | undefined,
) {
  return notificationType === 'level_up' || notificationType === 'quest';
}
