import { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { PhotoFrame } from '../types/models';
import { useApiImageLoader } from './ApiImageLoader';

type Props = {
  width: number;
  height: number;
  label: string;
  photoUrl: string;
  photoFrame?: PhotoFrame;
  className?: string;
};

const CroppedPhoto = ({
  width,
  height,
  label,
  photoUrl,
  photoFrame,
  className,
}: Props) => {
  const { localSignedUrl } = useApiImageLoader(photoUrl);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const image = new Image();
    const canvas = canvasRef.current;

    if (canvas && photoFrame) {
      image.src = localSignedUrl ?? photoUrl;
      const ctx = canvas.getContext('2d');

      image.addEventListener('load', () => {
        if (ctx) {
          ctx.drawImage(
            image,
            // crop coordinates: x, y
            image.width * photoFrame.upper_left_x,
            image.height * photoFrame.upper_left_y,
            // crop size: width, height
            image.width * photoFrame.width,
            image.height * photoFrame.height,
            // canvas coordinates: x, y
            0,
            0,
            // canvas size (affects scale): width, height
            width,
            height,
          );
        }
      });
    }
  }, [localSignedUrl, height, width, photoFrame, photoUrl]);

  return (
    <CroppedPhotoRoot
      className={className}
      style={{
        width,
        height,
      }}
    >
      {photoFrame ? (
        <CroppedPhotoCanvas
          ref={canvasRef}
          aria-label={label}
          width={width}
          height={height}
        />
      ) : (
        <CroppedPhotoImg
          width={width}
          height={height}
          src={localSignedUrl ?? photoUrl}
          alt={label}
        />
      )}
    </CroppedPhotoRoot>
  );
};

export default CroppedPhoto;

const CroppedPhotoRoot = styled.div`
  border-radius: 50%;
`;

const CroppedPhotoCanvas = styled.canvas`
  border-radius: 50%;
  background: rgb(255 255 255 / 0.1);
`;

const CroppedPhotoImg = styled.img`
  display: block;
  font-size: 0;
  border-radius: 50%;
  object-fit: cover;
  background: rgba(255, 255, 255, 0.1);
`;
