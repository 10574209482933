import { css } from 'styled-components/macro';

// Safari still does not support rounded outline
// @see https://bugs.webkit.org/show_bug.cgi?id=20807
// @see https://browserstrangeness.bitbucket.io/css_hacks.html#safari
const safariOnly = (strings, ...interpolations) => css`
  @supports (-webkit-hyphens: none) {
    ${css(strings, ...interpolations)}
  }
`;

export default function outlineCss({
  rgb = '255 255 255',
  outlineOpacity = '90%',
  offset = '-2px',
  size = '2px',
}: {
  rgb?: string;
  outlineOpacity?: string;
  offset?: string;
  shadowSize?: 'medium' | 'small';
  size?: string;
} = {}) {
  return css`
    outline: ${size} solid rgba(${rgb} / ${outlineOpacity});
    outline-offset: ${offset};
    ${safariOnly`
      outline: none;
      box-shadow: 0 0 0 ${size} rgba(${rgb} / ${outlineOpacity}) inset;
    `}
  `;
}

export function outlineCardCss({
  rgb = '255 255 255',
  outlineOpacity = '50%',
  offset = '-2px',
  size = '2px',
}: {
  rgb?: string;
  outlineOpacity?: string;
  shadow?: string;
  offset?: string;
  size?: string;
} = {}) {
  return css`
    outline: ${size} solid rgba(${rgb} / ${outlineOpacity});
    outline-offset: ${offset};

    ${safariOnly`
      outline: none;
      box-shadow: 0 0 0 ${size} rgba(${rgb} / ${outlineOpacity}) inset;
    `}
  `;
}

export function errorOutlineCss() {
  return outlineCss({ rgb: '254 158 152' });
}
