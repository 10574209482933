import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/macro';

import ShowcaseDialogLayout from '../../../components/DialogLayout/ShowcaseDialogLayout';
import { useDesktopQuery } from '../../../components/responsive';
import Video from '../../../components/Video';

type Props = {
  children: React.ReactNode;
  cta?: string | React.ReactNode;
  backgroundUrl?: string;
  bgColor: string;
  bgGradient?: string;
  videoSrc?: string;
  className?: string;
  'data-testid': string;
};

const Layout = ({
  children,
  cta,
  backgroundUrl,
  bgColor,
  bgGradient,
  videoSrc,
  className,
  'data-testid': dataTestId,
}: Props) => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const prefersReducedMotion = useMediaQuery({
    query: '(prefers-reduced-motion: reduce)',
  });

  const isDesktop = useDesktopQuery();

  const playVideo = React.useCallback(
    (ref: React.RefObject<HTMLVideoElement>) => {
      if (!ref.current) return;

      if (!prefersReducedMotion) {
        ref.current.play().catch((e) => console.warn(e));
      } else {
        ref.current.pause();
      }
    },
    [prefersReducedMotion],
  );

  React.useEffect(() => {
    playVideo(videoRef);
  }, [playVideo]);

  return (
    <ShowcaseDialogLayout
      className={className}
      data-testid={dataTestId}
      dialogBgColor={bgColor}
      backgroundImageUrl={backgroundUrl}
      backgroundImageFormats={['webp', 'avif']}
      showcaseContent={
        <>
          <StyledVideo
            key={videoSrc}
            preload="true"
            playsInline
            muted
            loop
            ref={videoRef}
            tabIndex={-1}
            src={videoSrc}
          />
        </>
      }
      hideShowcase={!isDesktop}
    >
      {children}
    </ShowcaseDialogLayout>
  );
};

export default Layout;

const StyledVideo = styled(Video)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  z-index: 2;
`;
