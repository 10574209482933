import { logEvent } from '@amplitude/analytics-browser';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { updateUserProfile } from '../../../actions/profile';
import { AuthHeader, SubTitle, Title } from '../../../routes/Auth/AuthLayout';
import { OnboardingSubmitButton } from '../../../routes/Auth/SignupYourNameAndPronouns';
import { ageRanges } from '../../../routes/Auth/consts';
import { AgeGateStatus, MetricsEvents } from '../../../types/enums';
import { UserProfileAgeRange } from '../../../types/models';
import useSubmitStatus from '../../../utils/useSubmitStatus';
import RadioButtonSelector from '../../RadioButtonSelector';
import Scrollable from '../../Scrollable';

export const AgeGateRange = ({ onSubmit }: { onSubmit: () => void }) => {
  const dispatch = useDispatch();
  const [age, setAge] = useState<UserProfileAgeRange | undefined>();
  const submitStatus = useSubmitStatus();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!age) return;

    try {
      submitStatus.setStatus('submitting');

      const profile = await dispatch(
        updateUserProfile(
          {
            age_range: age,
          },
          false,
        ),
      );

      if (profile.birthday_status !== AgeGateStatus.Success) {
        logEvent(MetricsEvents.YoungsterError, {
          age: age,
          cause: 'chat',
        });
        submitStatus.setStatus('error');
      } else {
        logEvent(MetricsEvents.AgeRangeSet, {
          age: age,
        });
        submitStatus.setStatus('success');
      }

      onSubmit?.();
    } catch (e) {
      submitStatus.setStatus(
        'error',
        'Something happened. Please try again later',
      );
    }
  };

  return (
    <AgeGateRangeForm onSubmit={handleSubmit}>
      <AuthHeader>
        <Title>How old are you?</Title>
        <SubTitle>
          We need this info to make your experience more relevant & safe.
        </SubTitle>
      </AuthHeader>

      <AgeRangeScrollableWrapper>
        <AgeRangeSelector
          id="user-age"
          value={age}
          onChange={(value: string) => {
            setAge(value as UserProfileAgeRange);
          }}
          variants={ageRanges}
        />
      </AgeRangeScrollableWrapper>

      <OnboardingSubmitButton
        showSpinner={submitStatus.isSubmitting()}
        disabled={!age}
      >
        Continue
      </OnboardingSubmitButton>
    </AgeGateRangeForm>
  );
};

const AgeRangeSelector = styled(RadioButtonSelector)`
  width: 100%;
  max-width: 350px;
`;

const AgeGateRangeForm = styled.form`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 15px 30px 40px;
  position: relative;
  height: 100svh;
  width: 100%;

  @media ${(p) => p.theme.breakpoints.tablet} {
    margin: auto;
    padding: 0px;
    max-width: 350px;
    justify-content: center;
  }
`;

const AgeRangeScrollableWrapper = styled(Scrollable)`
  overflow-y: auto;
  margin: -30px;
  padding: 30px;
  align-self: stretch;
`;
