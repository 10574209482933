import * as React from 'react';
import styled from 'styled-components/macro';
import { SubscriptionListing } from '../../types/models';
import useFocusGroup from '../../utils/useFocusGroup';
import PriceCard from './PriceCard';

type Props = {
  className?: string;
  priceId: string | null;
  subscriptions: SubscriptionListing[];
  onChange: (id: string) => void;
};

function PriceList(props: Props) {
  const { priceId, subscriptions, onChange, className } = props;

  const ref = React.useRef<HTMLUListElement>(null);

  useFocusGroup(
    {
      getGroupElements: () => {
        return ref.current?.getElementsByTagName('button') ?? [];
      },
      options: {
        keybindings: {
          next: { key: 'ArrowRight' },
          prev: { key: 'ArrowLeft' },
        },
        wrap: true,
      },
    },
    [subscriptions],
  );

  const hideDetails = !subscriptions.find((s) => s.details_up);

  return (
    <PriceListRoot className={className} ref={ref} role="radiogroup">
      {subscriptions.map((subscription, idx) => {
        const selected = subscription.stripe_subscription_id === priceId;

        return (
          <PriceCard
            selected={selected}
            focusable={priceId ? selected : idx === 0}
            key={subscription.stripe_subscription_id}
            subscription={subscription}
            onClick={() => onChange(subscription.stripe_subscription_id)}
            hideDetails={hideDetails}
          />
        );
      })}
      {subscriptions.length === 0 ? (
        <>
          <EmptyStripeCard />
          <EmptyStripeCard />
          <EmptyStripeCard />
        </>
      ) : null}
    </PriceListRoot>
  );
}

export default PriceList;

const EmptyStripeCard = styled.li`
  flex: 0 0 auto;
  border: 1px solid transparent;
  background: ${(p) => p.theme.navigationBgColor};
  width: 116px;
  height: 130px;
  border-radius: 25px;
`;

const PriceListRoot = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;

  @media ${(p) => p.theme.breakpoints.tablet} {
    flex-direction: row;
  }
`;
