import { ErrorFields } from '../types/http';

export class ApiError extends Error {
  // eslint-disable-next-line no-restricted-globals
  name = 'ApiError';
  statusCode: number;
  stack: string | undefined;
  message: string;
  errorFields?: ErrorFields;
  code?: number;
  technicalMessage?: string;

  constructor(
    message: string,
    statusCode: number,
    url: string,
    errorFields?: ErrorFields,
    code?: number,
    technicalMessage?: string,
  ) {
    super(message);
    const extendedMessage =
      process.env.NODE_ENV === 'development' ? `${message} [${url}]` : message;
    this.statusCode = statusCode;
    const stack = new Error(extendedMessage).stack?.split('\n') ?? [];
    stack.splice(1, 1);
    this.stack = stack.join('\n');
    this.message = extendedMessage;
    this.errorFields = errorFields;
    this.code = code;
    this.technicalMessage = technicalMessage;
  }

  static EmailInvalid = 2;
  static ReplikaNameInvalid = 1;
  static PasswordTooShort = 3;
  static EmptyName = 4;
  static NoSubscription = 5;
  static Conflict = 6;
  static InappropriateBackstory = 9;
}
