import { matchPath } from 'react-router';
import { SidePanel } from 'src/types/enums';
import { TNavigationLink } from './NavigationLinks';

export const isCurrentRoute = (
  link: TNavigationLink,
  openedSidePanel: SidePanel,
  pathname: string,
): boolean => {
  const url = typeof link.route === 'string' ? link.route : link.route.pathname;
  const isModal = typeof link.route === 'string' ? false : !!link.route.search;

  if (isModal) {
    return false;
  }

  if ('sidePanel' in link) {
    return 'sidePanelActiveLinks' in link &&
      Array.isArray(link.sidePanelActiveLinks)
      ? link.sidePanelActiveLinks.includes(openedSidePanel)
      : link.sidePanel === openedSidePanel;
  }

  if (openedSidePanel === SidePanel.Initial) {
    return url && url !== '/' ? !!matchPath(pathname, url) : pathname === '/';
  }

  return false;
};
