import { WS_VERSION } from './constants';

declare global {
  interface Window {
    ENV?: Record<string, string>;
  }
}

export const REPLIKA_FEEDBACK_EMAIL = 'my@replika.com';

let CLIENT_API_BASE_URL = getEnv('CLIENT_API_BASE_URL', ''); // same origin by default

// hotfix 26.11.23: broken env vars for my.replika.ai
if (window.location.hostname === 'my.replika.ai') {
  CLIENT_API_BASE_URL = 'https://my.replika.ai';
}

export const LANDING_URL = getEnv('LANDING_URL', 'https://replika.com');

let CLIENT_API_WS_BASE_URL = getEnv(
  'CLIENT_API_WS_BASE_URL',
  'wss://ws.replika.com',
);

// hotfix 26.11.23: broken env vars for my.replika.ai
if (window.location.hostname === 'my.replika.ai') {
  CLIENT_API_WS_BASE_URL = 'wss://ws.replika.ai';
}

export const WS_URL = CLIENT_API_WS_BASE_URL + '/' + WS_VERSION;

export const HELP_REPLIKA_URL = 'https://help.replika.com';
export const CREDITS_REPLIKA_URL = LANDING_URL + '/legal/credits';
export const TERMS_REPLIKA_URL = LANDING_URL + '/legal/terms';
export const TERMS_SUBS_REPLIKA_URL =
  LANDING_URL + '/legal/terms#2.4.-subscriptions';
export const PRIVACY_REPLIKA_URL = LANDING_URL + '/legal/privacy';
export const GET_IOS_URL = LANDING_URL + '/get-ios';
export const GET_ANDROID_URL = LANDING_URL + '/get-android';

export const API_BASE_URL = CLIENT_API_BASE_URL + '/api/mobile/1.5';

// replika-web-app-images debug without CDN
// export const AWS_BUCKET_URL =
//   'https://s3-us-west-1.amazonaws.com/replika-web-app-images';
export const AWS_BUCKET_URL = 'https://1637693156.rsc.cdn77.org';
export const AWS_AVATARS_BUCKET_URL =
  'https://replika-default-avatars.s3-us-west-1.amazonaws.com/v1';
export const VIDEO_URL_BASE = AWS_BUCKET_URL + '/web/video';
export const MAIN_PAGE_URL_BASE = AWS_BUCKET_URL + '/web/main-page';

export const LANDING_BUCKET_URL = 'https://1716637182.rsc.cdn77.org';

export const DEFAULT_RADIO_TRACK_URL =
  'https://s3-us-west-1.amazonaws.com/replika-content-images/music/7665b3fc0ba739a906ac47f743890606.mp3';

export const DEFAULT_CATEGORY_ICON_URL = `${MAIN_PAGE_URL_BASE}/default_category_icon.png`;

function getEnv(key: string, defaultValue: string) {
  return (process.env['REACT_APP_' + key] ?? window.ENV?.[key]) || defaultValue;
}

export const REDDIT_COMMUNITY_URL = 'https://www.reddit.com/r/ReplikaOfficial';
export const FACEBOOK_COMMUNITY_URL =
  'https://www.facebook.com/groups/replikabeta';
export const DISCORD_COMMUNITY_URL = 'https://discord.gg/MzV2Jr9uhD';
