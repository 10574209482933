import { CategoryTreeNode } from '../../types/models';

export default function findParentCategory(
  id: string,
  category: CategoryTreeNode | null,
  level = 0,
  parentCategory: CategoryTreeNode | null = null,
): CategoryTreeNode | null {
  if (!category) return null;

  if (category.id === id) return parentCategory ?? category;

  if (category.children) {
    for (let cat of category.children) {
      let found = findParentCategory(
        id,
        cat,
        level - 1,
        parentCategory ?? (level <= 0 ? cat : null),
      );

      if (found != null) return found;
    }
  }

  return null;
}
