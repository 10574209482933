import { useSelector } from 'react-redux';
import useWs from '../../core/api/useWs';
import { WsEventNames } from '../../types/enums';

export function useDiaryUnreadUpdate() {
  return useWs(WsEventNames.DiaryUnreadUpdate);
}

export function useUnreadCounters() {
  const unreadDiaries = useDiaryUnreadUpdate();

  const newMemoriesCount = useSelector(
    (state) => state.memory.persist.newMemories.newMemoriesCount,
  );

  return {
    diary: unreadDiaries?.unread_diaries ?? 0,
    memory: newMemoriesCount,
  };
}
