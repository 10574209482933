import { useDispatch } from 'react-redux';
import { dropMission } from '../../../actions/coaching';
import {
  ConfirmDialogBody,
  Dialog,
} from '../../DialogLayout/legacy/DialogLayout';

type Props = {
  onClose: () => void;
};

const DropMissionDialog = (props: Props) => {
  const dispatch = useDispatch();
  const { onClose } = props;

  return (
    <Dialog data-testid="drop-mission-dialog">
      <ConfirmDialogBody
        title="Mission in progress"
        description="You have a mission in progress. Do you want to just chat with Replika instead?"
        confirmText="Chat"
        onConfirm={() => {
          onClose();
          dispatch(dropMission());
        }}
        onCancel={onClose}
      />
    </Dialog>
  );
};

export default DropMissionDialog;
