// based on
// https://github.com/jotaijs/jotai-devtools/blob/main/src/utils/useAtomsDebugValue.ts

import { useStore } from 'jotai/react';
import type { Atom } from 'jotai/vanilla';
import { isEqual, mapValues } from 'lodash';
import { useEffect, useRef } from 'react';
import createLogger from './createLogger';

const logger = createLogger('Atoms');
const changeLogger = createLogger('Atoms', {
  format: (tag, styles, [key, ...args]) => [
    `%c[${tag}] %catom changed: ${key}`,
    ...styles,
    ...args,
  ],
  styles: [
    'color: #4CAF50; font-weight: bold;',
    'color: inherit; font-weight: bold;',
  ],
});

type Store = ReturnType<typeof useStore>;
type AtomState = NonNullable<
  ReturnType<NonNullable<Store['dev_get_atom_state']>>
>;

function atomToPrintable(atom: Atom<unknown>) {
  return atom.debugLabel || atom.toString();
}

function stateToPrintable(store: Store, atoms: Atom<unknown>[]) {
  let state: Record<
    string,
    { error?: unknown; value?: unknown; dependents: string[] }
  > = {};

  for (let atom of atoms) {
    const mounted = store.dev_get_mounted?.(atom);
    if (!mounted) continue;
    const dependents = mounted.t;
    const atomState = store.dev_get_atom_state?.(atom) || ({} as AtomState);
    const key = atomToPrintable(atom);
    state[key] = {
      error: 'e' in atomState ? atomState.e : undefined,
      value: 'v' in atomState ? atomState.v : undefined,
      dependents: Array.from(dependents).map(atomToPrintable),
    };
  }

  return state;
}

export default function useAtomsDebugValue() {
  const store = useStore();
  const stateRef = useRef({});

  useEffect(() => {
    const devSubscribeStore: Store['dev_subscribe_store'] =
      store?.dev_subscribe_store;

    if (!devSubscribeStore) {
      return;
    }

    const callback = () => {
      let atomsChanged = false;

      const newState = stateToPrintable(
        store,
        Array.from(store.dev_get_mounted_atoms?.() ?? []),
      );

      for (let key in newState) {
        if (!isEqual(newState[key], stateRef.current[key])) {
          atomsChanged = true;
          if (newState[key]?.error) {
            changeLogger.error(key, newState[key]?.error, newState[key]?.value);
          } else {
            changeLogger.debug(key, newState[key]?.value);
          }
        }
      }

      if (atomsChanged) {
        logger.debug(mapValues(newState, (v) => v?.value));
      }

      stateRef.current = newState;
    };

    const unsubscribe = devSubscribeStore?.(callback, 2);
    callback();
    return unsubscribe;
  }, [store]);
}
