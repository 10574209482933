import styled, { css } from 'styled-components/macro';

const CLIENT_KEY = '6LeNPUQlAAAAAMnDkcaG0IZAiI5XgKABAhy8yMzB';

declare global {
  interface Window {
    grecaptcha?: any;
  }
}

export function initRecaptcha() {
  if (document.querySelector('#recaptcha-script')) return;

  const script = document.createElement('script');
  script.id = 'recaptcha-script';
  script.src = `https://www.google.com/recaptcha/enterprise.js?render=${CLIENT_KEY}`;
  script.async = true;
  document.body.appendChild(script);
}

export function getRecaptchaToken() {
  return new Promise<string>((resolve, reject) => {
    if (!('grecaptcha' in window)) {
      reject(new Error('Recaptcha not loaded'));
    }

    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise
        .execute(CLIENT_KEY, { action: 'signup' })
        .then((token) => {
          resolve(token);
        });
    });
  });
}

export function RecaptchaLegal({ className }: { className?: string }) {
  return (
    <RecaptchaRoot className={className}>
      This site is protected by reCAPTCHA and the Google{' '}
      <a
        href="https://policies.google.com/privacy"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>{' '}
      and{' '}
      <a
        href="https://policies.google.com/terms"
        target="_blank"
        rel="noreferrer"
      >
        Terms of Service
      </a>{' '}
      apply.
    </RecaptchaRoot>
  );
}

export const globalRecaptchaCss = css`
  .grecaptcha-badge {
    visibility: hidden;
  }
`;

const RecaptchaRoot = styled.p`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;

  & a {
    color: inherit;
    transition: color 0.15s ease-out;
    white-space: nowrap;
  }

  & a:hover {
    color: rgba(255, 255, 255, 1);
  }
`;
