import { atom } from 'jotai';
import { atomFamily, atomWithStorage } from 'jotai/utils';

export type ApiCreds = {
  deviceId: string | null;
  authToken: string | null;
  userId: string | null;
};

// For future use (after migrating api credentials from redux):

// export const apiCredsAtom = atomWithStorage<ApiCreds>('replika.apiCreds', {
//   deviceId: null,
//   authToken: null,
//   userId: null,
// });

// back compatibility with redux
const defaultValue = {
  deviceId: null,
  authToken: null,
  userId: null,
} as const;
const legacyReduxKey = 'auth';
const storedValue = localStorage.getItem(legacyReduxKey);

export const apiCredsAtom = atomWithStorage<ApiCreds>(
  'replika.apiCreds',
  storedValue != null ? (JSON.parse(storedValue) as ApiCreds) : defaultValue,
  {
    getItem(key) {
      const storedValue = localStorage.getItem(legacyReduxKey);

      return storedValue != null
        ? (JSON.parse(storedValue) as ApiCreds)
        : defaultValue;
    },
    setItem(key, value) {
      const storedValue = localStorage.getItem(legacyReduxKey);

      if (storedValue != null) {
        // avoid losing extra data in redux auth
        value = { ...(JSON.parse(storedValue) as ApiCreds), ...value };
        localStorage.setItem(legacyReduxKey, JSON.stringify(value));
      } else {
        localStorage.setItem(legacyReduxKey, JSON.stringify(value));
      }
    },
    removeItem(key) {},
    subscribe(key, callback, initialValue) {
      const handleStorageChange = (event) => {
        if (event.key === legacyReduxKey) {
          try {
            callback(JSON.parse(event.newValue ?? '{}') as ApiCreds);
          } catch (e) {
            console.error(e);
            callback(initialValue);
          }
        }
      };
      window.addEventListener('storage', handleStorageChange);
      window.addEventListener('reduxPersistUpdate', handleStorageChange);

      return () => {
        window.removeEventListener('storage', handleStorageChange);
        window.removeEventListener('reduxPersistUpdate', handleStorageChange);
      };
    },
  },
  {
    getOnInit: true,
  },
);
apiCredsAtom.debugLabel = 'api/apiCredsAtom';

export const wsCacheAtom = atomFamily((key: string) => atom<any>(null));
