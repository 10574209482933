import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import XpBar from './XpBar';

export const XpBarWithMood = () => {
  const bot = useSelector((state) => state.profile.persist.bot);
  const lvl = bot?.stats?.current_level?.level_index;

  return !!lvl ? (
    <StyledXpBar bot={bot}>
      <XpInfo>Level {lvl}</XpInfo>
    </StyledXpBar>
  ) : null;
};

export const StyledXpBar = styled(XpBar)`
  width: 122px;
  height: 34px;
  border-radius: 14px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 145px;
    height: 44px;
    border-radius: 19px;
  }
`;

const XpInfo = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;
