import guid from 'simple-guid';
import { RootState } from '../types/states';
import {
  IncomingWebsocketMessage,
  OutcomingWebsocketMessage,
} from '../types/websocket';
import { WS_URL } from './uri';

let ws: WebSocket | null = null;

// Storage for promises that should be resolved when paired websocket message
// (with the same token) is received
const RESOLVERS_MAP = {};

const WS_HANDLERS: WsHandler[] = [];

type WsHandler = (message: IncomingWebsocketMessage) => void;

export function addWsHandler(handler: WsHandler) {
  WS_HANDLERS.push(handler);
}

export function removeWsHandler(handler: WsHandler) {
  const index = WS_HANDLERS.indexOf(handler);
  if (index !== -1) {
    WS_HANDLERS.splice(index, 1);
  }
}

// Message queue is filled when websocket is not initialized or not in open state
let messageQueue: OutcomingWebsocketMessage[] = [];

const sendOrQueue = (message: OutcomingWebsocketMessage) => {
  if (!ws || ws.readyState !== WebSocket.OPEN) {
    messageQueue.push(message);
  } else {
    ws.send(JSON.stringify(message));
  }
};

export const closeConnection = () => {
  if (ws) {
    ws.onerror = null;
    ws.close();
  }

  ws = null;
};

function isWebsocketMessage(m: unknown): m is IncomingWebsocketMessage {
  return (
    typeof m === 'object' && m !== null && 'event_name' in m && 'payload' in m
  );
}

export const openConnection = ({ onOpen, onClose, onWsMessage, onError }) => {
  try {
    closeConnection();

    ws = new WebSocket(WS_URL);

    ws.onopen = (e) => {
      if (messageQueue.length > 0) {
        messageQueue.forEach((message) => ws!.send(JSON.stringify(message)));
        messageQueue = [];
      }
      onOpen(e);
    };

    ws.onclose = onClose;

    ws.onmessage = (e) => {
      if (typeof e.data !== 'string') {
        console.error('Unknown message format', e);
        return;
      }

      const websocketMessage = JSON.parse(e.data);

      if (!isWebsocketMessage(websocketMessage)) {
        console.error('Unknown message format', e);
        return;
      }

      onWsMessage(websocketMessage);

      WS_HANDLERS.forEach((handler) => handler(websocketMessage));

      const token = websocketMessage.token?.toLowerCase();

      if (websocketMessage.event_name === 'error') {
        onError(
          'messageError',
          new Error(websocketMessage.payload.error_message),
        );

        if (token && RESOLVERS_MAP[token]) {
          RESOLVERS_MAP[token].reject(
            new Error(websocketMessage.payload.error_message),
          );
          delete RESOLVERS_MAP[token];
        }
      } else {
        if (token && RESOLVERS_MAP[token]) {
          RESOLVERS_MAP[token].resolve(websocketMessage);
          delete RESOLVERS_MAP[token];
        }
      }
    };

    ws.onerror = () => {
      // note: event passed here has no meaningful info
      onError('connectionError', new Error('Websocket error'));
    };
  } catch (err) {
    onError('connectionError', err);
  }
};

export const sendMessageWithAuth = (
  m: OutcomingWebsocketMessage,
  auth: {
    userId?: string | null;
    authToken?: string | null;
    deviceId?: string | null;
  },
): Promise<IncomingWebsocketMessage> => {
  if (!auth.deviceId) {
    return Promise.reject(
      new Error('Not sufficient auth data (no deviceId set)'),
    );
  }

  const token = guid().toLowerCase();
  const tokenizedMessage: OutcomingWebsocketMessage = {
    ...m,
    token,
    auth: {
      user_id: auth.userId ?? undefined,
      auth_token: auth.authToken ?? undefined,
      device_id: auth.deviceId,
    },
  };

  try {
    sendOrQueue(tokenizedMessage);
  } catch (err) {
    return Promise.reject(err);
  }

  return new Promise((resolve, reject) => {
    RESOLVERS_MAP[token] = { resolve, reject };
  });
};

export const sendMessage = (m: OutcomingWebsocketMessage, state: RootState) => {
  return sendMessageWithAuth(m, state.auth.persist);
};
