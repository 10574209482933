import { ComponentProps, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { LANDING_BUCKET_URL } from '../../../utils/uri';

type Props = ComponentProps<'video'> & {
  paused?: boolean;
};

const DESKTOP_POSTER_URL = `${LANDING_BUCKET_URL}/web/main-page/movie_placeholder_desktop_opt.jpg`;

const DESKTOP_COVER_VIDEO_URL = `${LANDING_BUCKET_URL}/web/video/replika_bg_opt.mp4`;

function CoverVideo({ paused, ...rest }: Props) {
  const ref = useRef<HTMLVideoElement>(null);

  const prefersReducedMotion = useMediaQuery({
    query: '(prefers-reduced-motion: reduce)',
  });

  useEffect(() => {
    if (!ref.current) return;

    if (!paused && !prefersReducedMotion) {
      ref.current.play().catch((e) => {
        console.warn(e);
      });
    } else {
      ref.current.pause();
    }
  }, [prefersReducedMotion, paused]);

  return (
    <video
      ref={ref}
      preload="true"
      autoPlay
      muted
      loop
      playsInline
      poster={DESKTOP_POSTER_URL}
      tabIndex={-1}
      {...rest}
    >
      <source src={DESKTOP_COVER_VIDEO_URL} type="video/mp4" />
    </video>
  );
}

export default CoverVideo;
