import { StoreCategories } from '../../core/Store/useStoreCategories';
import { StorefrontCategory } from '../../types/models';
import findParentCategories from './findParentCategories';

export default function findStoreFrontCategory(
  id: string,
  storeFrontCategories: StorefrontCategory[],
  rootCategories?: StoreCategories,
) {
  for (let rootCategory of Object.values(rootCategories || {})) {
    const foundParents = findParentCategories(id, rootCategory);
    const parentIds = foundParents.map((cat) => cat.id);
    if (foundParents.length > 0) {
      for (let storeFrontCategory of storeFrontCategories) {
        if (parentIds.includes(storeFrontCategory.category_id)) {
          return {
            category: storeFrontCategory,
            rootCategory: foundParents[0]!,
          };
        }
      }
    }
  }

  return null;
}
