import { useDispatch } from 'react-redux';
import swrFetcher from 'src/core/api/swrFetcher';
import { AsyncActionTypes } from 'src/types/enums';
import { CategoryTree } from 'src/types/models';
import { successAction } from 'src/utils/asyncAction';
import getUnityBundleVersion from 'src/utils/getUnityBundleVersion';
import toQueryString from 'src/utils/toQueryString';
import { API_BASE_URL } from 'src/utils/uri';
import useSWRImmutable from 'swr/immutable';

const CategoryKeys = ['customization', 'dialog', 'room'];

export type StoreCategories = {
  [P in (typeof CategoryKeys)[number]]: CategoryTree | null;
};

export const useStoreCategories = () => {
  const dispatch = useDispatch();
  const { data } = useSWRImmutable<StoreCategories>(
    `${API_BASE_URL}/store/category_tree`,
    (url) => {
      const requests = CategoryKeys.map((categoryKey) =>
        swrFetcher<CategoryTree>(
          `${url}??${toQueryString({
            category_key: categoryKey,
            // prevent caching for older versions
            _ubv_: getUnityBundleVersion(),
          })}`,
          {
            method: 'GET',
          },
        ),
      );
      return Promise.all(requests).then((res) => {
        return res.reduce((acc, categoryTreeNode, index) => {
          const categoryKey = CategoryKeys[index];
          if (categoryKey) {
            acc[categoryKey] = categoryTreeNode;
            dispatch(
              successAction(
                AsyncActionTypes.GetCategoryTreeNode,
                categoryTreeNode,
                { categoryKey },
              ),
            );
          }
          return acc;
        }, {} as StoreCategories);
      });
    },
  );

  return data;
};
