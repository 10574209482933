import { ModalRoutes } from '../../../types/enums';
import ModalRouteMatch, { ModalRoute } from '../ModalRoutes/ModalRouteMatch';
import TransitionModalRoute from '../ModalRoutes/TransitionModalRoute';
import AccountSettingsModal from './AccountSettingsModal';
import MyProfileModal from './MyProfileModal';
import SettingsModal from './SettingsModal';
import { SettingsVersionHistory } from './VersionHistory';

export default function settingsRoutes(instant: boolean) {
  return (
    <TransitionModalRoute instant={instant} path={ModalRoutes.Settings}>
      <ModalRouteMatch>
        <ModalRoute path={ModalRoutes.Settings} exact>
          <SettingsModal />
        </ModalRoute>
        <ModalRoute path={ModalRoutes.SettingsMyProfile} exact>
          <MyProfileModal />
        </ModalRoute>
        <ModalRoute path={ModalRoutes.SettingsAccountSettings} exact>
          <AccountSettingsModal />
        </ModalRoute>
        <ModalRoute path={ModalRoutes.SettingsVersionHistory} exact>
          <SettingsVersionHistory />
        </ModalRoute>
      </ModalRouteMatch>
    </TransitionModalRoute>
  );
}
