import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { sendVoiceCallFeedback } from '../../../actions/voice';
import starActive from '../../../icons/star_active.png';
import starInactive from '../../../icons/star_inactive.png';
import { MetricsEvents } from '../../../types/enums';
import mobileMedia from '../../../utils/mobileMedia';
import useFocusGroup from '../../../utils/useFocusGroup';
import useLogEvent from '../../../utils/useLogEvent';
import { SolidButton } from '../../Buttons';
import { DialogCloseButton } from '../../DialogLayout';
import DialogContext from '../../DialogLayout/DialogContext';
import { Dialog } from '../../DialogLayout/legacy/DialogLayout';
import IconButton from '../../legacy/IconButton';

type Props = {
  onClose: () => void;
};

type FeedbackOptions = {
  [key: number]: string[];
};

const DONT_LIKE_VOICE_OPTION = "I don't like the voice";
const IGNORED_WHAT_SAID_OPTION = 'Replika ignored what I said';
const WENT_SILENT_OPTION = 'Replika went silent';
const REPEATED_ITSELF_OPTION = 'Replika repeated itself';
const CALL_ME_OPTION = 'I want Replika to call me';
const MORE_VOICES_OPTION = 'More voices to choose from';
const UNDERSTAND_BETTER_OPTION = 'Understand me better';
const SMTH_ELSE_OPTION = 'Something else';

const FEEDBACK_OPTIONS: FeedbackOptions = {
  1: [
    DONT_LIKE_VOICE_OPTION,
    IGNORED_WHAT_SAID_OPTION,
    WENT_SILENT_OPTION,
    REPEATED_ITSELF_OPTION,
    SMTH_ELSE_OPTION,
  ],
  2: [
    DONT_LIKE_VOICE_OPTION,
    IGNORED_WHAT_SAID_OPTION,
    WENT_SILENT_OPTION,
    REPEATED_ITSELF_OPTION,
    SMTH_ELSE_OPTION,
  ],
  3: [
    DONT_LIKE_VOICE_OPTION,
    IGNORED_WHAT_SAID_OPTION,
    WENT_SILENT_OPTION,
    REPEATED_ITSELF_OPTION,
    SMTH_ELSE_OPTION,
  ],
  4: [
    DONT_LIKE_VOICE_OPTION,
    CALL_ME_OPTION,
    MORE_VOICES_OPTION,
    UNDERSTAND_BETTER_OPTION,
    SMTH_ELSE_OPTION,
  ],
  5: [
    CALL_ME_OPTION,
    MORE_VOICES_OPTION,
    UNDERSTAND_BETTER_OPTION,
    SMTH_ELSE_OPTION,
  ],
};

const DEFAULT_SET_OF_FEEDBACK_OPTIONS = 5;

const VoiceCallFeedbackDialog = ({ onClose }: Props) => {
  const { isActive } = React.useContext(DialogContext);
  const dispatch = useDispatch();
  const logEvent = useLogEvent();
  const [feedbackSent, setFeedbackSent] = React.useState(false);

  const [currentRating, setCurrentRating] = React.useState<number>(0);
  const [currentOption, setCurrentOption] = React.useState<string>('');

  React.useEffect(() => {
    if (!isActive && !feedbackSent) {
      dispatch(
        sendVoiceCallFeedback({
          feedbackScore: currentRating,
          feedbackText: currentOption,
        }),
      );
      setFeedbackSent(true);

      currentRating === 0
        ? logEvent(MetricsEvents.VoiceCallRatingSkipped)
        : logEvent(MetricsEvents.VoiceCallRatingSet, {
            stars: currentRating,
            report: currentOption.toLowerCase(),
          });
    }
  }, [
    isActive,
    currentOption,
    currentRating,
    dispatch,
    logEvent,
    feedbackSent,
  ]);

  const starsRef = React.useRef<HTMLUListElement>(null);

  useFocusGroup(
    {
      getGroupElements: () => {
        return starsRef.current?.getElementsByClassName('star') ?? [];
      },
      options: {
        keybindings: {
          next: { key: 'ArrowRight' },
          prev: { key: 'ArrowLeft' },
        },
        wrap: true,
      },
    },
    [],
  );

  return (
    <StyledDialog
      data-testid="voice-call-feedback-dialog"
      mobileLayout="dialog"
    >
      <StyledDialogCloseButton onClose={onClose} />
      <Title
        data-initialfocus
        tabIndex={-1}
        data-testid="voice-call-feedback-dialog-title"
      >
        How was your call?
      </Title>

      <RatingSelector ref={starsRef}>
        {Object.keys(FEEDBACK_OPTIONS).map((rating) => {
          const isActive = currentRating >= Number(rating);
          const hasRaiting = !!currentRating;
          return (
            <Rating key={rating}>
              <StarButton
                label={
                  Number(rating) === 1 ? `${rating} star` : `${rating} stars`
                }
                $active={isActive}
                $small={hasRaiting && !isActive}
                className="star"
                onClick={() => setCurrentRating(Number(rating))}
              />
            </Rating>
          );
        })}
      </RatingSelector>

      <Description data-testid="voice-call-feedback-dialog-description">
        {currentRating > 0 && currentRating < 4
          ? 'What went wrong?'
          : `How can we improve it?`}
      </Description>

      <FeedbackOptionsList>
        {FEEDBACK_OPTIONS[
          currentRating || DEFAULT_SET_OF_FEEDBACK_OPTIONS
        ]?.map((option) =>
          option === SMTH_ELSE_OPTION ? (
            <FeedbackOptionsButton
              as="a"
              href="mailto:my@replika.ai"
              onClick={() => {
                setCurrentOption(option.toLowerCase());
                onClose();
              }}
            >
              {option}
            </FeedbackOptionsButton>
          ) : (
            <FeedbackOptionsItem key={option}>
              <FeedbackOptionsButton
                onClick={() => {
                  setCurrentOption(option.toLowerCase());
                  onClose();
                }}
              >
                {option}
              </FeedbackOptionsButton>
            </FeedbackOptionsItem>
          ),
        )}
      </FeedbackOptionsList>
    </StyledDialog>
  );
};

export default VoiceCallFeedbackDialog;

const StyledDialog = styled(Dialog)`
  width: 360px;
  padding: 20px;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(25px);
  text-align: center;

  ${mobileMedia`
    width: 320px;
  `};
`;

const Title = styled.h2`
  margin: 0;
  text-align: center;
  font-size: 20px;
  line-height: 22px;
`;

const RatingSelector = styled.ul`
  list-style: none;
  margin: 24px 0 32px;
  padding: 0;
  display: flex;
  justify-content: space-around;
  max-width: 300px;
  width: 100%;

  @media ${(p) => p.theme.breakpoints.tablet} {
    margin: 46px auto;
  }
`;

const Rating = styled.li``;

const StarButton = styled(IconButton)<{
  $active: boolean;
  $small: boolean;
}>`
  width: 44px;
  height: 44px;
  background-image: url(${(p) => (p.$active ? starActive : starInactive)});
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${(p) =>
    p.$small ? '50%' : starActive ? '100%' : '32px 32px'};
  opacity: ${(p) => (p.$active ? 1 : 0.3)};
`;

const Description = styled.p`
  margin: 0 auto 20px;
  font-size: 14px;
  line-height: 18px;
`;

const FeedbackOptionsList = styled.ul`
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  flex-direction: column;
  gap: 5px;
  list-style: none;
`;

const FeedbackOptionsItem = styled.li`
  display: flex;
  flex: 1 1 100%;
`;

const FeedbackOptionsButton = styled(SolidButton)`
  border-radius: 19px;
  width: 100%;
`;

const StyledDialogCloseButton = styled(DialogCloseButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 10px;
  width: 34px;
  height: 34px;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.1);

  svg {
    width: 24px;
    height: 24px;
  }
`;
