import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import Switch from '../../../components/Switch';

type Props = {
  checked: boolean;
  className?: string;
  children: ReactNode;
  onCheckedChange: (checked: boolean) => void;
};

export default function SwitchButton({
  children,
  className,
  onCheckedChange,
  checked,
}: Props) {
  return (
    <SwitchButtonRoot
      data-state={checked && 'checked'}
      className={className}
      onClick={() => onCheckedChange(!checked)}
    >
      {children}
      <Switch
        checked={checked}
        id="item-enabled"
        onCheckedChange={onCheckedChange}
      />
    </SwitchButtonRoot>
  );
}

const SwitchButtonRoot = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  height: 54px;
  padding: 15px;
  font-family: ${(p) => p.theme.fonts.display};
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &[data-state='checked'] {
    color: #c1ffc0;
  }
`;
