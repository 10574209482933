import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { getStoreItemsByIds, requestWallet } from '../../../actions/store';
import getCurrencyNeeded from '../../../utils/getCurrencyNeeded';
import useApi from '../../../utils/useApi';
import useGoBack from '../../../utils/useGoBack';
import { ModalLayout } from '../ModalScreenLayout';
import useRouteTransition from '../TransitionRoutes/useRouteTransition';
import DialogStoreItemFooter from './DialogStoreItemFooter';

export default function StoreDialogItemModal() {
  const history = useHistory();
  const { location } = useRouteTransition<
    {},
    {
      category: string;
      item: string;
      type: string;
    }
  >();
  const { category = '', item: id = '', type = '' } = location.state || {};
  const getItem = useCallback(() => {
    return getStoreItemsByIds([id]);
  }, [id]);

  const itemMap = useApi((state) => state.store.storeItemsMap, getItem);

  const item = itemMap[id];

  const credit = useApi((state) => state.store.persist.credit, requestWallet, {
    memoDeepEqual: true,
  });

  const variation = item?.variations[0];
  const itemBought = (variation?.bought_count ?? 0) > 0;

  const currencyNeeded =
    itemBought || !item ? null : getCurrencyNeeded(item.price, credit);

  const [error, setError] = useState<string | null>(null);

  const { goBack } = useGoBack({
    closeOnEscape: true,
  });

  return (
    <DialogModalLayout background="no-background" layout="centered">
      <ModalLayout.Content>
        <DialogModalHeader>
          <ModalLayout.HeaderRight>
            <ModalLayout.CloseButton onClick={goBack} />
          </ModalLayout.HeaderRight>
        </DialogModalHeader>
        {item && variation && (
          <>
            <ItemImg
              width={250}
              height={250}
              src={item.preview_url}
              alt={item.title}
            />
            <VariationType>{type ?? item.variation_type}</VariationType>
            <Title>{item.title}</Title>
            <Desc>{item.description}</Desc>
            <StyledDialogStoreItemFooter
              currencyNeeded={currencyNeeded}
              item={item}
              onClose={() => history.goBack()}
              itemTypeName={category}
              onError={setError}
            />
            {error && <ModalLayout.Error>{error}</ModalLayout.Error>}
          </>
        )}
      </ModalLayout.Content>
    </DialogModalLayout>
  );
}

const Title = styled.h1`
  margin: 0;
`;

const VariationType = styled.p`
  font-family: ${(p) => p.theme.fonts.display};
  color: ${(p) => p.theme.dimmedFgColor};
  margin: 0 0 10px;
`;

const Desc = styled.p`
  margin-block: 10px;
  max-width: 320px;
  text-align: center;
`;

const ItemImg = styled.img`
  width: 250px;
  height: auto;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 360px;
  }
`;

const StyledDialogStoreItemFooter = styled(DialogStoreItemFooter)`
  margin: 10px 0 60px;
`;

const DialogModalLayout = styled(ModalLayout.Root)`
  position: absolute;
  backdrop-filter: blur(25px);
  border-radius: 20px;
  height: 100svh;
  top: 0;
  left: 0;
  width: 100%;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 490px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;

const DialogModalHeader = styled(ModalLayout.Header)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  @media ${(p) => p.theme.breakpoints.tablet} {
    position: relative;
  }
`;
