import { useMemo } from 'react';
import styled from 'styled-components/macro';
import {
  DateSelectTrigger,
  DateSelectTriggerIcon,
  DateSelectTriggerLabel,
  DateSelectTriggerWrapper,
  LAYOUTS,
  StyledArrowIcon,
} from './DateSelectRadixView';
import { DateSelectViewProps } from './models';

export const DateSelectNativeView = ({
  id,
  className,
  date,
  invalid,
  onDateChange,
  options,
  layout,
  arrowIcon = <StyledArrowIcon />,
  monthPlaceholder,
  dayPlaceholder,
  yearPlaceholder,
}: DateSelectViewProps) => {
  const monthOptions = useMemo(
    () =>
      monthPlaceholder
        ? [{ label: monthPlaceholder, value: '' }, ...options.monthOption]
        : options.monthOption,
    [monthPlaceholder, options.monthOption],
  );
  const dayOptions = useMemo(
    () =>
      dayPlaceholder
        ? [{ label: dayPlaceholder, value: '' }, ...options.dayOptions]
        : options.dayOptions,
    [dayPlaceholder, options.dayOptions],
  );
  const yearOptions = useMemo(
    () =>
      yearPlaceholder
        ? [{ label: yearPlaceholder, value: '' }, ...options.yearOptions]
        : options.yearOptions,
    [options.yearOptions, yearPlaceholder],
  );

  return (
    <DateSelectNativeRoot $layout={layout} id={id} className={className}>
      <DateSelectTriggerWrapper style={LAYOUTS[layout].month}>
        <DateSelectTrigger
          aria-invalid={invalid}
          as="select"
          value={date.month}
          onChange={(event) => onDateChange('month', event?.target.value)}
          name="month"
        >
          {monthOptions.map(({ label, value }) => (
            <DateSelectOption key={value} value={value}>
              {label}
            </DateSelectOption>
          ))}
        </DateSelectTrigger>

        <DateSelectTriggerLabel>Month</DateSelectTriggerLabel>
        <DateSelectTriggerIcon>{arrowIcon}</DateSelectTriggerIcon>
      </DateSelectTriggerWrapper>

      <DateSelectTriggerWrapper style={LAYOUTS[layout].day}>
        <DateSelectTrigger
          aria-invalid={invalid}
          as="select"
          value={date.day}
          onChange={(event) => onDateChange('day', event?.target.value)}
          name="day"
        >
          {dayOptions.map(({ label, value }) => {
            return (
              <DateSelectOption key={value} value={value}>
                {label}
              </DateSelectOption>
            );
          })}
        </DateSelectTrigger>

        <DateSelectTriggerLabel>Day</DateSelectTriggerLabel>
        <DateSelectTriggerIcon>{arrowIcon}</DateSelectTriggerIcon>
      </DateSelectTriggerWrapper>

      <DateSelectTriggerWrapper style={LAYOUTS[layout].year}>
        <DateSelectTrigger
          aria-invalid={invalid}
          as="select"
          value={date.year}
          onChange={(event) => onDateChange('year', event?.target.value)}
          name="year"
        >
          {yearOptions.map(({ label, value }) => {
            return (
              <DateSelectOption key={value} value={value}>
                {label}
              </DateSelectOption>
            );
          })}
        </DateSelectTrigger>

        <DateSelectTriggerLabel>Year</DateSelectTriggerLabel>
        <DateSelectTriggerIcon>{arrowIcon}</DateSelectTriggerIcon>
      </DateSelectTriggerWrapper>
    </DateSelectNativeRoot>
  );
};

const DateSelectNativeRoot = styled.div<{ $layout: 'horizontal' | 'vertical' }>`
  display: flex;
  flex-wrap: ${(p) => (p.$layout === 'vertical' ? 'wrap' : 'nowrap')};
  gap: 5px;
`;

const DateSelectOption = styled.option`
  background: initial;
  color: initial;
`;
