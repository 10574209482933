import { Dispatch } from 'redux';
import { UiAction as A, InitAction } from '../types/actions';
import {
  ActionTypes,
  Dialogs,
  PromptsCauseList,
  SidePanel,
  StorageKeys,
  Themes,
} from '../types/enums';
import { Notification, SystemNotificationIcon } from '../types/models';
import { DialogOptions, HintStatus, RootState, UiHints } from '../types/states';
import { setUserProperties } from '../utils/metrics';
import { getThemeName } from '../utils/withThemeName';
import { initChatWithHistory } from './init';
import { disconnectWebsocket } from './websocket';

export const setActiveWindow =
  (isActiveWindow: boolean) =>
  (dispatch: Dispatch<A | InitAction>, getState: () => RootState) => {
    const state = getState();

    if (isActiveWindow !== state.ui.isActiveWindow) {
      dispatch<A>({
        type: ActionTypes.SetActiveWindow,
        isActiveWindow,
      });

      if (!isActiveWindow) {
        disconnectWebsocket()(dispatch);
      } else {
        initChatWithHistory()(dispatch, getState);
      }
    }
  };

export const goneOnline = (): A => ({
  type: ActionTypes.GoneOnline,
});

export const goneOffline = (): A => ({
  type: ActionTypes.GoneOffline,
});

export const setTheme =
  (themeName: Themes) =>
  (dispatch: Dispatch<A | InitAction>, getState: () => RootState) => {
    const { userId } = getState().auth.persist;

    if (!userId) {
      return;
    }

    setUserProperties({
      theme: themeName.toLowerCase(),
    });

    dispatch({
      type: ActionTypes.SetTheme,
      themeName,
      userId,
    });
  };

export const toggleTheme =
  () => (dispatch: Dispatch<A | InitAction>, getState: () => RootState) => {
    const state = getState();
    const themeName = getThemeName(state);

    return setTheme(themeName !== Themes.Dark ? Themes.Dark : Themes.Default)(
      dispatch,
      getState,
    );
  };

export const acceptGdpr = (): A => ({
  type: ActionTypes.AcceptGdpr,
});

export const setHintStatus = (hint: keyof UiHints, status: HintStatus): A => ({
  type: ActionTypes.SetHintStatus,
  hint,
  status,
});

export const setActiveDialog = (options: DialogOptions | null): A => {
  return {
    type: ActionTypes.SetActiveDialog,
    options,
  };
};

export const closeDialog = (dialogType: Dialogs): A => {
  return {
    type: ActionTypes.CloseDialog,
    dialogType,
  };
};

export const queueDialog = (options: DialogOptions): A => {
  return {
    type: ActionTypes.QueueDialog,
    options,
  };
};

export const setAllHints =
  (status: HintStatus) => (dispatch: Dispatch<any>) => {
    dispatch(setHintStatus('messageReaction', status));
    dispatch(setHintStatus('replikaDay', status));
  };

export const setShowRelationshipStatusButton = (
  showRelationshipStatusButton: boolean,
): A => ({
  type: ActionTypes.SetShowRelationshipStatusButton,
  showRelationshipStatusButton,
});

export const queueNotification = (notification: Notification): A => {
  return {
    type: ActionTypes.QueueNotification,
    notification,
  };
};

export const dismissNotification = (id: string): A => ({
  type: ActionTypes.DismissNotification,
  id,
});

export const setRedirectTo = (redirectTo: string | null) => {
  if (redirectTo) {
    sessionStorage.setItem(StorageKeys.REDIRECT_TO, redirectTo);
  } else {
    sessionStorage.removeItem(StorageKeys.REDIRECT_TO);
  }

  return {
    type: ActionTypes.SetRedirectTo,
    redirectTo,
  };
};

export const queueSystemNotification = (
  message: string,
  icon?: SystemNotificationIcon,
): A => {
  return {
    type: ActionTypes.QueueSystemNotification,
    message,
    icon,
  };
};

export const dismissSystemNotification = (id: string): A => {
  return {
    type: ActionTypes.DismissSystemNotification,
    id,
  };
};

export const setActiveSidePanel = (
  panel: SidePanel,
  cause?: PromptsCauseList,
): A => {
  return {
    type: ActionTypes.SetActiveSidePanel,
    panel,
    cause,
  };
};
