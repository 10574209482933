import createFocusGroup from 'focus-group';
import * as React from 'react';

type Keybinding = {
  key?: string;
  keyCode?: number;
  metaKey?: boolean;
  ctrlKey?: boolean;
  altKey?: boolean;
};

type Keybindings = {
  next?: Keybinding;
  prev?: Keybinding;
  first?: Keybinding;
  last?: Keybinding;
};

type FocusGroupOptions = {
  keybindings?: Keybindings | 'left-right';
  members?: Node[] | NodeListOf<Node>;
  wrap?: boolean;
  stringSearch?: boolean;
  stringSearchDelay?: number;
};

type GetGroupElements = () =>
  | Node[]
  | NodeListOf<Node>
  | HTMLCollectionOf<Element>;

type FocusGroupConfig = {
  getGroupElements: GetGroupElements;
  options?: FocusGroupOptions;
  focusNodeIndex?: number;
  disabled?: boolean;
};

function normalizeOptions(options?: FocusGroupOptions) {
  if (options?.keybindings === 'left-right') {
    return {
      ...options,
      keybindings: {
        prev: { key: 'ArrowLeft' },
        next: { key: 'ArrowRight' },
      },
    };
  }

  return options;
}

const useFocusGroup = (config: FocusGroupConfig, effectInputs: any[]) => {
  const [focusGroup, setFocusGroup] = React.useState<any>(null);

  React.useLayoutEffect(() => {
    let fg = focusGroup;
    if (!fg) {
      fg = createFocusGroup(normalizeOptions(config.options));
      setFocusGroup(fg);
    }

    if (!config.disabled) {
      fg.setMembers(config.getGroupElements());

      fg.activate();
    }

    if (config.focusNodeIndex !== undefined) {
      fg.focusNodeAtIndex(config.focusNodeIndex);
    }

    return () => {
      fg.deactivate();
    };
    // eslint-disable-next-line local-rules/exhaustive-deps
  }, effectInputs);
};

export default useFocusGroup;
