import styled from 'styled-components/macro';
import { ReactComponent as StripeBadgeIcon } from '../icons/StripeBadge.svg';

const StripeBadge = styled(StripeBadgeIcon).attrs({
  role: 'img',
  'aria-label': 'Powered by Stripe',
})`
  display: block;
  margin: 0;
  opacity: 0.4;
  color: ${(p) => p.theme.fgColor};
`;

export default StripeBadge;
