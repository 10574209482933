import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import {
  getUserProfile,
  setPasswordAndEmail,
  updateEmail,
  updatePassword,
} from '../../../actions/profile';
import { Routes, SolidButton } from '../../../components/Buttons';
import { ApiError } from '../../../utils/apiError';
import * as SettingsForm from './SettingsForm';
import * as SettingsLayout from './SettingsLayout';

type PasswordFieldValues = {
  currentPassword: string;
  newPassword: string;
};

type EmailFieldValues = {
  currentPassword: string;
  newEmail: string;
};

const VERIFICATION_LINK_MESSAGE = (email?: string) =>
  email
    ? `We sent a verification link to ${email}. Please follow the link to verify the email.`
    : 'We sent a verification link to your current email address. Please follow the link to verify the email change.';

export default function SettingsAccountSettingsModal() {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.profile.persist.userProfile);
  const email = useSelector(
    (state) => state.profile.persist.userProfile?.email_settings?.email,
  );

  const [resendParams, setResendParams] = useState({ email: '', password: '' });

  const handlePasswordFormSubmit = async (data: PasswordFieldValues) => {
    await dispatch(updatePassword(data.currentPassword, data.newPassword));

    return {
      currentPassword: '',
      newPassword: '',
    };
  };

  const handleEmailFormSubmit = async (data: EmailFieldValues) => {
    if (!userProfile?.email_settings?.email) {
      await dispatch(setPasswordAndEmail(data.currentPassword, data.newEmail));
    } else {
      await dispatch(updateEmail(data.currentPassword, data.newEmail));
    }

    setResendParams({ email: data.newEmail, password: data.currentPassword });

    await dispatch(getUserProfile());

    return {
      currentPassword: '',
      newEmail: '',
    };
  };

  return (
    <SettingsLayout.Root>
      <SettingsLayout.Header title="Account settings" />
      <SettingsLayout.Content fill>
        <SettingsForm.Root
          initialValues={{
            currentPassword: '',
            newPassword: '',
          }}
          onSubmit={handlePasswordFormSubmit}
        >
          <SettingsLayout.Group>
            <SettingsLayout.GroupTitle>
              Change password
            </SettingsLayout.GroupTitle>
            <SettingsForm.Fields>
              <SettingsForm.PasswordInput
                labeled
                field="newPassword"
                id="account-settings-new-password"
                placeholder="New password"
                autoComplete="new-password"
              />
              <SettingsForm.PasswordInput
                labeled
                field="currentPassword"
                id="account-settings-current-password"
                placeholder="Password"
                autoComplete="new-password"
              />
            </SettingsForm.Fields>
          </SettingsLayout.Group>
          <SettingsForm.Footer>
            <SettingsForm.Message />
            <SettingsForm.SubmitButton>Save</SettingsForm.SubmitButton>
          </SettingsForm.Footer>
        </SettingsForm.Root>
        <SettingsLayout.Separator />
        <SettingsForm.Root
          initialValues={{
            currentPassword: '',
            newEmail: email,
          }}
          onSubmit={handleEmailFormSubmit}
        >
          <SettingsLayout.Group>
            <SettingsLayout.GroupTitle>Change email</SettingsLayout.GroupTitle>
            <SettingsForm.Fields>
              <SettingsForm.Input
                field="newEmail"
                id="account-settings-new-email"
                placeholder="Email"
                type="email"
                autoComplete="new-password"
              />
              <SettingsForm.PasswordInput
                labeled
                field="currentPassword"
                id="account-settings-current-password-email"
                placeholder="Current password"
                autoComplete="new-password"
              />
            </SettingsForm.Fields>
          </SettingsLayout.Group>
          <SettingsForm.Footer>
            <SettingsForm.Message
              successMessage={<EmailVerification resendParams={resendParams} />}
            />
            <SettingsForm.SubmitButton hideOnSuccess>
              Save
            </SettingsForm.SubmitButton>
          </SettingsForm.Footer>
        </SettingsForm.Root>
        <SettingsLayout.Separator />
        <SettingsLayout.LinkGroup>
          <SettingsLayout.Item
            title="Delete Replika"
            to={{
              pathname: Routes.DeleteAccount,
              state: { passThroughModal: true },
            }}
            danger
          />
        </SettingsLayout.LinkGroup>
      </SettingsLayout.Content>
    </SettingsLayout.Root>
  );
}

function EmailVerification({
  resendParams,
}: {
  resendParams: { email: string; password: string };
}) {
  const dispatch = useDispatch();
  const [resendStatus, setResendStatus] = useState<
    'idle' | 'sending' | 'success' | 'error'
  >('idle');
  const [resendError, setResendError] = useState<string | null>(null);

  return (
    <EmailVerificationRoot>
      <Description>{VERIFICATION_LINK_MESSAGE()}</Description>

      <ResendVerificationButton
        type="button"
        onClick={async () => {
          try {
            setResendError(null);
            setResendStatus('sending');
            await dispatch(
              updateEmail(resendParams.password, resendParams.email),
            );
            setResendStatus('success');
          } catch (e) {
            setResendStatus('error');
            if (e instanceof ApiError) setResendError(e.message);
          }
        }}
        showSpinner={resendStatus === 'sending'}
      >
        Resend verification link
      </ResendVerificationButton>

      {resendStatus === 'error' && (
        <EmailVerificationMessage aria-live="assertive" $hasError>
          {resendError}
        </EmailVerificationMessage>
      )}
    </EmailVerificationRoot>
  );
}

const Description = styled.p`
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  margin-block: 0;
`;

const EmailVerificationRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
`;

const ResendVerificationButton = styled(SolidButton)`
  height: 34px;
  border-radius: 14px;
  padding-inline: 15px;
  font-size: 14px;
`;

const EmailVerificationMessage = styled.div<{ $hasError?: boolean }>`
  margin-top: 15px;
  font-size: 14px;
  min-height: 20px;
  text-align: center;
  color: ${(p) => (p.$hasError ? '#FE9E98' : '#fff')};
`;
