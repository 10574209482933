import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getImageSignedUrl } from '../actions/image';

type Props = {
  src: string | undefined;
  children: (
    objectUrl: string | undefined,
    isImageLoaded: boolean,
  ) => React.ReactNode;
};

const FETCHING_IMAGES = new Map();

export function useApiImageLoader(src: string | undefined) {
  const dispatch = useDispatch();
  const signedUrl = useSelector((state) => {
    const record = state.image.persist.signedUrlCache.find(
      (c) => c.url === src,
    );

    return record?.signedUrl;
  });

  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  const [localSignedUrl, setLocalSignedUrl] = React.useState(signedUrl);

  const isLocalImage = src?.includes('data:image');

  const fetchSignedUrl = React.useCallback(() => {
    if (src) {
      let cancelled = false;

      FETCHING_IMAGES.set(src, true);

      dispatch(getImageSignedUrl(src))
        .then(({ image_url }) => {
          if (!cancelled) {
            setLocalSignedUrl(image_url);
          }
          FETCHING_IMAGES.delete(src);
        })
        .catch(() => {
          FETCHING_IMAGES.delete(src);
        });

      return () => {
        cancelled = true;
      };
    }

    return;
  }, [src, dispatch]);

  // if we have signedUrl, try to load image and re-request signedUrl on error
  React.useEffect(() => {
    if (src && !isLocalImage && localSignedUrl && !isImageLoaded) {
      const img = new Image();
      let cancelled = false;

      img.onload = () => {
        if (!cancelled) {
          setIsImageLoaded(true);
        }
        img.src = '';
      };

      img.src = localSignedUrl;

      // for some reason, onerror is called when image is already cached,
      // so we skip it in that case
      if (!img.complete) {
        img.onerror = (e) => {
          if (!FETCHING_IMAGES.has(src)) {
            console.warn(e);
            fetchSignedUrl();
          }
        };
      }

      return () => {
        cancelled = true;
      };
    }

    return;
  }, [src, isLocalImage, localSignedUrl, isImageLoaded, fetchSignedUrl]);

  // reset isImageLoaded if src changed
  React.useEffect(() => {
    setIsImageLoaded(false);
  }, [src]);

  // if there's no signedUrl in cache yet, request it
  React.useEffect(() => {
    if (src && !isLocalImage && !localSignedUrl) {
      fetchSignedUrl();
    }
  }, [src, isLocalImage, localSignedUrl, fetchSignedUrl]);

  return { localSignedUrl, isImageLoaded };
}

const ApiImageLoader = (props: Props) => {
  const { src, children } = props;

  const { localSignedUrl, isImageLoaded } = useApiImageLoader(src);

  return <>{children(localSignedUrl, isImageLoaded)}</>;
};

export default ApiImageLoader;
