import styled from 'styled-components/macro';
import { MessageActionType } from '../../../types/models';
import useBotName from '../../../utils/useBotName';
import {
  Dialog,
  DialogBody,
  DialogHeader,
} from '../../DialogLayout/legacy/DialogLayout';
import ReactionDialogBody from './ReactionDialogBody';

type Props = {
  onClose: () => void;
  messageId: string;
  permittedActions?: MessageActionType[];
};

const ReactionDialog = ({ onClose, messageId, permittedActions }: Props) => {
  const botName = useBotName();
  const title = (
    <>
      What do you think about <BotName>{botName}</BotName> phrase?
    </>
  );

  return (
    <StyledDialog data-testid="reaction-dialog" mobileLayout="action-sheet">
      <DialogHeader onClose={onClose} title={<Title>{title}</Title>} />
      <DialogBody>
        <StyledBody
          messageId={messageId}
          onClose={onClose}
          permittedActions={permittedActions}
        />
      </DialogBody>
    </StyledDialog>
  );
};

export default ReactionDialog;

const StyledDialog = styled(Dialog)`
  width: 400px;
  background: rgba(256, 256, 256, 0.2);
`;

const StyledBody = styled(ReactionDialogBody)`
  margin-bottom: 20px;
`;

const Title = styled.h3`
  margin: 0 30px;
  text-align: center;
  color: #fff;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  white-space: pre-wrap;
`;

const BotName = styled.span`
  max-width: 100%;
  display: inline-block;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
`;
