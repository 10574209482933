import { Options } from 'focus-trap';
import { ReactNode, useEffect, useState } from 'react';
import FocusTrap from '../FocusTrap';

declare var _iub: any;

export default function DialogFocusTrap({ children }: { children: ReactNode }) {
  const cookieBannerShown =
    _iub?.cs?.api?.bannerShown ||
    !!document.querySelector('.iubenda-cs-visible');

  const [containers, setContainers] = useState<HTMLElement[]>([]);

  useEffect(() => {
    setContainers(
      Array.from(
        document.querySelectorAll(
          '#dialog-scroll, [data-dialogtabbable="true"]',
        ),
      ),
    );
  }, []);

  const focusTrapOptions: Options = {
    fallbackFocus: () =>
      document.querySelector<HTMLElement>('[aria-modal="true"]') ??
      document.body,
    allowOutsideClick(e) {
      return e.target instanceof HTMLElement
        ? // allow clicking on background to close dialog
          e.target.id === 'dialog-back' ||
            e.target.id === 'dialog-scroll' ||
            // allow certain elements (like buttons in popovers) to be active
            e.target.getAttribute('data-indialog') === 'true' ||
            // allow cookie banner interaction
            cookieBannerShown
        : false;
    },
    initialFocus() {
      return (
        document.querySelector<HTMLElement>('[data-initialfocus]') ?? false
      );
    },
  };

  return (
    <FocusTrap
      focusTrapOptions={focusTrapOptions}
      containerElements={containers}
    >
      {children}
    </FocusTrap>
  );
}
