import { SubscriptionListing } from '../types/models';

export default function getSubscriptionPeriod(
  period?: SubscriptionListing['period'],
) {
  if (period) {
    switch (period.period_timeunit) {
      case 'day':
      case 'week':
        return period.period_amount === 1
          ? `${period.period_amount} ${period.period_timeunit}`
          : `${period.period_amount} ${period.period_timeunit}s`;
      case 'month':
        return `${period.period_amount}`;
      case 'year':
        return `${period.period_amount * 12}`;
    }
  }

  return 'lifetime';
}
