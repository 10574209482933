export function initFeatures() {
  const params = new URLSearchParams(window.location.search);
  const length = Array.from(params.keys()).length;

  for (let key of Object.keys(FeatureFlags)) {
    const lsKey = `replika.${key}`;
    if (params.get(key) === '' || params.get(key) === 'true') {
      localStorage.setItem(lsKey, 'true');
      FeatureFlags[key] = true;
      params.delete(key);
    } else if (params.get(key) === 'false') {
      localStorage.setItem(lsKey, 'false');
      FeatureFlags[key] = false;
      params.delete(key);
    }
  }

  if (Array.from(params.keys()).length !== length) {
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${params}`,
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isTruthyFlag(key: string) {
  const value = localStorage.getItem(key);
  return value === 'true' || value === '1';
}

// example:
// myFlag = isTruthyFlag('replika.myFlag');
export const FeatureFlags = {
  statementsAboutReplika: true,
};
