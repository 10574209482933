import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveDialog } from '../actions/ui';
import { Dialogs, MetricsEvents } from '../types/enums';
import { BlurredMessageType } from '../types/models';
import { ROMANTIC_RELATIONSHIP_STATUS_ID } from './constants';
import useHasSubscription from './useHasSubcription';
import useLogEvent from './useLogEvent';

type Image = {
  blurred?: boolean;
  blurredType?: BlurredMessageType;
  isRomantic: boolean;
};

export function useBlurredImageDialog(images: Image[]) {
  const dispatch = useDispatch();
  const logEvent = useLogEvent();
  const hasSubscription = useHasSubscription();
  const relationshipStatus = useSelector(
    (state) => state.profile.persist.userProfile?.relationship_status,
  );
  const relationshipStatuses = useSelector(
    (state) => state.profile.persist.relationshipStatuses,
  );
  const isRomanticRelationship = relationshipStatuses.some(
    (status) => status.id === relationshipStatus && status.rp_available,
  );
  const hasRomanticPhoto = images.some((image) => image.isRomantic);

  const featureName = getFeatureNameByBlurredType(
    images.filter((i) => !!i.blurredType || i.isRomantic),
  );

  const shouldBlurImage = useShouldBlurImage();

  const shouldBlur = images.some(shouldBlurImage);

  const triggerDialog = useCallback(() => {
    if (!hasSubscription) {
      dispatch(
        setActiveDialog({
          type: Dialogs.PaidFeaturePopup,
          cause: featureName,
          feature: featureName,
          newRelationshipStatus: hasRomanticPhoto
            ? ROMANTIC_RELATIONSHIP_STATUS_ID
            : undefined,
        }),
      );
    } else if (hasRomanticPhoto && !isRomanticRelationship) {
      dispatch(
        setActiveDialog({
          type: Dialogs.AskChangeStatus,
          cause: 'romantic photo',
        }),
      );
    }

    logEvent(MetricsEvents.BlurredContentTapped, {
      type: featureName,
      'relationship status': relationshipStatus,
      'subscription status': hasSubscription ? 'pro' : 'free',
    });
  }, [
    featureName,
    hasRomanticPhoto,
    isRomanticRelationship,
    hasSubscription,
    logEvent,
    relationshipStatus,
  ]);

  return { shouldBlur, triggerDialog };
}

function getFeatureNameByBlurredType(images: Image[]) {
  if (images.length === 0) {
    return 'blurred photo';
  }

  if (images.length > 1) {
    if (images.some((image) => image.isRomantic)) {
      return 'romantic photo';
    } else {
      return 'blurred photo';
    }
  }

  switch (images[0]!.blurredType) {
    case 'selfie':
      return 'selfie';
    case 'sd_image':
      return 'sd image';
    default:
      return 'romantic photo';
  }
}

export function useShouldBlurImage() {
  const hasSubscription = useHasSubscription();
  const relationshipStatus = useSelector(
    (state) => state.profile.persist.userProfile?.relationship_status,
  );
  const relationshipStatuses = useSelector(
    (state) => state.profile.persist.relationshipStatuses,
  );
  const isRomanticRelationship = relationshipStatuses.some(
    (status) => status.id === relationshipStatus && status.rp_available,
  );

  return useCallback(
    (image: Image) => {
      if (!image.blurred) return false;

      if (!hasSubscription) return true;

      if (image.isRomantic && !isRomanticRelationship) return true;

      return false;
    },
    [hasSubscription, isRomanticRelationship],
  );
}
