import styled from 'styled-components/macro';

import { ReactComponent as CheckIcon } from '../../icons/Check.svg';
import { ReactComponent as LockIcon } from '../../icons/Lock.svg';
import imageSourceSet from '../../utils/imageSourceSet';
import { MAIN_PAGE_URL_BASE } from '../../utils/uri';

export function GemIcon(props: React.ImgHTMLAttributes<HTMLImageElement>) {
  return (
    <img
      {...props}
      alt={props.alt ?? 'gem'}
      {...imageSourceSet(`${MAIN_PAGE_URL_BASE}/gem_new.png`)}
    />
  );
}

export function CoinIcon(props: React.ImgHTMLAttributes<HTMLImageElement>) {
  return (
    <img
      {...props}
      alt={props.alt ?? 'coin'}
      {...imageSourceSet(`${MAIN_PAGE_URL_BASE}/coin_new.png`)}
    />
  );
}

function Counter({
  children,
  count,
  className,
  badge,
  dataTestId,
  locked,
  legacy,
}: {
  children: React.ReactNode;
  count: number | 'check' | null;
  className?: string;
  badge?: string;
  dataTestId?: string;
  locked?: boolean;
  legacy?: boolean;
}) {
  let countBadge;

  const legacyStyle = legacy
    ? { right: 8, bottom: -2, left: 'auto' }
    : undefined;

  if (count === 'check') {
    countBadge = (
      <Count role="presentation" data-testid={dataTestId} style={legacyStyle}>
        <CheckIcon />
      </Count>
    );
  } else if (count) {
    countBadge = (
      <Count aria-hidden data-testid={dataTestId} style={legacyStyle}>
        {count}
      </Count>
    );
  }
  return (
    <CounterRoot
      className={className}
      $grayscale={count === null}
      $badge={badge}
    >
      {children}
      {countBadge}
      {locked && <StyledLockIcon />}
    </CounterRoot>
  );
}

const CounterRoot = styled.div<{ $grayscale: boolean; $badge?: string }>`
  position: relative;
  width: 60px;
  height: 60px;

  & > img {
    filter: grayscale(${(p) => (p.$grayscale ? 1 : 0)});
    display: block;
    height: 100%;
    margin: 0 auto;
    transition: filter 0.25s ease-out;
  }

  & ~ & {
    margin-left: 10px;
  }

  &:after {
    display: ${(p) => (p.$badge ? 'block' : 'none')};
    content: '${(p) => p.$badge}';
    position: absolute;
    padding: 4px 6px;
    color: #222;
    background: #40e991;
    font-size: 12px;
    line-height: 12px;
    border-radius: 4px;
    top: 0;
    right: -25%;
  }
`;

const Count = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -12px;
  left: 0;
  right: 0;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  border-radius: 12px;
  height: 24px;
  min-width: 24px;
  width: fit-content;
  transform: translateX(50%);
  background: rgba(255, 255, 255, 0.8);

  @supports (backdrop-filter: blur(2px)) {
    backdrop-filter: blur(2px);
    background: rgba(255, 255, 255, 0.6);
  }

  & > svg {
    color: ${(p) => p.theme.successFgColor};
    width: 12px;
    position: absolute;
    top: 7px;
  }

  & > svg > path {
    stroke-width: 4;
  }
`;

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  width: 24px;
  height: 24px;
  top: -8px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  color: #fff;
  z-index: 1;
`;

export function GemCounter({
  count,
  badge,
  'data-testid': dataTestId,
  className,
  locked,
  legacy,
}: {
  count: number | 'check' | null;
  badge?: string;
  'data-testid'?: string;
  className?: string;
  locked?: boolean;
  legacy?: boolean;
}) {
  return (
    <Counter
      className={className}
      count={count}
      badge={badge}
      dataTestId={dataTestId}
      locked={locked}
      legacy={legacy}
    >
      <GemIcon alt={count ? `${count} gems` : ''} />
    </Counter>
  );
}

export function CoinCounter({
  count,
  badge,
  'data-testid': dataTestId,
  className,
  locked,
  legacy,
}: {
  count: number | 'check' | null;
  badge?: string;
  'data-testid'?: string;
  className?: string;
  locked?: boolean;
  legacy?: boolean;
}) {
  return (
    <Counter
      className={className}
      count={count}
      badge={badge}
      dataTestId={dataTestId}
      locked={locked}
      legacy={legacy}
    >
      <CoinIcon alt={count ? `${count} coins` : ''} />
    </Counter>
  );
}

const ICONS = {
  gem: GemIcon,
  coin: CoinIcon,
};

type StoreItemPrice = {
  amount: number;
  currency: 'gem' | 'coin';
};

export function PriceIcon({
  price,
  className,
  alt,
}: {
  price: StoreItemPrice;
  className?: string;
  alt?: string;
}) {
  const Icon = ICONS[price.currency];

  return <Icon className={className} alt={alt ?? price.currency} />;
}
