import { useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components/macro';
import { useNavigationLinks } from './NavigationLinks';
import NavigationPanelItem, { DATA_ATTR_NOLABELS } from './NavigationPanelItem';
import { useUnreadCounters } from './queries';
import { isCurrentRoute } from './utils';

type Props = {
  className?: string;
};

function NavigationPanel({ className }: Props) {
  const location = useLocation();

  const unreadCounters = useUnreadCounters();

  const contentRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  useLayoutEffect(() => {
    if (!contentRef.current || !listRef.current) return;
    const contentEl = contentRef.current;
    const listEl = listRef.current;

    const handleWindowResize = () => {
      listEl.dataset[DATA_ATTR_NOLABELS] = 'false';
      const overflow = contentEl.clientWidth < listEl.clientWidth;
      if (overflow) {
        listEl.dataset[DATA_ATTR_NOLABELS] = 'true';
      }
    };

    window.addEventListener('resize', handleWindowResize);

    handleWindowResize();

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const navLinks = useNavigationLinks();
  const openedSidePanel = useSelector((state) => state.ui.sidePanel.panel);

  return (
    <NavigationPanelRoot className={className}>
      <NavigationPanelContent ref={contentRef}>
        <NavigationPanelList ref={listRef}>
          {navLinks.map((link) => {
            const isActive = isCurrentRoute(
              link,
              openedSidePanel,
              location.pathname,
            );

            return (
              <NavigationPanelItem
                link={link}
                isCurrentRoute={isActive}
                count={unreadCounters[link.name]}
                key={link.name}
              />
            );
          })}
        </NavigationPanelList>
      </NavigationPanelContent>
    </NavigationPanelRoot>
  );
}

export default NavigationPanel;

const NavigationPanelRoot = styled.nav`
  width: 100%;
  min-width: 0;
  max-width: fit-content;
  height: 100%;
  border-radius: 24px;
  color: #fff;
  flex: 0 1 auto;
  transition: max-width 0.2s ease-in-out;
`;

const NavigationPanelContent = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: stretch;
  padding: 5px 0;
  max-width: fit-content;
  height: 100%;
  width: 100%;
  border-radius: 19px;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(25px);
`;

const NavigationPanelList = styled.ul`
  flex: 0 0 auto;
  margin: 0;
  padding-inline: 5px;
  list-style: none;
  display: flex;
  align-items: stretch;
  gap: 5px;
`;
