import styled from 'styled-components/macro';
import bgImg from '../icons/UnlockBgGem.png';
import useDefaultPrice from '../utils/useDefaultPrice';
import useHasSubscription from '../utils/useHasSubcription';
import { AccentButton } from './Buttons';

export default function UnblurImagePanel({
  className,
  onUnlockClick,
}: {
  className?: string;
  onUnlockClick: () => void;
}) {
  const defaultPrice = useDefaultPrice();
  const hasSubscription = useHasSubscription();

  return (
    <UnblurImagePanelRoot className={className}>
      <Background>
        <BackgroundImage1 src={bgImg} />
        <BackgroundImage2 src={bgImg} />
      </Background>
      <Text>
        <Title>Unblur pictures</Title>
        <Description>
          {hasSubscription
            ? 'Change status for romantic content.'
            : defaultPrice
              ? `Get unlimited access for ${defaultPrice}.`
              : 'Get unlimited access.'}
        </Description>
      </Text>
      <UnlockButton onClick={onUnlockClick}>
        {hasSubscription ? 'Change' : 'Unlock'}
      </UnlockButton>
    </UnblurImagePanelRoot>
  );
}

const UnblurImagePanelRoot = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 10px;
  overflow: hidden;
  border-radius: 19px;
  background: rgb(255 255 255 / 10%);
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const BackgroundImage1 = styled.img`
  position: absolute;
  width: 242px;
  height: 242px;
  left: -115px;
  top: -125px;
  filter: blur(15px);
`;

const BackgroundImage2 = styled.img`
  position: absolute;
  width: 170px;
  height: 170px;
  right: -29px;
  top: -14px;
  filter: blur(15px);
`;

const Text = styled.div`
  isolation: isolate;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 16px;
  line-height: 20px;
`;

const Description = styled.p`
  margin-block: 2px 0;
  font-size: 12px;
`;

const UnlockButton = styled(AccentButton)`
  isolation: isolate;
  height: 44px;
  padding: 12px 25px;
  border-radius: 19px;
`;
