import { replace } from 'connected-react-router';
import { sortBy, uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadImage } from 'src/actions/image';
import { queueSystemNotification, setActiveDialog } from 'src/actions/ui';
import CircleSpinner from 'src/components/CircleSpinner';
import CroppedPhoto from 'src/components/CroppedPhoto';
import FormSelect from 'src/components/Form/FormSelect';
import { StaticallyLabledTextInput } from 'src/components/Inputs';
import LoadingPage from 'src/components/LoadingPage';
import {
  deleteMemory,
  getMemory,
  getMemoryCategories,
  getMemoryRelations,
  updateMemoryPerson,
} from 'src/features/Memory/actions';
import { ReactComponent as PersonIcon } from 'src/icons/Person.svg';
import { ModalForm } from 'src/routes/modals/ModalForm';
import ModalRouteMatch, {
  ModalRoute,
} from 'src/routes/modals/ModalRoutes/ModalRouteMatch';
import { Dialogs, MetricsEvents, ModalRoutes, Routes } from 'src/types/enums';
import {
  MemoryPerson,
  PhotoFrame,
  isMemoryCategoryType,
} from 'src/types/models';
import { SubmitStatus } from 'src/types/states';
import { IMAGE_MAX_PIXEL_AREA } from 'src/utils/constants';
import { captureError } from 'src/utils/initSentry';
import toModalRoute from 'src/utils/toModalRoute';
import useApi from 'src/utils/useApi';
import useBotName from 'src/utils/useBotName';
import useForm from 'src/utils/useForm';
import useGoBack from 'src/utils/useGoBack';
import useLogEvent from 'src/utils/useLogEvent';
import styled from 'styled-components/macro';
import ImageCropper from '../ImageCropper';
import * as MemoryForm from '../MemoryForm';
import { MemoryFormWrapper } from '../MemoryForm';

type EditPersonFormWrapperProps = {
  memoryId: string;
  setFormDirty: React.Dispatch<React.SetStateAction<boolean>>;
};

type EditPersonFormProps = EditPersonFormWrapperProps & {
  person: MemoryPerson;
};

type FieldValues = {
  category: string;
  name: string;
  relationType: string;
  relationId: string;
};

// TODO: reuse SettingsLayout / SettingsForm

function EditPersonForm({
  memoryId,
  person,
  setFormDirty,
}: EditPersonFormProps) {
  const dispatch = useDispatch();
  const logEvent = useLogEvent();
  const botName = useBotName('your Replika');

  const { goBack } = useGoBack({
    fallbackRoute: Routes.Memory,
  });

  const [deleteStatus, setDeleteStatus] = useState<SubmitStatus>('initial');

  const memoryCategories = useApi(
    (state) => state.memory.persist.memoryCategories,
    getMemoryCategories,
    { memoDeepEqual: true },
  );

  // it's guranteed that there is at least one category of 'person' type
  const personsCategory = memoryCategories.find((category) =>
    isMemoryCategoryType(category, 'person'),
  );

  const isVirtualPet = !!person.virtual_pet;

  const relations = useApi(
    (state) => state.memory.persist.relations,
    getMemoryRelations,
  );
  const sortedRelations = useMemo(() => sortBy(relations, 'name'), [relations]);
  const relationCategoriesOptions = useMemo(
    () =>
      uniqBy(
        sortedRelations.map(({ category }) => ({
          label:
            isVirtualPet && category === 'Pets' ? "Replika's pets" : category,
          value: category,
        })),
        'value',
      ),
    [isVirtualPet, sortedRelations],
  );

  const { category: personRelationCategory, name: personRelationName } =
    relations.find((relation) => relation.id === person.relation_id) ?? {
      category: undefined,
      name: undefined,
    };

  const {
    isDirty,
    handleSubmit,
    reset,
    isSubmitting,
    errors,
    register,
    watch,
    setValue,
  } = useForm<FieldValues>({
    defaultValues: {
      category: personsCategory ? personsCategory.id : '',
      name: person.name,
      relationType: personRelationCategory,
      relationId: person.relation_id,
    },
  });

  const [watchName, watchRelationType, watchRelationId] = watch([
    'name',
    'relationType',
    'relationId',
  ]);

  const [originalImageUrl] = useState(person.photo?.photo_url);
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [imageFile, setImageFile] = useState<File | undefined>();
  const [imageMarkedForDeletion, setImageMarkedForDeletion] = useState(false);
  const [imageUploadStatus, setImageUploadStatus] =
    useState<SubmitStatus>('initial');
  const [frame, setFrame] = useState<PhotoFrame | undefined>(
    person.photo?.frame,
  );

  useEffect(() => {
    if (
      (!!imageUrl && imageUrl !== originalImageUrl) ||
      imageMarkedForDeletion
    ) {
      setFormDirty(true);
    } else {
      setFormDirty(isDirty);
    }
  }, [
    setFormDirty,
    isDirty,
    imageUrl,
    originalImageUrl,
    imageMarkedForDeletion,
  ]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const handleCropperClose = () => {
    setImageUrl(originalImageUrl);

    dispatch(
      replace(
        toModalRoute<{
          memoryType: 'persons' | 'facts';
          memoryId: string;
          source?: string;
        }>(ModalRoutes.MemoryRecordEdit, {
          memoryType: 'persons',
          memoryId,
        }),
      ),
    );
  };

  const handleCropperSave = (frameData: PhotoFrame) => {
    setFrame({
      height: frameData.height,
      width: frameData.width,
      upper_left_x: frameData.upper_left_x,
      upper_left_y: frameData.upper_left_y,
    });

    dispatch(
      replace(
        toModalRoute<{
          memoryType: 'persons' | 'facts';
          memoryId: string;
          source?: string;
        }>(ModalRoutes.MemoryRecordEdit, {
          memoryType: 'persons',
          memoryId,
        }),
      ),
    );
  };

  const handleImageSave = async () => {
    if (!imageFile) return;

    try {
      setImageUploadStatus('submitting');

      const { image_url: savedImageUrl } = await dispatch(
        uploadImage(imageFile.slice(), IMAGE_MAX_PIXEL_AREA),
      );

      setImageUploadStatus('success');
      return savedImageUrl;
    } catch (e) {
      setImageUploadStatus('error');
      captureError(e);

      return Promise.reject(e);
    }
  };

  const handleImageUpload = (files: File[]) => {
    if (files[0]) {
      setImageFile(files[0]);

      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result as any);

        dispatch(
          replace(
            toModalRoute<{
              memoryType: 'persons' | 'facts';
              memoryId: string;
              source?: string;
            }>(ModalRoutes.MemoryRecordEditImageUpload, {
              memoryType: 'persons',
              memoryId,
            }),
          ),
        );
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleImageDelete = () => {
    setImageFile(undefined);
    setImageUrl(undefined);

    if (originalImageUrl) {
      setImageMarkedForDeletion(true);
    }
  };

  const handlePersonDelete = async () => {
    try {
      setDeleteStatus('submitting');

      await dispatch(deleteMemory('persons', memoryId));

      const relation = relations.find(
        (relation) => relation.id === person.relation_id,
      );

      logEvent(MetricsEvents.PersonOrPetFactDeleted, {
        type: relation?.category.toLowerCase() ?? 'other',
        relationship: relation?.name.toLowerCase() ?? 'other',
      });

      setDeleteStatus('success');

      goBack();
    } catch (e) {
      setDeleteStatus('error');

      dispatch(
        queueSystemNotification(
          e instanceof Error ? e.message : 'Something went wrong',
          'warning',
        ),
      );

      captureError(e);
    }
  };

  const handleDeleteButtonClick = () => {
    dispatch(
      setActiveDialog({
        type: Dialogs.Confirmation,
        content: {
          title: 'Delete a person?',
          description: `This action will make ${botName} forget all information about your ${personRelationName}.`,
          secondaryText: 'Delete',
          primaryText: 'Cancel',
        },
        onSecondaryClick: handlePersonDelete,
      }),
    );
  };

  const handleFormSubmit = async (data: FieldValues) => {
    const { name, relationId } = data;

    try {
      const savedImageUrl = await handleImageSave();

      await dispatch(
        updateMemoryPerson(memoryId, {
          name,
          relation_id: relationId,
          ...(!!savedImageUrl
            ? {
                photo: {
                  photo_url: savedImageUrl,
                  ...(frame ? { frame } : {}),
                },
              }
            : { photo: imageMarkedForDeletion ? undefined : person.photo }),
        }),
      );

      const relation = relations.find((relation) => relation.id === relationId);

      logEvent(MetricsEvents.PersonOrPetFactEdited, {
        type: relation?.category.toLowerCase() ?? 'other',
        relationship: relation?.name.toLowerCase() ?? 'other',
      });
    } catch (e) {
      dispatch(
        queueSystemNotification(
          e instanceof Error ? e.message : 'Something went wrong',
          'warning',
        ),
      );

      return captureError(e);
    }

    reset({}, { keepValues: true });
    goBack();
  };

  return (
    <ModalRouteMatch>
      <ModalRoute exact path={ModalRoutes.MemoryRecordEdit}>
        <MemoryFormWrapper>
          <ModalForm.Body onSubmit={handleSubmit(handleFormSubmit)}>
            <StyledFormSelect
              id="select-memory-category"
              label="Category"
              {...register('category', {
                required: true,
              })}
              defaultValue={personsCategory ? personsCategory.id : ''}
              onChange={(value) => {
                setValue('category', value, {
                  shouldDirty: true,
                  shouldValidate: true,
                  shouldTouch: true,
                });
              }}
              options={sortBy(memoryCategories, 'name').map((category) => ({
                value: category.id,
                label: category.name,
              }))}
              disabled
            />

            {imageUrl && imageUploadStatus !== 'submitting' ? (
              <StyledCroppedPhoto
                width={184}
                height={184}
                label={`${watchName ?? 'Person'}'s photo`}
                photoUrl={imageUrl}
                photoFrame={frame}
              />
            ) : !imageMarkedForDeletion &&
              originalImageUrl &&
              imageUploadStatus !== 'submitting' ? (
              <StyledCroppedPhoto
                width={184}
                height={184}
                label={`${watchName ?? 'Person'}'s photo`}
                photoUrl={originalImageUrl}
                photoFrame={frame}
              />
            ) : imageUploadStatus === 'submitting' ? (
              <SpinnerContainer>
                <StyledSpinner lineWidth={10} />
              </SpinnerContainer>
            ) : (
              <PersonPhotoPlaceholder>
                <PersonIcon />
              </PersonPhotoPlaceholder>
            )}

            {!isVirtualPet && (
              <ModalForm.ImageActions>
                <MemoryForm.EditPersonImageButton
                  id="edit-person-image"
                  onUpload={handleImageUpload}
                />

                {(imageUrl || originalImageUrl) && (
                  <MemoryForm.DeletePersonImageButton
                    id="delete-person-image"
                    onClick={handleImageDelete}
                  />
                )}
              </ModalForm.ImageActions>
            )}

            <>
              <StyledStaticallyLabledTextInput
                id="edit-person-name"
                placeholder="Add"
                staticLabel="Name"
                {...register('name', {
                  required: true,
                })}
                autoComplete="off"
                aria-invalid={!!errors.name}
              />
              {errors.name?.message && (
                <ModalForm.FieldError error={errors.name.message} />
              )}
            </>

            <StyledFormSelect
              id="select-person-type"
              label="Type"
              placeholder="Add"
              {...register('relationType', {
                required: true,
              })}
              defaultValue={watchRelationType}
              onChange={(value) => {
                setValue('relationType', value, {
                  shouldDirty: true,
                  shouldValidate: true,
                  shouldTouch: true,
                });

                const relationId = sortedRelations.filter(
                  (relation) => relation.category === value,
                )[0]?.id;

                if (relationId) {
                  setValue('relationId', relationId, {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true,
                  });
                }
              }}
              options={relationCategoriesOptions}
              invalid={!!errors.relationType}
              disabled={isVirtualPet}
            />

            {watchRelationType ? (
              <StyledFormSelect
                id="select-person-relastionship"
                key={watchRelationType}
                placeholder="Add"
                label="Relationship"
                {...register('relationId', {
                  required: true,
                })}
                defaultValue={watchRelationId}
                onChange={(value) => {
                  setValue('relationId', value, {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true,
                  });
                }}
                options={sortedRelations.reduce<
                  { value: string; label: string }[]
                >(
                  (accum, curr) =>
                    curr.category === watchRelationType
                      ? [...accum, { value: curr.id, label: curr.name }]
                      : accum,
                  [],
                )}
                invalid={!!errors.relationId}
                disabled={isVirtualPet}
              />
            ) : null}

            <ModalForm.Actions>
              <ModalForm.SaveEditButton
                type="submit"
                showSpinner={isSubmitting}
                disabled={!isDirty && !imageFile && !imageMarkedForDeletion}
              >
                Save
              </ModalForm.SaveEditButton>

              <ModalForm.DeleteButton
                type="button"
                onClick={handleDeleteButtonClick}
                aria-label="delete person"
                showSpinner={deleteStatus === 'submitting'}
                disabled={isVirtualPet}
              />
            </ModalForm.Actions>
          </ModalForm.Body>
        </MemoryFormWrapper>
      </ModalRoute>

      <ModalRoute exact path={ModalRoutes.MemoryRecordEditImageUpload}>
        <ImageCropper
          imageUrl={imageUrl}
          handleClose={handleCropperClose}
          handleSave={handleCropperSave}
        />
      </ModalRoute>
    </ModalRouteMatch>
  );
}

const StyledCroppedPhoto = styled(CroppedPhoto)`
  margin-top: 40px;
`;

const SpinnerContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 184px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
`;

const StyledSpinner = styled(CircleSpinner)`
  width: 54px;
  height: 54px;
`;

const PersonPhotoPlaceholder = styled(PersonIcon)`
  margin-top: 40px;
  width: 184px;
  height: 184px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
`;

const StyledStaticallyLabledTextInput = styled(StaticallyLabledTextInput)`
  margin-top: 30px;
`;

const StyledFormSelect = styled(FormSelect)`
  margin-top: 10px;
`;

export default function EditPersonFormWrapper(
  props: EditPersonFormWrapperProps,
) {
  const persons = useApi((state) => state.memory.persist.persons, getMemory);
  const person = persons?.find((p) => p.id === props.memoryId);

  if (!person) {
    return <StyledLoadingPage />;
  }

  return <EditPersonForm person={person} {...props} />;
}

const StyledLoadingPage = styled(LoadingPage)`
  width: 100%;
  min-height: auto;
  background: transparent;
`;
