import { Stripe } from '@stripe/stripe-js';
import { retryInvoice } from '../actions/subscriptions';
import { StripeSubscriptionStatus } from '../types/models';
import { TDA } from '../types/redux';
import { processIncompletePayment, retryIncompletePayment } from './stripe';

export default async function processStripePayment({
  paymentMethodId,
  stripe,
  latestInvoiceId,
  priceId,
  dispatch,
  onCreate,
}: {
  paymentMethodId: string;
  stripe: Stripe;
  latestInvoiceId: string | null;
  priceId: string | null;
  dispatch: TDA;
  onCreate: (priceId: string) => Promise<StripeSubscriptionStatus>;
}) {
  let invoiceId: string | null = null;

  if (latestInvoiceId) {
    const invoice = await dispatch(
      retryInvoice({ paymentMethodId, latestInvoiceId }),
    );

    invoiceId = await retryIncompletePayment(paymentMethodId, stripe, invoice);
  } else if (priceId) {
    const subscriptionStatus = await onCreate(priceId);

    invoiceId = await processIncompletePayment(
      paymentMethodId,
      stripe,
      subscriptionStatus,
    );
  }

  return invoiceId;
}
