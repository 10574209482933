import { Location } from 'history';
import { ReactNode, useEffect, useRef } from 'react';
import { match, useLocation } from 'react-router-dom';
import useTransition from 'react-transition-state';
import TransitionContext from './TransitionContext';
import matchPathOrState from './matchPathOrState';

export type RouteMatcher = (
  location: Location,
  path: string,
  stateToMatch: Object | undefined,
  exact?: boolean,
) => match<{}> | null;

type TransitionRouteProps = {
  path: string;
  state?: Object;
  children: ReactNode;
  routeMatcher?: RouteMatcher;
  exact?: boolean;
  instant?: boolean;
  // Making sure only the first match is rendered
  // (should probably use context for this)
  _firstMatch?: boolean;
};

export const TIMEOUTS = {
  enter: 350,
  exit: 250,
};

export function getTimeout(status: string) {
  if (status === 'entering') return TIMEOUTS.enter;
  if (status === 'exiting') return TIMEOUTS.exit;
  return 0;
}

export default function TransitionRoute({
  path,
  children,
  state,
  exact,
  instant,
  routeMatcher = matchPathOrState,
  _firstMatch = false,
}: TransitionRouteProps) {
  const location = useLocation();
  const match = _firstMatch ? routeMatcher(location, path, state, exact) : null;
  const paramsRef = useRef(match?.params);
  const locationRef = useRef(location);

  const key = match ? path + '#' + Object.values(match.params).join('-') : null;

  const prevKeyRef = useRef<string | null>(null);

  const [{ status, isMounted }, toggle] = useTransition({
    timeout: TIMEOUTS,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true,
  });

  useEffect(() => {
    if (instant) {
      return;
    }
    const to = setTimeout(() => {
      paramsRef.current = match?.params;
    }, TIMEOUTS.exit);

    return () => {
      clearTimeout(to);
      paramsRef.current = match?.params;
    };
  }, [match?.params, instant]);

  useEffect(() => {
    if (instant) {
      return;
    }
    const to = setTimeout(() => {
      locationRef.current = location;
    }, TIMEOUTS.exit);

    return () => {
      clearTimeout(to);
      locationRef.current = location;
    };
  }, [location, instant]);

  // out-in transition (fade out previous screen, then fade in new screen)
  useEffect(() => {
    if (instant) {
      return;
    }
    if (prevKeyRef.current && key && prevKeyRef.current !== key) {
      toggle(false);
      const to = setTimeout(() => {
        toggle(true);
      }, TIMEOUTS.exit);
      prevKeyRef.current = key;
      return () => clearTimeout(to);
    } else if (!prevKeyRef.current && key) {
      toggle(true);
    } else if (prevKeyRef.current && !key) {
      toggle(false);
    }
    prevKeyRef.current = key;
    return;
  }, [toggle, key, instant]);

  if (instant) {
    return key ? <>{children}</> : null;
  }

  const contextStatus = status === 'entered' && !match ? 'exiting' : status;

  const context = {
    status: contextStatus,
    timeout: getTimeout(contextStatus),
    params:
      (contextStatus === 'entered' ? match?.params : paramsRef.current) ?? {},
    location: contextStatus === 'entered' ? location : locationRef.current,
    instant,
  };

  return isMounted ? (
    <TransitionContext.Provider value={context}>
      {children}
    </TransitionContext.Provider>
  ) : null;
}
