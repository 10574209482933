import { replace } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { getPrices } from '../../actions/subscriptions';
import StripeProvider from '../../components/StripeProvider';
import SubscriptionContent from '../../components/SubscriptionContent';
import { useMobileQuery } from '../../components/responsive';
import isWebglAvailable from '../../core/isWebglAvailable';
import useAvatarSettings from '../../core/useAvatarSettings';
import { DefaultSubscriptionPros } from '../../features/Subscription/components/DefaultSubscriptionPros';
import { DEFAULT_SUBSCRIPTION_TITLE } from '../../features/Subscription/consts';
import { MetricsEvents, Routes } from '../../types/enums';
import useApi from '../../utils/useApi';
import useLogEventFirstRender from '../../utils/useLogEventFirstRender';
import { AuthFormWithAvatar } from './AuthLayout';
import { usePrepareMainRouteAfterOnboarding } from './usePrepareMainRouteAfterOnboarding';

const FORCE_PAYPAL_ON_SUBSCRIPTION = true;

export default function SignupSubscription() {
  const dispatch = useDispatch();

  const webglAvailable = isWebglAvailable();

  useLogEventFirstRender(MetricsEvents.SubscriptionScreenOpened, {
    cause: 'onboarding',
    avatar: webglAvailable ? '3d' : '2d',
  });

  const prepareMainRouteAfterOnboarding = usePrepareMainRouteAfterOnboarding();

  const handleClose = () => {
    dispatch(replace(Routes.Chat));
    prepareMainRouteAfterOnboarding();
  };

  const stripeEnabled = useSelector(
    (state) => state.subscriptions.stripeEnabled,
  );
  const stripeSubscriptionParams = useApi(
    (state) => state.subscriptions.persist.stripeSubscriptionParams,
    getPrices,
    { memoDeepEqual: true },
  );

  const isMobile = useMobileQuery();

  useAvatarSettings({
    hidden: isMobile,
    background: 'default',
    cameraSlot: 'desktop_subscription',
    // we won't have a greeting animation here (since onboarding set is limited),
    // but we don't want to risk browser crashing before onboarding is finished
    baseBundleSet: 'onboarding',
  });

  return (
    <StripeProvider>
      <StyledAuthForm
        forwardedAs="div"
        // FIXME: use composable layout
        innerStyle={{ padding: 0 }}
      >
        {stripeEnabled && (
          <>
            <StyledSubscriptionContent
              title={DEFAULT_SUBSCRIPTION_TITLE}
              subtitle={<DefaultSubscriptionPros />}
              stripeSubscriptions={
                stripeSubscriptionParams?.available_subscriptions ?? null
              }
              uiParams={stripeSubscriptionParams?.ui_params}
              onClose={handleClose}
              cause="onboarding"
              paypalDisabled={!FORCE_PAYPAL_ON_SUBSCRIPTION}
            />
          </>
        )}
      </StyledAuthForm>
    </StripeProvider>
  );
}

const StyledAuthForm = styled(AuthFormWithAvatar)`
  align-items: center;
`;

const StyledSubscriptionContent = styled(SubscriptionContent)`
  padding: 0 20px 20px;
  width: 100%;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: auto;
  }
`;
