import { Location } from 'history';
import { matchPath } from 'react-router-dom';
import matchPathOrState from '../TransitionRoutes/matchPathOrState';

export default function matchModalRoutes(
  location: Location,
  pathToMatch: string,
  stateToMatch: Object | undefined,
  exact?: boolean,
) {
  const match = matchPathOrState(location, '*', stateToMatch);

  if (!match) return null;

  const modalRoute = new URLSearchParams(location.search).get('modal');

  if (!modalRoute) return null;

  return matchPath(modalRoute, { path: pathToMatch, exact });
}
