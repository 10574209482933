import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components/macro';

type Props = {
  rowCount?: number;
  maxColCount?: number;
  className?: string;
};

const CardStab = ({ rowCount = 2, maxColCount = 10, className }: Props) => {
  const [colCount, setColCount] = useState(maxColCount);
  const ref = useRef<HTMLDivElement>(null);

  const calculateColCount = useCallback(() => {
    if (!ref.current) return;
    let children = ref.current.childNodes;
    let cols = 1;
    let firstOffset: null | number = null;

    // find the item with a different offsetTop than the first item,
    // that will be the number of columns
    for (let i = 0; i < children.length; i++) {
      let node = children[i] as HTMLElement;
      if (firstOffset == null) {
        firstOffset = node.getBoundingClientRect().top;
      } else {
        let offset = node.getBoundingClientRect().top;
        if (offset > firstOffset) {
          break;
        } else {
          cols = i + 1;
        }
      }
    }
    if (colCount !== cols) {
      setColCount(cols);
    }
  }, [colCount]);

  useLayoutEffect(() => {
    calculateColCount();

    const handleResize = () => {
      setColCount(maxColCount);
      calculateColCount();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [calculateColCount, maxColCount]);

  const stabArray: JSX.Element[] = [];
  for (let i = 0; i < rowCount * colCount; i++) {
    stabArray.push(<Stab key={i} style={{ animationDelay: i * 83 + 'ms' }} />);
  }

  return (
    <CardStabRoot ref={ref} className={className}>
      {stabArray}
    </CardStabRoot>
  );
};

const CardStabRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
`;

const blinkAnimation = keyframes`
  0% {
    opacity: 0.1;
  }

  35% {
    opacity: 0.05;
  }

  68% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.1;
  }
`;

const Stab = styled.div`
  width: 163px;
  height: 208px;
  border: none;
  border-radius: 19px;
  background: rgba(255, 255, 255);
  opacity: 0.1;
  animation: ${blinkAnimation} 1.9s linear infinite;
`;

export default CardStab;
