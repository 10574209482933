import MemoryRecordAddModal from 'src/features/Memory/Components/MemoryModals/MemoryRecordAddModal';
import MemoryRecordEditModal from 'src/features/Memory/Components/MemoryModals/MemoryRecordEditModal';
import { useMobileQuery } from '../../components/responsive';
import { ModalRoutes } from '../../types/enums';
import Purchase from '../Wallet/Purchase';
import WalletItemSection from '../Wallet/WalletItemSection';
import ModalRouteMatch, { ModalRoute } from './ModalRoutes/ModalRouteMatch';
import TransitionModalRoute, {
  getModalRouteMatcher,
} from './ModalRoutes/TransitionModalRoute';
import ModalScreenLayout from './ModalScreenLayout';
import settingsRoutes from './SettingsModal/settingsRoutes';
import StoreBasketModal from './StoreBasketModal';
import StoreDialogItemModal from './StoreDialogItemModal';
import TransitionRoutes from './TransitionRoutes';

// Transitions on mobile are instant for now, otherwise we'd need to implement
// nested transition routing, which would be tricky
export default function Modals() {
  const isMobile = useMobileQuery();
  return (
    <TransitionRoutes
      root={ModalScreenLayout}
      getRouteMatcher={getModalRouteMatcher}
      instant={isMobile}
    >
      {settingsRoutes(isMobile)}
      <TransitionModalRoute instant={isMobile} path={ModalRoutes.ModalRoot}>
        <ModalRouteMatch>
          <ModalRoute path={ModalRoutes.StoreCategoryTabItem} exact>
            <StoreDialogItemModal />
          </ModalRoute>
          <ModalRoute path={ModalRoutes.StoreBasket} exact>
            <StoreBasketModal />
          </ModalRoute>
          <ModalRoute path={ModalRoutes.Wallet} exact>
            <WalletItemSection />
          </ModalRoute>
          <ModalRoute path={ModalRoutes.WalletPurchase} exact>
            <Purchase />
          </ModalRoute>
          <ModalRoute path={ModalRoutes.MemoryRecordAdd}>
            <MemoryRecordAddModal />
          </ModalRoute>
          <ModalRoute path={ModalRoutes.MemoryRecordEdit}>
            <MemoryRecordEditModal />
          </ModalRoute>
        </ModalRouteMatch>
      </TransitionModalRoute>
    </TransitionRoutes>
  );
}
