const sleep = (t: number) => new Promise((r) => setTimeout(r, t));

export default async function retry(
  fn: () => Promise<any>,
  timeout: number = 3 * 1000, // 3s
  maxTime: number = 3 * 60 * 1000, // 3min
) {
  const startTime = Date.now();
  let timeoutModifier = 1;

  while (Date.now() - startTime < maxTime) {
    try {
      const res = await fn();
      if (res) return res;
    } catch {}
    await sleep(timeout * timeoutModifier);
    timeoutModifier *= 1.5;
  }

  throw new Error(`Error while sending request`);
}
