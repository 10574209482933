import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import Picture from '../components/Picture';
import { MAIN_PAGE_URL_BASE } from '../utils/uri';
import CircleSpinner from './CircleSpinner';

const IMG_POSITION_CSS = {
  default_web: {
    mobile: css`
      height: 45vh;
      height: 45svh;
      margin: 10vh auto -10vh;
    `,
    tablet: css`
      height: min(85vh, 85vw);
      min-height: 500px;
      margin-top: calc(11vh + max(0px, 50vh - 50vw));
      margin-left: 45%;
      transform: translateX(-100%);
    `,
  },
  room: {
    mobile: css`
      height: 65vh;
      height: 65svh;
      margin: 12vh auto -12vh;
    `,
    tablet: css`
      height: min(65vh, 65vw);
      min-height: 300px;
      margin-top: calc(18vh + max(0px, 50vh - 50vw));
      margin-left: 50%;
      transform: translateX(-50%);
    `,
  },
  store: {
    mobile: css`
      height: 45vh;
      height: 45svh;
      margin: 10vh auto -10vh;
    `,
    tablet: css`
      height: min(85vh, 85vw);
      min-height: 500px;
      margin-top: calc(11vh + max(0px, 50vh - 50vw));
      margin-left: calc(50% - min(32vh, 32vw));
      transform: translateX(-50%);
    `,
  },
  chat: {
    mobile: css`
      display: none;
    `,
    tablet: css`
      display: flex;
      height: min(80vh, 80vw);
      min-height: 300px;
      margin-top: calc(12vh + max(0px, 50vh - 50vw));
      margin-left: calc(8vw - 5vh);
    `,
  },
  deleteAccount: {
    mobile: css`
      height: 150vh;
      height: 150svh;
      width: 100vw;
      object-fit: cover;
      margin: 10vh auto -10vh;
    `,
    tablet: css`
      width: auto;
      height: min(85vh, 85vw);
      min-height: 500px;
      margin-top: calc(11vh + max(0px, 50vh - 50vw));
      margin-left: calc(50% - min(32vh, 32vw));
      transform: translateX(-50%);
    `,
  },
} as const;

type FallbackPosition = keyof typeof IMG_POSITION_CSS;

// FIXME: take into account corresponding camera slots
export default function AvatarFallback({
  className,
  avatarType,
  avatarImageBaseUrl,
  fallbackPosition = 'default_web',
}: {
  className?: string;
  avatarType: string | null;
  avatarImageBaseUrl?: string | null;
  fallbackPosition?: FallbackPosition;
}) {
  const avatarStatus = useSelector((state) => state.avatars.avatarStatus);

  if (!avatarType) {
    return (
      <AvatarFallbackRoot
        className={className}
        $fallbackPosition={fallbackPosition}
      />
    );
  }

  const urlBase =
    avatarImageBaseUrl ??
    `${MAIN_PAGE_URL_BASE}/avatars/v3/Avatar_type_${avatarType.replace(
      /^avatar_/,
      '',
    )}.png`;

  return (
    <AvatarFallbackRoot
      className={className}
      $fallbackPosition={fallbackPosition}
    >
      {avatarStatus !== 'idle' ? (
        <StyledSpinnerContainer>
          <StyledSpinner />
        </StyledSpinnerContainer>
      ) : (
        <Picture width={720} height={1000} src={urlBase} />
      )}
    </AvatarFallbackRoot>
  );
}

export function CurrentAvatarFallback({
  className,
  fallbackPosition,
}: {
  className?: string;
  fallbackPosition?: FallbackPosition;
}) {
  const avatarType = useSelector(
    (state) => state.profile.persist.bot?.avatar_v2?.avatar_type,
  );

  return (
    <AvatarFallback
      className={className}
      avatarType={avatarType ?? null}
      fallbackPosition={fallbackPosition}
    />
  );
}

const StyledSpinner = styled(CircleSpinner)`
  width: 100px;
  height: 100px;
`;

const StyledSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 330 / 690;
`;

const AvatarFallbackRoot = styled.div<{ $fallbackPosition: FallbackPosition }>`
  width: 100%;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;

  filter: ${(p) => p.theme.avatarFallbackFilter};

  & img {
    display: block;
    width: auto;
  }

  & img,
  & ${StyledSpinnerContainer} {
    ${(p) => IMG_POSITION_CSS[p.$fallbackPosition].mobile}
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    & img,
    & ${StyledSpinnerContainer} {
      ${(p) => IMG_POSITION_CSS[p.$fallbackPosition].tablet}
    }
  }
`;
