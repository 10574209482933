import styled from 'styled-components/macro';
import CircleSpinner from '../components/CircleSpinner';
import { AriaReadableH1 } from './AriaReadable';

type Props = { className?: string };

const LoadingPage = ({ className }: Props) => {
  return (
    <LoadingPageRoot className={className}>
      <AriaReadableH1>Loading Replika</AriaReadableH1>
      <SpinnerContainer>
        <StyledSpinner />
      </SpinnerContainer>
    </LoadingPageRoot>
  );
};

export default LoadingPage;

const LoadingPageRoot = styled.main`
  background: ${(p) => p.theme.bgColor};
  height: 100vh;
  height: 100svh;
  min-height: 100vh;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SpinnerContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSpinner = styled(CircleSpinner)`
  width: 100px;
  height: 100px;
  color: ${(p) => p.theme.dimmedFgColor};
`;
