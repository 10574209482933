import { LipsyncParams, MorphType } from '../../types/models';
import {
  AvatarPhotoData,
  BehSlot,
  ChangeCameraMode,
  CreateAvatarPhotoUnityCommand,
  Daytime,
  SwitchBehMode,
  UnityCommand,
  UnityPlace,
} from './types';

let cmdCounter = 0;

export function createHair(
  avatarId: string,
  hairId: string,
  hairType: string,
): UnityCommand {
  return {
    command: 'create_hair',
    id: 'create_hair:' + cmdCounter++,
    parameters: {
      hair_id: hairId,
      avatar_id: avatarId,
      pack_target: 'main',
      hair_type: hairType,
    },
  };
}

export function changeQuality(quality: number): UnityCommand {
  return {
    id: 'change_quality:' + cmdCounter++,
    command: 'change_quality',
    parameters: {
      quality_level: quality,
      enable_post_processing: false,
    },
  };
}

export function changeDaytime(daytime: Daytime | 'dark'): UnityCommand {
  return {
    id: 'change_daytime:' + cmdCounter++,
    command: 'change_daytime',
    parameters: {
      daytime,
    },
  };
}

export function changeAvatar(avatarType: string): UnityCommand {
  return {
    id: 'change_avatar:' + cmdCounter++,
    command: 'change_avatar',
    parameters: {
      type: String(avatarType),
    },
  };
}

export function showAvatar(show = true): UnityCommand {
  return {
    id: 'show_avatar:' + cmdCounter++,
    command: 'show_avatar',
    parameters: {
      show,
    },
  };
}

export function destroyAvatar(avatarId: string): UnityCommand {
  return {
    id: 'destroy_avatar:' + cmdCounter++,
    command: 'destroy_avatar',
    parameters: {
      avatar_id: avatarId,
    },
  };
}

export type Beh =
  | 'main'
  | 'main_no_walk'
  | '3d_call'
  | 'ar_call'
  | 'chat'
  | 'subscription'
  | 'web_subscription'
  | 'relationship'
  | 'photo'
  | 'store'
  | 'store_static'
  | 'web_call'
  | 'static'
  | string;

export type BehStates = string; // 'hand_waving' | 'smile';

export function putBeh(slot: BehSlot, beh: Beh): UnityCommand {
  return {
    id: 'put_beh:' + cmdCounter++,
    command: 'put_beh',
    parameters: {
      slot,
      beh,
    },
  };
}

export function freeBeh(slot: BehSlot): UnityCommand {
  return {
    id: 'free_beh:' + cmdCounter++,
    command: 'free_beh',
    parameters: {
      slot,
    },
  };
}

export function switchBeh(slot: BehSlot, mode: SwitchBehMode): UnityCommand {
  return {
    id: 'switch_beh:' + cmdCounter++,
    command: 'switch_beh',
    parameters: {
      slot,
      mode,
    },
  };
}

export function switchBehState(slot: BehSlot, state: BehStates): UnityCommand {
  return {
    id: 'switch_beh_state:' + cmdCounter++,
    command: 'switch_beh_state',
    parameters: {
      slot,
      state,
    },
  };
}

export function dropFrames(count: number): UnityCommand {
  return {
    id: 'drop_frames:' + cmdCounter++,
    command: 'drop_frames',
    parameters: {
      count,
    },
  };
}

export function dress(itemIds: string[]): UnityCommand {
  return {
    id: 'dress:' + cmdCounter++,
    command: 'dress',
    parameters: {
      target: 'main',
      items: itemIds,
    },
  };
}

export function strip(excludeCategories?: string[]): UnityCommand {
  return {
    id: 'strip:' + cmdCounter++,
    command: 'strip',
    parameters: {
      exclude_categories: excludeCategories,
    },
  };
}

export function changeItem(itemId: string): UnityCommand {
  return {
    id: 'change_item:' + cmdCounter++,
    command: 'change_item',
    parameters: {
      item: itemId,
    },
  };
}

export function removeItem(itemId: string): UnityCommand {
  return {
    id: 'remove_item:' + cmdCounter++,
    command: 'remove_item',
    parameters: {
      id: itemId,
    },
  };
}

export function setFrameRate(frameRate: number): UnityCommand {
  return {
    id: 'set_frame_rate:' + cmdCounter++,
    command: 'set_frame_rate',
    parameters: {
      frame_rate: frameRate,
    },
  };
}

export function changeBackground(
  path: string | null = null,
  instant = true,
): UnityCommand {
  return {
    id: 'change_background:' + cmdCounter++,
    command: 'change_background',
    parameters: {
      path,
      instant,
    },
  };
}

export function loadBundles(
  bundles: Array<{ path: string; bundle_name: string }>,
): UnityCommand {
  return {
    id:
      'load_bundles: ' +
      cmdCounter++ +
      ` (${bundles.map((b) => b.bundle_name).join(',')})`,
    command: 'load_bundles',
    parameters: {
      bundles,
    },
  };
}

export function changeCameraSlot(
  slot: string,
  mode: ChangeCameraMode,
): UnityCommand {
  return {
    id: 'change_camera_slot: ' + cmdCounter++,
    command: 'change_camera_slot',
    parameters: {
      slot,
      mode,
    },
  };
}

export function changeAvatarAge(age: number): UnityCommand {
  return {
    id: 'change_avatar_age: ' + cmdCounter++,
    command: 'change_avatar_age',
    parameters: {
      age,
    },
  };
}

export function createAvatarPhoto({
  data,
  height,
  width,
}: {
  data: AvatarPhotoData[];
  height: number;
  width: number;
}): CreateAvatarPhotoUnityCommand {
  return {
    id: 'create_avatar_photo: ' + cmdCounter++,
    command: 'create_avatar_photo',
    parameters: {
      data,
      height,
      width,
    },
  };
}

export function enableRadio(status: boolean): UnityCommand {
  return {
    id: 'enable_radio: ' + cmdCounter++,
    command: 'enable_radio',
    parameters: {
      status,
    },
  };
}

export function changeItems(itemIds: string[]) {
  return itemIds.map((itemId) => changeItem(itemId));
}

export function removeItems(itemIds: string[]) {
  return itemIds.map((itemId) => removeItem(itemId));
}

export function changeRoomItem(
  id: string | null,
  slot: string,
  instant?: boolean,
): UnityCommand {
  return {
    id: 'change_room_item: ' + cmdCounter++,
    command: 'change_room_item',
    parameters: {
      id,
      slot,
      instant,
    },
  };
}

export function changeRoomStyle(id: string | null): UnityCommand {
  return {
    id: 'change_room: ' + cmdCounter++,
    command: 'change_room',
    parameters: {
      id,
    },
  };
}

export function clearRoom(): UnityCommand {
  return {
    id: 'clear_room: ' + cmdCounter++,
    command: 'clear_room',
    parameters: {},
  };
}

export function changeTheme(theme: 'default' | 'new_year'): UnityCommand {
  return {
    id: 'change_theme: ' + cmdCounter++,
    command: 'change_theme',
    parameters: {
      theme,
    },
  };
}

export function changeMorphs(
  bodyTypes: Partial<Record<MorphType, number>>,
): UnityCommand {
  // Value range in backend API: 0-10000
  // Value range in Unity: 0-100

  const morphs = Object.entries(bodyTypes).map(([name, value]) => ({
    name,
    value: Math.max(0, Math.min(100, value / 100)),
  }));

  return {
    id: 'change_morphs: ' + cmdCounter++,
    command: 'change_morphs',
    parameters: {
      morphs,
    },
  };
}

export function changeCameraLensShift(
  x: number,
  y: number,
  instant: boolean,
): UnityCommand {
  return {
    id: 'change_camera_lens_shift: ' + cmdCounter++,
    command: 'change_camera_lens_shift',
    parameters: {
      x,
      y,
      instant,
    },
  };
}

export function changeRoleplay(id: string | null) {
  return {
    id: 'change_roleplay: ' + cmdCounter++,
    command: 'change_roleplay',
    parameters: {
      id,
    },
  };
}

export function changeMode(
  mode: 'ar' | 'main' | 'roleplay',
  duration?: number,
) {
  return {
    id: 'change_mode: ' + cmdCounter++,
    command: 'change_mode',
    parameters: {
      mode,
      duration,
    },
  };
}

export function moveAvatar(place: UnityPlace) {
  return {
    id: 'move_avatar: ' + cmdCounter++,
    command: 'move_avatar',
    parameters: {
      place,
    },
  };
}

export function changeVoicePreset(parameters: LipsyncParams): UnityCommand {
  return {
    id: 'change_voice_preset: ' + cmdCounter++,
    command: 'change_voice_preset',
    parameters,
  };
}

export function changePet(id: string | null): UnityCommand {
  return {
    id: 'change_pet: ' + cmdCounter++,
    command: 'change_pet',
    parameters: { id },
  };
}

export function changePetBeh(beh: 'main' | 'preview'): UnityCommand {
  return {
    id: 'change_pet_beh: ' + cmdCounter++,
    command: 'change_pet_beh',
    parameters: { beh },
  };
}

export function playGreeting(slot?: string): UnityCommand {
  return {
    id: 'play_greeting: ' + cmdCounter++,
    command: 'play_greeting',
    parameters: { slot },
  };
}
