import { replace } from 'connected-react-router';
import { useInputField } from 'form-atoms';
import { useAtom } from 'jotai';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { Routes } from '../../components/Buttons';
import useAvatarSettings from '../../core/useAvatarSettings';
import { AuthFormWithAvatar, SubmitButton } from './AuthLayout';
import { replikaNameAtom, replikaPronounsAtom } from './atoms';

const PRONOUNS = {
  male: 'his',
  female: 'her',
  'non-binary': 'their',
};

export default function SignupUniquelyYours() {
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(replace(Routes.SignupReplikaLook));
  };

  const replikaNameField = useInputField(replikaNameAtom);
  const [replikaPronouns] = useAtom(replikaPronounsAtom);

  useAvatarSettings({
    hidden: false,
    background: 'onboarding',
    cameraSlot: 'desktop_onboarding_face',
    baseBundleSet: 'onboarding',
  });

  return (
    <StyledAuthForm onSubmit={handleSubmit}>
      <h1>Uniquely yours</h1>
      <Description>
        {`Make ${replikaNameField.props.value} stand out by customizing ${PRONOUNS[replikaPronouns]} look, outfit, and
        personality. You'll be able to change your selection anytime.`}
      </Description>
      <SubmitButton type="submit">Continue</SubmitButton>
    </StyledAuthForm>
  );
}

const StyledAuthForm = styled(AuthFormWithAvatar)`
  align-items: center;
  text-align: center;
`;

const Description = styled.p`
  font-family: ${(p) => p.theme.fonts.display};
  max-width: 500px;
  margin: 0 0 40px;
  font-size: 20px;
  line-height: 24px;
`;
