import styled from 'styled-components/macro';
import Scrollable from './Scrollable';

/**
 * TranslucentBox is a container that has a blurred background.
 *
 * Usage example:
 *
 * ```
 * <TranslucentBox.Root>
 *   <TranslucentBox.Background />
 *   <TranslucentBox.Content>
 *     {...}
 *   </TranslucentBox.Content>
 * </TranslucentBox.Root>
 * ```
 */
const TranslucentBoxRoot = styled.div`
  position: relative;
  isolation: isolate;
`;

const TranslucentBoxBackground = styled.div`
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: inherit;
  background: ${(p) => p.theme.bgColor};

  @media ${(p) => p.theme.breakpoints.tablet} {
    background: rgba(0 0 0 / 20%);

    @supports (backdrop-filter: blur(25px)) {
      background: rgba(0 0 0 / 15%);
      backdrop-filter: blur(25px);
    }
  }
`;

// overflow: hidden; can break backdrop-filter
const DesktopTranslucentPanel = styled(TranslucentBoxRoot)`
  @media ${(p) => p.theme.breakpoints.tablet} {
    border-radius: 24px;
    /* overflow: hidden; */
  }
`;

function TranslucentBoxContent({
  scrollable,
  ...rest
}: { scrollable?: boolean } & React.HTMLAttributes<HTMLDivElement>) {
  return scrollable ? (
    <TranslucentBoxContentScrollableRoot {...rest} />
  ) : (
    <TranslucentBoxContentRoot {...rest} />
  );
}

const TranslucentBoxContentRoot = styled.div.attrs({
  'data-translucentbox': true,
})`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
`;

const TranslucentBoxContentScrollableRoot = styled(Scrollable).attrs({
  'data-translucentbox': true,
})`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const TranslucentBoxContentOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export {
  TranslucentBoxBackground as Background,
  TranslucentBoxContent as Content,
  DesktopTranslucentPanel as DesktopPanel,
  TranslucentBoxContentOverlay as Overlay,
  TranslucentBoxRoot as Root,
};
