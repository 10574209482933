import * as React from 'react';

type Props = {
  id: string;
  className?: string;
  canvasId: string;
  children?: React.ReactNode;
  priority: number;
  loading?: boolean;
};

const priorityMap: Record<string, number> = {};

let lastCanvasSize: string | null = null;

function moveCanvas(canvasId, priority, ref, result) {
  let el = document.querySelector('#' + canvasId);

  if (!(el instanceof HTMLElement)) {
    setTimeout(() => moveCanvas(canvasId, priority, ref, result), 10);
    return;
  }

  const maxPriority = Math.max(...Object.values(priorityMap)) ?? 0;
  if (priority < maxPriority) return;

  let canvasEl = el;

  let parent = canvasEl.parentNode;

  let width = canvasEl.offsetWidth;
  let height = canvasEl.offsetHeight;
  let placeholder = document.createElement('div');
  placeholder.style.width = width + 'px';
  placeholder.style.height = height + 'px';

  let canvasSize = width + 'x' + height;

  let needsRefresh = canvasSize !== lastCanvasSize;

  if (needsRefresh) canvasEl.style.visibility = 'hidden';
  ref.current.replaceChildren(canvasEl);
  parent?.appendChild(placeholder);

  result.callback = () => {
    parent?.removeChild(placeholder);
    parent?.appendChild(canvasEl);
  };

  if (needsRefresh) {
    setTimeout(() => {
      canvasEl.style.visibility = 'visible';
    }, 10);
  }

  lastCanvasSize = canvasSize;
}

// This component grabs a canvas by id from wherever it is on the page
// and appends it as its child
// This way we don't have to re-instatiate Unity if we need it somewhere else (like in a dialog)
function CanvasView({
  id,
  priority,
  className,
  canvasId,
  children,
  loading,
}: Props) {
  const ref = React.useRef<HTMLDivElement>(null);
  const fallbackRef = React.useRef(children);
  const canvasMovedRef = React.useRef(false);

  React.useEffect(() => {
    priorityMap[id] = priority;

    return () => {
      delete priorityMap[id];
    };
  }, [id, priority]);

  React.useEffect(() => {
    if (!ref.current || loading) return;

    let result: { callback: any } = { callback: null };

    moveCanvas(canvasId, priority, ref, result);

    canvasMovedRef.current = true;

    return () => {
      if (!result.callback) return;

      result.callback();
      canvasMovedRef.current = false;
    };
  }, [canvasId, priority, loading]);

  return (
    <div className={className} ref={ref}>
      {fallbackRef.current}
    </div>
  );
}

export default React.memo(CanvasView);

// Since we a cheating (i.e. breaking React rendering logic),
// React shouldn't find out that canvas, grabbed by CanvasView, is not in its place.
// So we wrap it in div to guarantee that canvas (or it's siblings) is not changed.
function WrappedCanvasWithRef(
  props: React.HTMLAttributes<HTMLCanvasElement>,
  ref: React.Ref<HTMLCanvasElement>,
) {
  const { className, style, ...rest } = props;
  return (
    <div className={className} style={{ overflow: 'hidden', ...style }}>
      <canvas ref={ref} style={{ width: '100%', height: '100%' }} {...rest} />
    </div>
  );
}

export const WrappedCanvas = React.memo(React.forwardRef(WrappedCanvasWithRef));
