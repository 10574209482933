import { ReactNode, useEffect, useState } from 'react';
import { PopupboxManager } from 'react-popupbox';
import styled from 'styled-components/macro';
import ApiImageLoader from '../components/ApiImageLoader';
import { TranslusentIconButton } from '../components/Buttons';
import RandomBlurredImage from '../components/RandomBlurredImage';
import UnblurImagePanel from '../components/UnblurImagePanel';
import { ReactComponent as ArrowIcon } from '../icons/ArrowBack.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { BlurredMessageType } from '../types/models';
import { useBlurredImageDialog } from '../utils/useBlurredImage';

export type Image = {
  url: string;
  title?: string;
  subtitle?: string;
  blurred?: boolean;
  blurredType?: BlurredMessageType;
  isRomantic: boolean;
  additionalInfo?: unknown;
};

export default function PopupBoxContent({
  images,
  index,
  onClose,
  fullscreen = false,
  customFooter = null,
  onUnlockClick,
}: {
  images: Image[];
  index?: number;
  onClose: () => void;
  fullscreen?: boolean;
  customFooter?: ((props: { image: Image }) => ReactNode) | null;
  onUnlockClick?: () => void;
}) {
  const [current, setCurrent] = useState(index ?? 0);
  const image = images[current];

  // We wouldn't need this if react-popupbox API wasn't so awkward
  useEffect(() => {
    const handleOpenChange = ({ show }) => {
      if (!show || index === undefined) return;
      setCurrent(index);
    };
    PopupboxManager.addChangeListener(handleOpenChange);

    return () => {
      PopupboxManager.removeChangeListener(handleOpenChange);
    };
  }, [index]);

  const { shouldBlur, triggerDialog } = useBlurredImageDialog(
    image ? [image] : [],
  );

  if (!image) return null;

  const showPrev = current > 0;
  const showNext = current < images.length - 1 && images.length > 1;

  const imageBlurred = image.blurred && shouldBlur;

  const handleUnlockClick = () => {
    onUnlockClick?.();

    triggerDialog();
  };

  return (
    <ImageContainer onClick={onClose}>
      {showPrev && (
        <PrevButton
          onClick={(e) => {
            e.stopPropagation();
            setCurrent(current - 1);
          }}
          label="Previous image"
        >
          <ArrowIcon />
        </PrevButton>
      )}
      <ImageContent>
        {!fullscreen && (
          <Header>
            {image.title && <Title>{image.title}</Title>}
            {image.subtitle && <Subtitle>{image.subtitle}</Subtitle>}
          </Header>
        )}
        <CloseButton
          onClick={onClose}
          label="Close image"
          data-testid="picture-close-button"
        >
          <CloseIcon />
        </CloseButton>
        <ImageWrapper>
          {!imageBlurred ? (
            <ApiImageLoader src={image.url} key={current}>
              {(objectUrl, isImageLoaded) => {
                return (
                  <LightboxImage
                    data-adjustbrightness
                    src={objectUrl}
                    $fullscreen={fullscreen}
                  />
                );
              }}
            </ApiImageLoader>
          ) : (
            <BlurredLightboxImage data-adjustbrightness src={image.url} />
          )}
        </ImageWrapper>
        <Footer>
          {imageBlurred && (
            <UnblurImagePanel onUnlockClick={handleUnlockClick} />
          )}
          {!imageBlurred && !!customFooter && customFooter({ image })}
        </Footer>
      </ImageContent>
      {showNext && (
        <NextButton
          onClick={(e) => {
            e.stopPropagation();
            setCurrent(current + 1);
          }}
          label="Next image"
        >
          <ArrowIcon style={{ transform: 'scaleX(-1)' }} />
        </NextButton>
      )}
    </ImageContainer>
  );
}

const Header = styled.div`
  padding-top: 25px;
  text-align: center;
`;

const Footer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 14px;
  color: ${(p) => p.theme.fgColor};
`;

const Subtitle = styled.p`
  margin-block: 5px 0;
  font-size: 12px;
  color: ${(p) => p.theme.dimmedFgColor};
`;

const CloseButton = styled(TranslusentIconButton)`
  position: fixed;
  z-index: 1;

  top: 15px;
  right: 15px;

  & > svg {
    width: 26px;
    height: 26px;
  }

  @media (${(p) => p.theme.breakpoints.tablet}) {
    top: 20px;
    right: 20px;

    & > svg {
      width: 32px;
      height: 32px;
    }
  }
`;

const PrevButton = styled(TranslusentIconButton)`
  position: fixed;
  z-index: 1;

  left: 15px;

  & > svg {
    width: 26px;
    height: 26px;
  }

  @media (${(p) => p.theme.breakpoints.tablet}) {
    left: 20px;

    & > svg {
      width: 32px;
      height: 32px;
    }
  }
`;

const NextButton = styled(TranslusentIconButton)`
  position: fixed;
  z-index: 1;

  right: 15px;

  & > svg {
    width: 26px;
    height: 26px;
  }

  @media (${(p) => p.theme.breakpoints.tablet}) {
    right: 20px;

    & > svg {
      width: 32px;
      height: 32px;
    }
  }
`;

const ImageContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const ImageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  gap: 20px;
`;

const ImageWrapper = styled.div`
  min-width: 100%;
  overflow: hidden;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
`;

const LightboxImage = styled.img<{ $fullscreen: boolean }>`
  max-width: ${(p) =>
    p.$fullscreen ? '100vw' : 'calc(min(80svh, 100vw - 60px))'};
  max-height: ${(p) => (p.$fullscreen ? '100svh' : '80svh')};
  object-fit: cover;

  border-radius: ${(p) => (p.$fullscreen ? 0 : '14px')};

  @media (${(p) => p.theme.breakpoints.tablet}) {
    max-height: 80svh;
    max-width: calc(min(80svh, 100svw - 60px));
    border-radius: 24px;
  }
`;

const BlurredLightboxImage = styled(RandomBlurredImage)`
  height: 100%;
  width: 50vh;
  min-width: 100%;
  border-radius: 14px;
  overflow: hidden;

  @media (${(p) => p.theme.breakpoints.tablet}) {
    border-radius: 24px;
  }
`;
