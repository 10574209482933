import { Pronouns, PronounsValues } from '../types/enums';
import RadioButtonSelector from './RadioButtonSelector';
import { useMobileQuery } from './responsive';

type Props = {
  id: string;
  value: PronounsValues;
  onChange: (value: PronounsValues) => void;
  autoFocus?: boolean;
  buttonTheme?: 'outline' | 'solid' | 'square' | 'darkSolid';
};

export const PRONOUN_MAP = {
  [PronounsValues.He]: Pronouns.HeHim,
  [PronounsValues.She]: Pronouns.SheHer,
  [PronounsValues.They]: Pronouns.TheyThem,
};

const PRONOUNS_OPTS = Object.keys(PronounsValues).map((key) => ({
  label: PRONOUN_MAP[PronounsValues[key]],
  value: PronounsValues[key] as PronounsValues,
}));

export default function PronounsSelector(props: Props) {
  const isMobile = useMobileQuery();

  return (
    <RadioButtonSelector
      variants={PRONOUNS_OPTS}
      layout={isMobile ? 'vertical' : 'horizontal-wrap'}
      {...props}
    />
  );
}
