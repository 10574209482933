const TOTAL_SECONDS = 3;
const COUNT_PER_SECOND = 4;
const BUFFER_SIZE = TOTAL_SECONDS * COUNT_PER_SECOND;

export default function fpsCounter(onFPSCalculated: (fps: number) => void) {
  let fps: number[] = [];
  let counter = 0;
  let index = 0;
  let cancelled = false;

  function refreshLoop() {
    if (cancelled) return;
    window.requestAnimationFrame(() => {
      counter++;
      refreshLoop();
    });
  }

  refreshLoop();

  const to1 = setInterval(() => {
    fps[index] = counter;
    counter = 0;
    index = (index + 1) % BUFFER_SIZE;
  }, 1000 / COUNT_PER_SECOND);

  const to2 = setInterval(() => {
    const averageFps =
      fps.reduce((acc, n) => acc + n / fps.length, 0) * COUNT_PER_SECOND;
    onFPSCalculated(Math.round(averageFps));
  }, TOTAL_SECONDS * 1000);

  return () => {
    cancelled = true;
    clearInterval(to1);
    clearInterval(to2);
  };
}
