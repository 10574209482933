import { useState } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as HamburgerIcon } from '../../icons/Hamburger.svg';
import MainMenu from '../../routes/Main/MainMenu';
import { TranslusentIconButton } from '../Buttons';

export const MobileMenuButton = ({ className }: { className?: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {isOpen ? (
        <MainMenu onClose={() => setIsOpen(false)} />
      ) : (
        <SettingsButton onClick={() => setIsOpen(true)} className={className}>
          <HamburgerIcon />
        </SettingsButton>
      )}
    </>
  );
};

const SettingsButton = styled(TranslusentIconButton)`
  flex-shrink: 0;
  & img {
    height: 28px;
    width: 28px;
  }
`;

export const ContentMenuButton = styled(MobileMenuButton)`
  background: rgba(255, 255, 255, 0.1);
`;
