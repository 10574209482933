import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { dismissNotification } from '../../actions/ui';
import { Notification } from '../../types/models';
import {
  CoinCounter,
  CoinIcon,
  GemCounter,
  GemIcon,
} from '../legacy/WalletIcons';
import ChatPopup from './ChatPopup';
import LevelUpPopupBg from './LevelUpPopupBg';
import PopupCloseButton from './PopupCloseButton';

type Props = {
  className?: string;
  notification: Notification | undefined;
  id: string | undefined;
  onClose: () => void;
};

type DismissCallback = (id: string) => void;

const NOTIFICATION_TIMEOUT = 4000;

function getNotificationContent(
  notification: Notification | undefined,
  onClose: () => void,
  dispatch: any,
) {
  if (!notification) {
    return null;
  }

  switch (notification.type) {
    case 'level_up':
      return (
        <BorderlessPopupRoot>
          <BorderlessPopupContent>
            <BorderlessPopupBg />
            <h2>LVL UP</h2>
            <p>Welcome to Level {notification.level}</p>
          </BorderlessPopupContent>
        </BorderlessPopupRoot>
      );

    case 'wallet_update':
      const { credit, earned } = notification;

      let icon;
      let text: string;

      if (earned.gems_count > 0) {
        icon = <StyledGemCounter count={earned.gems_count} />;
        text = `You received ${earned.gems_count} gems!`;
      } else {
        icon = <StyledCoinCounter count={earned.coins_count} />;
        text = `You received ${earned.coins_count} coins!`;
      }

      return (
        <Popup>
          {icon}
          <div>
            {text}
            <PopupSubtext>
              Now you have <CoinIcon /> {credit.coins_count} <GemIcon />{' '}
              {credit.gems_count}
            </PopupSubtext>
          </div>
        </Popup>
      );

    case 'warning':
      const { message, submessage } = notification;

      return (
        <WarningPopupRoot>
          <h2>{message}</h2>
          <p>{submessage}</p>
        </WarningPopupRoot>
      );
    default:
      return null;
  }
}

const WarningPopupRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 445px;
  max-width: 90vw;
  text-align: center;
  padding: 10px 20px 15px;
  border-radius: 24px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(25px);
  margin-top: 40px;

  h2,
  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
  }

  h2 {
    font-family: ${(p) => p.theme.fonts.display};
    opacity: 0.7;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    margin-top: 0px;
  }
`;

const BorderlessPopupRoot = styled.div`
  display: block;
  width: 100vw;

  &:before {
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    display: block;
    width: 100vw;
    height: calc(100% + 40px);
    background: linear-gradient(
      180deg,
      #203378 0%,
      rgba(32, 51, 120, 0.7) 52.08%,
      rgba(32, 48, 120, 0) 100%
    );
  }
`;

const BorderlessPopupBg = styled(LevelUpPopupBg)`
  position: absolute;
  inset: 0;
  pointer-events: none;
`;

const BorderlessPopupContent = styled.div`
  display: block;
  position: relative;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  padding: 20px 0;
  font-family: ${(p) => p.theme.fonts.display};
  color: #fff;
  font-size: 16px;
  line-height: 20px;

  &:hover {
    text-decoration: none;
  }

  h2,
  p {
    margin: 0;
    padding: 0;
  }

  h2 {
    color: #6ded72;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
    text-transform: uppercase;
    opacity: 0.7;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    max-width: 90vw;
    width: 520px;
  }
`;

const Popup = styled.div`
  display: flex;
  align-items: center;
  width: 270px;
  font-size: 14px;
  line-height: 18px;
  text-align: left;

  & > * ~ * {
    margin-left: 20px;
  }
`;

const StyledGemCounter = styled(GemCounter)`
  transform: scale(0.85);
  margin: -5px;
`;

const StyledCoinCounter = StyledGemCounter.withComponent(CoinCounter);

const PopupSubtext = styled.p`
  margin: 10px 0 0;
  color: ${(p) => p.theme.dimmedFgColor};

  & > img {
    width: 8px;
  }
`;

function NotificationPopup({ className, notification, id, onClose }: Props) {
  const [lastNotification, setLastNotification] = React.useState<null | {
    id: string;
    notification: Notification;
  }>(id && notification ? { id, notification } : null);

  const dismissRef = React.useRef<undefined | DismissCallback>(undefined);

  const dispatch = useDispatch();

  // keep dismissing callback relevant, while preventing timeout reset (see below)
  React.useEffect(() => {
    dismissRef.current = (notificationId: string) => {
      if (notificationId) {
        onClose();
        dispatch(dismissNotification(notificationId));
      }
    };
  }, [onClose, dispatch]);

  React.useEffect(() => {
    if (notification && id) {
      setLastNotification({ notification, id });
    }
  }, [notification, id]);

  React.useEffect(() => {
    const notificationType = lastNotification?.notification.type;
    if (
      notificationType === 'level_up' ||
      notificationType === 'wallet_update' ||
      notificationType === 'warning'
    ) {
      const t = setTimeout(() => {
        if (lastNotification?.id && dismissRef.current) {
          dismissRef.current(lastNotification?.id);
        }
      }, NOTIFICATION_TIMEOUT);

      return () => clearTimeout(t);
    }
    return;
  }, [lastNotification]);

  const content = getNotificationContent(
    lastNotification?.notification,
    () => {
      if (dismissRef.current && id) {
        dismissRef.current(id);
      }
    },
    dispatch,
  );

  const hasContent = !!content;

  // if no notification rendered (meaning it's of unknown type), automatically dismiss it
  React.useEffect(() => {
    if (dismissRef.current && lastNotification && !hasContent) {
      dismissRef.current(lastNotification.id);
    }
  }, [hasContent, lastNotification]);

  if (!lastNotification) {
    return <NotificationPopupRoot className={className} />;
  }

  if (
    ['warning', 'level_up'].indexOf(lastNotification.notification.type) !== -1
  ) {
    return content;
  }

  return (
    <NotificationPopupRoot className={className}>
      <PopupCloseButton
        onClick={() => {
          if (dismissRef.current && id) {
            dismissRef.current(id);
          }
        }}
      />
      {content}
    </NotificationPopupRoot>
  );
}

export default NotificationPopup;

const NotificationPopupRoot = styled(ChatPopup)`
  position: relative;
  min-width: 250px;
  max-width: 90vw;
  text-align: center;
  padding: 20px;
  margin-top: 20px;

  & > h2 {
    font-size: 16px;
    line-height: 20px;
    margin: 24px 0 0;
  }

  & > p {
    color: ${(p) => p.theme.dimmedFgColor};
    font-size: 14px;
    line-height: 18px;
    margin: 8px 32px 0;
  }
`;
