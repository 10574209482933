import { push, replace } from 'connected-react-router';
import { Dispatch } from 'redux';
import * as A from '../types/actions';
import { AsyncActionStatus } from '../types/asyncActions';
import {
  ActionTypes,
  AsyncActionTypes,
  MetricsEvents,
  Routes,
} from '../types/enums';
import { OauthSignInMethod } from '../types/models';
import { DA } from '../types/redux';
import { RootState } from '../types/states';
import { ApiResult, apiAction } from '../utils/asyncAction';
import { CAPABILITIES } from '../utils/constants';
import fetchOptions from '../utils/fetchOptions';
import { signIn } from '../utils/firebase';
import getUnityBundleVersion from '../utils/getUnityBundleVersion';
import isAuthSuccessResult from '../utils/isAuthSuccessResult';
import { logEvent } from '../utils/metrics';
import { API_BASE_URL } from '../utils/uri';
import { closeConnection } from '../utils/websocket';
import { getPersonalBot } from './profile';

export const handleLoginFieldsChange: (
  field: 'emailOrPhone' | 'password' | 'code',
  value: string,
) => A.LoginFieldChange = (field, value) => ({
  type: ActionTypes.LoginFieldChange,
  field,
  value,
});

export function setResendTimeout(seconds: number): A.SetCodeResendTimeout {
  return {
    type: ActionTypes.SetCodeResendTimeout,
    seconds,
  };
}

function startCountdown(s: number) {
  return (dispatch: Dispatch<A.AuthAction>) => {
    const cb = () => {
      dispatch<A.AuthAction>(setResendTimeout(s--));

      if (s >= 0) {
        setTimeout(cb, 1000);
      }
    };

    cb();
  };
}

export const checkAccountExistance =
  (emailOrPhone: string): DA<ApiResult<A.AccountExists>> =>
  async (dispatch, getState) => {
    const endpoint = `${API_BASE_URL}/auth/sign_in/actions/get_auth_type`;

    const fetchOpts = fetchOptions(getState(), 'POST', {
      id_string: emailOrPhone,
    });

    return apiAction<A.AccountExists>(
      AsyncActionTypes.AccountExists,
      dispatch,
      { emailOrPhone },
      {
        onRequest: () => fetch(endpoint, fetchOpts),
        onSuccess: (result: { auth_type: 'phone' | 'password' }) => {
          if (result.auth_type === 'phone') {
            sendSmsCode(emailOrPhone)(dispatch, getState);
            dispatch(push(Routes.LoginInputCode));
          } else {
            dispatch(push(Routes.LoginInputPassword));
          }
        },
      },
    );
  };

const sendSmsCode =
  (emailOrPhone: string): DA<ApiResult<A.SendSmsCode>> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'POST', {
      id_type: 'phone',
      id_string: emailOrPhone,
    });

    const endpoint = `${API_BASE_URL}/auth/sign_in/actions/send_sms_code`;

    return apiAction<A.SendSmsCode>(
      AsyncActionTypes.SendSmsCode,
      dispatch,
      { emailOrPhone },
      {
        onRequest: () => fetch(endpoint, fetchOpts),
        onSuccess: (result) => {
          startCountdown(result.retry_timeout)(dispatch);
        },
      },
    );
  };

const handleLoginSuccess = (dispatch, getState: () => RootState) => {
  getPersonalBot()(dispatch, getState);
};

export const sendPasswordLogin =
  (
    emailOrPhone: string,
    password: string,
    idType: 'email' | 'phone',
  ): DA<ApiResult<A.PasswordLogin>> =>
  async (dispatch, getState) => {
    const state = getState();

    const endpoint = `${API_BASE_URL}/auth/sign_in/actions/auth_by_password`;
    const fetchOpts = fetchOptions(state, 'POST', {
      id_type: idType,
      id_string: emailOrPhone,
      password,
      capabilities: CAPABILITIES,
      unity_bundle_version: getUnityBundleVersion(),
    });

    return apiAction<A.PasswordLogin>(
      AsyncActionTypes.PasswordLogin,
      dispatch,
      { emailOrPhone },
      {
        onRequest: () => fetch(endpoint, fetchOpts),
        onSuccess: () => handleLoginSuccess(dispatch, getState),
      },
    );
  };

export const sendTokenLogin =
  (single_use_token: string): DA<ApiResult<A.TokenLogin>> =>
  async (dispatch, getState) => {
    const state = getState();

    const endpoint = `${API_BASE_URL}/auth/sign_in/actions/auth_by_single_use_token`;
    const fetchOpts = fetchOptions(state, 'POST', {
      single_use_token,
      capabilities: CAPABILITIES,
      unity_bundle_version: getUnityBundleVersion(),
    });

    return apiAction<A.TokenLogin>(
      AsyncActionTypes.TokenLogin,
      dispatch,
      {},
      {
        onRequest: () => fetch(endpoint, fetchOpts),
        onSuccess: () => getPersonalBot(true)(dispatch, getState),
      },
    );
  };

export const recoverPassword =
  (
    emailOrPhone: string,
    idType: 'phone' | 'email' = 'email',
  ): DA<ApiResult<A.RecoverPassword>> =>
  async (dispatch, getState) => {
    const state = getState();

    const endpoint = `${API_BASE_URL}/auth/sign_in/actions/recover_password`;
    const fetchOpts = fetchOptions(state, 'POST', {
      id_type: idType,
      id_string: emailOrPhone,
    });

    return apiAction<A.RecoverPassword>(
      AsyncActionTypes.RecoverPassword,
      dispatch,
      { emailOrPhone },
      {
        onRequest: () => fetch(endpoint, fetchOpts),
      },
    );
  };

export const resendSMSCode =
  (emailOrPhone: string): DA<ApiResult<A.ResendSmsCode>> =>
  async (dispatch, getState) => {
    const state = getState();

    const endpoint = `${API_BASE_URL}/auth/sign_in/actions/send_sms_code`;
    const fetchOpts = fetchOptions(state, 'POST', {
      id_type: 'phone',
      id_string: emailOrPhone,
    });

    return apiAction<A.ResendSmsCode>(
      AsyncActionTypes.ResendSmsCode,
      dispatch,
      { emailOrPhone },
      {
        onRequest: () => fetch(endpoint, fetchOpts),
        onSuccess: (result) => {
          startCountdown(result.retry_timeout)(dispatch);
        },
      },
    );
  };

export const sendVoiceCode =
  (emailOrPhone: string): DA<ApiResult<A.SendVoiceCode>> =>
  async (dispatch, getState) => {
    const state = getState();

    const endpoint = `${API_BASE_URL}/auth/sign_in/actions/send_voice_code`;
    const fetchOpts = fetchOptions(state, 'POST', {
      id_type: 'phone',
      id_string: emailOrPhone,
    });

    return apiAction<A.SendVoiceCode>(
      AsyncActionTypes.SendVoiceCode,
      dispatch,
      { emailOrPhone },
      {
        onRequest: () => fetch(endpoint, fetchOpts),
        onSuccess: (result) => {
          startCountdown(result.retry_timeout)(dispatch);
        },
      },
    );
  };

export const sendLogOutRequest =
  (redirect: boolean): DA<ApiResult<A.Logout>> =>
  async (dispatch, getState) => {
    const endpoint = `${API_BASE_URL}/logout`;
    const fetchOpts = fetchOptions(getState(), 'POST', undefined, undefined, {
      bypassAgeLock: true,
    });

    return apiAction<A.Logout>(
      AsyncActionTypes.Logout,
      dispatch,
      {},
      {
        onRequest: () => fetch(endpoint, fetchOpts),
        onSuccess: () => {
          closeConnection();

          if (redirect) dispatch(push('/'));
        },
        onError: () => {
          closeConnection();

          if (redirect) dispatch(push('/'));
        },
      },
    );
  };

export const logoutOnAuthError =
  (): DA<ApiResult<A.Logout>> => async (dispatch, getState) => {
    closeConnection();

    dispatch(push('/'));

    return dispatch({
      type: AsyncActionTypes.Logout,
      status: AsyncActionStatus.success,
      result: {},
      params: {},
    });
  };

export const resendConfirmationEmail =
  (): DA<ApiResult<A.ResendConfirmationEmail>> =>
  async (dispatch, getState) => {
    const endpoint = `${API_BASE_URL}/profile/actions/verify_email`;
    const fetchOpts = fetchOptions(getState(), 'POST');

    return apiAction<A.ResendConfirmationEmail>(
      AsyncActionTypes.ResendConfirmationEmail,
      dispatch,
      {},
      {
        onRequest: () => fetch(endpoint, fetchOpts),
      },
    );
  };

export const deleteAccount =
  (
    password: string,
    reason: string,
    redirectToMain = true,
  ): DA<ApiResult<A.DeleteAccount>> =>
  async (dispatch, getState) => {
    const state = getState();
    const { userId } = state.auth.persist;

    if (!userId) {
      throw new Error('No user id');
    }

    const endpoint = `${API_BASE_URL}/profile`;
    const fetchOpts = fetchOptions(
      state,
      'DELETE',
      password.length > 0 ? { password } : {},
      undefined,
      { bypassAgeLock: true },
    );

    return apiAction<A.DeleteAccount>(
      AsyncActionTypes.DeleteAccount,
      dispatch,
      { userId, reason },
      {
        onRequest: () => fetch(endpoint, fetchOpts),
        preDispatchSuccess: (res) => {
          // dispatch this before routing triggered by auth,
          // so that current path won't be memorized in redirectTo
          if (redirectToMain) {
            dispatch(replace('/'));
          }

          return res;
        },
        onSuccess: () => {
          logEvent(MetricsEvents.AccountDeleted, { reason }, userId);

          closeConnection();
        },
      },
    );
  };

export const oauthSignIn =
  (oauthSignInMethod: OauthSignInMethod): DA<{}> =>
  async (dispatch) => {
    dispatch({
      type: ActionTypes.OauthSignInRequest,
    });

    try {
      const firebaseToken = await signIn(oauthSignInMethod);

      return dispatch({
        type: ActionTypes.OauthSignInSuccess,
        firebaseToken,
        authType: oauthSignInMethod,
      });
    } catch (err) {
      return dispatch({
        type: ActionTypes.OauthSignInError,
        err,
      });
    }
  };

export const authByOauth =
  (): DA<ApiResult<A.AuthByOauth>> => async (dispatch, getState) => {
    const state = getState();
    const firebaseToken = state.auth.persist.firebaseToken;

    if (!firebaseToken) {
      return Promise.reject(new Error('Could not log in. Try again later'));
    }

    const endpoint = `${API_BASE_URL}/auth/sign_in/actions/auth_by_firebase_token`;
    const fetchOpts = fetchOptions(state, 'POST', {
      firebase_token: firebaseToken,
      capabilities: CAPABILITIES,
      unity_bundle_version: getUnityBundleVersion(),
      firebase_project_id: 'replika-auth',
    });

    return apiAction<A.AuthByOauth>(
      AsyncActionTypes.AuthByOauth,
      dispatch,
      {},
      {
        onRequest: () => fetch(endpoint, fetchOpts),
        onSuccess: (result) => {
          if (isAuthSuccessResult(result)) {
            return handleLoginSuccess(dispatch, getState);
          }
        },
      },
    );
  };
