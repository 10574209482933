import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MetricsEvents } from '../types/enums';
import { AnalyticsEventProperties, logEvent } from './metrics';

export default function useLogEvent() {
  const userId = useSelector((state) => state.auth.persist.userId);

  return useCallback(
    function (
      event: MetricsEvents,
      eventProperties?: AnalyticsEventProperties,
    ) {
      logEvent(event, eventProperties, userId);
    },
    [userId],
  );
}
