import * as A from '../types/actions';
import { ToSuccess } from '../types/asyncActions';
import { AsyncActionTypes } from '../types/enums';
import { Reducer } from '../types/redux';
import { MediaState } from '../types/states';
import applyReducer from '../utils/applyReducer';

const DEFAULT_STATE: MediaState = {
  persist: {
    music: [],
  },
};

type RSuccess<X> = Reducer<MediaState, ToSuccess<X>>;

const setMedia: RSuccess<A.GetMedia> = (state, { result, params }) => ({
  ...state,
  persist: {
    ...state.persist,
    [params.mediaType]: result,
  },
});

export default function media(
  state: MediaState = DEFAULT_STATE,
  action: A.AnyAction,
): MediaState {
  return applyReducer(
    'media',
    {
      [AsyncActionTypes.GetMedia]: {
        success: setMedia,
      },
    },
    state,
    action,
  );
}
