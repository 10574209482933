import { useAtom, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import { useContext, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { updateBot } from '../../actions/profile';
import {
  avatarProfileStorePreviewAtom,
  currentAvatarAtom,
} from '../../core/atoms';
import StoreCameraSlotContext from '../../routes/Store/StoreCameraSlotContext';
import { BotPatch, CameraSlot, CategoryTreeNode } from '../../types/models';
import { useMobileQuery } from '../responsive';
import { AgeRange } from './StoreAgeRange';
import { BodyTypeRange } from './StoreBodyTypeRange';
import { StoreCategoryGridSectionHeader } from './StoreCategoryGridSection';

interface Props {
  section: CategoryTreeNode;
  title?: string;
  avatarId: string;
  index: number;
  onVisible: (index: number, visible: boolean) => void;
}

export const StoreRangeGrid = ({
  section,
  title,
  avatarId,
  index,
  onVisible,
}: Props) => {
  const setAvatarSettings = useContext(StoreCameraSlotContext);
  const isMobile = useMobileQuery();

  const dispatch = useDispatch();
  const avatarModel = useSelector((state) =>
    state.avatars.modelsV2.find((a) => a.id === avatarId),
  );
  const isBodyType = section.key === 'body_type';
  const isAge = section.key === 'age';
  const [avatar, updateAvatarPreview] = useAtom(avatarProfileStorePreviewAtom);
  const setCurrentAvatar = useSetAtom(currentAvatarAtom);

  const debouncedUpdateBot = useMemo(
    () =>
      debounce((patch: BotPatch) => {
        dispatch(updateBot(patch));
      }, 1000),
    [dispatch],
  );

  const handleUpdateBot = (patch: BotPatch) => {
    const slot = section.camera_slot || 'default_store';
    setAvatarSettings({
      cameraSlot: (isMobile ? slot : 'desktop_' + slot) as CameraSlot,
    });
    const { age, body_type } = patch.avatar_v2 || {};

    updateAvatarPreview({ age, bodyType: body_type });
    // optimistic update to prevent flickering on quick store exit
    // (this whole thing begs for a refactor)
    setCurrentAvatar({ age, bodyType: body_type });

    debouncedUpdateBot(patch);
  };

  const { ref } = useInView({
    delay: 400,
    onChange(inView) {
      onVisible?.(index, inView);
    },
  });

  const grid = isBodyType ? (
    <BodyTypeRange
      avatar={avatar}
      bodyTypeParams={avatarModel?.body_type_params}
      updateBot={handleUpdateBot}
    />
  ) : isAge ? (
    <AgeRange avatar={avatar} updateBot={handleUpdateBot} />
  ) : null;
  return (
    <div id={'grid-section-' + section.id} ref={(el) => ref(el)}>
      <StoreCategoryGridSectionHeader>{title}</StoreCategoryGridSectionHeader>
      <StoreRangeGridSection>{grid}</StoreRangeGridSection>
    </div>
  );
};

const StoreRangeGridSection = styled.div`
  padding: 20px 10px 0px;
  font-family: ${(p) => p.theme.fonts.display};
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    font-size: 16px;
    line-height: 22px;
    padding: 20px 10px 0;
  }
`;
