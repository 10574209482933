import { useSelector } from 'react-redux';
import outlineCss from 'src/components/outlineCss';
import {
  isSubscriptionActive,
  isSubscriptionPending,
} from 'src/utils/subscription';
import styled from 'styled-components/macro';
import { AriaButtonProps, Routes } from '../../../components/Buttons';
import { UnstyledLink } from '../../../components/Link';
import Picture from '../../../components/Picture';
import { MAIN_PAGE_URL_BASE } from '../../../utils/uri';
import {
  DEFAULT_SUBSCRIPTION_SUBTITLE,
  DEFAULT_SUBSCRIPTION_TITLE,
} from '../consts';

interface IProps extends AriaButtonProps {
  title?: string;
  subtitle?: string;
  to?: string;
  enabled?: boolean;
}

export function UnlockPro({
  className,
  title = DEFAULT_SUBSCRIPTION_TITLE,
  subtitle = DEFAULT_SUBSCRIPTION_SUBTITLE,
  to = Routes.Subscription,
  enabled,
}: IProps) {
  const subscription = useSelector(
    (state) => state.subscriptions.persist.subscription,
  );

  const isHidden =
    isSubscriptionActive(subscription) || isSubscriptionPending(subscription);

  if (!enabled && isHidden) return null;

  return (
    <UnlockProRoot className={className} to={to}>
      <UnlockProBg>
        <Picture src={MAIN_PAGE_URL_BASE + '/unlock_pro.png'} alt="" />
      </UnlockProBg>
      <UnlockProImg>
        <Picture src={MAIN_PAGE_URL_BASE + '/unlock_pro.png'} alt="" />
      </UnlockProImg>
      <Text>
        <Title>{title}</Title>
        <Description>{subtitle}</Description>
      </Text>
    </UnlockProRoot>
  );
}

const UnlockProRoot = styled(UnstyledLink)`
  background: rgb(0 0 0 / 0.15);
  backdrop-filter: blur(25px);
  border-radius: 24px;
  color: ${(p) => p.theme.fgColor};

  flex: 0 0 auto;
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 6px;
  padding: 10px 15px 15px 10px;
  overflow: hidden;
  transition: background-color 0.2s ease-in;

  &:hover {
    background: rgb(0 0 0 / 0.25);
    text-decoration: none;
  }

  &:focus-visible:focus {
    ${outlineCss({ offset: '-3px', size: '3px' })}
  }
`;

const UnlockProBg = styled.div`
  position: absolute;
  top: -119px;
  left: -112px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  filter: blur(57.5px);
  height: 242px;
  width: 242px;

  img {
    height: auto;
    width: 130px;
  }
`;

const UnlockProImg = styled.div`
  height: 33px;
  width: 33px;
  flex: 0 0 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  img {
    height: auto;
    width: 24px;
  }
`;

const Text = styled.div`
  isolation: isolate;
  padding: 5px 0 0;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const Description = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
`;

export const SettingsUnlockPro = styled(UnlockPro)`
  background: rgb(255 255 255 / 0.1);
  margin: 0 20px 10px;

  &:hover {
    background: rgb(255 255 255 / 0.2);
  }
`;

export const LayoutUnlockPro = styled(UnlockPro)`
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 260px;
  visibility: hidden;

  @media ${(p) => p.theme.breakpoints.tabletLandscape} {
    visibility: visible;
  }
`;
