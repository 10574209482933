import { replace } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ModalRouteMatch, {
  ModalRoute,
} from 'src/routes/modals/ModalRoutes/ModalRouteMatch';
import { ModalLayout } from 'src/routes/modals/ModalScreenLayout';
import { ModalRoutes, Routes } from '../../../../../types/enums';
import { isKnownMemoryType } from '../../../../../types/models';
import useGoBack from '../../../../../utils/useGoBack';
import { useFormCloseConfirmation } from '../hooks';
import EditFactForm from './EditFactForm';
import EditPersonForm from './EditPersonForm';

const MemoryRecordEditModal = () => {
  const dispatch = useDispatch();
  const location = useLocation<{
    memoryType: string;
    memoryId: string;
  }>();

  const { memoryType, memoryId } = location.state ?? {};

  const [isFormDirty, setFormDirty] = useState(false);

  const { goBack } = useGoBack({
    closeOnEscape: true,
  });
  const handleFormClose = useFormCloseConfirmation(goBack);

  useEffect(() => {
    if (memoryType) {
      const isCorrectMemoryType = isKnownMemoryType(memoryType);

      if (!isCorrectMemoryType) {
        dispatch(replace(Routes.Memory));
      }
    }
  }, [memoryType]);

  return (
    <>
      <ModalLayout.Popups />
      <ModalLayout.Root layout="right-sidebar">
        <ModalLayout.Header>
          <ModalLayout.HeaderLeft />
          <ModalRouteMatch>
            <ModalRoute exact path={ModalRoutes.MemoryRecordEdit}>
              <ModalLayout.HeaderTitle>Edit</ModalLayout.HeaderTitle>
            </ModalRoute>
            <ModalRoute exact path={ModalRoutes.MemoryRecordEditImageUpload}>
              <ModalLayout.HeaderTitle>Edit photo</ModalLayout.HeaderTitle>
            </ModalRoute>
          </ModalRouteMatch>
          <ModalLayout.HeaderRight>
            <ModalLayout.SidebarCloseButton
              onClick={isFormDirty ? handleFormClose : goBack}
            />
          </ModalLayout.HeaderRight>
        </ModalLayout.Header>
        <ModalLayout.Content>
          {memoryType === 'persons' && memoryId ? (
            <EditPersonForm memoryId={memoryId} setFormDirty={setFormDirty} />
          ) : null}
          {memoryType === 'facts' && memoryId ? (
            <EditFactForm memoryId={memoryId} setFormDirty={setFormDirty} />
          ) : null}
        </ModalLayout.Content>
      </ModalLayout.Root>
    </>
  );
};

export default MemoryRecordEditModal;
