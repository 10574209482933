import md5 from 'md5';
import { Themes } from '../../types/enums';
import { MorphType, StoreVariationItemBase } from '../../types/models';

export default function avatarKeyHash({
  type,
  age,
  variations,
  bodyType,
  theme,
  cameraSlot,
  pets = [],
  roomItems = [],
}: {
  type: string;
  age: number;
  variations: StoreVariationItemBase[];
  bodyType: Partial<Record<MorphType, number>> | undefined;
  theme: Themes;
  cameraSlot: string | null;
  pets?: StoreVariationItemBase[];
  roomItems?: StoreVariationItemBase[];
}) {
  return md5(
    JSON.stringify([
      type.replace(/^avatar_/, ''),
      age,
      ...variations.map((v) => v.id).sort(),
      theme,
      cameraSlot,
      bodyType,
      ...pets.map((v) => v.id).sort(),
      ...roomItems.map((v) => v.id).sort(),
    ]),
  );
}
