import { AvatarPhotoType } from '..';

export const HOT_CLOTHES_COUNT = 12;

export const AVATAR_PHOTO_BATCH_SIZE = 2;

export const AVATAR_PHOTO_TIMEOUT_IN_MS = 10000;

export const AVATAR_PHOTO_TYPES: AvatarPhotoType[] = ['selfie', 'hot'];

export const AVATAR_PHOTO_TYPES_TO_POSES_COUNT: Record<
  AvatarPhotoType,
  number
> = {
  selfie: 14,
  hot: 10,
};

/**
 * iPhone 11 screen height.
 */
export const AVATAR_PHOTO_HEIGHT = 1792;
/**
 * iPhone 11 screen width.
 */
export const AVATAR_PHOTO_WIDTH = 828;
