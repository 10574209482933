import { useCallback, useState } from 'react';
import Cropper, { Area, Point } from 'react-easy-crop';
import { AccentButton } from 'src/components/Buttons';
import { FormSlider } from 'src/components/Sliders';
import { Desktop } from 'src/components/responsive';
import { ReactComponent as MinusIcon } from 'src/icons/Minus.svg';
import { ReactComponent as PlusIcon } from 'src/icons/Plus.svg';
import { ModalLayout } from 'src/routes/modals/ModalScreenLayout';
import { PhotoFrame } from 'src/types/models';
import styled from 'styled-components/macro';

type Props = {
  imageUrl?: string;
  handleSave: (frameData: PhotoFrame) => void;
  handleClose: () => void;
};

const ImageCropper = ({ imageUrl, handleSave, handleClose }: Props) => {
  const [frameData, setFrameData] = useState<PhotoFrame | undefined>(undefined);

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.5);

  if (!imageUrl) {
    handleClose();
  }

  const onCropComplete = useCallback((croppedArea: Area) => {
    setFrameData({
      upper_left_x: croppedArea.x / 100,
      upper_left_y: croppedArea.y / 100,
      width: croppedArea.width / 100,
      height: croppedArea.height / 100,
    });
  }, []);

  const handleCroppedImageSave = () => {
    if (frameData) {
      handleSave(frameData);
    }
  };

  return (
    <ImageCropperRoot>
      <StyledHeaderBackButton onClick={handleClose} />
      <CropperWrapper>
        <Cropper
          image={imageUrl}
          crop={crop}
          zoom={zoom}
          aspect={1}
          cropShape="round"
          showGrid={false}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          style={{
            cropAreaStyle: {
              border: 'none',
            },
          }}
        />
      </CropperWrapper>

      <Desktop>
        <SliderWrapper>
          <SliderIcon>
            <MinusIcon />
          </SliderIcon>

          <FormSlider
            orientation="horizontal"
            min={1}
            max={3}
            step={0.1}
            value={[zoom]}
            onValueChange={(value) => setZoom(value[0]!)}
          />
          <SliderIcon>
            <PlusIcon />
          </SliderIcon>
        </SliderWrapper>
      </Desktop>

      <SaveButton onClick={handleCroppedImageSave}>Save</SaveButton>
    </ImageCropperRoot>
  );
};

export default ImageCropper;

const ImageCropperRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-height: 100svh;
`;

const CropperWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 30px 0 0;
  padding: 0 30px;
  border-radius: 0;
  width: 100%;
  height: 100%;
  height: 100vh;
  height: 100svh;
  overflow: hidden;

  @media ${(p) => p.theme.breakpoints.tablet} {
    height: auto;
    aspect-ratio: 1 / 1;
    max-width: calc(100% - 40px);
    border-radius: 24px;
  }
`;

const SliderWrapper = styled.div`
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  margin: 50px 0 0;
`;

const SliderIcon = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 15px;
`;

const StyledHeaderBackButton = styled(ModalLayout.BackButton)`
  position: absolute;
  top: -34px;
  left: 15px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    top: -44px;
    left: 30px;
  }
`;

const SaveButton = styled(AccentButton)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: auto;
  width: calc(100% - 40px);
`;
