import { AvatarPhotoData, AvatarPhotoType, UnityInstance } from '..';
import {
  CustomizationVariationItem,
  UnityBundleData,
} from '../../../types/models';
import createLogger from '../../../utils/createLogger';
import { createAvatarPhoto, loadBundles } from '../unityCommands';
import { execute } from '../unityUtils';
import {
  AVATAR_PHOTO_BATCH_SIZE,
  AVATAR_PHOTO_HEIGHT,
  AVATAR_PHOTO_TIMEOUT_IN_MS,
  AVATAR_PHOTO_TYPES,
  AVATAR_PHOTO_TYPES_TO_POSES_COUNT,
  AVATAR_PHOTO_WIDTH,
} from './consts';
import { getAvatarPhotoId } from './utils';

const logger = createLogger('PhotoStudio');

class PhotoStudioClass {
  private avatarVariations: CustomizationVariationItem[] | null = null;
  private unityInstance: UnityInstance | null = null;
  private commandsDataMap: Map<string, AvatarPhotoData> = new Map();
  private commandsDataList: AvatarPhotoData[] = [];
  private photoBundles: UnityBundleData[] = [];

  public takeAllPhotos = (
    instance: UnityInstance,
    variations: CustomizationVariationItem[],
    bundles: UnityBundleData[],
  ) => {
    logger.log('Start taking photos');

    this.unityInstance = instance;
    this.avatarVariations = variations;
    this.photoBundles = bundles;

    this.cleanCommandsData()
      .loadPhotoUnityBundles()
      .prepareUnityCommands()
      .sendAllCommandsWithInterval();
  };

  public getPhotoParams(id: string): AvatarPhotoData | undefined {
    return this.commandsDataMap.get(id);
  }

  public deletePhotoParams(id: string) {
    return this.commandsDataMap.delete(id);
  }

  public destroy = () => {
    this.commandsDataList = [];
    this.commandsDataMap.clear();
    this.avatarVariations = null;
    this.unityInstance = null;
  };

  public cleanCommandsData() {
    this.commandsDataList = [];
    this.commandsDataMap.clear();

    return this;
  }

  private loadPhotoUnityBundles() {
    if (!this.unityInstance) {
      return this;
    }

    execute(
      this.unityInstance,
      loadBundles(
        this.photoBundles.map(({ id, url }) => ({
          path: url,
          bundle_name: id,
        })),
      ),
    );

    return this;
  }

  private prepareUnityCommands() {
    const maxPosesCount = Math.max(
      ...Object.values(AVATAR_PHOTO_TYPES_TO_POSES_COUNT),
    );

    for (let i = 0; i < maxPosesCount; i++) {
      AVATAR_PHOTO_TYPES.forEach((type: AvatarPhotoType) => {
        const maxCount = AVATAR_PHOTO_TYPES_TO_POSES_COUNT[type];

        if (i >= maxCount) return;

        const photoId = getAvatarPhotoId(type, i.toString());
        const commandData = this.getAvatarPhotoData(
          photoId,
          type,
          i.toString(),
        );

        this.commandsDataMap.set(photoId, commandData);
        this.commandsDataList.push(commandData);
      });
    }

    return this;
  }

  private getAvatarPhotoData(
    photoId: string,
    type: AvatarPhotoType,
    pose: string,
  ): AvatarPhotoData {
    const items = this.avatarVariations?.map(({ unity_id }) => unity_id) || [];

    return {
      path: photoId,
      id: pose,
      type,
      items,
    };
  }

  private sendAllCommandsWithInterval() {
    const send = () => {
      const commandsData = this.commandsDataList.splice(
        0,
        AVATAR_PHOTO_BATCH_SIZE,
      );

      if (commandsData.length === 0 || !this.unityInstance) {
        return;
      }

      logger.log(`Create photo batch (${this.commandsDataList.length} left)`);

      execute(
        this.unityInstance,
        createAvatarPhoto({
          data: commandsData,
          height: AVATAR_PHOTO_HEIGHT,
          width: AVATAR_PHOTO_WIDTH,
        }),
      );

      setTimeout(() => {
        send();
      }, AVATAR_PHOTO_TIMEOUT_IN_MS);
    };

    setTimeout(() => {
      send();
    }, AVATAR_PHOTO_TIMEOUT_IN_MS);
  }
}

const photoStudio = new PhotoStudioClass();

export { PhotoStudioClass, photoStudio };
