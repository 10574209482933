import styled from 'styled-components/macro';

export default function DialogBody(props: {
  className?: string;
  children: React.ReactNode;
  mobileLayout?: 'fullscreen' | 'centered' | 'action-sheet';
}) {
  const { mobileLayout = 'fullscreen' } = props;

  let Root = DialogBodyRoot;
  if (mobileLayout === 'action-sheet') {
    Root = ActionSheetDialogBodyRoot;
  } else if (mobileLayout === 'centered') {
    Root = CenteredDialogBodyRoot;
  } else if (mobileLayout === 'fullscreen') {
    Root = FullscreenDialogBodyRoot;
  }

  return <Root {...props} />;
}

export const DialogBodyRoot = styled.div`
  flex: 0 0 auto;
  border-radius: 24px;
  max-width: 456px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ActionSheetDialogBodyRoot = styled(DialogBodyRoot)`
  padding: 0 20px 20px;
  max-width: 100%;
  justify-content: center;
`;

const CenteredDialogBodyRoot = DialogBodyRoot;

const FullscreenDialogBodyRoot = styled(DialogBodyRoot)`
  overflow-y: scroll;
  border-radius: 0px;
  margin: 0 auto;
  padding: 0 0 20px;
  width: 100vw;
  flex: 1 1 auto;

  @media ${(p) => p.theme.breakpoints.tablet} {
    flex: 0 0 auto;
    width: 100%;
    border-radius: 24px;
  }
`;
