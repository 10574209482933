import type {
  CreateOrderActions,
  OnApproveActions,
  OnApproveData,
  PayPalScriptOptions,
} from '@paypal/paypal-js/types';
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import styled from 'styled-components/macro';
import { SubscriptionListing } from '../../types/models';
import { PAYPAL_DISABLED_FUNDING_OPTIONS } from '../../utils/constants';
import { captureError } from '../../utils/initSentry';
import CircleSpinner from '../CircleSpinner';

const ConnectedButtons = ({
  amount,
  productId,
  userId,
  onSuccess,
  onStatusChange,
  onError,
}: {
  amount: number;
  productId: string;
  userId: string;
  onSuccess: (orderId: string, planId: string) => Promise<{} | void>;
  onStatusChange: (status: 'idle' | 'subscribing') => void;
  onError: (message: string) => void;
}) => {
  const [{ isPending }] = usePayPalScriptReducer();

  const createOrder = (
    data: Record<string, unknown>,
    actions: CreateOrderActions,
  ) => {
    onStatusChange('subscribing');

    const order = {
      purchase_units: [
        {
          amount: {
            value: `${amount}`,
            currency_code: 'USD',
            breakdown: {
              item_total: {
                value: `${amount}`,
                currency_code: 'USD',
              },
            },
          },
          items: [
            {
              name: 'lifetime',
              quantity: '1',
              unit_amount: { value: `${amount}`, currency_code: 'USD' },
              sku: productId,
            },
          ],
          custom_id: userId,
        },
      ],
    };

    return actions.order.create(order);
  };

  const handleApprove = async (
    data: OnApproveData,
    actions: OnApproveActions,
  ) => {
    onStatusChange('idle');

    onSuccess(data.orderID, productId);
  };

  const handleCancel = async () => onStatusChange('idle');

  const handleError = async (err: Record<string, unknown>) => {
    captureError(err);

    if (err.message && typeof err.message === 'string') {
      onError(err.message);
    } else {
      const message =
        err.message &&
        typeof err.message === 'object' &&
        err.message.hasOwnProperty('message') &&
        typeof err.message.message === 'string'
          ? err.message.message
          : 'Error while sending request';

      onError(message);
    }
  };

  return (
    <>
      {isPending ? <StyledSpinner /> : null}
      <PayPalButtons
        style={{
          label: 'paypal',
          color: 'gold',
          shape: 'pill',
        }}
        createOrder={createOrder}
        onApprove={handleApprove}
        onCancel={handleCancel}
        onError={handleError}
      />
    </>
  );
};

const PayPalPayment = ({
  paypalClientId,
  selectedSubscription,
  userId,
  onSuccess,
  onStatusChange,
  onError,
}: {
  paypalClientId: string;
  selectedSubscription: SubscriptionListing;
  userId: string;
  onSuccess: (orderId: string, productId: string) => Promise<{} | void>;
  onStatusChange: (status: 'idle' | 'subscribing') => void;
  onError: (message: string) => void;
}) => {
  const initialOptions: PayPalScriptOptions = {
    'client-id': paypalClientId,
    currency: 'USD',
    intent: 'capture',
    'disable-funding': PAYPAL_DISABLED_FUNDING_OPTIONS,
  };

  const amount = selectedSubscription.price.amount;

  const productId = selectedSubscription.paypal_product_id;

  return (
    <PayPalScriptProvider options={initialOptions}>
      <ConnectedButtons
        amount={amount}
        productId={productId}
        userId={userId}
        onSuccess={onSuccess}
        onStatusChange={onStatusChange}
        onError={onError}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalPayment;

const StyledSpinner = styled(CircleSpinner)`
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  color: ${(p) => p.theme.dimmedFgColor};
`;
