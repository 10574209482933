import { HOT_CLOTHES_COUNT } from './consts';

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

export function getRandomHotItem() {
  const index = getRandomInt(0, HOT_CLOTHES_COUNT);

  return `hot_${index}`;
}

export const getAvatarPhotoId = (type: 'selfie' | 'hot', pose: string) =>
  `${type}_${pose}`;

type Func = () => Promise<unknown>;

export const createThrottlePromisesFunction = (maxCount: number = 2) => {
  const queue: Func[] = [];
  let pendingCount = 0;

  function doJob() {
    while (pendingCount < maxCount && queue.length > 0) {
      const func = queue.shift();

      if (!func) return;

      const promise = func();

      if (promise instanceof Promise) {
        pendingCount += 1;

        promise
          .catch((error) => {
            console.warn(error);
          })
          // eslint-disable-next-line no-loop-func
          .finally(() => {
            pendingCount -= 1;

            doJob();
          });
      }
    }
  }

  return (func: Func) => {
    queue.push(func);

    doJob();
  };
};
