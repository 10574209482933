import * as React from 'react';
import { useSelector } from 'react-redux';
import { setSubscriptionEventData } from '../actions/subscriptions';
import { GaActions, GaLabels, MetricsEvents } from '../types/enums';
import { Subscription } from '../types/models';
import { SubsriptionDialogCause } from '../types/states';
import useLogEvent from '../utils/useLogEvent';
import {
  trackCampaignswellOneTimePaymentEvent,
  trackCampaignswellSubscribeEvent,
  trackGaEvent,
} from './metrics';
import { isSubscriptionActive } from './subscription';

// FIXME: fix @types/facebook-pixel definition
declare var fbq: any;

type TrackingOptions = {
  cause: SubsriptionDialogCause;
  subscription: Subscription | null;
};

export default function useSubscriptionTracking({
  cause,
  subscription,
}: TrackingOptions) {
  const logEvent = useLogEvent();
  const subscriptionEventData = useSelector(
    (state) => state.subscriptions.subscriptionEventData,
  );
  const [eventLogged, setEventLogged] = React.useState(false);

  const hasSubscription = isSubscriptionActive(subscription);

  React.useEffect(() => {
    if (subscriptionEventData && hasSubscription && !eventLogged) {
      const {
        value,
        currency,
        payment_type,
        product_id,
        discount,
        coupon,
        eventId,
        period,
      } = subscriptionEventData;
      setSubscriptionEventData(null);

      fbq?.(
        'track',
        'Subscribe',
        {
          value,
          currency,
          payment_type,
          discount,
        },
        /**
         * Deduplication of events
         * @see  https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events/
         */
        {
          eventID: eventId,
        },
      );

      /**
       * GTAG purcahse event
       * @see https://developers.google.com/tag-platform/gtagjs/reference/events#purchase
       */
      trackGaEvent(GaActions.Purchase, {
        label: GaLabels.SubscriptionSucceded,
        value: value,
        currency,
        checkout_option: payment_type,
        transaction_id: eventId,
        items: [
          {
            item_id: product_id,
            item_name: 'Subscription',
            price: value,
            currency,
            coupon,
            discount,
          },
        ],
      });

      logEvent(MetricsEvents.SubscriptionSucceeded, {
        cause,
        product_id,
        period,
      });

      if (
        subscription &&
        subscription.source !== 'legacy' &&
        subscription.source !== 'promo'
      ) {
        if (subscriptionEventData.period === 'lifetime') {
          trackCampaignswellOneTimePaymentEvent({
            transaction_id: subscription.token_id,
            payment_system: subscription.source,
            price: subscription.price.amount,
            currency: subscription.price.currency,
            product_id: subscription.subscription_id,
            quantity: 1,
          });
        } else {
          trackCampaignswellSubscribeEvent({
            subscription_id: subscription.token_id,
            payment_system: subscription.source,
            price: subscription.price.amount,
            currency: subscription.price.currency,
            product_id: subscription.subscription_id,
          });
        }
      }

      setEventLogged(true);
    }
  }, [
    cause,
    hasSubscription,
    subscription,
    subscriptionEventData,
    logEvent,
    eventLogged,
  ]);
}
