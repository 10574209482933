import { push } from 'connected-react-router';
import { useSetAtom } from 'jotai';
import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';
import styled, {
  ThemeProvider,
  createGlobalStyle,
  css,
} from 'styled-components/macro';
import AppRoutes, { AuthRoutes, ConfirmEmailRoutes } from './AppRoutes';
import { logoutOnAuthError } from './actions/auth';
import { resetPersist } from './actions/init';
import { getUserProfile } from './actions/profile';
import { Dialogs } from './components/FullscreenLayout';
import GlobalConfirmPopupProvider from './components/GlobalConfirmPopup';
import NetworkStatus from './components/NetworkStatus';
import outlineCss from './components/outlineCss';
import A11yNavigationNotifier from './core/App/A11yNavigationNotifier';
import A11yNotifier from './core/App/A11yNotifier';
import DevHelperDialog from './core/App/DevHelperDialog';
import ErrorScreen from './core/App/ErrorScreen';
import GlobalUnity from './core/App/GlobalUnity';
import useCurrentDaytime from './core/AvatarUnity/useCurrentDaytime';
import { useUnity3dDisabled } from './core/AvatarUnity/useUnity3dDisabled';
import { clearApiCache } from './core/api/apiCacheProvider';
import { dayTimeAtom } from './core/atoms';
import useInAuth from './core/useInAuth';
import './css/fonts/fonts.css';
import {
  AgeGateStatus,
  MetricsEvents,
  Routes,
  Themes,
  ZIndices,
} from './types/enums';
import { ApiError } from './utils/apiError';
import getTheme from './utils/getTheme';
import { captureError } from './utils/initSentry';
import isDebugEnabled from './utils/isDebugEnabled';
import isMobileClient from './utils/isMobileClient';
import { AnalyticsEventProperties, updateMetrics } from './utils/metrics';
import mobileMedia from './utils/mobileMedia';
import { globalRecaptchaCss } from './utils/recaptcha';
import { clearSessionSetting, getSessionSetting } from './utils/session';
import useAtomsDebugValue from './utils/useAtomsDebugValue';
import useLogEvent from './utils/useLogEvent';
import { useThemeName } from './utils/withThemeName';

type State = {
  error: any;
};

function DebugJotai() {
  useAtomsDebugValue();

  return null;
}

const AppGlobal = ({ children }: { children: React.ReactNode }) => {
  const debugEnabled = isDebugEnabled();

  return (
    <>
      {debugEnabled && <DebugJotai />}
      <A11yNavigationNotifier />
      <Dialogs />
      <A11yNotifier>
        <StyledGlobalConfirmPopupProvider>
          {children}
        </StyledGlobalConfirmPopupProvider>
      </A11yNotifier>
      <NetworkStatus />
      <GlobalStyle $isDev={debugEnabled} />
      {!isMobileClient() && <GlobalUnity />}
      {debugEnabled && <DevHelperDialog />}
    </>
  );
};

function observeAutoTranslation(
  logEvent: (
    event: MetricsEvents,
    eventProperties: AnalyticsEventProperties,
  ) => void,
) {
  if (!document || !window.MutationObserver) {
    return;
  }

  try {
    const html = document.documentElement;
    const observer = new MutationObserver(() => {
      // https://www.ctrl.blog/entry/detect-machine-translated-webpages.html
      const isAutoTranslated = !!document.querySelector(
        'html.translated-ltr, head.translated-rtl, ya-tr-span, *[_msttexthash]',
      );

      if (isAutoTranslated) {
        const lang = document.documentElement.getAttribute('lang');
        logEvent(MetricsEvents.PageIsAutoTranslated, { lang });
      }
    });

    observer.observe(html, {
      attributes: true,
      childList: false,
      subtree: false,
    });
  } catch (e) {
    console.error('HTML mutation observer error:', e);
  }
}

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const userId = useSelector((state) => state.auth.persist.userId);

  const trialPeriodExpired = useSelector(
    (state) => state.auth.trialPeriodExpired,
  );
  const bot = useSelector((state) => state.profile.persist.bot);
  const bdStatus = useSelector(
    (state) => state.profile.persist.userProfile?.birthday_status,
  );

  let themeName = useThemeName();
  const logEvent = useLogEvent();

  const inAuth = useInAuth();

  React.useEffect(() => {
    if (!inAuth) {
      clearApiCache();
    }
  }, [inAuth]);

  if (inAuth) {
    themeName = Themes.Default;
  }

  const setDaytime = useSetAtom(dayTimeAtom);
  const currentDayTime = useCurrentDaytime();
  const is3dDisabled = useUnity3dDisabled();

  React.useEffect(() => {
    document?.documentElement.classList.toggle('unity3dIsOn', !is3dDisabled);
  }, [is3dDisabled]);

  React.useEffect(() => {
    setDaytime(themeName === Themes.Dark ? 'dark' : currentDayTime);
  }, [themeName, currentDayTime]);

  React.useEffect(() => {
    observeAutoTranslation(logEvent);

    const handleRejection = (event) => {
      const err = event.reason;
      if (err instanceof ApiError && err.statusCode === 401) {
        dispatch(logoutOnAuthError());
        event.preventDefault();
      }
    };

    window.addEventListener('unhandledrejection', handleRejection);

    return () => {
      window.removeEventListener('unhandledrejection', handleRejection);
    };
  }, [userId, dispatch, logEvent]);

  React.useEffect(() => {
    if (userId) {
      updateMetrics(userId);
    }
  }, [userId]);

  const needProfileFetch =
    userId && (!inAuth || bdStatus === AgeGateStatus.Locked);

  React.useEffect(() => {
    if (needProfileFetch) {
      dispatch(getUserProfile(!inAuth));
    }
  }, [needProfileFetch, dispatch, inAuth]);

  let theme = getTheme(themeName);

  const hasAvatar = !!bot?.avatar_v2;

  let routes: React.ReactNode = null;

  // user is not logged in, or just signed up (and we're waiting for testimonial timeout to pass)
  if (inAuth) {
    routes = <AuthRoutes />;

    // user is logged in, but his trial is expired
  } else if (trialPeriodExpired) {
    routes = <ConfirmEmailRoutes />;

    // user is logged in and we are good to go
  } else {
    routes = <AppRoutes hasAvatar={hasAvatar} />;
  }

  const redirect = getSessionSetting('redirect');

  React.useEffect(() => {
    const routes = Object.values(Routes);

    if (inAuth || !redirect || !routes.includes(`/${redirect}`)) return;

    dispatch(
      push(redirect, {
        from: location.pathname + location.search,
      }),
    );

    clearSessionSetting('redirect');
  }, [dispatch, inAuth, redirect, location.pathname, location.search]);

  return (
    <ThemeProvider theme={theme}>
      <AppGlobal>{routes}</AppGlobal>
    </ThemeProvider>
  );
}

class AppErrorCatcher extends React.Component<{ dispatch: Dispatch }, State> {
  static getDerivedStateFromError(error) {
    return { error };
  }

  theme = getTheme(Themes.Default);
  state = { error: null };

  componentDidCatch(err, info) {
    if (!(err instanceof ApiError)) {
      captureError(err, info);
    }
  }

  public render() {
    if (this.state.error) {
      return (
        <ThemeProvider theme={this.theme}>
          <ErrorScreen
            onClose={() => {
              this.props.dispatch(resetPersist(['ui', 'auth']));
              window.location.reload();
            }}
          />
        </ThemeProvider>
      );
    }

    return <App />;
  }
}

export default connect()(AppErrorCatcher);

const customCRAErrorFrameCSS = css`
  @media (min-width: 414px) {
    body > iframe {
      width: 30% !important;
      min-width: 400px !important;
      left: auto !important;
      right: 0 !important;
      border-left: 1px solid #aaa !important;
      opacity: 0.95;
    }
  }
`;

// customized scrollbars are shown when "Show scroll bars" system preference is set to "always"
const scrollbarCSS = css`
  * {
    scrollbar-width: thin;
    scrollbar-color: ${(p) => p.theme.scrollThumbColor} transparent;
  }

  *::-webkit-scrollbar {
    background-color: transparent;
  }

  *::-webkit-scrollbar:vertical {
    width: 6px;
  }

  *::-webkit-scrollbar:horizontal {
    height: 6px;
  }

  *::-webkit-scrollbar-track {
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: ${(p) => p.theme.scrollThumbColor};
  }
`;

const GlobalStyle = createGlobalStyle<{ $isDev: boolean }>`
  html {
    min-height: 100vh;
    min-height: 100svh;
    overflow: hidden;
  }

  ${mobileMedia`
    html {
      overflow: auto;
    }
  `}

  body {
    margin: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    min-height: 100vh;
    min-height: 100svh;

    font-family: ${(p) => p.theme.fonts.body};
    background: ${(p) => p.theme.bgColor};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ${(p) => (p.$isDev ? customCRAErrorFrameCSS : '')}

  #root {
    min-height: 100vh;
    min-height: 100svh;
    overflow: hidden;
    isolation: isolate;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${(p) => p.theme.fonts.display};
    font-weight: normal;
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  a:focus-visible:focus {
    ${outlineCss({ offset: '2px' })}
  }

  a img {
    border: 0;
  }

  body img[data-adjustbrightness='true'],
  body picture[data-adjustbrightness='true'] > img {
    filter: ${(p) => p.theme.adjustImageBrightnessFilter};
  }

  * { box-sizing: border-box }

  /* legacy */
  :where(*:focus:not(.focus-visible):not(:focus-visible)) {
    outline: none;
  }

  /* legacy */
  :where(.focus-visible:focus,
  :focus-visible:focus) {
    outline-color: ${(p) => p.theme.outlineColor};
  }

  :where(.focus-visible:focus[tabindex='-1'],
  :focus-visible:focus[tabindex='-1']) {
    outline: none;
  }

  /* legacy */

  .popupbox.popupbox {
    z-index: ${ZIndices.Popupbox};
  }

  [data-hidescrollbar='true'] {
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  a > svg,
  button > svg {
    pointer-events: none;
  }

  ${scrollbarCSS}

  ${globalRecaptchaCss}
`;

const StyledGlobalConfirmPopupProvider = styled(GlobalConfirmPopupProvider)`
  position: absolute;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  z-index: ${ZIndices.GlobalConfirmPopup};
`;
