import useMergedRef from '@react-hook/merged-ref';
import { isEqual } from 'lodash';
import * as React from 'react';
import styled from 'styled-components/macro';

type Props = {
  children?: React.ReactNode;
  maskSize?: number | [number, number];
  layoutEffectHandler?: (ref: React.RefObject<HTMLDivElement>) => void;
  masked?: boolean;
  className?: string;
  style?: React.CSSProperties;
  direction?: 'vertical' | 'horizontal';
  noScrollbar?: boolean;
  onMaskedSidesChange?: (sides: ('start' | 'end')[]) => void;
} & React.HTMLAttributes<HTMLDivElement>;

const MIN_SCROLLBAR_SIZE = 8;

type ScrollDimensions = {
  scrollSize: number;
  clientSize: number;
  scrollPos: number;
  offsetSize: number;
  scrollbarSize: number;
};

function getScrollDimensions(
  el: HTMLElement,
  direction: 'vertical' | 'horizontal',
): ScrollDimensions {
  if (direction === 'vertical') {
    return {
      scrollSize: el.scrollHeight,
      clientSize: el.clientHeight,
      scrollPos: el.scrollTop,
      offsetSize: el.offsetHeight,
      scrollbarSize: el.offsetWidth - el.clientWidth,
    };
  } else {
    return {
      scrollSize: el.scrollWidth,
      clientSize: el.clientWidth,
      scrollPos: el.scrollLeft,
      offsetSize: el.offsetWidth,
      scrollbarSize: el.offsetHeight - el.clientHeight,
    };
  }
}

function getMaskedSides(d: ScrollDimensions) {
  return (
    [
      d.scrollPos > 0 && 'start',
      Math.ceil(d.scrollPos) + d.offsetSize < d.scrollSize && 'end',
    ] as const
  ).filter(Boolean);
}

function getScrollableSize(d: ScrollDimensions) {
  const needScrollbar = d.scrollSize > d.offsetSize;
  let scrollbarSize = d.scrollbarSize;

  // Fix for clients that render scrollbar on top of content
  // Make sure that content has a padding in that case
  if (scrollbarSize === 0 && needScrollbar) {
    scrollbarSize = MIN_SCROLLBAR_SIZE;
  }

  return [scrollbarSize, needScrollbar] as const;
}

function Scrollable(
  {
    children,
    layoutEffectHandler,
    maskSize = 32,
    masked = true,
    style,
    direction = 'vertical',
    noScrollbar,
    onMaskedSidesChange,
    onScroll,
    ...rest
  }: Props,
  ref: React.Ref<HTMLDivElement>,
) {
  let wrapperRef = React.useRef<HTMLDivElement>(null);

  const [scrollbarSize, setScrollbarSize] = React.useState(MIN_SCROLLBAR_SIZE);

  const [needScrollbar, setNeedscrollbar] = React.useState(false);

  const [maskedSides, setMaskedSides] = React.useState<('start' | 'end')[]>([]);

  React.useEffect(() => {
    const wrapperEl = wrapperRef.current;
    if (!wrapperEl) return;

    const handleResize = () => {
      const dimensions = getScrollDimensions(wrapperEl, direction);
      if (dimensions.scrollSize > 0) {
        setNeedscrollbar(dimensions.scrollSize > dimensions.offsetSize);
      }
    };
    window.addEventListener('resize', handleResize);

    let observer: ResizeObserver;

    if (window.ResizeObserver) {
      observer = new ResizeObserver(handleResize);
      observer.observe(wrapperEl);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      if (observer) {
        observer.unobserve(wrapperEl);
      }
    };
  }, [direction]);

  React.useLayoutEffect(() => {
    const wrapperEl = wrapperRef.current;
    if (!wrapperEl) return;
    if (!masked) return;

    const dimensions = getScrollDimensions(wrapperEl, direction);
    const [scrollbarSize, needScrollbar] = getScrollableSize(dimensions);

    setNeedscrollbar(needScrollbar);
    setScrollbarSize(scrollbarSize);

    if (layoutEffectHandler) {
      layoutEffectHandler(wrapperRef);
    }

    const maskedSides = getMaskedSides(dimensions);

    setMaskedSides(maskedSides.filter(Boolean));
  }, [layoutEffectHandler, children, masked, direction]);

  const handleScroll = (e) => {
    const wrapperEl = wrapperRef.current;
    if (!wrapperEl) return;
    if (!masked) return;

    const dimensions = getScrollDimensions(wrapperEl, direction);
    const newMaskedSides = getMaskedSides(dimensions);
    const [newScrollbarSize] = getScrollableSize(dimensions);

    if (newScrollbarSize !== scrollbarSize) {
      setScrollbarSize(scrollbarSize);
    }

    if (!isEqual(maskedSides, newMaskedSides)) {
      setMaskedSides(newMaskedSides);
    }

    onScroll?.(e);
  };

  const maskSizeStart = Array.isArray(maskSize) ? maskSize[0] : maskSize;
  const maskSizeEnd = Array.isArray(maskSize) ? maskSize[1] : maskSize;

  const styleWithVars = masked
    ? ({
        ...style,
        '--scrollbar-size': noScrollbar ? '0px' : scrollbarSize + 'px',
        '--mask-size-start':
          maskedSides.indexOf('start') !== -1 ? maskSizeStart + 'px' : '0px',
        '--mask-size-end':
          maskedSides.indexOf('end') !== -1 ? maskSizeEnd + 'px' : '0px',
      } as React.CSSProperties)
    : style;

  const mergedRef = useMergedRef(ref, wrapperRef);

  return (
    <ScrollableRoot
      data-hidescrollbar={noScrollbar}
      data-direction={masked && needScrollbar ? direction : undefined}
      ref={mergedRef}
      onScroll={handleScroll}
      style={styleWithVars}
      {...rest}
    >
      {children}
    </ScrollableRoot>
  );
}

export default React.forwardRef(Scrollable);

const ScrollableRoot = styled.div`
  &[data-direction='vertical'] {
    mask-image: linear-gradient(to bottom, transparent, black),
      linear-gradient(black, black),
      linear-gradient(to bottom, black, transparent),
      linear-gradient(black, black);

    mask-size:
      calc(100% - var(--scrollbar-size)) var(--mask-size-start),
      calc(100% - var(--scrollbar-size))
        calc(100% - var(--mask-size-start) - var(--mask-size-end)),
      calc(100% - var(--scrollbar-size)) var(--mask-size-end),
      var(--scrollbar-size) 100%;

    mask-position:
      0 0,
      0 var(--mask-size-start),
      0 100%,
      100% 0;
    mask-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  }

  &[data-direction='horizontal'] {
    mask-image: linear-gradient(to right, transparent, black),
      linear-gradient(black, black),
      linear-gradient(to right, black, transparent),
      linear-gradient(black, black);

    mask-size:
      var(--mask-size-start) calc(100% - var(--scrollbar-size)),
      calc(100% - var(--mask-size-start) - var(--mask-size-end))
        calc(100% - var(--scrollbar-size)),
      var(--mask-size-end) calc(100% - var(--scrollbar-size)),
      100% var(--scrollbar-size);

    mask-position:
      0 0,
      var(--mask-size-start) 0,
      100% 0,
      0 100%;
    mask-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  }
`;
