import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';

import {
  convertGems,
  getGemConversions,
  getGemPurchases,
} from '../../actions/store';
import { queueSystemNotification } from '../../actions/ui';
import { MetricsEvents, ModalRoutes } from '../../types/enums';
import { GemConversion, GemPurchase } from '../../types/models';
import { logEvent } from '../../utils/metrics';
import useApi from '../../utils/useApi';

import CardStab from '../../components/CardStab';
import Scrollable from '../../components/Scrollable';
import WalletButton from '../../components/WalletButton';
import toModalRoute from '../../utils/toModalRoute';
import useGoBack from '../../utils/useGoBack';
import { ModalLayout } from '../modals/ModalScreenLayout';
import WalletItemCard, {
  ItemGemConversionProps,
  ItemGemProps,
} from './WalletItemCard';

const MORE_GEMS_NEEDED_MESSAGE = 'More gems needed';

type GemsSectionProps = {
  type: 'Gems';
  items: GemPurchase[];
};

type CoinsSectionProps = {
  type: 'Coins';
  items: GemConversion[];
};

type WalletItemSectionProps = GemsSectionProps | CoinsSectionProps;

function WalletItemSection({ type, items }: WalletItemSectionProps) {
  const dispatch = useDispatch();

  const totalGems = Number(
    useSelector((state) => state.store.persist.credit?.gems_count),
  );
  const systemNotificiations = useSelector(
    (state) => state.ui.systemNotifications,
  );

  const [inProgressItem, setInProgressItem] = React.useState<string | null>(
    null,
  );

  const { from } = useLocation<{ from?: string }>().state ?? {};

  const handleCardClick = async (id: string, amount: number) => {
    if (!id || !type) return;

    if (type === 'Gems') {
      return;
    }

    if (Number(amount) > totalGems) {
      if (
        !systemNotificiations.some(
          (notification) => notification.message === MORE_GEMS_NEEDED_MESSAGE,
        )
      ) {
        dispatch(queueSystemNotification(MORE_GEMS_NEEDED_MESSAGE, 'gem'));
      }
    } else {
      try {
        logEvent(MetricsEvents.CurrencyPurchaseButtonTapped, {
          currencyType: 'coins',
          amount: amount,
        });

        setInProgressItem(id);
        await dispatch(convertGems(id));
        setInProgressItem(null);
      } catch (e) {}
    }
  };

  return (
    <>
      <SectionContent>
        {items?.map((item: GemPurchase | GemConversion) => {
          const to =
            type === 'Gems'
              ? toModalRoute(ModalRoutes.WalletPurchase, {
                  source: undefined,
                  from,
                  id: item.product_id,
                })
              : undefined;

          return (
            <WalletItemCard
              key={item.product_id}
              inProgress={inProgressItem === item.product_id}
              // switch to "satisfy" after TS upgrade?
              cardInfo={{ type, item } as ItemGemProps | ItemGemConversionProps}
              to={to}
              onClick={() => handleCardClick(item.product_id, item.gems_count)}
            />
          );
        })}
      </SectionContent>
    </>
  );
}

function ItemsSections() {
  const { goBack } = useGoBack({
    closeOnEscape: true,
  });

  const gemPurchases = useApi(
    (state) => state.store.persist.gemPurchases,
    getGemPurchases,
    {
      memoDeepEqual: true,
    },
  );

  const gemConversions = useApi(
    (state) => state.store.persist.gemConversions,
    getGemConversions,
    {
      memoDeepEqual: true,
    },
  );

  const noItems = !gemPurchases?.length && !gemConversions?.length;

  return (
    <ModalLayout.Root layout="right-sidebar">
      <ModalLayout.Header>
        <ModalLayout.HeaderLeft>
          <ItemListTitle>Wallet</ItemListTitle>
        </ModalLayout.HeaderLeft>
        <ModalLayout.HeaderRight>
          <WalletButton source="store" variant="small" />
          <StyledCloseButton onClick={goBack} />
        </ModalLayout.HeaderRight>
      </ModalLayout.Header>
      <ModalLayout.Content>
        <ItemList masked>
          <SectionHeader>Gems</SectionHeader>
          {noItems ? (
            <CardStab />
          ) : (
            <WalletItemSection type="Gems" items={gemPurchases} />
          )}
          <SectionHeader>Coins</SectionHeader>
          {noItems ? (
            <CardStab />
          ) : (
            <WalletItemSection type="Coins" items={gemConversions} />
          )}
        </ItemList>
      </ModalLayout.Content>
    </ModalLayout.Root>
  );
}

export default withRouter(ItemsSections);

const ItemList = styled(Scrollable)`
  min-height: 0;
  overflow-y: auto;
  padding: 0 16px 24px;
  scrollbar-gutter: stable both-edges;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding: 0 0 30px;
    width: auto;
  }
`;

const ItemListTitle = styled.h1`
  font-weight: 400;
  font-size: 36px;
  line-height: 36px;
  color: #ffffff;
  margin: 0;
`;

const SectionHeader = styled.h2`
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  color: #ffffff;
  margin: 0;
  margin-bottom: 15px;
  margin-top: 30px;
`;

const SectionContent = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(155px, auto));
  max-width: 100%;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    grid-template-columns: repeat(3, 116px);
    justify-content: flex-start;
    max-width: 100%;
  }
`;

const StyledCloseButton = styled(ModalLayout.CloseButton)`
  width: 34px;
  height: 34px;
  border-radius: 14px;

  svg {
    width: 24px;
    height: 24px;
  }
`;
