import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { queueSystemNotification } from '../actions/ui';

function NetworkStatus() {
  const connectedToInternet = useSelector(
    (state) => state.ui.connectedToInternet,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!connectedToInternet) {
      dispatch(
        queueSystemNotification('No internet connection', 'no-connection'),
      );
    }
  }, [connectedToInternet]);
  return null;
}

export default NetworkStatus;
