import { useSelector } from 'react-redux';
import { Themes } from '../types/enums';
import { RootState } from '../types/states';

export const getThemeName = (state: RootState) => {
  const { userId } = state.auth.persist;

  return (userId && state.ui.persist.themeNames[userId]) || Themes.Default;
};

export function useThemeName() {
  return useSelector((state) => getThemeName(state));
}
