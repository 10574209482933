import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { recoverPassword } from '../../actions/auth';
import { LinkButton } from '../../components/Buttons';
import CircleSpinner from '../../components/CircleSpinner';

export const ForgotPasswordButton = ({
  onClick,
  disabled,
  email,
}: {
  onClick?: () => void;
  disabled?: boolean;
  email: string;
}) => {
  const dispatch = useDispatch();

  const [{ status, recoveryText }, setForgotPassword] = useState<{
    status: 'idle' | 'requested' | 'received';
    recoveryText?: string;
  }>({
    status: 'idle',
    recoveryText: '',
  });

  useEffect(() => {
    setForgotPassword({ status: 'idle', recoveryText: '' });
  }, [email]);

  const handleSendRecoveryEmail = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    onClick?.();
    setForgotPassword({ status: 'requested' });
    try {
      const { recovery_text: text } = await dispatch(recoverPassword(email));
      setForgotPassword({ status: 'received', recoveryText: text });
    } catch (e) {
      if (e instanceof Error)
        setForgotPassword({ status: 'received', recoveryText: e.message });
    }
  };

  return recoveryText ? (
    <RecoveryText>{recoveryText}</RecoveryText>
  ) : (
    <Button
      type="button"
      onClick={handleSendRecoveryEmail}
      disabled={disabled || !email}
    >
      {status === 'requested' ? (
        <Spinner lineWidth={3} />
      ) : (
        'Forgot your password?'
      )}
    </Button>
  );
};

const RecoveryText = styled.p`
  margin: 16px;
`;

const Spinner = styled(CircleSpinner)`
  width: 18px;
  height: 18px;
`;

const Button = styled(LinkButton)`
  color: inherit;
  margin: 16px;
  text-align: left;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  &[aria-disabled='true'] {
    color: rgba(255 255 255 / 70%);
  }
`;
