import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import * as React from 'react';
import styled, { withTheme } from 'styled-components/macro';

import { Theme } from '../../types/theme';
import outlineCss from '../outlineCss';

type Props = {
  className?: string;
  theme: Theme;
  onCardComplete: (complete: boolean) => void;
  onFocusChanged?: (focused: boolean) => void;
};

const webkitAutofillClassName = 'stripeWebkitAutofillReplika';

function getStyleOptions(theme: Theme): StripeCardElementOptions {
  return {
    style: {
      base: {
        color: '#fff',
        fontSize: '16px',
        '::placeholder': {
          color: theme.placeholderColor,
        },
        lineHeight: '20px',
        ':-webkit-autofill': {
          backgroundColor: 'transparent',
          color: '#fff',
        },
        backgroundColor: 'transparent',
      },
      invalid: {
        color: theme.errorFgColor,
        iconColor: theme.errorFgColor,
      },
    },
    classes: { webkitAutofill: webkitAutofillClassName },
  };
}

function StripeCardInput({
  className,
  theme,
  onCardComplete,
  onFocusChanged,
}: Props) {
  const [cardElementFocused, setCardElementFocused] = React.useState(false);

  const styleOptions = React.useMemo(() => getStyleOptions(theme), [theme]);

  return (
    <StripeCardInputRoot $focused={cardElementFocused}>
      <CardElement
        onFocus={() => {
          setCardElementFocused(true);
          onFocusChanged?.(true);
        }}
        onBlur={() => {
          setCardElementFocused(false);
          onFocusChanged?.(false);
        }}
        options={styleOptions}
        onChange={({ complete }) => onCardComplete(complete)}
      />
    </StripeCardInputRoot>
  );
}

export default withTheme(StripeCardInput);

export const StripeCardInputRoot = styled.div<{ $focused: boolean }>`
  width: 100%;
  padding: 18px 20px;
  border-radius: 24px;

  background-color: ${(p) => p.theme.inputBgColor};

  outline: none;

  ${(p) => p.$focused && outlineCss()}

  .${webkitAutofillClassName} {
    background-color: transparent !important;
  }
`;
