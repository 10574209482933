import { useSelector } from 'react-redux';
import { isSubscriptionActive } from './subscription';

export default function useHasSubscription() {
  const subscription = useSelector(
    (state) => state.subscriptions.persist.subscription,
  );

  return isSubscriptionActive(subscription);
}
