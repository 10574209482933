import texts from './texts.json';

// TODO: automate syncing with copyright spreadsheet

type Key = keyof typeof texts;

// This doesn't work for now, but if Typescript will support importing json as const
// (@see https://github.com/microsoft/TypeScript/issues/32063)
// we can use presise type for `params` argument

/* eslint-disable @typescript-eslint/no-unused-vars */
type ExtractParams<T extends string, U = string | number> = string extends T
  ? { [k in string]?: U }
  : T extends `${infer _Start}{${infer Param}}${infer Rest}`
    ? { [k in Param]: U } & ExtractParams<Rest, U>
    : T extends `${infer _Start}{${infer Param}}`
      ? { [k in Param]: U }
      : {};
/* eslint-enable @typescript-eslint/no-unused-vars */

export default function getText<K extends Key, V extends (typeof texts)[K]>(
  key: K,
  params?: ExtractParams<V>,
): string {
  let text = texts[key] ?? key;

  for (const param in params) {
    text = text.replace(
      new RegExp('\\{' + param + '\\}', 'g'),
      params[param]!.toString(),
    );
  }

  return text;
}
