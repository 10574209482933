import { useForm, useFormStatus, useInputField } from 'form-atoms';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { sendPasswordLogin } from '../../actions/auth';
import { setOnboardingStatus } from '../../actions/signup';
import { Routes } from '../../components/Buttons';
import { LabeledTextInput, PasswordInput } from '../../components/Inputs';
import Link from '../../components/Link';
import { useResetAvatar } from '../../core/atoms';
import { MetricsEvents } from '../../types/enums';
import { Progress } from '../../types/states';
import { ApiError } from '../../utils/apiError';
import { logEvent } from '../../utils/metrics';
import useLogEventFirstRender from '../../utils/useLogEventFirstRender';
import { AuthForm, SubmitButton, SubmitError, Title } from './AuthLayout';
import { ForgotPasswordButton } from './ForgotPasswordButton';
import OAuth from './OAuth';
import { TokenLogin } from './TokenLogin';
import { authAtom, tokenLoginAtom } from './atoms';

type Props = {
  className?: string;
};

function Login({ className }: Props) {
  const location = useLocation<{ from: string }>();
  useLogEventFirstRender(MetricsEvents.LoginScreenOpened, {
    from: location.state?.from,
  });
  const searchParams = new URLSearchParams(location.search);
  const single_use_token = searchParams.get('single_use_token');

  const dispatch = useDispatch();

  const oauthStatus = useSelector((state) => state.auth.oauthStatus);
  const oauthInProgress =
    oauthStatus === 'submitting' || oauthStatus === 'success';

  const passwordLoginInProgress = useSelector(
    (state) => state.login.status === Progress.sending,
  );

  const { isLoading: isTokenLoginInProgress } = useAtomValue(tokenLoginAtom);

  const [submitError, setSubmitError] = useState<null | string>(null);

  const { fieldAtoms, submit, reset } = useForm(authAtom);

  const emailField = useInputField<'email'>(fieldAtoms.email, {
    type: 'email',
  });
  const passwordField = useInputField<'password'>(fieldAtoms.password, {
    type: 'password',
  });

  const resetAvatar = useResetAvatar();

  const isLoginInProgress = passwordLoginInProgress || isTokenLoginInProgress;

  const handleSubmit = submit(async (values) => {
    const trimmedPassword = values.password.substr(0, 128);
    try {
      setSubmitError(null);

      await dispatch(sendPasswordLogin(values.email, trimmedPassword, 'email'));

      dispatch(setOnboardingStatus('initial'));
      reset();
      resetAvatar();
    } catch (e) {
      if (e instanceof ApiError) {
        logEvent(MetricsEvents.SigninError, { error: e.technicalMessage });
      }
      if (e instanceof Error) {
        setSubmitError(e.message);
      }
    }
  });

  const formStatus = useFormStatus(authAtom);

  return (
    <AuthForm onSubmit={handleSubmit}>
      <Title>Log in</Title>
      <OAuth />
      {!!single_use_token && <TokenLogin />}
      <LabeledTextInput
        id="login-email"
        placeholder="Email"
        errors={emailField.state.errors}
        {...emailField.props}
      />
      <PasswordInput
        id="login-password"
        labeled
        placeholder="Password"
        errors={passwordField.state.errors}
        {...passwordField.props}
      />
      <ForgotPasswordButton
        email={emailField.props.value}
        onClick={() => logEvent(MetricsEvents.ForgotPassword)}
        disabled={emailField.props['aria-invalid']}
      />
      <SubmitError error={submitError} />
      <SubmitButton
        disabled={oauthInProgress}
        showSpinner={isLoginInProgress}
        $dimmedAccent={
          !emailField.state.touched ||
          !passwordField.state.touched ||
          formStatus.validateStatus !== 'valid'
        }
      >
        Log in
      </SubmitButton>
      <NewAccount>
        New to Replika?{' '}
        <Link to={{ pathname: Routes.Signup, state: { from: 'login' } }}>
          Create an account
        </Link>
      </NewAccount>
    </AuthForm>
  );
}

export default Login;

const NewAccount = styled.p`
  text-align: center;
  font-family: ${(p) => p.theme.fonts.display};
  color: rgba(255 255 255 / 70%);
  & a {
    color: rgb(255 255 255);
    font-weight: bold;
  }
`;
