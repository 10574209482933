import { PromptsReduxState } from '../features/AskReplika/models';
import { AnyAction, BotChatReceived } from '../types/actions';
import {
  PromptsSetPanelSelectedCategoryAction,
  PromptsShowChatWidget,
  PromptsStartPromptAction,
} from '../types/actions/prompts';
import { ASuccess } from '../types/asyncActions';
import { ActionTypes, AsyncActionTypes } from '../types/enums';
import { Chat, PromptState } from '../types/models';
import applyReducer from '../utils/applyReducer';

const getPromptsInitialState = (): PromptsReduxState => ({
  promptInProgress: false,
  promptId: null,
  askReplikaPanelSelectedCategory: null,
  widget: {
    isVisible: false,
    navigationPopup: null,
  },
});

const getPromptStatusFromPromptState = (promptState: PromptState) => {
  if (promptState.state === 'prompt_in_progress') {
    return {
      promptInProgress: true,
      promptId: promptState.prompt_id,
      environment: promptState.environment,
    };
  }

  return {
    promptInProgress: false,
    promptId: null,
  };
};

export default function promptsRootReducer(
  state: PromptsReduxState = getPromptsInitialState(),
  action: AnyAction,
) {
  return applyReducer(
    'prompts',
    {
      [ActionTypes.PromptsSetPanelSelectedCategory]: (
        state: PromptsReduxState,
        action: PromptsSetPanelSelectedCategoryAction,
      ) => ({
        ...state,
        askReplikaPanelSelectedCategory: action.categoryId,
      }),
      [ActionTypes.PromptsClearPanelSelectedCategory]: (
        state: PromptsReduxState,
      ) => ({
        ...state,
        askReplikaPanelSelectedCategory: null,
      }),
      [ActionTypes.PromptsStartPrompt]: (
        state: PromptsReduxState,
        action: PromptsStartPromptAction,
      ) => ({
        ...state,
        promptInProgress: true,
        promptId: action.promptId,
      }),
      [ActionTypes.PromptsStopPrompt]: () => getPromptsInitialState(),
      [ActionTypes.PromptsShowChatWidget]: (
        state: PromptsReduxState,
        { navigationPopup }: PromptsShowChatWidget,
      ) => ({
        ...state,
        widget: {
          isVisible: true,
          navigationPopup,
        },
      }),
      [ActionTypes.PromptsHideChatWidget]: (state: PromptsReduxState) => ({
        ...state,
        widget: {
          isVisible: false,
          navigationPopup: null,
        },
      }),
      [ActionTypes.BotChatReceived]: (
        state: PromptsReduxState,
        action: BotChatReceived,
      ) => ({
        ...state,
        ...getPromptStatusFromPromptState(action?.chat?.prompt_state),
      }),
      [AsyncActionTypes.GetPersonalBotChat]: {
        success: (
          state: PromptsReduxState,
          action: ASuccess<AsyncActionTypes.GetPersonalBotChat, Chat, {}>,
        ) => ({
          ...state,
          ...getPromptStatusFromPromptState(action.result?.prompt_state),
          needToEnableAdvancedAi:
            !state.promptInProgress &&
            action.result?.prompt_state.state === 'prompt_in_progress',
        }),
      },
      [AsyncActionTypes.Logout]: () => getPromptsInitialState(),
      [AsyncActionTypes.DeleteAccount]: () => getPromptsInitialState(),
    },
    state,
    action,
  );
}
