import styled from 'styled-components/macro';
import { AriaButtonProps, IconButton } from '../../components/Buttons';
import { Desktop } from '../../components/responsive';
import { ReactComponent as LogoIcon } from '../../icons/Logo.svg';

type HeaderProfileProps = AriaButtonProps;

export default function HeaderProfile(props: HeaderProfileProps) {
  const { to, onClick, label } = props;

  return (
    <>
      <Desktop>
        <LogoLink
          to={to}
          onClick={(e) => {
            onClick?.(e);
          }}
          aria-label={label}
        >
          <LogoIcon />
        </LogoLink>
      </Desktop>
    </>
  );
}

const LogoLink = styled(IconButton)`
  border-radius: 19px;
  background: rgb(0 0 0 / 15%);
  width: 44px;
  height: 44px;
  padding: 4px;
  backdrop-filter: blur(15px);

  transition: background 0.15s ease-out;

  &:hover {
    background: rgb(0 0 0 / 20%);
  }

  & > svg {
    width: 36px;
    height: 36px;
  }
`;
