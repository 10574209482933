import { isValid } from 'date-fns';
import { ReactNode, useMemo, useState } from 'react';
import { Desktop, Mobile } from '../responsive';
import { DateSelectNativeView } from './DateSelectNativeView';
import { DateSelectRadixView } from './DateSelectRadixView';
import { DateChangeHandler, DateState } from './models';
import { getDateSelectOptions, getParsedDate } from './utils';

export const DEFAULT_DATE = new Date(2000, 0, 1);
const DEFAULT_MIN_DATE = new Date(1900, 1, 1);
const DEFAULT_MAX_DATE = new Date();

export type DateSelectProps = {
  id?: string;
  className?: string;
  onChange?: (date: Date, isFilled: boolean) => void;
  defaultDate?: Date;
  invalid?: boolean;
  minDate?: Date;
  maxDate?: Date;
  layout?: 'horizontal' | 'vertical';
  arrowIcon?: ReactNode;
  monthPlaceholder?: string;
  dayPlaceholder?: string;
  yearPlaceholder?: string;
};

export const DateSelect = ({
  id,
  className,
  onChange,
  defaultDate,
  invalid = false,
  minDate = DEFAULT_MIN_DATE,
  maxDate = DEFAULT_MAX_DATE,
  layout = 'horizontal',
  arrowIcon,
  monthPlaceholder,
  dayPlaceholder,
  yearPlaceholder,
}: DateSelectProps) => {
  const [date, setDate] = useState<DateState>({
    day:
      defaultDate && isValid(defaultDate)
        ? defaultDate.getDate().toString()
        : undefined,
    month:
      defaultDate && isValid(defaultDate)
        ? (defaultDate.getMonth() + 1).toString()
        : undefined,
    year:
      defaultDate && isValid(defaultDate)
        ? defaultDate.getFullYear().toString()
        : undefined,
  });
  const options = useMemo(
    () => getDateSelectOptions(minDate, maxDate),
    [minDate, maxDate],
  );

  const handleDateChange: DateChangeHandler = (
    field: keyof DateState,
    value: string,
  ) => {
    setDate((prev) => {
      const newState = {
        ...prev,
        [field]: value,
      };

      const filled = Object.values(newState).every((i) => !!i);

      onChange?.(getParsedDate(newState), filled);

      return newState;
    });
  };

  return (
    <>
      <Desktop>
        <DateSelectRadixView
          id={id}
          className={className}
          date={date}
          invalid={invalid}
          onDateChange={handleDateChange}
          options={options}
          layout={layout}
          arrowIcon={arrowIcon}
          monthPlaceholder={monthPlaceholder}
          dayPlaceholder={dayPlaceholder}
          yearPlaceholder={yearPlaceholder}
        />
      </Desktop>
      <Mobile>
        <DateSelectNativeView
          id={id}
          className={className}
          date={date}
          invalid={invalid}
          onDateChange={handleDateChange}
          options={options}
          layout={layout}
          arrowIcon={arrowIcon}
          monthPlaceholder={monthPlaceholder}
          dayPlaceholder={dayPlaceholder}
          yearPlaceholder={yearPlaceholder}
        />
      </Mobile>
    </>
  );
};
