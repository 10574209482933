import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { queueSystemNotification } from '../../../../actions/ui';
import { AccentButton } from '../../../../components/Buttons';
import CircleSpinner from '../../../../components/CircleSpinner';
import { DialogFooter } from '../../../../components/DialogLayout';
import {
  Dialog,
  DialogBody,
} from '../../../../components/DialogLayout/legacy/DialogLayout';
import FactList from '../../../../components/FactList';
import Scrollable from '../../../../components/Scrollable';
import { useMobileQuery } from '../../../../components/responsive';
import { FeatureFlags } from '../../../../core/featureFlags';
import { MemoryFactType, MetricsEvents } from '../../../../types/enums';
import {
  MemoryFactWithType,
  isCustomerFactWithCategory,
  isMemoryCategoryType,
} from '../../../../types/models';
import { Progress } from '../../../../types/states';
import useApi from '../../../../utils/useApi';
import useBotName from '../../../../utils/useBotName';
import useLogEvent from '../../../../utils/useLogEvent';
import useLogEventFirstRender from '../../../../utils/useLogEventFirstRender';
import { getMemoryCategories, processNewMemories } from '../../actions';
import { editablePersonPredicate } from '../../utils';
import PersonsList from '../PersonList';

type Props = {
  onClose: () => void;
};

function NewMemoriesDialog({ onClose }: Props) {
  const dispatch = useDispatch();
  const logEvent = useLogEvent();

  useLogEventFirstRender(MetricsEvents.MemoryNewFactsBottomSheetOpened);

  const isMobile = useMobileQuery();
  const botName = useBotName();

  const [factsSelected, setFactsSelected] = useState<string[] | undefined>();
  const [personsSelected, setPersonsSelected] = useState<
    string[] | undefined
  >();

  const newMemories = useSelector((state) => state.memory.persist.newMemories);
  const getMemoryProgress = useSelector(
    (state) => state.memory.getMemoryProgress,
  );
  const processNewMemoriesProgress = useSelector(
    (state) => state.memory.processNewMemoriesProgress,
  );

  const memoryCategories = useApi(
    (state) => state.memory.persist.memoryCategories,
    getMemoryCategories,
    {
      memoDeepEqual: true,
    },
  );

  // it's guranteed that there is at least one category of 'replika_fact' type
  const replikaFactCategory = memoryCategories?.find((category) =>
    isMemoryCategoryType(category, 'replika_fact'),
  );

  useEffect(() => {
    if (newMemories.persons && !personsSelected) {
      setPersonsSelected(newMemories.persons.map((person) => person.id));
    }

    if (newMemories.sortedFacts && !factsSelected) {
      setFactsSelected(newMemories.sortedFacts.map((fact) => fact.id));
    }
  }, [newMemories, personsSelected, factsSelected]);

  const handleSubmit = async () => {
    // prepare facts for processing
    const {
      customerFactsToSave,
      cutsomerFactsToDiscard,
      robotFactsToSave,
      robotFactsToDiscard,
    } = newMemories.sortedFacts?.reduce<{
      customerFactsToSave: string[];
      cutsomerFactsToDiscard: string[];
      robotFactsToSave: string[];
      robotFactsToDiscard: string[];
    }>(
      (accum, curr) => {
        if (factsSelected?.includes(curr.id)) {
          return curr.factType === MemoryFactType.CustomerFacts
            ? {
                ...accum,
                customerFactsToSave: [...accum.customerFactsToSave, curr.id],
              }
            : {
                ...accum,
                robotFactsToSave: [...accum.robotFactsToSave, curr.id],
              };
        } else {
          return curr.factType === MemoryFactType.CustomerFacts
            ? {
                ...accum,
                cutsomerFactsToDiscard: [
                  ...accum.cutsomerFactsToDiscard,
                  curr.id,
                ],
              }
            : {
                ...accum,
                robotFactsToDiscard: [...accum.robotFactsToDiscard, curr.id],
              };
        }
      },
      {
        customerFactsToSave: [],
        cutsomerFactsToDiscard: [],
        robotFactsToSave: [],
        robotFactsToDiscard: [],
      },
    ) ?? {
      customerFactsToSave: [],
      cutsomerFactsToDiscard: [],
      robotFactsToSave: [],
      robotFactsToDiscard: [],
    };

    // prepare persons for processing
    const { personsToSave, personsToDiscard } = newMemories.persons
      ?.filter(editablePersonPredicate)
      .reduce<{
        personsToSave: string[];
        personsToDiscard: string[];
      }>(
        (accum, curr) =>
          personsSelected?.includes(curr.id)
            ? { ...accum, personsToSave: [...accum.personsToSave, curr.id] }
            : {
                ...accum,
                personsToDiscard: [...accum.personsToDiscard, curr.id],
              },

        { personsToSave: [], personsToDiscard: [] },
      ) ?? {
      personsToSave: [],
      personsToDiscard: [],
    };

    await dispatch(
      processNewMemories({
        save_customer_fact_ids: customerFactsToSave,
        discard_customer_fact_ids: cutsomerFactsToDiscard,
        save_robot_fact_ids: robotFactsToSave,
        discard_robot_fact_ids: robotFactsToDiscard,
        save_person_ids: personsToSave,
        discard_person_ids: personsToDiscard,
      }),
    );

    // show notification
    const memoriesCount =
      (personsSelected?.length ?? 0) + (factsSelected?.length ?? 0);

    if (memoriesCount > 0) {
      const message =
        memoriesCount === 1
          ? '1 memory added'
          : `${memoriesCount} memories added`;
      dispatch(queueSystemNotification(message, 'check'));
    }

    /**
     * handle analytics
     * NB events are sent in batches by amplitude
     * @see https://www.docs.developers.amplitude.com/data/sdks/browser-2/#configuration
     */
    memoryCategories.forEach((category) => {
      if (isMemoryCategoryType(category, 'person')) {
        if (personsToSave.length > 0) {
          logEvent(MetricsEvents.MemoryNewFactsSaved, {
            category: category.name,
            'facts number': personsToSave.length,
          });
        }

        if (personsToDiscard.length > 0) {
          logEvent(MetricsEvents.FactsDeleted, {
            source: 'new facts screen',
            category: category.name,
            'facts number': personsToDiscard.length,
          });
        }
      } else if (isMemoryCategoryType(category, 'replika_fact')) {
        if (robotFactsToSave.length > 0) {
          logEvent(MetricsEvents.MemoryNewFactsSaved, {
            category: 'Replika facts',
            'facts number': robotFactsToSave.length,
          });
        }

        if (robotFactsToDiscard.length > 0) {
          logEvent(MetricsEvents.FactsDeleted, {
            source: 'new facts screen',
            category: 'Replika facts',
            'facts number': robotFactsToDiscard.length,
          });
        }
      } else {
        const {
          customerFactsToSaveFromCurrentCategory,
          customerFactsToDiscardFromCurrentCategory,
        } = newMemories.sortedFacts?.reduce<{
          customerFactsToSaveFromCurrentCategory: string[];
          customerFactsToDiscardFromCurrentCategory: string[];
        }>(
          (accum, curr) => {
            if (
              isCustomerFactWithCategory(curr) &&
              curr.category_id === category.id
            ) {
              return factsSelected?.includes(curr.id)
                ? {
                    ...accum,
                    customerFactsToSaveFromCurrentCategory: [
                      ...accum.customerFactsToSaveFromCurrentCategory,
                      curr.id,
                    ],
                  }
                : {
                    ...accum,
                    customerFactsToDiscardFromCurrentCategory: [
                      ...accum.customerFactsToDiscardFromCurrentCategory,
                      curr.id,
                    ],
                  };
            } else {
              return accum;
            }
          },
          {
            customerFactsToSaveFromCurrentCategory: [],
            customerFactsToDiscardFromCurrentCategory: [],
          },
        ) ?? {
          customerFactsToSaveFromCurrentCategory: [],
          customerFactsToDiscardFromCurrentCategory: [],
        };

        if (customerFactsToSaveFromCurrentCategory.length > 0) {
          logEvent(MetricsEvents.MemoryNewFactsSaved, {
            category: category.name,
            'facts number': customerFactsToSaveFromCurrentCategory.length,
          });
        }

        if (customerFactsToDiscardFromCurrentCategory.length > 0) {
          logEvent(MetricsEvents.FactsDeleted, {
            source: 'new facts screen',
            category: category.name,
            'facts number': customerFactsToDiscardFromCurrentCategory.length,
          });
        }
      }
    });

    // close dialog
    onClose();
  };

  const isLoaded = getMemoryProgress === Progress.success;

  return (
    <StyledDialog mobileLayout="fullscreen">
      <StyledDialogBody maskSize={isMobile ? 20 : 84} as={Scrollable}>
        <Title data-initialfocus>New memories</Title>
        <Description>
          Here is what {botName} learned about you recently. Choose what you
          want to keep. You can edit all of the saved memories later.
        </Description>

        {isLoaded ? (
          <>
            {!!newMemories.persons?.length && (
              <Category>
                <CategoryTitle>Friends & Family</CategoryTitle>

                <PersonsList
                  persons={newMemories.persons}
                  personsSelected={personsSelected}
                  onPersonsSelectedChange={setPersonsSelected}
                  selectableMode
                />
              </Category>
            )}

            {memoryCategories.map((category) => {
              let facts: MemoryFactWithType[] = [];

              if (FeatureFlags.statementsAboutReplika) {
                facts =
                  newMemories.sortedFacts?.filter(
                    (fact) =>
                      'category_id' in fact && fact.category_id === category.id,
                  ) ?? [];
              } else {
                const isReplikaFactCategory =
                  replikaFactCategory?.id === category.id;

                facts =
                  (isReplikaFactCategory
                    ? newMemories.sortedFacts?.filter(
                        (fact) => fact.factType === MemoryFactType.RobotFacts,
                      )
                    : newMemories.sortedFacts?.filter(
                        (fact) =>
                          isCustomerFactWithCategory(fact) &&
                          fact.category_id === category.id,
                      )) ?? [];
              }

              return facts.length ? (
                <Category key={category.id} data-category={category?.id}>
                  <CategoryTitle>{category.name}</CategoryTitle>

                  <FactList
                    temporary={category.type === 'temporary_fact'}
                    facts={facts}
                    factsSelected={factsSelected}
                    setFactsSelected={setFactsSelected}
                    selectableMode
                  />
                </Category>
              ) : null;
            })}
          </>
        ) : (
          <SpinnerContainer>
            <StyledCircleSpinner lineWidth={10} />
          </SpinnerContainer>
        )}
      </StyledDialogBody>

      <StyledDialogFooter>
        <SubmitButton
          showSpinner={processNewMemoriesProgress === Progress.sending}
          onClick={handleSubmit}
        >
          {personsSelected?.length === newMemories.persons?.length &&
          factsSelected?.length === newMemories?.sortedFacts?.length
            ? 'Keep all'
            : personsSelected?.length || factsSelected?.length
              ? 'Keep selected'
              : 'Discard all'}
        </SubmitButton>
      </StyledDialogFooter>
    </StyledDialog>
  );
}

export default NewMemoriesDialog;

const StyledDialog = styled(Dialog)`
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(25px);

  @media ${(p) => p.theme.breakpoints.tablet} {
    max-height: 820px;
  }
`;

const StyledDialogBody = styled(DialogBody)`
  max-width: 480px;
  padding: 0 20px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    overflow: auto;
    max-height: 820px;
    padding: 0 20px 84px;
  }
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCircleSpinner = styled(CircleSpinner)`
  width: 100px;
  height: 100px;
`;

const Title = styled.h2`
  margin: 30px 0 5px;
  font-size: 28px;
  line-height: 32px;
`;

const Description = styled.p`
  margin: 0;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.12px;
  color: rgba(255 255 255 / 50%);
`;

const Category = styled.div`
  width: 100%;
  padding: 30px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const CategoryTitle = styled.h3`
  width: 100%;
  margin: 0 0 10px;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
`;

const StyledDialogFooter = styled(DialogFooter)`
  padding: 0 20px 20px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding: 20px;
    position: fixed;
    bottom: 0px;
  }
`;

const SubmitButton = styled(AccentButton)`
  height: 54px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    height: 44px;
  }
`;
