import * as React from 'react';
import styled from 'styled-components/macro';
import FocusTrap from './FocusTrap';
import LinkButton from './legacy/LinkButton';
import SolidButton from './legacy/SolidButton';

type ConfirmRequest = {
  id: string;
  title: string;
  message: string;
  confirmLabel: string;
  rejectLabel: string;
  confirmOnEscape?: boolean;
};

type ConfirmResult = { id: string };

type ConfirmResolver = {
  resolve: ((res: ConfirmResult) => void) | null;
  reject: ((reason?: any) => void) | null;
};

type Props = {
  className?: string;
  request: ConfirmRequest | null;
  onConfirm: () => void;
  onReject: () => void;
  onEscape: () => void;
};

function GlobalConfirmPopup({
  className,
  request,
  onConfirm,
  onReject,
  onEscape,
}: Props) {
  if (!request) {
    return null;
  }

  return (
    <FocusTrap focusTrapOptions={{ onDeactivate: onEscape }}>
      <GlobalConfirmPopupRoot role="dialog" className={className}>
        <Title>{request.title}</Title>
        <Message>{request.message}</Message>
        <SolidButton onClick={onConfirm}>{request.confirmLabel}</SolidButton>
        <LinkButton style={{ marginTop: 30 }} onClick={onReject}>
          {request.rejectLabel}
        </LinkButton>
      </GlobalConfirmPopupRoot>
    </FocusTrap>
  );
}

const GlobalConfirmPopupRoot = styled.div`
  color: ${(p) => p.theme.fgColor};
  text-align: center;
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
  background: ${(p) => p.theme.confirmPopupBgColor};
  padding: 30px;
`;

const Title = styled.h2`
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 10px;
`;

const Message = styled.p`
  max-width: 400px;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 30px;
`;

export const ConfirmPopupContext = React.createContext<{
  request: ConfirmRequest | null;
  requestConfirm: (request: ConfirmRequest) => Promise<ConfirmResult>;
}>({
  request: null,
  requestConfirm: (request) => Promise.reject('noop'),
});

function GlobalConfirmPopupProvider({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  const [request, setRequest] = React.useState<ConfirmRequest | null>(null);
  const resolverRef = React.useRef<ConfirmResolver>({
    resolve: null,
    reject: null,
  });

  const requestConfirm = React.useCallback((req) => {
    setRequest(req);
    return new Promise<ConfirmResult>((resolve, reject) => {
      resolverRef.current = { resolve, reject };
    });
  }, []);

  return (
    <ConfirmPopupContext.Provider value={{ request, requestConfirm }}>
      <GlobalConfirmPopup
        className={className}
        onConfirm={() => {
          if (!request) return;
          resolverRef.current.resolve?.({ id: request.id });
          setRequest(null);
          resolverRef.current = { resolve: null, reject: null };
        }}
        onReject={() => {
          if (!request) return;
          resolverRef.current.reject?.({ id: request.id });
          setRequest(null);
          resolverRef.current = { resolve: null, reject: null };
        }}
        onEscape={() => {
          if (!request) return;
          if (request.confirmOnEscape) {
            resolverRef.current.resolve?.({ id: request.id });
          } else {
            resolverRef.current.reject?.({ id: request.id });
          }
          setRequest(null);
          resolverRef.current = { resolve: null, reject: null };
        }}
        request={request}
      />
      {children}
    </ConfirmPopupContext.Provider>
  );
}

export default GlobalConfirmPopupProvider;
