import {
  ComponentPropsWithoutRef,
  ComponentType,
  ReactElement,
  ReactFragment,
  ReactNode,
  cloneElement,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import useTransition, { TransitionStatus } from 'react-transition-state';
import TransitionRoute, { RouteMatcher, TIMEOUTS } from './TransitionRoute';
import flattenFragments from './flattenFragments';
import matchPathOrState from './matchPathOrState';

export type TransitionRouteElement = ReactElement<
  ComponentPropsWithoutRef<typeof TransitionRoute>
>;

type Props = {
  root: ComponentType<{ status: TransitionStatus; children: ReactNode }>;
  instant?: boolean;
  getRouteMatcher?: (
    element: TransitionRouteElement,
  ) => RouteMatcher | undefined;
  children: (
    | TransitionRouteElement
    | ReactFragment
    | boolean
    | null
    | undefined
  )[];
};

export default function TransitionRoutes({
  children,
  instant,
  root: RootComponent,
  getRouteMatcher = () => matchPathOrState,
}: Props) {
  const location = useLocation();
  const truthyChildren = flattenFragments<TransitionRouteElement>(children);
  const match = truthyChildren.find((child) => {
    const routeMatcher = getRouteMatcher(child) ?? matchPathOrState;
    return routeMatcher(
      location,
      child.props.path,
      child.props.state,
      child.props.exact,
    );
  });
  const hasMatch = !!match;

  const [{ status, isMounted }, toggle] = useTransition({
    timeout: TIMEOUTS,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true,
    initialEntered: hasMatch,
  });

  useEffect(() => {
    toggle(hasMatch);
  }, [toggle, hasMatch]);

  const isRendered = instant ? hasMatch : isMounted;

  return isRendered ? (
    <RootComponent status={status}>
      {truthyChildren.map((child, idx) => {
        return cloneElement(child, { _firstMatch: match === child, key: idx });
      })}
    </RootComponent>
  ) : null;
}
