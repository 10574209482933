import * as A from '../types/actions';
import { ToError, ToRequest, ToSuccess } from '../types/asyncActions';
import { ActionTypes, AsyncActionTypes, AuthTypes } from '../types/enums';
import { Reducer } from '../types/redux';
import { LoginState, Progress } from '../types/states';
import { ApiError } from '../utils/apiError';
import applyReducer from '../utils/applyReducer';

const DEFAULT_STATE: LoginState = {
  emailOrPhone: '',
  password: '',
  code: '',
  serverError: undefined,
  accessForbidden: false,
  inputError: undefined,
  confirmationCode: '',
  authType: null,
  idType: undefined,
  codeRetryTimeout: -1,
  codeExpiration: undefined,
  codeLength: undefined,
  status: Progress.initial,
};

type R<X extends A.AuthAction | A.InitAction> = Reducer<LoginState, X>;
type RRequest<X> = Reducer<LoginState, ToRequest<X>>;
type RError<X> = Reducer<LoginState, ToError<X>>;
type RSuccess<X> = Reducer<LoginState, ToSuccess<X>>;

const loginFieldChange: R<A.LoginFieldChange> = (state, action) => ({
  ...state,
  [action.field]: action.value,
  serverError: undefined,
  inputError: undefined,
});

const checkAccountRequest: RRequest<A.AccountExists> = (state) => ({
  ...state,
});

const checkAccountSuccess: RSuccess<A.AccountExists> = (state, action) => ({
  ...state,
  idType: action.result.id_type,
  authType:
    action.result.auth_type === 'password'
      ? AuthTypes.AuthByPassword
      : AuthTypes.SendSmsCode,
});

const phoneCodeRequestError: RError<A.AccountExists> = (state, action) => ({
  ...state,
  serverError: action.error.toString(),
});

const checkAccountError: RError<A.AccountExists> = (state, action) => ({
  ...state,
  serverError: action.error.message,
});

const phoneCodeRequestSuccess: RSuccess<A.SendSmsCode> = (state, action) => ({
  ...state,
  codeExpiration: action.result.expiration,
  codeRetryTimeout: action.result.retry_timeout,
  codeLength: action.result.code_length,
});

const loginRequest: RRequest<A.PasswordLogin> = (state) => ({
  ...state,
  status: Progress.sending,
});

const passwordLoginSuccess: RSuccess<A.PasswordLogin> = (state, action) => ({
  ...state,
  password: '',
  status: Progress.success,
});

const passwordLoginError: RError<A.PasswordLogin> = (state, action) => ({
  ...state,
  serverError: action.error.message,
  accessForbidden:
    action.error instanceof ApiError &&
    action.error.statusCode === 403 &&
    !(action.error.message === 'Invalid password'),
  status: Progress.error,
});

const setCodeResendTimeout: R<A.SetCodeResendTimeout> = (state, action) => ({
  ...state,
  codeRetryTimeout: action.seconds,
});

const resendSMSRequestSuccess: RSuccess<A.ResendSmsCode> = (state, action) => ({
  ...state,
  codeRetryTimeout: action.result.retry_timeout,
  codeLength: action.result.code_length,
});

const logoutSuccess: RSuccess<A.Logout> = (state) => ({
  ...DEFAULT_STATE,
});

const logoutError: RError<A.Logout> = (state) => ({
  ...DEFAULT_STATE,
});

const deleteAccountSuccess: RSuccess<A.DeleteAccount> = (state) => ({
  ...DEFAULT_STATE,
});

export default function login(
  state: LoginState = DEFAULT_STATE,
  action: A.AnyAction,
) {
  return applyReducer(
    'login',
    {
      [ActionTypes.LoginFieldChange]: loginFieldChange,
      [AsyncActionTypes.AccountExists]: {
        request: checkAccountRequest,
        success: checkAccountSuccess,
        error: checkAccountError,
      },
      [AsyncActionTypes.SendSmsCode]: {
        success: phoneCodeRequestSuccess,
        error: phoneCodeRequestError,
      },
      [AsyncActionTypes.PasswordLogin]: {
        request: loginRequest,
        success: passwordLoginSuccess,
        error: passwordLoginError,
      },
      [ActionTypes.SetCodeResendTimeout]: setCodeResendTimeout,
      [AsyncActionTypes.ResendSmsCode]: {
        success: resendSMSRequestSuccess,
      },
      [AsyncActionTypes.SendVoiceCode]: {
        success: resendSMSRequestSuccess,
      },
      [AsyncActionTypes.Logout]: {
        success: logoutSuccess,
        error: logoutError,
      },
      [AsyncActionTypes.DeleteAccount]: {
        success: deleteAccountSuccess,
      },
    },
    state,
    action,
  );
}
