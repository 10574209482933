import { UseInputField } from 'form-atoms';
import { ApiError } from '../../utils/apiError';

export default function catchServerErrors(
  e: unknown,
  fields: Record<string, UseInputField<React.HTMLInputTypeAttribute, string>>,
) {
  let hasFieldError = false;

  if (e instanceof ApiError) {
    for (let [key, field] of Object.entries(fields)) {
      let fieldError = e.errorFields?.[key];
      if (fieldError) {
        hasFieldError = true;
        field.actions.setErrors([fieldError]);
      }
    }
  }

  if (!hasFieldError && e instanceof Error) {
    return e.message;
  }

  return null;
}
