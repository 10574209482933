import { Cache } from 'swr';
import createLogger from '../../utils/createLogger';
import {
  createReplikaDB,
  deleteApiCacheFromDB,
  getApiCacheKeysFromDB,
  readApiCacheFromDB,
  saveApiCacheToDB,
} from '../AvatarUnity/replikaDB';
import { SWRKey } from './types';

const logger = createLogger('SWR Cache');

function getMapKey(key: SWRKey) {
  return typeof key === 'string' ? key : key.url;
}

function persist(key: SWRKey) {
  return typeof key === 'string' || !key.noCachePersist;
}

async function initApiCache(db: IDBDatabase, map: Map<string, any>) {
  const keys = await getApiCacheKeysFromDB(db);

  for (const key of keys) {
    const data = await readApiCacheFromDB(db, key);
    if (!map.has(key)) {
      map.set(key, { data });
    }
  }
}

let map = new Map();
let db: IDBDatabase | null = null;

export async function clearApiCache() {
  if (!db) return;

  const keys = await getApiCacheKeysFromDB(db);

  for (const key of keys) {
    deleteApiCacheFromDB(db, key);
  }

  map = new Map();
}

export default function apiCacheProvider(): Cache {
  createReplikaDB().then((_db) => {
    db = _db;
    initApiCache(db, map);
  });

  return {
    get(key) {
      return map.get(getMapKey(key));
    },
    set(key, value) {
      const mapkey = getMapKey(key);

      const inprogress = value.isLoading || value.isValidating;

      if (db && persist(key) && !inprogress) {
        saveApiCacheToDB(db, mapkey, value.data);
      }

      map.set(getMapKey(key), value);
      logger.debug(map);
    },
    delete(key) {
      const mapkey = getMapKey(key);

      if (db && persist(key)) {
        deleteApiCacheFromDB(db, mapkey);
      }

      map.delete(mapkey);
      logger.debug(map);
    },
    keys() {
      return map.keys();
    },
  };
}
