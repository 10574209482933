import { useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { MetricsEvents } from '../../../types/enums';
import useLogEvent from '../../../utils/useLogEvent';
import { AccentButton, SolidButton } from '../../Buttons';
import {
  Dialog,
  DialogBody,
  DialogCloseButton,
} from '../../DialogLayout/legacy/DialogLayout';

type Props = {
  onClose: () => void;
};

const HelpInCrisisDialog = ({ onClose }: Props) => {
  const logEvent = useLogEvent();

  const ref = useRef<HTMLDivElement>(null);

  const getHelpConfig = useSelector((state) => state.profile.getHelpConfig);

  const handleLifelineCallClick = () => {
    logEvent(MetricsEvents.NeedHelpHotlineCalled);
  };

  const handleLifelineChatClick = () => {
    logEvent(MetricsEvents.NeedHelpLifelineChatClicked);
  };

  const handleHotlineUrlClick = () => {
    logEvent(MetricsEvents.NeedHelpWebsiteClicked);
  };

  return (
    <>
      <CloseButton onClose={onClose} inDialog />

      <StyledDialog mobileLayout="fullscreen">
        <StyledDialogBody ref={ref}>
          <Title>If you’re in crisis</Title>
          <Description>
            Replika is not designed to help with crisis situations. <br />
            If you’re in the US, please call the National Suicide Prevention
            Lifeline (toll-free).
          </Description>

          {getHelpConfig && (
            <HelpLinks>
              <LifeLineLink
                as="a"
                href={getHelpConfig.web_chat.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLifelineChatClick}
              >
                Lifeline chat
              </LifeLineLink>
              <CallLink
                as="a"
                href={`tel:${getHelpConfig.phone.number}`}
                onClick={handleLifelineCallClick}
              >
                Call {getHelpConfig.phone.number}
              </CallLink>

              <LocalInfo>
                If you’re elsewhere, please call a local hotline
              </LocalInfo>

              <HotlineLink
                as="a"
                href={getHelpConfig.web_help.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleHotlineUrlClick}
              >
                {getHelpConfig.web_help.title}
              </HotlineLink>
            </HelpLinks>
          )}
        </StyledDialogBody>
      </StyledDialog>
    </>
  );
};

export default HelpInCrisisDialog;

const CloseButton = styled(DialogCloseButton)`
  position: fixed;
  border-radius: 50%;
  z-index: 2;

  width: 34px;
  height: 34px;
  top: 15px;
  right: 15px;
  svg {
    width: 24px;
    height: 24px;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 54px;
    height: 54px;
    top: 30px;
    right: 30px;
    svg {
      width: 36px;
      height: 36px;
    }
  }
`;

const StyledDialog = styled(Dialog)`
  min-width: 350px;
  margin-top: 132px;
  background: none;
  box-shadow: none;

  @media ${(p) => p.theme.breakpoints.tablet} {
    margin: 0;
  }
`;

const StyledDialogBody = styled(DialogBody)`
  text-align: center;
  padding: 0 30px 30px;
`;

const Title = styled.h3`
  margin: 0 0 15px;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
`;

const Description = styled.p`
  margin: 0 0 30px;
  max-width: 350px;
  font-size: 14px;
  line-height: 18px;
`;

const HelpLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LifeLineLink = styled(SolidButton)`
  width: 315px;
  margin: 0 0 15px;

  @media (max-width: 320px) {
    width: 280px;
  }
`;

const CallLink = styled(AccentButton)`
  width: 315px;
  margin: 0 0 15px;

  @media (max-width: 320px) {
    width: 280px;
  }
`;

const LocalInfo = styled.p`
  margin: 80px 0 20px;
  font-family:
    PPPangramSansRounded-Bold,
    -apple-system,
    BlinkMacSystemFont,
    Helvetica,
    Arial,
    sans-serif;
`;

const HotlineLink = styled(SolidButton)`
  width: 315px;

  @media (max-width: 320px) {
    width: 280px;
  }
`;
