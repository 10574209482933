import { CategoryTreeNode } from '../../types/models';

export default function findParentCategories(
  id: string,
  category: CategoryTreeNode | null,
  parentCategories: CategoryTreeNode[] = [],
): CategoryTreeNode[] {
  if (!category) return [];

  if (category.id === id) return [...parentCategories, category];

  if (category.children) {
    parentCategories = [...parentCategories, category];
    for (let cat of category.children) {
      let found = findParentCategories(id, cat, parentCategories);

      if (found.length > 0) {
        return found;
      }
    }
  }

  return [];
}
