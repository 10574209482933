import {
  ImageGenerationFailure,
  ImageGenerationPreview,
  ImageGenerationSuccess,
} from '../features/ImageGen/models';
import { ChatSuggestionListIncomingPayload } from './actions/chat';
import { SubscriptionRequestTypes, WsEventNames as WEN } from './enums';
import {
  Bot,
  BotMood,
  BotStatsUpdate,
  Chat,
  Credit,
  CurrencyEarn,
  FeedbackCloseType,
  FeedbackType,
  Message,
  MessageActionType,
  MessageEmotion,
  MessageOutcomingPayload,
  MessageReaction,
  NavigationPopup,
  NewMemory,
  Notification,
  RememberedStatement,
  UserProfile,
  WalletUpdateType,
} from './models';
import { SaleScreen } from './states';

export type ErrorIncomingPayload = {
  error_message: string;
};

export type HistoryIncomingPayload = {
  messages: Message[];
  message_reactions: MessageReaction[];
  more: boolean;
};

export type MessageIncomingPayload = Message;

export type AddMessageReactionPayload = {
  addReaction: {
    message_id: string;
    chat_id: string;
    reaction: MessageActionType;
  };
};

export function isAddReaction(val: object): val is AddMessageReactionPayload {
  return val.hasOwnProperty('addReaction');
}

export type RemoveMessageReactionPayload = {
  removeReaction: string;
};

export function isRemoveReaction(
  val: object,
): val is RemoveMessageReactionPayload {
  return val.hasOwnProperty('removeReaction');
}

export type MessageReactionIncomingPayload =
  | AddMessageReactionPayload
  | RemoveMessageReactionPayload;

export type FeedbackIncomingPayload = {
  session_id: string;
  feedback_view: 'Popup';
};

export type BotPayload = Bot;

export type BotStatsPayload = BotStatsUpdate;

export type BotChatPayload = Chat;

export type TypingPayload = {};

export type VoiceModeAction = 'enable' | 'disable';

export type VoiceMode = 'enabled' | 'disabled';

export type VoiceModePayload = {
  state: VoiceMode;
};

export type JourneyChangedPayload = {
  affected_track_ids: string[];
};

export type StatementRememberedPayload = RememberedStatement;

export type NavigationPopupPayload = {
  navigation_popup: NavigationPopup;
};

export type WalletPayload = {
  credit: Credit;
  earned?: CurrencyEarn;
  update_type?: WalletUpdateType;
};

export type NotificationsPayload = {
  notifications: Notification[];
};

export type SaleScreenIncomingPayload = SaleScreen;

export type ProfilePayload = UserProfile;

export type AdvancedAiMessagesPayload = {
  available_messages: number;
};

export type DiaryUnreadUpdatePayload = {
  unread_diaries: number;
};

export type BotMoodUpdatePayload = {
  mood_v2: BotMood;
};

interface IncomingWebsocketMessageBase<E extends WEN, P> {
  token?: string;
  event_name: E;
  payload: P;
}

type I<E extends WEN, P> = IncomingWebsocketMessageBase<E, P>;

export type IncomingWebsocketMessage =
  | I<WEN.Error, ErrorIncomingPayload>
  | I<WEN.Message, MessageIncomingPayload>
  | I<WEN.History, HistoryIncomingPayload>
  | I<WEN.Bot, BotPayload>
  | I<WEN.PersonalBotStats, BotStatsPayload>
  | I<WEN.PersonalBotChat, BotChatPayload>
  | I<WEN.MessageReaction, MessageReactionIncomingPayload>
  | I<WEN.ConversationFeedback, FeedbackIncomingPayload>
  | I<WEN.StartTyping, TypingPayload>
  | I<WEN.StopTyping, TypingPayload>
  | I<WEN.VoiceMode, VoiceModePayload>
  | I<WEN.JourneyChanged, JourneyChangedPayload>
  | I<WEN.StatementRemembered, StatementRememberedPayload>
  | I<WEN.NavigationPopup, NavigationPopupPayload>
  | I<WEN.WalletRequest, WalletPayload>
  | I<WEN.Wallet, WalletPayload>
  | I<WEN.Notifications, NotificationsPayload>
  | I<WEN.ChatSuggestionList, ChatSuggestionListIncomingPayload>
  | I<WEN.UserProfile, ProfilePayload>
  | I<WEN.AdvancedAiMessages, AdvancedAiMessagesPayload>
  | I<
      WEN.SubscriptionRequest,
      { type: SubscriptionRequestTypes; sale: SaleScreen }
    >
  | I<WEN.DiaryUnreadUpdate, DiaryUnreadUpdatePayload>
  | I<WEN.BotMoodUpdate, BotMoodUpdatePayload>
  | I<WEN.MemoryNewFactsUpdate, NewMemory>
  | I<WEN.MessageEmotion, MessageEmotion>
  | I<WEN.VoiceSampleStream, VoiceSampleStreamPayload>
  | I<WEN.AIGeneratedImageSuccess, ImageGenerationSuccess>
  | I<WEN.AIGeneratedImageFailure, ImageGenerationFailure>
  | I<WEN.AIGeneratedImagePreview, ImageGenerationPreview>;

export type HistoryOutcomingPayload = {
  chat_id: string;
  last_message_id?: string;
  limit: number;
};

export type StartChatSuggestionOutcomingPayload = {
  id: string;
  scenario_id: string;
  name: string;
  chat_id: string;
};

interface OutcomingWebsocketMessageBase<E extends WEN, P> {
  token?: string;
  event_name: E;
  payload: P;
  auth?: {
    device_id: string;
    user_id: string | undefined;
    auth_token: string | undefined;
  };
}

export type MessageReactionOutcomingPayload =
  | AddMessageReactionPayload
  | RemoveMessageReactionPayload;

export type FeedbackReactionOutcomingPayload = {
  session_id: string;
  feedback: FeedbackType;
  feedback_text?: string | undefined;
  feedback_view: 'Popup';
};

export type FeedbackCloseOutcomingPayload = {
  session_id: string;
  close_action: FeedbackCloseType;
  feedback_view: 'Popup';
};

export type FeedbackOutcomingPayload =
  | FeedbackReactionOutcomingPayload
  | FeedbackCloseOutcomingPayload;

export type TextInputPayload = {
  chat_id: string;
};

export type EmptyPayload = {};

export type StartMissionPayload = {
  mission_id: string;
  current_mission_action?: 'skip' | 'drop';
};

export type LipsyncPhoneme = {
  start: number;
  end: number;
  phoneme: string;
};

export type VoiceSampleStreamPayload = {
  meta: {
    checksum: number;
    total_chunks: number;
    chunk_index: number;
    timestamp: number;
    permitted_actions?: MessageActionType[];
  };
  lipsync_data?: LipsyncPhoneme[];
  data: string;
  text: string;
  id: string;
};

export type MessageRerollOutcomingPayload = {
  message_id: string;
};

type O<E extends WEN, P> = OutcomingWebsocketMessageBase<E, P>;

export type OutcomingWebsocketMessage =
  | O<WEN.Init, EmptyPayload>
  | O<WEN.History, HistoryOutcomingPayload>
  | O<WEN.Message, MessageOutcomingPayload>
  | O<WEN.MessageReaction, MessageReactionOutcomingPayload>
  | O<WEN.ConversationFeedback, FeedbackOutcomingPayload>
  | O<WEN.TextInputDetected, TextInputPayload>
  | O<WEN.AppForeground, EmptyPayload>
  | O<WEN.StartMission, StartMissionPayload>
  | O<WEN.SkipMission, EmptyPayload>
  | O<WEN.DropMission, EmptyPayload>
  | O<WEN.MainScreen, EmptyPayload>
  | O<WEN.ApplicationStarted, EmptyPayload>
  | O<WEN.ChatScreen, EmptyPayload>
  | O<WEN.WalletRequest, EmptyPayload>
  | O<WEN.ChangeVoiceMode, {}>
  | O<WEN.StartVoiceStreaming, {}>
  | O<WEN.StreamVoiceChunks, {}>
  | O<WEN.EndVoiceStreaming, {}>
  | O<WEN.ChatSuggestionList, EmptyPayload>
  | O<WEN.StartChatSuggestion, StartChatSuggestionOutcomingPayload>
  | O<WEN.SendVoiceCallFeedback, {}>
  | O<WEN.UserProfile, EmptyPayload>
  | O<WEN.MessageReroll, MessageRerollOutcomingPayload>
  | O<WEN.DiaryUnreadUpdate, {}>
  | O<WEN.BotMoodUpdate, {}>;
