function getSuffix(res: number) {
  return res < 2 ? '@2x' : `@${res}x`;
}

// TODO: make sure to upload 3x images
export default function imageSourceSet(
  src: string,
  maxResolution = 2,
  ext: null | 'avif' | 'webp' = null,
) {
  const resolutions = [1.5, 2, 3].filter((res) => res <= maxResolution);
  const srcSet = resolutions.map((res) => {
    const replacement = getSuffix(res) + (ext ? `.${ext}` : '.$1');
    const path = src.replace(/\.(png|jpg|jpeg)/, replacement);

    return `${path} ${res}x`;
  });

  return {
    src,
    srcSet: srcSet.join(','),
  };
}
