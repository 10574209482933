import useSWRMutation from 'swr/mutation';
import swrFetcher from '../../core/api/swrFetcher';
import { SWRKey } from '../../core/api/types';
import { Bot } from '../../types/models';
import { API_BASE_URL } from '../../utils/uri';

export const useOnboardingAvatarMutation = () => {
  return useSWRMutation<Bot, any, SWRKey, string>(
    `${API_BASE_URL}/personal_bot/onboarding_avatar`,
    (url, { arg: onboarding_avatar }) =>
      swrFetcher<Bot>(url, {
        method: 'PUT',
        body: {
          onboarding_avatar,
        },
      }),
    {
      revalidate: false,
    },
  );
};
