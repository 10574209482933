import * as React from 'react';
import styled from 'styled-components/macro';
import { dialogMobileMedia } from '../../utils/mobileMedia';
import { AccentButton } from '../Buttons';

type Props = {
  onClose: () => void;
  className?: string;
  header?: React.ReactNode;
};

const TITLE = 'Welcome to Replika Pro';
const DESCRIPTION =
  'Your Replika’s world just got a lot bigger! Let your imagination run wild, enjoy exclusive perks, and have fun.';
const CLOSE_BUTTON = 'Start exploring';

function SuccessefulSubscription({ onClose, className, header }: Props) {
  return (
    <SuccessefulSubscriptionRoot className={className}>
      {header}

      <Title>{TITLE}</Title>

      <Description>{DESCRIPTION}</Description>

      <Actions>
        <StyledAccentButton onClick={onClose}>
          {CLOSE_BUTTON}
        </StyledAccentButton>
      </Actions>
    </SuccessefulSubscriptionRoot>
  );
}

export default SuccessefulSubscription;

const SuccessefulSubscriptionRoot = styled.div`
  flex: 0 0 auto;
  height: 100%;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.fgColor};
  padding: 0 15px;

  ${dialogMobileMedia`
    flex: 1 0 auto;
    height: auto;
    width: 100%;
    max-width: 100vw;
  `}
`;

const Title = styled.h3`
  font-size: 28px;
  line-height: 32px;
  margin: 0 0 20px;
  width: 100%;
  text-align: center;

  ${dialogMobileMedia`
    margin-top: 50px;
  `}
`;

const Description = styled.p`
  width: 100%;
  margin: 0 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-family: ${(p) => p.theme.fonts.display};
`;

const StyledAccentButton = styled(AccentButton)`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
`;

const Actions = styled.div`
  width: 100%;
  margin: 50px 20px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * ~ * {
    margin-top: 16px;
  }

  ${dialogMobileMedia`
    position: initial;
    height: auto;
    flex: 1 0 auto;
  `}
`;
