import * as React from 'react';
import styled, { keyframes } from 'styled-components/macro';

import outlineCss from 'src/components/outlineCss';
import { ReactComponent as CloseIcon } from '../../../icons/Close.svg';
import { dialogMobileMedia } from '../../../utils/mobileMedia';
import FocusTrap from '../../FocusTrap';
import IconButton from '../../legacy/IconButton';
import LinkButton from '../../legacy/LinkButton';
import SolidButton from '../../legacy/SolidButton';
import { DialogMobile } from '../../responsive';
import DialogContext from '../DialogContext';

declare var _iub: any;

type MobileLayout = 'fullscreen' | 'dialog' | 'action-sheet';

export const APPEAR_TIMEOUT = 300;

const APPEARING_ANIMATION = keyframes`
  from {
    transform: translate(0, 20%);
    opacity: 0;
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
`;

const HIDING_ANIMATION = keyframes`
  from {
    transform: translate(0, 0);
    opacity: 1;
  }

  to {
    transform: translate(0, 20%);
    opacity: 0;
  }
`;

export const DialogBody = styled.div`
  flex: 0 0 auto;
  padding: 0 45px;
  border-radius: 8px;
  max-width: 456px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DialogBodyForm = DialogBody.withComponent('form');

function mobileLayoutCss(mobileLayout: MobileLayout) {
  switch (mobileLayout) {
    case 'dialog':
      return dialogMobileMedia`
        margin: 10vh 0;
        max-width: 90vw;
      `;

    case 'fullscreen':
      return dialogMobileMedia`
        max-height: initial;
        left: 0;
        top: 0;
        height: 100svh;
        animation: none;
        font-size: 0.8em;
        line-height: 1.5em;
        border-radius: 0;
        margin: 0vh;
        && {
          min-width: auto;
          width: 100vw;
        }
      `;

    case 'action-sheet':
      return dialogMobileMedia`
        margin: 0vh;
        bottom: 0;
        border-radius: 8px 8px 0 0;
        && {
          min-width: auto;
          width: 100vw;
        }
      `;
  }
}

function mobileLayoutBodyCss(mobileLayout: MobileLayout) {
  switch (mobileLayout) {
    case 'dialog':
      return '';
    case 'fullscreen':
      return dialogMobileMedia`
        overflow-y: scroll;
        border-radius: 0px;
        margin: 0 auto;
        padding: 0 20px 20px;
        width: 100vw;
        flex: 1 1 auto;
      `;
    case 'action-sheet':
      return dialogMobileMedia`
        padding: 0 20px 20px;
        max-width: 100%;
        justify-content: center;
      `;
  }
}

const DialogRoot = styled.div<{
  $isActive: boolean;
  $mobileLayout?: MobileLayout;
}>`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;

  animation: ${(p) => (p.$isActive ? APPEARING_ANIMATION : HIDING_ANIMATION)}
    ${APPEAR_TIMEOUT}ms ease-in-out both;
  pointer-events: ${(p) => (p.$isActive ? 'initial' : 'none')};
  user-select: ${(p) => (p.$isActive ? 'inherit' : 'none')};

  max-width: 100vw;
  background: ${(p) => p.theme.dialogBgColor};
  box-shadow: 0 5px 20px 0 ${(p) => p.theme.shadowColor};
  color: ${(p) => p.theme.fgColor};
  border-radius: 24px;
  margin: 10vh 0;

  ${({ $mobileLayout = 'fullscreen' }) => mobileLayoutCss($mobileLayout)};

  & ${DialogBody}, & ${DialogBodyForm} {
    ${({ $mobileLayout = 'fullscreen' }) => mobileLayoutBodyCss($mobileLayout)};
  }
`;

type DialogProps = {
  className?: string;
  mobileLayout?: MobileLayout;
  'data-testid'?: string;
  children: React.ReactNode;
};

export function Dialog(props: DialogProps) {
  const { isActive, onScrollClick } = React.useContext(DialogContext);
  const { mobileLayout, children, className } = props;

  const [preventClick, setPreventClick] = React.useState(false);

  const cookieBannerShown =
    _iub?.cs?.api?.bannerShown ||
    !!document.querySelector('.iubenda-cs-visible');

  const [containers, setContainers] = React.useState<HTMLElement[]>([]);

  React.useEffect(() => {
    setContainers(
      Array.from(
        document.querySelectorAll(
          '#dialog-scroll, [data-dialogtabbable="true"]',
        ),
      ),
    );
  }, []);

  return (
    <FocusTrap
      focusTrapOptions={{
        allowOutsideClick(e) {
          return e.target instanceof HTMLElement
            ? // allow clicking on background to close dialog
              e.target.id === 'dialog-back' ||
                e.target.id === 'dialog-scroll' ||
                // allow certain elements (like buttons in popovers) to be active
                e.target.getAttribute('data-indialog') === 'true' ||
                // allow cookie banner interaction
                cookieBannerShown
            : false;
        },
        initialFocus() {
          return (
            document.querySelector<HTMLElement>('[data-initialfocus]') ?? false
          );
        },
      }}
      containerElements={containers}
    >
      <DialogScroll
        id="dialog-scroll"
        onClick={(e) => {
          if (!preventClick) {
            onScrollClick(e);
          } else {
            setPreventClick(false);
          }
        }}
        onPointerDown={(e) => {
          if (
            e.target instanceof HTMLElement &&
            e.target.id !== 'dialog-scroll'
          ) {
            setPreventClick(true);
          }
        }}
        data-testid={props['data-testid']}
        $mobileLayout={mobileLayout}
      >
        <DialogRoot
          role="dialog"
          aria-modal="true"
          className={className}
          $isActive={isActive}
          $mobileLayout={mobileLayout}
        >
          {children}
        </DialogRoot>
      </DialogScroll>
    </FocusTrap>
  );
}

type DialogHeaderProps = {
  title?: React.ReactNode;
  titleRole?: 'primary' | 'secondary';
  subtitle?: React.ReactNode;
  onClose?: () => void;
  mobileAction?: React.ReactNode;
  className?: string;
};

export const DialogHeader = ({
  onClose,
  mobileAction = null,
  title,
  titleRole = 'primary',
  subtitle,
  className,
}: DialogHeaderProps) => {
  return (
    <DialogHeaderRoot className={className}>
      {typeof title === 'string' ? (
        <Title
          data-initialfocus
          $role={titleRole}
          $closable={!!onClose}
          tabIndex={-1}
        >
          {title}
        </Title>
      ) : (
        title
      )}
      {typeof subtitle === 'string' ? (
        <Subtitle>{subtitle}</Subtitle>
      ) : (
        subtitle
      )}
      {onClose && <DialogCloseButton onClose={onClose} />}
      {mobileAction && <DialogMobile>{mobileAction}</DialogMobile>}
    </DialogHeaderRoot>
  );
};

export const DialogCloseButton = ({
  onClose,
  inDialog,
  className,
}: {
  className?: string;
  inDialog?: boolean;
  onClose: () => void;
}) => (
  <DialogCloseButtonRoot
    className={className}
    onClick={onClose}
    type="button"
    label="close popup"
    data-indialog={inDialog}
  >
    <StyledCloseIcon />
  </DialogCloseButtonRoot>
);

const DialogHeaderRoot = styled.div`
  flex: 0 0 auto;
  font-size: 20px;
  padding: 30px 30px 0;
  position: relative;
  align-items: center;

  ${dialogMobileMedia`
    padding: 24px 30px 10px;
  `}
`;

const DialogCloseButtonRoot = styled(IconButton)`
  position: absolute;
  height: 34px;
  width: 34px;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 14px;
  color: ${(p) => p.theme.fgColor};
  background: ${(p) => p.theme.navigationBgColor};

  &:focus,
  &:focus-visible {
    ${outlineCss()}
  }

  & > svg {
    width: 26px;
    height: 26px;
  }

  ${dialogMobileMedia`
    right: 15px;
    top: 15px;
  `}
`;

const StyledCloseIcon = styled(CloseIcon)`
  display: block;
  width: 16px;
  height: 16px;
`;

export const FormContents = styled.form`
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.borderColor};
  padding: 40px 0 20px;

  &:last-child {
    border-bottom: none;
  }

  ${dialogMobileMedia`
    padding: 0 0 20px;
    border-bottom: 0;
    max-width: 320px;
    margin: 0 auto;
  `}
`;

export const DialogFooter = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
`;

export const CenteredDialogBody = styled(DialogBody)`
  text-align: center;
  padding: 16px 32px 32px;
`;

export const CenteredDialogBodyForm = CenteredDialogBody.withComponent('form');

type ConfirmDialogBodyProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  confirmText: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmTo?: string;
  cancelTo?: string;
};

const ConfirmDialogTitle = styled.h2``;

const ConfirmDialogDescription = styled.p`
  margin: 16px auto 32px;
  max-width: 300px;
  font-size: 16px;
  line-height: 22px;
`;

const Title = styled.h3<{ $closable: boolean; $role: 'primary' | 'secondary' }>`
  margin: ${(p) => (p.$closable ? '0 30px 0 30px' : 0)};
  text-align: center;
  color: ${(p) =>
    p.$role === 'secondary' ? p.theme.secondaryFgColor : p.theme.fgColor};
  font-size: ${(p) => (p.$role === 'secondary' ? 16 : 20)}px;
  line-height: ${(p) => (p.$role === 'secondary' ? 18 : 24)}px;
`;

const Subtitle = styled.h4`
  display: block;
  font-size: 15px;
  line-height: 20px;
  font-weight: normal;
  text-align: center;
  margin: 16px 0;
  width: 100%;
  padding: 0 30px;
`;

export const DialogButton = styled(SolidButton)`
  background-color: ${(p) =>
    p.disabled
      ? p.theme.dialogButtonDisabledBgColor
      : p.theme.dialogButtonBgColor};
`;

export const ConfirmDialogBody = ({
  title,
  description,
  confirmText,
  cancelText = 'Cancel',
  onConfirm,
  onCancel,
  confirmTo,
  cancelTo,
}: ConfirmDialogBodyProps) => {
  return (
    <CenteredDialogBody>
      <ConfirmDialogTitle data-initialfocus tabIndex={-1}>
        {title}
      </ConfirmDialogTitle>
      <ConfirmDialogDescription>{description}</ConfirmDialogDescription>
      <DialogButton to={confirmTo} onClick={onConfirm}>
        {confirmText}
      </DialogButton>
      <CancelButton to={cancelTo} onClick={onCancel}>
        {cancelText}
      </CancelButton>
    </CenteredDialogBody>
  );
};

const CancelButton = styled(LinkButton)`
  margin-top: 16px;
  font-size: 16px;
`;

function mobileLayoutScrollCss(mobileLayout: MobileLayout) {
  switch (mobileLayout) {
    case 'action-sheet':
      return dialogMobileMedia`
        bottom: 0;
        /* fixes weird Chrome bug */
        overflow-y: hidden;
      `;

    default:
      return '';
  }
}

const DialogScroll = styled.div.attrs({ 'data-hidescrollbar': true })<{
  $mobileLayout?: MobileLayout;
}>`
  flex: 1 0 auto;
  position: absolute;
  z-index: 1;
  overflow-y: auto;
  max-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ $mobileLayout = 'fullscreen' }) => mobileLayoutScrollCss($mobileLayout)}
`;

export function DialogTabbable({ children }) {
  return <div data-dialogtabbable>{children}</div>;
}
