import * as A from '../../types/actions';
import { AsyncActionTypes, MemoryFactType } from '../../types/enums';
import {
  CustomerFact,
  MemoryCategory,
  MemoryFactCreate,
  MemoryFactPatch,
  MemoryPerson,
  MemoryPersonCreate,
  MemoryPersonPatch,
  MemoryRelation,
  NewMemory,
  RobotFact,
} from '../../types/models';
import { DA } from '../../types/redux';
import { apiAction } from '../../utils/asyncAction';
import fetchOptions from '../../utils/fetchOptions';
import { API_BASE_URL } from '../../utils/uri';

export const getMemory =
  (): DA<{
    [MemoryFactType.CustomerFacts]: CustomerFact[];
    [MemoryFactType.RobotFacts]: RobotFact[];
    persons: MemoryPerson[];
  }> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetMemory>(
      AsyncActionTypes.GetMemory,
      dispatch,
      {},
      {
        onRequest: () => fetch(`${API_BASE_URL}/memory/v3/`, fetchOpts),
      },
    );
  };

export const getNewMemory = (): DA<NewMemory> => async (dispatch, getState) => {
  const fetchOpts = fetchOptions(getState(), 'GET');

  return apiAction<A.GetNewMemory>(
    AsyncActionTypes.GetNewMemory,
    dispatch,
    {},
    {
      onRequest: () => fetch(`${API_BASE_URL}/memory/v3/new_facts`, fetchOpts),
    },
  );
};

export const getMemoryRelations =
  (): DA<MemoryRelation[]> => async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetMemoryRelations>(
      AsyncActionTypes.GetMemoryRelations,
      dispatch,
      {},
      {
        onRequest: () => fetch(`${API_BASE_URL}/memory/relations`, fetchOpts),
      },
    );
  };

export const getMemoryCategories =
  (): DA<MemoryCategory[]> => async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetMemoryCategories>(
      AsyncActionTypes.GetMemoryCategories,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/memory/v3/unstructured_fact_categories`,
            fetchOpts,
          ),
      },
    );
  };

export const createMemoryPerson =
  (person: MemoryPersonCreate): DA<MemoryPerson> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'POST', person);

    return apiAction<A.CreateMemoryPerson>(
      AsyncActionTypes.CreateMemoryPerson,
      dispatch,
      { person },
      {
        onRequest: () => fetch(`${API_BASE_URL}/memory/persons`, fetchOpts),
      },
    );
  };

export const updateMemoryPerson =
  (id: string, person: MemoryPersonPatch): DA<{}> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'PUT', person);

    return apiAction<A.UpdateMemoryPerson>(
      AsyncActionTypes.UpdateMemoryPerson,
      dispatch,
      { id, person },
      {
        onRequest: () =>
          fetch(`${API_BASE_URL}/memory/persons/${id}`, fetchOpts),
      },
    );
  };

export const createMemoryFact =
  (factType: MemoryFactType, fact: MemoryFactCreate): DA<{}> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'POST', fact);

    return apiAction<A.CreateMemoryFact>(
      AsyncActionTypes.CreateMemoryFact,
      dispatch,
      { fact, factType },
      {
        onRequest: () =>
          fetch(`${API_BASE_URL}/memory/v3/${factType}`, fetchOpts),
      },
    );
  };

export const updateMemoryFact =
  (factType: MemoryFactType, id: string, fact: MemoryFactPatch): DA<{}> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'PUT', fact);

    return apiAction<A.UpdateMemoryFact>(
      AsyncActionTypes.UpdateMemoryFact,
      dispatch,
      { fact, factType: fact.fact_type || factType, id },
      {
        onRequest: () =>
          fetch(`${API_BASE_URL}/memory/v3/${factType}/${id}`, fetchOpts),
      },
    );
  };

export const processNewMemories =
  (newMemories: {
    save_customer_fact_ids: string[];
    discard_customer_fact_ids: string[];
    save_robot_fact_ids: string[];
    discard_robot_fact_ids: string[];
    save_person_ids: string[];
    discard_person_ids: string[];
  }): DA<{}> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'POST', newMemories);

    return apiAction<A.ProcessNewMemories>(
      AsyncActionTypes.ProcessNewMemories,
      dispatch,
      newMemories,
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/memory/v3/actions/process_new_facts`,
            fetchOpts,
          ),
      },
    );
  };

export const markMemoryRead =
  (memoryType: MemoryFactType | 'persons', id: string): DA<{}> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'POST', {
      customer_fact_ids:
        memoryType === MemoryFactType.CustomerFacts ? [id] : [],
      robot_fact_ids: memoryType === MemoryFactType.RobotFacts ? [id] : [],
      person_ids: memoryType === 'persons' ? [id] : [],
    });

    return apiAction<A.MarkMemoryRead>(
      AsyncActionTypes.MarkMemoryRead,
      dispatch,
      { id, memoryType },
      {
        onRequest: () =>
          fetch(`${API_BASE_URL}/memory/v3/actions/mark_as_read`, fetchOpts),
      },
    );
  };

export const deleteMemory =
  (memoryType: MemoryFactType | 'persons', id: string): DA<{}> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'DELETE');

    return apiAction<A.DeleteMemory>(
      AsyncActionTypes.DeleteMemory,
      dispatch,
      { id, memoryType },
      {
        onRequest: () =>
          fetch(`${API_BASE_URL}/memory/v3/${memoryType}/${id}`, fetchOpts),
      },
    );
  };

export const batchDeleteMemories =
  ({
    customer_fact_ids,
    robot_fact_ids,
    person_ids,
  }: {
    customer_fact_ids: string[];
    robot_fact_ids: string[];
    person_ids: string[];
  }): DA<{}> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'DELETE', {
      robot_fact_ids,
      customer_fact_ids,
      person_ids,
    });

    return apiAction<A.BatchDeleteMemories>(
      AsyncActionTypes.BatchDeleteMemories,
      dispatch,
      {
        customer_fact_ids,
        robot_fact_ids,
        person_ids,
      },
      {
        onRequest: () =>
          fetch(`${API_BASE_URL}/memory/v3/actions/batch_delete`, fetchOpts),
      },
    );
  };
