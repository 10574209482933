import { UnityWebEngine } from '../../types/models';

export const LOCAL_WEB_ENGINE: UnityWebEngine = {
  files: {
    'web-engine.loader.js': '/.eggine/web-engine.loader.js',
    'web-engine.wasm.gz': '/.eggine/web-engine.wasm.gz',
    'web-engine.data.gz': '/.eggine/web-engine.data.gz',
    'web-engine.framework.js.gz': '/.eggine/web-engine.framework.js.gz',
  },
};

export const LOCAL_BASE_BUNDLES = [
  'base_anima_emotions',
  'base_anima_idles',
  'base_anima_listening',
  'base_anima_room_interaction',
  'base_anima_store',
  'base_anima_talking',
  'base_anima_thinking',
  'base_anima_walking',
  'base_anima',
  'base_beh',
  'base_hair',
  'base_lipsync',
  'base_model_body',
  'base_model_clothes',
  'base_model_hairs_1_10',
  'base_model_hairs_11_20',
  'base_model_hairs_21_30',
  'base_model_hairs_31_40',
  'base_model_head_hair',
  'base_model_head',
  'base_model_shaders',
  'base_model',
  'base_pfp',
  'base_prefabs',
  'base_roleplay',
  'base_selfie',
];

export const BACKGROUNDS = {
  'main-page-framed': {
    DEFAULT: '/images/backgrounds/main-page-framed.jpg',
    DARK: '/images/backgrounds/main-page-framed--dark.jpg',
  },
  onboarding: {
    DEFAULT: '/images/backgrounds/onboarding.jpg',
    DARK: '/images/backgrounds/onboarding.jpg',
  },
  default: {
    DEFAULT: '/images/backgrounds/default.jpg',
    DARK: '/images/backgrounds/default--dark.jpg',
  },
  'default-arc': {
    DEFAULT: '/images/backgrounds/default-arc.jpg',
    DARK: '/images/backgrounds/default-arc--dark.jpg',
  },
  sunset: {
    DEFAULT: '/images/backgrounds/sunset.jpg',
    DARK: '/images/backgrounds/sunset--dark.jpg',
  },
  purple: {
    DEFAULT: '/images/backgrounds/purple.jpg',
    DARK: '/images/backgrounds/purple.jpg',
  },
  silverPink: {
    DEFAULT: '/images/backgrounds/silverPink.jpg',
    DARK: '/images/backgrounds/silverPink.jpg',
  },
  'arc-centered': {
    DEFAULT: '/images/backgrounds/arc-centered.jpg',
    DARK: '/images/backgrounds/arc-centered--dark.jpg',
  },
} as const;

export const EXCLUDE_ONBOARDING_BUNDLES = [
  'base_anima_emotions',
  'base_anima_idles',
  'base_anima_listening',
  'base_anima_room_interaction',
  'base_anima_store',
  'base_anima_talking',
  'base_anima_thinking',
  'base_anima_walking',
  'base_anima',
  'base_lipsync',
  'base_roleplay',
  'base_selfie',
];
