import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import * as React from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
  onError: (message: string) => void;
  onSuccess?: () => void;
  onStatusChange: (status: 'idle' | 'subscribing') => void;
  setLatestInvoiceId: (latestInvoiceId: string | null) => void;
  processPayment: (
    paymentMethodId: string,
    stripe: Stripe,
  ) => Promise<string | null>;
};

function StripeForm({
  className,
  children,
  onError,
  onSuccess,
  onStatusChange,
  setLatestInvoiceId,
  processPayment,
}: Props) {
  const elements = useElements();
  const stripe = useStripe();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!stripe || !elements) return;
      onError('');

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) return;

      onStatusChange('subscribing');

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        if (error.message) {
          onError(error.message);
        }
        console.error(error);
        onStatusChange('idle');
      } else if (paymentMethod) {
        const paymentMethodId = paymentMethod.id;

        const invoiceId = await processPayment(paymentMethodId, stripe);

        if (invoiceId) {
          onError('Your card was declined.');
        } else {
          onSuccess?.();
        }

        onStatusChange('idle');
      }
    } catch (e) {
      if (e instanceof Error) {
        onError(e.message);
      }
      setLatestInvoiceId(null);
      onStatusChange('idle');
    }
  };

  return (
    <form className={className} onSubmit={handleSubmit}>
      {children}
    </form>
  );
}

export default StripeForm;
