import { isEnvironment } from './environment';
import { getSessionSetting } from './session';

export default function isDebugEnabled() {
  if (isEnvironment('localhost')) {
    return getSessionSetting('debug') !== false;
  } else {
    return getSessionSetting('debug') === true;
  }
}
