import { isEqual } from 'lodash';
import * as A from '../../types/actions';
import { ToError, ToRequest, ToSuccess } from '../../types/asyncActions';
import {
  ActionTypes,
  AsyncActionTypes,
  MemoryFactType,
} from '../../types/enums';
import {
  MemoryFactWithType,
  MemoryPerson,
  MemoryRecord,
  MemoryRelation,
} from '../../types/models';
import { Reducer } from '../../types/redux';
import { MemoryState, Progress } from '../../types/states';
import applyReducer from '../../utils/applyReducer';

const DEFAULT_STATE: MemoryState = {
  getNewMemoryProgress: Progress.initial,
  getMemoryProgress: Progress.initial,
  getMemoryCategoriesProgress: Progress.initial,
  processNewMemoriesProgress: Progress.initial,
  batchDeleteMemoriesProgress: Progress.initial,
  persist: {
    newMemories: {
      sortedFacts: undefined,
      persons: undefined,
      newMemoriesCount: 0,
    },
    memoryCategories: [],
    sortedFacts: undefined,
    persons: undefined,
    relations: [],
  },
};

type R<X extends A.MemoryAction | A.IncomingWsAction> = Reducer<MemoryState, X>;
type RRequest<X> = Reducer<MemoryState, ToRequest<X>>;
type RError<X> = Reducer<MemoryState, ToError<X>>;
type RSuccess<X> = Reducer<MemoryState, ToSuccess<X>>;

const resetState: R<any> = (state) => ({
  ...DEFAULT_STATE,
});

function sortMemoriesByTime<T extends MemoryRecord>(memories: Array<T>) {
  return memories.sort((a, b) => {
    if (!a.creation_timestamp) return 1;
    if (!b.creation_timestamp) return -1;
    if (a.creation_timestamp === b.creation_timestamp) return 0;
    return a.creation_timestamp < b.creation_timestamp ? 1 : -1;
  });
}

function sortPersonsByRelation(
  memories: MemoryPerson[],
  relations: MemoryRelation[],
) {
  return memories.sort((a, b) => {
    const aRelationName = relations.find(
      (relation) => relation.id === a.relation_id,
    )?.name;
    const bRelationName = relations.find(
      (relation) => relation.id === b.relation_id,
    )?.name;

    if (!aRelationName) return 1;
    if (!bRelationName) return -1;
    if (aRelationName === bRelationName) {
      // we want to push persons without a name to the start of same relationship group
      if (!a.name || !b.name) return 1;
      if (a.name === b.name) return 0;
      return a.name > b.name ? 1 : -1;
    }
    return aRelationName > bRelationName ? 1 : -1;
  });
}

const getMemoryRequest: RRequest<A.GetMemory> = (state) => ({
  ...state,
  getMemoryProgress: Progress.sending,
});

const getMemorySuccess: RSuccess<A.GetMemory> = (state, { result }) => {
  return {
    ...state,
    getMemoryProgress: Progress.success,
    persist: {
      ...state.persist,
      sortedFacts: sortMemoriesByTime<MemoryFactWithType>([
        ...result.customer_facts.map(
          (fact) =>
            ({
              ...fact,
              factType: MemoryFactType.CustomerFacts,
            }) as const,
        ),
        ...result.robot_facts.map(
          (fact) =>
            ({
              ...fact,
              factType: MemoryFactType.RobotFacts,
            }) as const,
        ),
      ]),
      persons: !isEqual(state.persist.persons, result.persons)
        ? sortPersonsByRelation(result.persons, state.persist.relations)
        : state.persist.persons,
    },
  };
};

const getMemoryError: RError<A.GetMemory> = (state) => ({
  ...state,
  getMemoryProgress: Progress.error,
});

const getNewMemoryRequest: RRequest<A.GetNewMemory> = (state) => ({
  ...state,
  getNewMemoryProgress: Progress.sending,
});

const getNewMemorySuccess: RSuccess<A.GetNewMemory> = (state, { result }) => {
  return {
    ...state,
    getNewMemoryProgress: Progress.success,
    persist: {
      ...state.persist,
      newMemories: {
        sortedFacts: sortMemoriesByTime<MemoryFactWithType>([
          ...result.customer_facts.map(
            (fact) =>
              ({
                ...fact,
                factType: MemoryFactType.CustomerFacts,
              }) as const,
          ),
          ...result.robot_facts.map(
            (fact) =>
              ({
                ...fact,
                factType: MemoryFactType.RobotFacts,
              }) as const,
          ),
        ]),
        persons: !isEqual(state.persist.newMemories.persons, result.persons)
          ? sortPersonsByRelation(result.persons, state.persist.relations)
          : state.persist.newMemories.persons,
        newMemoriesCount:
          result.customer_facts.length +
          result.robot_facts.length +
          result.persons.length,
      },
    },
  };
};

const getNewMemoryError: RError<A.GetNewMemory> = (state) => ({
  ...state,
  getNewMemoryProgress: Progress.error,
});

const setMemoryRelations: RSuccess<A.GetMemoryRelations> = (
  state,
  { result },
) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      relations: result,
      persons:
        state.persist.persons && !isEqual(state.persist.relations, result)
          ? sortPersonsByRelation(state.persist.persons, result)
          : state.persist.persons,
    },
  };
};

const getMemoryCategoriesRequest: RRequest<A.GetMemoryCategories> = (
  state,
) => ({
  ...state,
  getMemoryCategoriesProgress: Progress.sending,
});

const getMemoryCategoriesSuccess: RSuccess<A.GetMemoryCategories> = (
  state,
  { result },
) => {
  return {
    ...state,
    getMemoryCategoriesProgress: Progress.success,
    persist: {
      ...state.persist,
      memoryCategories: result,
    },
  };
};

const getMemoryCategoriesError: RError<A.GetMemoryCategories> = (state) => ({
  ...state,
  getMemoryCategoriesProgress: Progress.error,
});

const deleteMemory: RSuccess<A.DeleteMemory> = (state, { params }) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      ...(params.memoryType === 'persons'
        ? {
            persons: state.persist.persons?.filter((p) => p.id !== params.id),
          }
        : {
            sortedFacts: state.persist.sortedFacts?.filter(
              (f) => f.id !== params.id,
            ),
          }),
    },
  };
};

const batchDeleteMemoriesRequest: RRequest<A.BatchDeleteMemories> = (state) => {
  return {
    ...state,
    batchDeleteMemoriesProgress: Progress.sending,
  };
};

const batchDeleteMemoriesSuccess: RSuccess<A.BatchDeleteMemories> = (
  state,
  { params },
) => {
  return {
    ...state,
    batchDeleteMemoriesProgress: Progress.success,
    persist: {
      ...state.persist,
      sortedFacts: sortMemoriesByTime<MemoryFactWithType>([
        ...(state.persist.sortedFacts?.filter(
          (fact) =>
            !params.customer_fact_ids.includes(fact.id) &&
            !params.robot_fact_ids.includes(fact.id),
        ) || []),
      ]),
      persons: sortPersonsByRelation(
        state.persist.persons?.filter(
          (person) => !params.person_ids.includes(person.id),
        ) || [],
        state.persist.relations,
      ),
    },
  };
};

const batchDeleteMemoriesError: RError<A.BatchDeleteMemories> = (state) => {
  return {
    ...state,
    batchDeleteMemoriesProgress: Progress.error,
  };
};

const processNewMemoriesRequest: RRequest<A.ProcessNewMemories> = (state) => {
  return {
    ...state,
    processNewMemoriesProgress: Progress.sending,
  };
};

const processNewMemoriesSuccess: RSuccess<A.ProcessNewMemories> = (
  state,
  { params },
) => {
  return {
    ...state,
    processNewMemoriesProgress: Progress.success,
    filterOption: 'all',
    filteredFacts: [],
    persist: {
      ...state.persist,
      newMemories: DEFAULT_STATE.persist.newMemories,
      sortedFacts: sortMemoriesByTime<MemoryFactWithType>([
        ...(state.persist.newMemories?.sortedFacts?.filter(
          (fact) =>
            params.save_customer_fact_ids.includes(fact.id) ||
            params.save_robot_fact_ids.includes(fact.id),
        ) || []),
        ...(state.persist.sortedFacts ? state.persist.sortedFacts : []),
      ]),
      persons: sortPersonsByRelation(
        [
          ...(state.persist.newMemories?.persons?.filter((person) =>
            params.save_person_ids.includes(person.id),
          ) || []),
          ...(state.persist.persons ? state.persist.persons : []),
        ],
        state.persist.relations,
      ),
    },
  };
};

const processNewMemoriesError: RError<A.ProcessNewMemories> = (state) => {
  return {
    ...state,
    processNewMemoriesProgress: Progress.error,
  };
};

const markMemoryRead: RSuccess<A.MarkMemoryRead> = (state, { params }) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      ...(params.memoryType === 'persons'
        ? {
            persons: state.persist.persons?.map((p) => {
              const isRead = p.id === params.id;

              return {
                ...p,
                read: isRead ? isRead : p.read,
              };
            }),
          }
        : {
            sortedFacts:
              state.persist.sortedFacts &&
              sortMemoriesByTime<MemoryFactWithType>(
                state.persist.sortedFacts.map((f) => {
                  const isRead = f.id === params.id;

                  return {
                    ...f,
                    read: isRead ? isRead : f.read,
                  };
                }),
              ),
          }),
    },
  };
};

const createMemoryPerson: RSuccess<A.CreateMemoryPerson> = (
  state,
  { result },
) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      persons: sortPersonsByRelation(
        [result, ...(state.persist.persons ? state.persist.persons : [])],
        state.persist.relations,
      ),
    },
  };
};

const updateMemoryPerson: RSuccess<A.UpdateMemoryPerson> = (
  state,
  { params },
) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      persons: state.persist.persons?.map((p) =>
        p.id === params.id ? { ...p, ...params.person } : p,
      ),
    },
  };
};

const createMemoryFact: RSuccess<A.CreateMemoryFact> = (
  state,
  { result, params },
) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      sortedFacts: sortMemoriesByTime<MemoryFactWithType>([
        {
          ...result,
          factType: params.factType,
        },
        ...(state.persist.sortedFacts ? state.persist.sortedFacts : []),
      ]),
    },
  };
};

const updateMemoryFact: RSuccess<A.UpdateMemoryFact> = (
  state,
  { params, result },
) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      sortedFacts: state.persist.sortedFacts?.map((f) =>
        f.id === params.id ? { ...f, ...result, factType: params.factType } : f,
      ),
    },
  };
};

export default function memory(
  state: MemoryState = DEFAULT_STATE,
  action: A.AnyAction,
) {
  return applyReducer(
    'memory',
    {
      [AsyncActionTypes.Logout]: {
        success: resetState,
        error: resetState,
      },
      [AsyncActionTypes.DeleteAccount]: {
        success: resetState,
      },
      [AsyncActionTypes.GetMemory]: {
        request: getMemoryRequest,
        success: getMemorySuccess,
        error: getMemoryError,
      },
      [AsyncActionTypes.GetNewMemory]: {
        request: getNewMemoryRequest,
        success: getNewMemorySuccess,
        error: getNewMemoryError,
      },
      [AsyncActionTypes.GetMemoryRelations]: {
        success: setMemoryRelations,
      },
      [AsyncActionTypes.GetMemoryCategories]: {
        request: getMemoryCategoriesRequest,
        success: getMemoryCategoriesSuccess,
        error: getMemoryCategoriesError,
      },
      [ActionTypes.WsMemoryNewFactsUpdateReceived]: getNewMemorySuccess,
      [AsyncActionTypes.CreateMemoryPerson]: {
        success: createMemoryPerson,
      },
      [AsyncActionTypes.UpdateMemoryPerson]: {
        success: updateMemoryPerson,
      },
      [AsyncActionTypes.CreateMemoryFact]: {
        success: createMemoryFact,
      },
      [AsyncActionTypes.UpdateMemoryFact]: {
        success: updateMemoryFact,
      },
      [AsyncActionTypes.DeleteMemory]: {
        success: deleteMemory,
      },
      [AsyncActionTypes.BatchDeleteMemories]: {
        request: batchDeleteMemoriesRequest,
        success: batchDeleteMemoriesSuccess,
        error: batchDeleteMemoriesError,
      },
      [AsyncActionTypes.ProcessNewMemories]: {
        request: processNewMemoriesRequest,
        success: processNewMemoriesSuccess,
        error: processNewMemoriesError,
      },
      [AsyncActionTypes.MarkMemoryRead]: {
        success: markMemoryRead,
      },
    },
    state,
    action,
  );
}
