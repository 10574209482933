import { getDefaultStore } from 'jotai';
import { ServerError } from '../../types/http';
import { ApiError } from '../../utils/apiError';
import {
  JSONObject,
  JSONValue,
  fetchOptionsWithAuth,
} from '../../utils/fetchOptions';
import { ageLockAtom } from '../atoms';
import { apiCredsAtom } from './atoms';
import { SWRKey } from './types';

const MESSAGE_KEYS =
  process.env.NODE_ENV === 'development'
    ? ['error_title', 'error_message', 'message', 'technical_message', 'code']
    : ['error_title', 'error_message', 'message'];

async function processResponse(response: Response) {
  if (response.status >= 300) {
    const serverError = (await response.json()) as ServerError;
    const messageKey = MESSAGE_KEYS.find((key) => !!serverError[key]);
    const errorFields = serverError.fields;

    const message = messageKey && serverError[messageKey];
    const error = new ApiError(
      message ?? 'Server error',
      response.status,
      response.url,
      errorFields,
      'error_code' in serverError ? serverError.error_code : undefined,
      'technical_message' in serverError
        ? serverError.technical_message
        : undefined,
    );
    throw error;
  }

  const jsonStr = await response.text();
  let json;

  if (jsonStr === 'OK') {
    console.warn('Expected JSON response, got OK');
    return {};
  }

  try {
    json = jsonStr ? JSON.parse(jsonStr) : null;
  } catch (e) {
    console.error('Expected JSON response, got:', jsonStr);
    throw e;
  }

  return json as JSONValue;
}

type FetchOptions =
  | {
      method: 'GET';
      noCachePersist?: boolean;
      bypassAgeLock?: boolean;
      customHeaders?: Record<string, string>;
    }
  | {
      method: 'POST' | 'PUT' | 'PATCH' | 'DELETE';
      body?: FormData | Blob | JSONObject;
      noCachePersist?: boolean;
      bypassAgeLock?: boolean;
      customHeaders?: Record<string, string>;
    };

export default async function swrFetcher<Result = unknown>(
  swrKey: SWRKey,
  options?: FetchOptions,
) {
  const url = typeof swrKey === 'string' ? swrKey : swrKey.url;

  const apiCreds = getDefaultStore().get(apiCredsAtom);
  const ageLock = getDefaultStore().get(ageLockAtom);

  const body = options && options.method !== 'GET' ? options.body : undefined;

  const fetchOpts = fetchOptionsWithAuth(
    {
      ...apiCreds,
      ageLock,
    },
    options?.method ?? 'GET',
    body,
    options?.customHeaders,
    { bypassAgeLock: options?.bypassAgeLock },
  );

  const response = await fetch(url, fetchOpts);

  return (await processResponse(response)) as Result;
}
