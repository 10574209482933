import * as React from 'react';
import styled from 'styled-components/macro';
import { isEnvironment } from '../utils/environment';
import AriaStatusText from './AriaStatusText';

function normalizeMessage(message: string) {
  return isEnvironment('localhost') ? message.replace(/\[.*\]$/, '') : message;
}

const ErrorSuccessMessage = ({
  hasError,
  scrollIntoView,
  children,
  ...rest
}: {
  hasError: boolean;
  scrollIntoView?: boolean;
  children: React.ReactNode;
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (hasError && scrollIntoView && children && ref.current) {
      ref.current.scrollIntoView();
    }
  }, [hasError, scrollIntoView, children, ref]);

  const message =
    typeof children === 'string' ? normalizeMessage(children) : children;

  return (
    <ErrorSuccessMessageRoot ref={ref} as="div" $hasError={hasError} {...rest}>
      {message}
    </ErrorSuccessMessageRoot>
  );
};

export default ErrorSuccessMessage;

const ErrorSuccessMessageRoot = styled(AriaStatusText)<{ $hasError?: boolean }>`
  margin-top: 0;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  text-align: center;
  color: ${(p) =>
    p.$hasError ? p.theme.errorLightFgColor : p.theme.dimmedFgColor};
  font-size: 16px;
  max-height: 0px;
  transition:
    max-height 0.2s ease-out,
    margin-top 0.2s ease-out;

  &:not(:empty) {
    margin-top: 20px;
    max-height: 100px;
  }
`;
