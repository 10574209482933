import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { AriaButton } from './Buttons';
import outlineCss from './outlineCss';

type TabsSize = 'small' | 'medium';

type Props<T> = {
  className?: string;
  tabs: T[];
  selected?: T;
  onSelect: (value: T) => void;
  getLabel?: (tab: T) => ReactNode;
  size?: TabsSize;
};

function SlidingTabsContainer<T extends string>({
  className,
  tabs,
  selected,
  onSelect,
  getLabel = (tab) => tab,
  size = 'medium',
}: Props<T>) {
  const n = tabs.length;

  return (
    <SlidingTabsInner $count={n} $size={size} className={className}>
      {tabs.map((tab, idx) => {
        return (
          <SlidingTab
            type="button"
            aria-checked={tab === selected}
            $width={100 / n}
            $size={size}
            key={idx}
            onClick={() => onSelect(tab)}
          >
            {getLabel(tab)}
          </SlidingTab>
        );
      })}
      <Glider
        $width={100 / n}
        $index={selected != null ? tabs.indexOf(selected) : 0}
      />
    </SlidingTabsInner>
  );
}

const SlidingTabsRoot = styled.div`
  border-radius: 24px;
  background: rgba(0 0 0 / 10%);
  backdrop-filter: blur(25px);
  padding: 5px;
`;

const SlidingTabsInner = styled.div<{
  $count: number;
  $size: TabsSize;
}>`
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 19px;
  height: ${(p) => (p.$size === 'small' ? '32px' : '44px')};
`;

const SlidingTab = styled(AriaButton)<{ $width: number; $size: TabsSize }>`
  z-index: 1;
  flex: 1 0 ${(p) => p.$width}%;
  font-size: ${(p) => (p.$size === 'small' ? '14px' : '16px')};
  line-height: ${(p) => (p.$size === 'small' ? '18px' : '16px')};
  text-align: center;
  border-radius: 19px;
  background: transparent;
  border: none;
  padding: ${(p) => (p.$size === 'small' ? '7 11px' : '15px 20px')};
  cursor: pointer;
  font-family: ${(p) => p.theme.fonts.display};
  color: ${(p) => p.theme.fgColor};

  &:focus-visible:focus {
    ${outlineCss()}
  }
`;

const Glider = styled.div<{ $width: number; $index: number }>`
  position: absolute;
  height: 100%;
  width: ${(p) => p.$width}%;
  background: rgba(255 255 255 / 20%);
  z-index: 0;
  transform: translateX(${(p) => p.$index * 100}%);
  transition: transform 0.25s ease-out;
  border-radius: 19px;
`;

const SlidingTabs = {
  Root: SlidingTabsRoot,
  Container: SlidingTabsContainer,
};

export default SlidingTabs;
