import isDebugEnabled from './isDebugEnabled';

type Config = {
  isEnabled?: () => boolean;
  format?: (tag: string, ...args: any[]) => string[];
  styles?: string[];
};

const timeFormat = new Intl.DateTimeFormat('en', {
  timeZone: 'UTC',
  minute: '2-digit',
  second: '2-digit',
  // @ts-ignore
  fractionalSecondDigits: 3,
});

export default function createLogger(tag: string, config?: Config) {
  const {
    isEnabled = isDebugEnabled,
    format = (tag, styles, args) => {
      const time = timeFormat.format(performance.now());
      return [`%c[${time}] %c[${tag}]`, ...styles, ...args];
    },
    styles = ['color: #999999;', 'color: #4CAF50; font-weight: bold;'],
  } = config ?? {};

  return {
    error(...args) {
      if (!isEnabled()) return;
      // eslint-disable-next-line no-console
      console.error(...format(tag, styles, args));
    },
    warn(...args) {
      if (!isEnabled()) return;
      // eslint-disable-next-line no-console
      console.warn(...format(tag, styles, args));
    },
    log(...args) {
      if (!isEnabled()) return;
      // eslint-disable-next-line no-console
      console.log(...format(tag, styles, args));
    },
    debug(...args) {
      if (!isEnabled()) return;
      // eslint-disable-next-line no-console
      console.debug(...format(tag, styles, args));
    },
  };
}
