import { atom, useAtomValue, useSetAtom } from 'jotai';
import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Dialogs } from '../../types/enums';

const MANDATORY_DIALOGS = [Dialogs.AgeGate, Dialogs.AgeRange];

const allowedDialogsAtom = atom<Dialogs[][]>([]);

export function useActiveDialog() {
  const nextInQueue = useSelector((state) => {
    return state.ui.dialogQueue[0];
  });

  const allowedDialogsQueue = useAtomValue(allowedDialogsAtom);
  const allowedDialogs = allowedDialogsQueue[allowedDialogsQueue.length - 1];

  if (!nextInQueue) return null;

  if (MANDATORY_DIALOGS.includes(nextInQueue.type)) return nextInQueue;

  if (allowedDialogs != null && !allowedDialogs.includes(nextInQueue.type))
    return null;

  return nextInQueue;
}

/**
 * This hooks determines what dialogs are allowed to be shown.
 * The dialog set from a deepest component with this hook is considered active.
 * For example, on modal screens only dialogs with Confirmation type are allowed,
 * but we may want to allow some other dialogs on a particular modal screen.
 * @param dialogs - if undefined, all dialogs are allowed
 */
export function useAllowedDialogs(dialogs?: Dialogs[]) {
  const dialogsRef = useRef(dialogs ?? Object.values(Dialogs));
  const setAllowedDialogs = useSetAtom(allowedDialogsAtom);

  // adds a new set of allowed dialogs
  useEffect(() => {
    let dialogs = dialogsRef.current;

    setAllowedDialogs((prev) => {
      return [...prev, dialogs];
    });

    return () => {
      setAllowedDialogs((prev) => {
        return prev.filter((d) => d !== dialogsRef.current);
      });
    };
  }, []);

  // if the dialogs change, update the list of allowed dialog sets preserving the order
  useEffect(() => {
    const d = dialogs == null ? Object.values(Dialogs) : dialogs;
    if (isEqual(d, dialogsRef.current)) {
      return;
    }

    setAllowedDialogs((prev) => {
      const index = prev.indexOf(dialogsRef.current);
      if (index === -1) return prev;
      prev[index] = d;
      return [...prev];
    });

    dialogsRef.current = d;
  }, [dialogs]);
}
