import { values } from 'lodash';
import * as React from 'react';
import styled from 'styled-components/macro';
import { PronounsValues } from '../../types/enums';
import capitalize from '../../utils/capitalize';
import RadioButtonSelector, {
  Props as SelectorProps,
} from './RadioButtonSelector';

type Props = {
  className?: string;
  value: PronounsValues | undefined;
  onChange: (pronoun: PronounsValues) => void;
};

const PRONOUN_OPTS = values(PronounsValues).map((v) => ({
  label: capitalize(v),
  value: v as PronounsValues,
}));

const PronounSelector = (props: Props) => {
  const { value, onChange, className } = props;

  return (
    <StyledRadioButtonSelector
      layout="horizontal"
      buttonTheme="outline"
      size="small"
      className={className}
      variants={PRONOUN_OPTS}
      value={value}
      onChange={onChange}
      id="pronoun"
    />
  );
};

export default PronounSelector;

const StyledRadioButtonSelector = styled<
  React.FC<SelectorProps<PronounsValues>>
>(RadioButtonSelector)`
  margin: 0 auto;
  max-width: 280px;
`;
