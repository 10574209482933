import * as React from 'react';

type Props = React.ComponentPropsWithRef<'video'> & {
  src?: string;
};

// Temporalily disable WebM format for current version of Chrome
// Remove this after v115 is released
const chromeVersion = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
const isOlderChrome = chromeVersion?.[2]
  ? parseInt(chromeVersion[2], 10) < 115
  : false;

function Video({ src, ...rest }: Props, ref: React.Ref<HTMLVideoElement>) {
  const [url, params] = src?.split('#') ?? [];
  const formats = params
    ? new URLSearchParams(params).get('formats')?.split(',') ?? []
    : [];

  return (
    <video ref={ref} {...rest}>
      {formats.map((fmt) => {
        if (fmt === 'h265') {
          return (
            <source
              key={fmt}
              src={url?.replace(/\.mp4$/, '.h265.mp4')}
              type="video/mp4; codecs=hevc"
            />
          );
        } else if (fmt === 'webm') {
          if (isOlderChrome) return null;
          return (
            <source
              key={fmt}
              src={url?.replace(/\.mp4$/, '.webm')}
              type="video/webm"
            />
          );
        }
        return null;
      })}
      <source src={url} type="video/mp4" />
    </video>
  );
}

export default React.forwardRef(Video);
