import { Children, ReactElement, ReactNode } from 'react';
import { isElement, isFragment } from 'react-is';

export default function flattenFragments<T extends ReactElement>(
  children: ReactNode | ReactNode[],
): T[] {
  if (!children) return [];
  return Children.toArray(children).reduce((acc: T[], child: ReactNode) => {
    if (isFragment(child)) {
      return [...acc, ...flattenFragments<T>(child.props.children)];
    }

    return child && isElement(child) ? [...acc, child as any] : acc;
  }, []);
}
