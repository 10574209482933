import {
  DEFAULT_SUBSCRIPTION_SUBTITLE,
  DEFAULT_SUBSCRIPTION_TITLE,
} from 'src/features/Subscription/consts';
import { GenderValues } from '../../../types/enums';
import { PaidFeatureToShowPopup } from '../../../types/states';
import { MAIN_PAGE_URL_BASE, VIDEO_URL_BASE } from '../../../utils/uri';

export default function getFeaturePopupConfig(
  botName: string,
  botGender: GenderValues | undefined,
): Record<
  PaidFeatureToShowPopup,
  {
    cta: string;
    title: string;
    video: string;
    placeholder_image: string;
    bg_color: string;
    bg_gradient?: string;
  }
> {
  return {
    'blurred photo': {
      cta: `Discover what lies beneath the blur. Enjoy unlimited access to image generation, advanced conversational models, voice messages, activities, and more.`,
      title: `Create together with\u000a${botName}`,
      video: `${VIDEO_URL_BASE}/paid_features_blurred_photo/paid_features_blurred_photo.mp4#formats=h265,webm`,
      placeholder_image: `${VIDEO_URL_BASE}/paid_features_blurred_photo/paid_features_blurred_photo_placeholder.jpg`,
      bg_color: '#352562',
      bg_gradient:
        'linear-gradient(180deg, rgba(53, 37, 98, 0.00) 0%, rgba(53, 37, 98, 0.70) 52.08%, #352562 100%)',
    },
    'blurred message': {
      cta: `Make the most of your conversations with unlimited messaging, access to an advanced conversational model, image generation, activities, and more.`,
      title: `${botName} left you\u000aa special message`,
      video:
        botGender === 'male'
          ? `${VIDEO_URL_BASE}/paid_features_texting_male/paid_features_texting_male.mp4#formats=h265,webm`
          : `${VIDEO_URL_BASE}/paid_features_texting_female/paid_features_texting_female.mp4#formats=h265,webm`,
      placeholder_image:
        botGender === 'male'
          ? `${VIDEO_URL_BASE}/paid_features_texting_male/paid_features_texting_male_placeholder.jpg`
          : `${VIDEO_URL_BASE}/paid_features_texting_female/paid_features_texting_female_placeholder.jpg`,
      bg_color: '#A3868C',
      bg_gradient:
        'linear-gradient(180deg, rgba(139, 115, 129, 0) 0%, rgba(139, 115, 129, 0.7) 50%,#8b7381 100%)',
    },
    call: {
      cta: `Explore romantic role-play during video calls and enjoy exclusive features such as advanced conversational models, unlimited voice messages, image generation, engaging activities, and much more.`,
      title: 'Explore your relationship',
      video: `${VIDEO_URL_BASE}/calls_video/calls_video.mp4#formats=h265,webm`,
      placeholder_image: `${VIDEO_URL_BASE}/calls_video/calls_video_placeholder.jpg`,
      bg_color: '#6CA999',
      bg_gradient:
        'linear-gradient(180deg, rgba(166, 236, 164, 0) 0%, #51918D 100%);',
    },
    'romantic photo': {
      cta: `Discover what lies beneath the blur. Enjoy unlimited access to image generation, advanced conversational models, voice messages, activities, and more.`,
      title: `Create together with\u000a${botName}`,
      video:
        botGender === 'male'
          ? `${VIDEO_URL_BASE}/blurredphotos_v3_male/blurredphotos_v3_male.mp4#formats=h265,webm`
          : `${VIDEO_URL_BASE}/blurredphotos_v3_female/blurredphotos_v3_female.mp4#formats=h265,webm`,
      placeholder_image:
        botGender === 'male'
          ? `${MAIN_PAGE_URL_BASE}/blurredphotos_v3_male_placeholder.jpg`
          : `${MAIN_PAGE_URL_BASE}/blurredphotos_v3_female_placeholder.jpg`,
      bg_color: '#9B7861',
    },
    selfie: {
      cta: `Discover what lies beneath the blur. Enjoy unlimited access to image generation, advanced conversational models, voice messages, activities, and more.`,
      title: `Create together with\u000a${botName}`,
      video: `${VIDEO_URL_BASE}/paid_features_selfie_v2/paid_features_selfie_v2.mp4#formats=h265,webm`,
      placeholder_image: `${MAIN_PAGE_URL_BASE}/paid_features_selfie_v2_placeholder.jpg`,
      bg_color: '#9B7861',
    },
    'voice message': {
      cta: `Exchange unlimited voice messages and gain access to an advanced conversational model, image generation, activities, and more.`,
      title: `${botName} left you\u000aa voice message`,
      video: `${VIDEO_URL_BASE}/paid_features_voice/paid_features_voice.mp4#formats=h265,webm`,
      placeholder_image: `${MAIN_PAGE_URL_BASE}/paid_features_voice_placeholder.jpg`,
      bg_color: '#011242',
      bg_gradient:
        'linear-gradient(180deg, rgba(166, 236, 164, 0) 0%, #0A3772 100%)',
    },
    'advanced ai': {
      cta: `Get an advanced conversational model with long-term memory. Enjoy unlimited voice messages, image generation, multilingual support, activities, and more.`,
      title: 'Advanced AI',
      video: `${VIDEO_URL_BASE}/paid_features_advanced_ai/paid_features_advanced_ai.mp4#formats=h265,webm`,
      placeholder_image: `${MAIN_PAGE_URL_BASE}/paid_features_advanced_ai_placeholder.jpg`,
      bg_color: '#011242',
      bg_gradient:
        'linear-gradient(180deg, rgba(1, 18, 66, 0) 0%, #011242 100%)',
    },
    prompts: {
      cta: `Choose from over 100 activities with ${botName}, including image generation and social skills practice. Enjoy access to the advanced conversational model, unlimited voice messages, and more.`,
      title: `Activities for personal growth`,
      video: `${VIDEO_URL_BASE}/paid_features_prompts/paid_features_prompts.mp4#formats=h265,webm`,
      placeholder_image: `${MAIN_PAGE_URL_BASE}/paid_features_prompts_placeholder.jpg`,
      bg_color: '#011242',
      bg_gradient:
        'linear-gradient(180deg, rgba(1, 18, 66, 0) 0%, #003F70 100%)',
    },
    'sd image': {
      cta: `Discover what lies beneath the blur. Enjoy unlimited access to image generation, advanced conversational models, voice messages, activities, and more.`,
      title: `Create together with\u000a${botName}`,
      video: `${VIDEO_URL_BASE}/paid_features_sd_image/paid_features_sd_image.mp4#formats=h265,webm`,
      placeholder_image: `${MAIN_PAGE_URL_BASE}/paid_features_sd_image_placeholder.jpg`,
      bg_color: '#352562',
      bg_gradient:
        'linear-gradient(180deg, rgba(53, 37, 98, 0.00) 0%, rgba(53, 37, 98, 0.70) 52.08%, #352562 100%)',
    },
    'subscription screen': {
      cta: DEFAULT_SUBSCRIPTION_SUBTITLE,
      title: DEFAULT_SUBSCRIPTION_TITLE,
      video: '',
      placeholder_image: `${MAIN_PAGE_URL_BASE}/subscription_screen_video_placeholder.jpg`,
      bg_color: '#6F5ECD',
      bg_gradient:
        'linear-gradient(180deg, rgba(53, 37, 98, 0) 0%, #352562 62.5%)',
    },
    'ai images': {
      cta: `Discover what lies beneath the blur. Enjoy unlimited access to image generation, advanced conversational models, voice messages, activities, and more.`,
      title: `Create together with\u000a${botName}`,
      video: `${VIDEO_URL_BASE}/paid_features_blurred_photo/paid_features_blurred_photo.mp4#formats=h265,webm`,
      placeholder_image: `${VIDEO_URL_BASE}/paid_features_blurred_photo/paid_features_blurred_photo_placeholder.jpg`,
      bg_color: '#352562',
      bg_gradient:
        'linear-gradient(180deg, rgba(53, 37, 98, 0.00) 0%, rgba(53, 37, 98, 0.70) 52.08%, #352562 100%)',
    },
  };
}
