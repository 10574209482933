import { getDefaultStore } from 'jotai';
import { avatarUpdatePreviewAtom, basketAtom } from '../core/atoms';
import * as A from '../types/actions';
import { AsyncActionTypes, WsEventNames } from '../types/enums';
import {
  AdvancedAiInfo,
  CategoryTree,
  Credit,
  GemConversion,
  GemPurchase,
  StoreItem,
  Storefront,
  StripeSubscriptionStatus,
} from '../types/models';
import { DA } from '../types/redux';
import { apiAction, wsAction } from '../utils/asyncAction';
import fetchOptions from '../utils/fetchOptions';
import getUnityBundleVersion from '../utils/getUnityBundleVersion';
import toQueryString from '../utils/toQueryString';
import { API_BASE_URL } from '../utils/uri';
import { sendMessage } from '../utils/websocket';

export const requestWallet =
  (): DA<{
    credit: Credit;
  }> =>
  async (dispatch, getState) => {
    const state = getState();

    return wsAction<A.WalletRequest>(
      AsyncActionTypes.WsWalletRequest,
      dispatch,
      {},
      {
        onRequest: () =>
          sendMessage(
            {
              event_name: WsEventNames.WalletRequest,
              payload: {},
            },
            state,
          ),
      },
    );
  };

export const getGemConversions =
  (): DA<GemConversion[]> => async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetGemConversions>(
      AsyncActionTypes.GetGemConversions,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(`${API_BASE_URL}/store/gem_conversions`, fetchOpts),
      },
    );
  };

export const getGemPurchases =
  (): DA<GemPurchase[]> => async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetGemPurchases>(
      AsyncActionTypes.GetGemPurchases,
      dispatch,
      {},
      {
        onRequest: () => {
          return fetch(`${API_BASE_URL}/store/gem_purchases`, fetchOpts);
          // return new Promise((resolve) =>
          //   resolve(
          //     new Response(
          //       JSON.stringify([
          //         {
          //           product_id: '10gems',
          //           gems_count: 10,
          //           product_image:
          //             'https://dve3es5wwmokl.cloudfront.net/store/gems_in_app_icon.png',
          //           price: {
          //             amount: 10,
          //             currency: 'USD',
          //           },
          //         },
          //         {
          //           product_id: '20gems',
          //           gems_count: 20,
          //           product_image:
          //             'https://dve3es5wwmokl.cloudfront.net/store/gems_in_app_icon.png',
          //           price: {
          //             amount: 10,
          //             currency: 'USD',
          //           },
          //         },
          //       ]),
          //       { status: 200 }
          //     )
          //   )
          // );
        },
      },
    );
  };

export const convertGems =
  (productId: string): DA<{ credit: Credit }> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'POST', {
      product_id: productId,
    });

    return apiAction<A.ConvertGems>(
      AsyncActionTypes.ConvertGems,
      dispatch,
      { productId },
      {
        onRequest: () =>
          fetch(`${API_BASE_URL}/store/actions/convert_gems`, fetchOpts),
      },
    );
  };

export const purchaseGems =
  (
    priceId: string,
    paymentMethodId: string,
    onSuccess?: (data: StripeSubscriptionStatus) => void,
  ): DA<StripeSubscriptionStatus> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'POST', {
      price_id: priceId,
      payment_method_id: paymentMethodId,
    });

    return apiAction<A.PurchaseGems>(
      AsyncActionTypes.PurchaseGems,
      dispatch,
      { priceId, paymentMethodId },
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/store/actions/stripe/purchase_gems`,
            fetchOpts,
          ),
        onSuccess: (data: StripeSubscriptionStatus) => {
          onSuccess?.(data);
        },
      },
    );
  };

export const getCategoryTreeNode =
  (categoryKey: string): DA<CategoryTree> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetCategoryTreeNode>(
      AsyncActionTypes.GetCategoryTreeNode,
      dispatch,
      { categoryKey },
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/store/category_tree?${toQueryString({
              category_key: categoryKey,
              // prevent caching for older versions
              _ubv_: getUnityBundleVersion(),
            })}`,
            fetchOpts,
          ),
      },
    );
  };

export const getStoreItems =
  ({
    categoryId,
    avatarId,
    limit = 50,
    offset = 0,
    onlyFree = false,
    categoryKey,
  }: {
    categoryKey?: string;
    categoryId?: string;
    avatarId?: string;
    limit: number;
    offset: number;
    onlyFree;
  }): DA<{ items: StoreItem[]; total_count: number }> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetStoreItems>(
      AsyncActionTypes.GetStoreItems,
      dispatch,
      { categoryId: categoryId ?? 'all', limit, offset, onlyFree },
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/store/store_items?${toQueryString({
              avatar_id: avatarId,
              category_id: categoryId,
              only_free: onlyFree,
              category_key: categoryKey,
              limit,
              offset,
              // prevent caching for older versions
              _ubv_: getUnityBundleVersion(),
            })}`,
            fetchOpts,
          ),
      },
    );
  };

export const getStoreItemsByIds =
  (ids: string[]): DA<StoreItem[]> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'POST', { ids });

    return apiAction<A.GetStoreItemsByIds>(
      AsyncActionTypes.GetStoreItemsByIds,
      dispatch,
      { ids },
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/store/store_items/actions/find_by_ids`,
            fetchOpts,
          ),
      },
    );
  };

export const getStoreUserItems =
  (limit: number = 50, offset: number = 0): DA<StoreItem[]> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');
    const store = getDefaultStore();

    return apiAction<A.GetStoreUserItems>(
      AsyncActionTypes.GetStoreUserItems,
      dispatch,
      { limit, offset },
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/store/user_items?${toQueryString({
              limit,
              offset,
              // prevent caching for older versions
              _ubv_: getUnityBundleVersion(),
            })}`,
            fetchOpts,
          ),
        onSuccess: (items) => {
          const basket = store.get(basketAtom);

          if (basket.length > 0) {
            const newBasket = basket.filter((basketItem) => {
              const boughtItem = items.find(
                (item) => item.id === basketItem.storeItem.id,
              );
              if (!boughtItem) return true;
              const variation =
                boughtItem.variations[basketItem.variationIndex ?? 0];
              return !variation || variation.bought_count === 0;
            });

            if (newBasket.length !== basket.length) {
              store.set(basketAtom, newBasket);
            }
          }
        },
      },
    );
  };

export const buyUserItems =
  (items: Array<{ id: string; count: number }>): DA<StoreItem[]> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'POST', {
      items: items.map((item) => ({
        variation_item_id: item.id,
        count: item.count,
      })),
    });

    return apiAction<A.BuyUserItems>(
      AsyncActionTypes.BuyUserItems,
      dispatch,
      { items },
      {
        onRequest: () =>
          fetch(`${API_BASE_URL}/store/actions/buy_user_items`, fetchOpts),
        onSuccess: (items: StoreItem[]) => {
          const store = getDefaultStore();

          const variations = items.reduce((acc, current) => {
            return [...acc, ...current.variations];
          }, [] as any);

          store.set(avatarUpdatePreviewAtom, variations);
        },
      },
    );
  };

export const switchUserItem =
  (id: string, enabled: boolean): DA<StoreItem[]> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'PUT', {
      variation_item_id: id,
      enabled,
    });

    return apiAction<A.SwitchUserItem>(
      AsyncActionTypes.SwitchUserItem,
      dispatch,
      { id, enabled },
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/store/store_properties/actions/switch_user_item`,
            fetchOpts,
          ),
      },
    );
  };

export const getStorefront =
  (): DA<Storefront> => async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetStorefront>(
      AsyncActionTypes.GetStorefront,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/store/storefront_v2?_ubv_=${getUnityBundleVersion()}`,
            fetchOpts,
          ),
      },
    );
  };

export const getAdvancedAiInfo =
  (): DA<AdvancedAiInfo> => async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetAdvancedAiInfo>(
      AsyncActionTypes.GetAdvancedAiInfo,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(`${API_BASE_URL}/store/advanced_ai/info_v2`, fetchOpts),
      },
    );
  };

export const buyAdvancedAiMessages =
  (): DA<{ available_messages: number }> => async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'POST');

    return apiAction<A.BuyAdvancedAiMessages>(
      AsyncActionTypes.BuyAdvancedAiMessages,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/store/advanced_ai/actions/buy_messages`,
            fetchOpts,
          ),
      },
    );
  };
