import { ReactNode } from 'react';
import { ModalRoutes } from '../../../types/enums';
import { TransitionRouteElement } from '../TransitionRoutes';
import TransitionRoute, {
  RouteMatcher,
} from '../TransitionRoutes/TransitionRoute';
import matchModalRoutes from './matchModalRoutes';

type TransitionModalRouteProps = {
  path: ModalRoutes;
  state?: Object;
  children: ReactNode;
  routeMatcher?: RouteMatcher;
  exact?: boolean;
  instant?: boolean;
};

function TransitionModalRoute({
  routeMatcher = matchModalRoutes,
  ...rest
}: TransitionModalRouteProps) {
  return <TransitionRoute {...rest} routeMatcher={routeMatcher} />;
}

export default TransitionModalRoute;

export function getModalRouteMatcher(element: TransitionRouteElement) {
  if (typeof element.type === 'string') return;
  return element.type === TransitionModalRoute ? matchModalRoutes : undefined;
}
