import { merge, pickBy } from 'lodash';
import Tooltip, { RCTooltip } from 'rc-tooltip';
import * as React from 'react';
import { LinkProps } from 'react-router-dom';
import { ZIndices } from '../../types/enums';
import AriaReadable from '../AriaReadable';
import Link from '../Link';

import 'rc-tooltip/assets/bootstrap.css';

type Props = {
  active?: boolean;
  label?: string;
  to?: LinkProps['to'];
  tooltipOptions?: { id: string } & RCTooltip.Props;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const DEFAULT_TOOLTIP_OPTIONS = {
  trigger: ['focus', 'hover'],
  mouseEnterDelay: 0.5,
  overlayStyle: {
    opacity: 1,
    zIndex: ZIndices.Popup,
  },
};

/**
 * Most basic a11y-friendly button component
 * NB: native 'disabled' attribute is omitted, otherwise the reader will ignore it completely
 * (instead of alerting user that there is a disabled button)
 */
function AriaButton(
  {
    children,
    label,
    active,
    disabled,
    onClick,
    to,
    className,
    style,
    tooltipOptions,
    ...rest
  }: Props,
  ref: React.Ref<HTMLButtonElement>,
) {
  const [popupVisible, setPopupVisible] = React.useState<boolean | undefined>(
    false,
  );

  const handleClick = (e) => {
    // we have to manage it manually since we omitted 'disabled' property
    if (disabled) {
      e.preventDefault();
      return;
    }

    setPopupVisible(false);

    if (onClick) {
      onClick(e);
    }
  };

  let buttonElement: React.ReactElement;

  if (to != null) {
    buttonElement = (
      <Link
        data-active={active}
        aria-disabled={!!disabled}
        onClick={handleClick}
        to={to}
        className={className}
        style={style}
        tabIndex={rest.tabIndex}
        aria-label={rest['aria-label']}
        aria-labelledby={rest['aria-label'] ? undefined : tooltipOptions?.id}
        {...(pickBy(rest, (val, key) => key.startsWith('data-')) as any)}
      >
        {label ? <AriaReadable>{label}</AriaReadable> : null}
        {children}
      </Link>
    );
  } else {
    buttonElement = (
      <button
        data-active={active}
        aria-pressed={active}
        aria-disabled={!!disabled}
        onClick={handleClick}
        className={className}
        style={style}
        ref={ref}
        aria-labelledby={rest['aria-label'] ? undefined : tooltipOptions?.id}
        {...rest}
      >
        {label && !tooltipOptions ? <AriaReadable>{label}</AriaReadable> : null}
        {children}
      </button>
    );
  }

  if (tooltipOptions) {
    const options = merge({ ...DEFAULT_TOOLTIP_OPTIONS }, tooltipOptions);
    return (
      <Tooltip
        {...options}
        visible={popupVisible}
        onVisibleChange={setPopupVisible}
      >
        {buttonElement}
      </Tooltip>
    );
  }

  return buttonElement;
}

export default React.forwardRef(AriaButton);
