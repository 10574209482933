import { Location } from 'history';
import { MouseEvent, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ModalRoutes, Routes } from '../types/enums';
import {
  createEscapeKeyHandler,
  registerKeydownListener,
  unregisterKeydownListener,
} from './eventQueue';
import toModalRoute from './toModalRoute';

type StateWithAnchors = {
  anchors?: Record<string, number>;
  anchorsNoModal?: Record<string, number>;
  canGoBack?: number;
  source?: string;
  passThroughModal?: boolean;
};

export type AnchorType = 'default' | 'subscription' | 'menu' | 'chat' | 'modal';

type UseGoBackOpts = {
  fallbackRoute?: string | { path: string; modal: boolean };
  state?: any;
  anchor?: AnchorType | null;
  closeOnEscape?: boolean;
  location?: Location<any>;
  inTransition?: boolean;
};

function getFallbackRoute(options: UseGoBackOpts) {
  if (!options.fallbackRoute) {
    return Routes.Chat;
  }

  if (typeof options.fallbackRoute === 'string') {
    return options.fallbackRoute;
  } else if (options.fallbackRoute.modal) {
    return toModalRoute(options.fallbackRoute.path as ModalRoutes);
  } else {
    return options.fallbackRoute.path;
  }
}

/**
 * The goal of this hook is to provide a way to navigate through the app without overflowing the browser history.
 * Namely, it provides a way to go back to the previous pages, while calculating the correct number of steps to go back.

 * The hook works in two modes: with or without anchor.
 * "Anchor" means a point in history where we want to go back to.

 * If anchor is not provided, we go back to the previous page.
 * (in both cases we check if we can go back, meaning there are enough points in history to go back to,
 * and if not, we go to the fallback route; this way we avoid going back to external pages)

 * If anchor is provided, we go back to the point in history where the anchor was set (or rather, the point before that).

 * For example, we open a page with `useGoBack({ anchor: 'overlay' })` hook from the main page.
 * Every consecutive page that uses this hook with the same anchor will return us to the main page, when we call `goBack()`.

 * `navLevel` shows how many pages we need to go back to reach the anchor.
 * If `navLevel` is more than 1, we usually want to show "back" button, that returns to the previous page.

 * Modal pages need a special treatment, since they can have non-modal pages in the background.
 * In this case, non-modal page should use `navLevelNoModal` to show the correct "back" button.

 * `passThroughModal` option in location.state skips the modal level when goBack is called.
 * It's useful for opening non-modal pages from a modal page.
 */
export default function useGoBack(options: UseGoBackOpts = {}) {
  const history = useHistory<StateWithAnchors>();

  let location = useLocation<StateWithAnchors>();

  if (options.location) {
    location = options.location;
  }

  const { state, anchor, closeOnEscape, inTransition } = options;
  const fallbackRoute = getFallbackRoute(options);

  let level = -1;
  let levelNoModal = -1;

  if (anchor && location.state?.anchors?.[anchor]) {
    level = location.state.anchors[anchor] ?? -1;
    levelNoModal = location.state.anchorsNoModal?.[anchor] ?? -1;
  }

  useEffect(() => {
    if (inTransition || !anchor) return;
    let state = location.state ?? {};
    if (state.anchors?.[anchor]) return;
    const defaultLevel =
      state.passThroughModal && anchor !== 'modal' && state.anchors?.modal
        ? state.anchors.modal
        : -1;

    state = {
      ...state,
      anchors: {
        ...state.anchors,
        [anchor]: defaultLevel,
      },
      anchorsNoModal: {
        ...state.anchorsNoModal,
        [anchor]: defaultLevel,
      },
    };
    history.replace({ ...location, state });
  }, [inTransition, anchor, location, history]);

  const { canGoBack = 0 } = location.state ?? {
    canGoBack: 0,
  };

  const goBack = useCallback(
    (e?: MouseEvent) => {
      e?.preventDefault();

      if (canGoBack < -level) {
        history.replace(fallbackRoute, state);
        return;
      }

      history.go(level);
    },
    [canGoBack, fallbackRoute, state, history, level],
  );

  useEffect(() => {
    if (!closeOnEscape) return;
    const listener = createEscapeKeyHandler(goBack);

    registerKeydownListener(listener);
    return () => unregisterKeydownListener(listener);
  }, [closeOnEscape, goBack]);

  return { goBack, navLevel: -level, navLevelNoModal: -levelNoModal } as const;
}
