import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { API_BASE_URL } from 'src/utils/uri';
import useSWRImmutable from 'swr/immutable';
import { FeatureFlagsAtom } from './atoms';
import { FeatureFlagsResponse } from './models';

export const useFeatureFlags = (shouldFetch = true) => {
  const { data, isLoading } = useSWRImmutable<FeatureFlagsResponse>(
    shouldFetch ? `${API_BASE_URL}/profile/feature_flags` : null,
    null,
    {
      revalidateOnMount: true,
    },
  );
  const setFeatureFlags = useSetAtom(FeatureFlagsAtom);
  useEffect(() => {
    if (!isLoading && data) {
      setFeatureFlags(
        data.features.reduce((featuresMap, feature) => {
          return {
            ...featuresMap,
            [feature.feature_name]: feature.feature_value,
          };
        }, {}),
      );
    }
  }, [isLoading, data]);
};
