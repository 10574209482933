import { isEqual } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgeGateStatus } from '../types/enums';
import { DA } from '../types/redux';
import { RootState } from '../types/states';

function defaultGetTimeout<T>(value: T) {
  return value ? 1000 : 0;
}

export default function useApi<T, R>(
  selector: (state: RootState) => T,
  getAction: (() => DA<R>) | null,
  options?: {
    useCache?: boolean;
    memoDeepEqual?: boolean;
    catchErrors?: boolean;
    getTimeout?: (cachedValue: T) => number;
  },
) {
  const {
    useCache = false,
    memoDeepEqual = false,
    catchErrors = false,
    getTimeout = defaultGetTimeout,
  } = options || {};
  const dispatch = useDispatch();

  const selectorResult = useSelector(selector);

  const ageLocked = useSelector(
    (state) => state.profile.persist.userProfile?.birthday_status,
  );

  const [result, setResult] = React.useState<T>(selectorResult);

  React.useEffect(() => {
    if (memoDeepEqual) {
      if (!isEqual(result, selectorResult)) {
        setResult(selectorResult);
      }
    }
  }, [result, selectorResult, memoDeepEqual]);

  const timeout = getTimeout(selectorResult);

  const apiLocked = ageLocked === AgeGateStatus.Locked;

  const canUseCache = useCache && result;

  React.useEffect(() => {
    if (getAction != null && !canUseCache) {
      const requestData = () => {
        if (apiLocked) {
          console.error('Age gate locked');
          return;
        }
        dispatch(getAction()).catch((e) => {
          if (!catchErrors) {
            throw e;
          } else {
            console.warn(`API error catched: ${e}`);
          }
        });
      };

      if (timeout) {
        const to = setTimeout(requestData, timeout);
        return () => clearTimeout(to);
      } else {
        requestData();
      }
    }
    return;
  }, [dispatch, getAction, catchErrors, timeout, apiLocked, canUseCache]);

  return memoDeepEqual ? result : selectorResult;
}
