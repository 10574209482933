import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { StoreItem, StoreVariationItem } from '../../types/models';
import StoreItemCard from '../Cards/StoreItemCard';
import { CustomizationGrid } from '../CustomizationGrid';
import { useMobileQuery } from '../responsive';

export type ItemTuple = [StoreItem, StoreVariationItem];

const spacingStyle = {
  gap: 8,
  paddingBlock: 15,
  gridPaddingInline: 8,
};

/** To minimize amount of rendered elements we replace offscreen grid
 * with a single placeholder element.
 * This function calculates the height for this placeholder based on item count and size.
 */
export function getGridHeight({
  count,
  itemSize,
  gridWidth,
}: {
  count: number;
  itemSize: number;
  gridWidth?: number;
}) {
  let itemPerRow = 4;
  let itemWidth = itemSize;

  // with known grid width we assume item size is variable
  // and calculate that size and how many items per row we can fit
  if (gridWidth) {
    itemPerRow = Math.floor(
      (gridWidth - spacingStyle.gridPaddingInline * 2 + spacingStyle.gap) /
        (itemSize + spacingStyle.gap),
    );
    itemWidth =
      (gridWidth -
        spacingStyle.gridPaddingInline * 2 -
        spacingStyle.gap * (itemPerRow - 1)) /
      itemPerRow;
  }

  const rowCount = Math.ceil(count / itemPerRow);

  return (
    spacingStyle.paddingBlock + // padding
    rowCount * itemWidth +
    (rowCount - 1) * spacingStyle.gap
  );
}

export const getGridItemWidth = (isMobile: boolean) => (isMobile ? 70 : 126);
export const MobileDialogItemWidth = 100;

export type StoreItemWithVariation = StoreItem & {
  variationIndex?: number;
};

type Props = {
  id: string;
  title?: string;
  items: StoreItemWithVariation[];
  selectedItems?: Record<string, ItemTuple>;
  onSelectItem?: (item: StoreItemWithVariation, variationId?: string) => void;
  index?: number;
  onVisible?: (index: number, visible: boolean) => void;
  noPriceBadge?: boolean;
  itemCount?: number;
  itemWidth?: number;
  className?: string;
  onRemoveItem?: (item: StoreItemWithVariation) => void;
  withOffsets?: boolean;
  emptyMessage?: string;
  noNewBadge?: boolean;
  noColorBadge?: boolean;
  noAdditionalIcons?: boolean;
};

export default function StoreCategoryGridSection(props: Props) {
  const {
    title,
    items,
    selectedItems = [],
    onSelectItem,
    onVisible,
    index = 0,
    id,
    noPriceBadge,
    itemCount,
    className,
    onRemoveItem,
    withOffsets,
    emptyMessage,
    noNewBadge,
    noColorBadge,
    noAdditionalIcons,
  } = props;

  const { ref, inView } = useInView({
    delay: 400,
    onChange(inView) {
      onVisible?.(index, inView);
    },
  });

  const isMobile = useMobileQuery();

  const itemWidth = props.itemWidth ?? getGridItemWidth(isMobile);

  const sectionRef = useRef<HTMLDivElement | null>(null);

  const length = (items.length || itemCount) ?? 0;

  const placeholderRef = useRef<HTMLDivElement | null>(null);
  const placeholderHeightRef = useRef<number | undefined>(undefined);
  useLayoutEffect(() => {
    if (!sectionRef.current) return;

    const gridHeight = getGridHeight({
      count: length,
      itemSize: itemWidth,
      gridWidth: sectionRef.current.clientWidth,
    });
    placeholderHeightRef.current = gridHeight;

    if (!placeholderRef.current) return;

    // we need to set height immediately after section render for proper initial scrolling
    placeholderRef.current.style.height = `${gridHeight}px`;
  }, [length, itemWidth]);

  const renderedItems: (StoreItemWithVariation | undefined)[] = items.length
    ? items
    : Array.from({ length });

  const [updatingItemId, setUpdatingItemId] = useState<string | null>(null);

  const avatarStatus = useSelector((state) => state.avatars.avatarStatus);
  const isUpdatingStore = avatarStatus === 'updating';

  useEffect(() => {
    if (!isUpdatingStore) {
      setUpdatingItemId(null);
    }
  }, [isUpdatingStore]);

  const isDialogItems = renderedItems[0]?.variation_type === 'Dialog';
  const grid = inView ? (
    renderedItems.length === 0 ? (
      <EmptyGridPlaceholder>{emptyMessage ?? 'No items'}</EmptyGridPlaceholder>
    ) : (
      <StyledCustomizationGrid
        minItemWidth={
          isDialogItems && isMobile ? MobileDialogItemWidth : itemWidth
        }
        minItemHeight={isDialogItems ? itemWidth * 1.25 : itemWidth}
        effectInputs={[renderedItems]}
      >
        {renderedItems.map((item, idx) => {
          if (!item) {
            return <StoreItemCard key={idx} item={null} size={itemWidth} />;
          }
          const selectedItem = selectedItems[item.id];
          const handleSelect = (variationId?: string) => {
            setUpdatingItemId(item.id);
            onSelectItem?.(item, variationId);
          };
          return (
            <StoreItemCard
              noColorBadge={noColorBadge}
              noNewBadge={noNewBadge}
              noPriceBadge={noPriceBadge || item.price.amount === 0}
              key={idx}
              item={item}
              tabIndex={idx === 0 ? 0 : -1}
              size={itemWidth}
              checked={!!selectedItem}
              onClick={(e) => {
                e.preventDefault();
                handleSelect();
              }}
              onScrollSelect={(variationId) => {
                if (selectedItems[item.id]) return;
                handleSelect(variationId);
              }}
              onRemove={onRemoveItem && (() => onRemoveItem(item))}
              variationId={
                item.variationIndex != null
                  ? item.variations[item.variationIndex]?.id
                  : undefined
              }
              showSpinner={isUpdatingStore && updatingItemId === item.id}
              noAdditionalIcons={noAdditionalIcons}
            />
          );
        })}
      </StyledCustomizationGrid>
    )
  ) : (
    <div
      ref={placeholderRef}
      style={{ height: placeholderHeightRef.current }}
    />
  );

  return (
    <StoreCategoryGridSectionRoot
      ref={(el) => {
        ref(el);
        sectionRef.current = el;
      }}
      id={id}
      className={className}
      style={{ height: renderedItems.length === 0 ? '100%' : 'auto' }}
    >
      {withOffsets && <Offset />}
      {title &&
        (isDialogItems ? (
          <PersonalitySectionHeader>{title}</PersonalitySectionHeader>
        ) : (
          <StoreCategoryGridSectionHeader>
            {title}
          </StoreCategoryGridSectionHeader>
        ))}
      {grid}
      {withOffsets && <Offset />}
    </StoreCategoryGridSectionRoot>
  );
}

const EmptyGridPlaceholder = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.dimmedFgColor};
  padding: 50px;
  text-align: center;
  font-family: ${(p) => p.theme.fonts.display};

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: auto;
    min-width: 420px;
  }
`;

// Using invisible offsets to trigger viewport observer
// before the section is actually visible
// ...might be overkill though
export const Offset = styled.div`
  position: absolute;
  width: 1px;
  height: 300px;
  top: -300px;
  pointer-events: none;
`;

export const StoreCategoryGridSectionRoot = styled.div`
  position: relative;

  &:last-child {
    padding-bottom: 15px;
  }
`;

export const StoreCategoryGridSectionHeader = styled.h2`
  margin: 0;
  padding: 15px 10px 0;

  font-size: 14px;
  line-height: 18px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    font-size: 20px;
    line-height: 22px;
    padding: 20px 10px 0;
  }
`;

export const PersonalitySectionHeader = styled(StoreCategoryGridSectionHeader)`
  font-size: 18px;
  line-height: 20px;
`;

/* Making sure spacings are in sync */
export const StyledCustomizationGrid = styled(CustomizationGrid)`
  gap: ${spacingStyle.gap}px;
  padding-block: ${spacingStyle.paddingBlock}px 0;
  padding-inline: ${spacingStyle.gridPaddingInline}px;
`;
