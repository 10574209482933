import styled, { keyframes } from 'styled-components/macro';
import { APPEAR_TIMEOUT } from '../DialogLayout/Dialog';

const APPEARING_ANIMATION = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const HIDING_ANIMATION = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const DialogBack = styled.div<{
  $isActive: boolean;
  $withoutBlur?: boolean;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${(p) => p.theme.fullscreenBgColors.modal};
  transition: background 300ms ease-in-out;
  animation: ${(p) => (p.$isActive ? APPEARING_ANIMATION : HIDING_ANIMATION)}
    ${APPEAR_TIMEOUT}ms ease-out both;

  @supports (backdrop-filter: blur(50px)) {
    backdrop-filter: ${(p) => (p.$withoutBlur ? 'none' : 'blur(50px)')};
    background: ${(p) => p.theme.fullscreenBgColors.modalBlurred};
  }
`;
