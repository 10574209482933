import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { useEffect } from 'react';
import { closeDialog } from 'src/actions/ui';
import { getPrices } from '../../../actions/subscriptions';
import CircleSpinner from '../../../components/CircleSpinner';
import { CloseButton } from '../../../components/DialogLayout/ShowcaseDialogLayout';
import StripeProvider from '../../../components/StripeProvider';
import SubscriptionContent from '../../../components/SubscriptionContent';
import { Dialogs, MetricsEvents } from '../../../types/enums';
import { PaidFeaturePopupProps } from '../../../types/states';
import { captureError } from '../../../utils/initSentry';
import useApi from '../../../utils/useApi';
import useBotName from '../../../utils/useBotName';
import useLogEventFirstRender from '../../../utils/useLogEventFirstRender';
import Layout from './Layout';
import getFeaturePopupConfig from './getFeaturePopupConfig';

type Props = {
  onClose: () => void;
} & PaidFeaturePopupProps;

const PaidFeaturePopupDialog = (props: Props) => {
  const {
    onClose,
    onCloseConfirm,
    cause,
    feature,
    newRelationshipStatus,
    closeAfterSuccess,
    onSubscriptionSuccess,
  } = props;
  const dispatch = useDispatch();
  const handleClose = () => {
    onCloseConfirm?.();
    onClose();
  };
  const stripeSubscriptionParams = useApi(
    (state) => state.subscriptions.persist.stripeSubscriptionParams,
    getPrices,
    { memoDeepEqual: true },
  );

  const botName = useBotName();
  const botGender = useSelector((state) => state.profile.persist.bot?.gender);

  const pricesEmpty =
    stripeSubscriptionParams?.available_subscriptions.length === 0;

  useLogEventFirstRender(
    MetricsEvents.PaidFeaturePopupShown,
    {
      feature,
      cause,
    },
    !!feature,
  );

  const stripeEnabled = useSelector(
    (state) => state.subscriptions.stripeEnabled,
  );

  const handleSuccess = () => {
    if (closeAfterSuccess) {
      onSubscriptionSuccess?.();
      onClose();
      return;
    }
  };

  const serverCapabilities = useSelector((state) => state.ws.capabilities);

  const config = getFeaturePopupConfig(botName, botGender);
  const pfpData = config[feature] ?? config['subscription screen'];

  useEffect(() => {
    if (!config[feature]) {
      captureError('PaidFeaturePopupDialog: unknown feature', {
        feature,
        cause,
      });
    }
  }, [cause, config, feature]);

  return (
    <StripeProvider>
      <Layout
        cta={pfpData.cta}
        backgroundUrl={pfpData.placeholder_image}
        videoSrc={pfpData.video}
        bgColor={pfpData.bg_color}
        bgGradient={pfpData.bg_gradient}
        data-testid="subscription-dialog"
      >
        {stripeEnabled || pricesEmpty ? (
          <StyledSubscriptionContent
            title={pfpData.title}
            subtitle={pfpData.cta}
            stripeSubscriptions={
              stripeSubscriptionParams?.available_subscriptions ?? null
            }
            uiParams={{
              background_url: '',
              title_up: '',
              purchase_button_text: 'Unlock Replika PRO',
            }}
            onClose={handleClose}
            newRelationshipStatus={newRelationshipStatus}
            cause={cause}
            spinnerBgColor={pfpData.bg_color}
            header={<CloseButton onClose={handleClose} />}
            paypalDisabled={serverCapabilities.indexOf('paypal') === -1}
            onSuccess={handleSuccess}
            onCancel={() => dispatch(closeDialog(Dialogs.PaidFeaturePopup))}
          />
        ) : (
          <SpinnerContainer>
            <CloseButton onClose={onClose} />
            <StyledCircleSpinner />
          </SpinnerContainer>
        )}
      </Layout>
    </StripeProvider>
  );
};

export default PaidFeaturePopupDialog;

const StyledSubscriptionContent = styled(SubscriptionContent)`
  padding: 50px 20px 20px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding: 64px 67px 26px;
    min-height: 656px;
    max-height: 800px;
  }
`;

const StyledCircleSpinner = styled(CircleSpinner)`
  width: 100px;
  height: 100px;
`;

const SpinnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 700px;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 434px;
  }

  @media ${(p) => p.theme.breakpoints.desktop} {
    width: 474px;
  }
`;
