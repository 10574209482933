import styled from 'styled-components/macro';
import { ZIndices } from '../../types/enums';
import { DialogBack } from './DialogBack';
import DialogContext from '../DialogLayout/DialogContext';
import { ReactNode } from 'react';

type InlineDialogProps = {
  children: ReactNode;
  isActive: boolean;
  onScrollClick?: () => void;
};

export const InlineDialog = ({
  children,
  isActive,
  onScrollClick = () => null,
}: InlineDialogProps) => {
  if (!isActive) return null;

  return (
    <InlineDialogRoot $visible={isActive}>
      <DialogBack id="dialog-back" $isActive={isActive} />
      <DialogContext.Provider
        value={{
          isActive,
          onScrollClick,
        }}
      >
        {children}
      </DialogContext.Provider>
    </InlineDialogRoot>
  );
};

const InlineDialogRoot = styled.div<{ $visible: boolean }>`
  position: fixed;
  inset: 0;
  z-index: ${ZIndices.Dialog};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
